import {base} from './base';

export const sol = base
  .extend('sol')
  // Colors
  .property('primary.color', '#38a389')
  .property('primary.font', 'Anton')

  // Page
  .property('page.background.color', '#fcf5ed')

  // Headings
  .property('heading1.transform', 'uppercase')
  .property('heading2.transform', 'uppercase')
  .property('heading3.transform', 'uppercase')

  // Text
  .property('text.font', 'Acumin Pro')
  .property('text.color', '#38a389')

  // Menu
  .property('menu.background.color', '#fcf5ed')
  .property('menu.text.font', 'Anton')
  .property('menu.text.color', '#38a389')

  // Image
  .property('image.border.radius', '12px')
  .property(
    'image.default',
    'https://images.teemill.com/820qhkkzqquqbimzmpubiyr6jqgrvao7w6blvxjogov7cpik.png.'
  )

  // Buttons
  .property('button.border.radius', '12px')

  // Quote
  .property('quote.text.color', '#FFFFFF')
  .property('quote.fullBackground.color', '#FF8371')

  // AnnouncementV2
  .property('announcement.v2.text.color', '#38a389')
  .property('announcement.v2.background.color', '#FFE283')

  // Newsletter
  .property('newsletter.text.color', '#FFFFFF')
  .property('newsletter.fullBackground.color', '#38a389')

  // Footer
  .property('footer.text.color', '#38a389')
  .property('footer.background.color', '#FCF5ED');
