<script setup lang="ts">
import {watch} from 'vue';
import {fontLoader, generateFontFamily} from '@teemill/common/helpers';

const props = withDefaults(
  defineProps<{
    font: string;
    element?: string;
  }>(),
  {
    font: 'Lato',
    element: 'div',
  }
);

watch(
  () => props.font,
  () => {
    fontLoader([props.font]);
  },
  {immediate: true}
);
</script>

<template>
  <component
    :is="element"
    class="tml-font"
    :style="{
      fontFamily: `${generateFontFamily(props.font)} !important`,
    }"
  >
    <slot />
  </component>
</template>
