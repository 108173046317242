import {PolicyViewerPage} from '@teemill/modules/policy';
import {hasTeemillPaymentProcessor} from '@teemill/common/guards';

export default [
  {
    path: '/terms-of-use/',
    name: 'terms-of-use',
    meta: {
      title: 'Terms of Use',
      layout: 'standard',
      allowPwaReload: true,
      access: [hasTeemillPaymentProcessor],
    },
    component: PolicyViewerPage,
    props: {
      urlName: 'terms-of-use',
    },
  },
  {
    path: '/terms-of-sale/',
    name: 'terms-of-sale',
    meta: {
      title: 'Terms of Sale',
      layout: 'standard',
      allowPwaReload: true,
      access: [hasTeemillPaymentProcessor],
    },
    component: PolicyViewerPage,
    props: {
      urlName: 'terms-of-sale',
    },
  },
  {
    path: '/use-of-cookies-and-disclaimer/',
    name: 'cookie-policy',
    meta: {
      title: 'Use of Cookies & Disclaimer',
      layout: 'standard',
      allowPwaReload: true,
      access: [hasTeemillPaymentProcessor],
    },
    component: () => import('./CookiePolicyPage.vue'),
    props: {
      urlName: 'cookie-policy',
    },
  },
  {
    path: '/privacy-policy/',
    name: 'privacy-policy',
    meta: {
      title: 'Privacy Policy',
      layout: 'standard',
      allowPwaReload: true,
      access: [hasTeemillPaymentProcessor],
    },
    component: PolicyViewerPage,
    props: {
      urlName: 'privacy-policy',
    },
  },
];
