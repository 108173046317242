import type {DirectiveDefinition} from './utilities/directiveHelpers';
import type {ComponentPublicInstance} from 'vue';
import {overlays} from '@teemill/modules/overlays';

import {getContext} from './utilities/directiveHelpers';

/**
 * @name tmlRequestDashboardPermission
 * @description If using composition api you need to defineExpose context binding properties.
 */
export const requestDashboardPermission: DirectiveDefinition = {
  name: 'request-dashboard-permission',
  directive: {
    mounted(el, binding) {
      const context = getContext(binding) as ComponentPublicInstance;

      if (binding.value) {
        let permissions = binding.value;

        if (typeof binding.value === 'string') {
          permissions = permissions.split(',');
        }

        if (el) {
          const missingPerms: string[] = [];

          permissions.forEach((permission: string) => {
            // @ts-expect-error !Deprecated
            if (!context.hasDashboardPermission(permission)) {
              missingPerms.push(permission);
            }
          });

          const requestPerms = missingPerms.join(',');

          if (missingPerms.length) {
            el.addEventListener('click', (e: Event) => {
              e.preventDefault();
              e.stopPropagation();

              overlays.requestDashboardPermission(requestPerms);
            });
          }
        }
      }
    },
  },
};
