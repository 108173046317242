import {App} from 'vue';
import axios, {AxiosError, AxiosInstance} from 'axios';
import {ApiResponse} from '../classes/apiResponse';
import {apiHost} from '@teemill/common/helpers';

export interface VueAxios extends AxiosInstance {
  install(app: App): void;

  isAxiosError(payload: any): payload is AxiosError;

  get(...args: Parameters<typeof axios.get>): ApiResponse;
  put(...args: Parameters<typeof axios.put>): ApiResponse;
  post(...args: Parameters<typeof axios.post>): ApiResponse;
  patch(...args: Parameters<typeof axios.patch>): ApiResponse;
  delete(...args: Parameters<typeof axios.delete>): ApiResponse;
}

const wrap = (method: Function, wrapper: Function) =>
  function wrap(this: any, ...args: any[]) {
    const output = method.bind(this)(...args);

    return wrapper(output) || output;
  };

/**
 * Install Api Response
 */
const wrapped = wrap(
  axios.Axios.prototype.request,
  (promise: Promise<unknown>) =>
    new ApiResponse((resolve, reject) => {
      promise.then(resolve).catch(reject);
    })
);

axios.Axios.prototype.request = wrapped;

const http = axios.create({
  withCredentials: true,
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-CSRF-TOKEN',
  baseURL: `${apiHost()}/omnis/v3`,
  headers: {
    'Content-Type': 'application/json',
  },
}) as VueAxios;

http.install = (app: App): void => {
  app.config.globalProperties.$http = http;
  app.config.globalProperties.axios = http;
  app.config.globalProperties.$axios = http;
};

http.isAxiosError = axios.isAxiosError;

export {http};
