import {ref, reactive, watch} from 'vue';
import {
  WidgetInstance,
  createWidget as createLiveChatWidget,
  MutableCustomerData,
  WidgetState,
  getData,
  CustomerData,
} from '@livechat/widget-core';
import {
  LiveSupportData,
  useNotifications,
} from '@teemill/modules/notifications';
import {useLiveSupport} from '@teemill/modules/notifications';
import {TmlStorage} from '@teemill/common/classes';
import {useWidgetState} from './composables/useWidgetState';
import {LiveChatCustomIdentityProvider} from './types/liveChatCustomIdentityProvider';
import {LiveChatProvider} from './providers/liveChatProvider';
import {LiveChatGroup, liveChatGroupMap} from './liveChatGroupMap';

type OptionsCustomerData = Partial<MutableCustomerData> & {
  sessionVariables?: Record<string, any>;
};

const widget = ref<WidgetInstance>();

const widgetState = useWidgetState();

const liveChatState = reactive<{
  notified: boolean;
  activeGroup?: string;
  groupHasChanged: boolean;
}>({
  notified: false,
  activeGroup: undefined,
  groupHasChanged: false,
});

const customerData = ref<OptionsCustomerData>();
const identityProvider = ref<LiveChatCustomIdentityProvider>();

const init = (options?: {
  customerData?: OptionsCustomerData;
  group?: LiveChatGroup;
  identityProvider?: LiveChatCustomIdentityProvider;
}) => {
  destroy();

  liveChatState.activeGroup =
    (TmlStorage.local.get('live-chat-group') as string) ||
    liveChatGroupMap[options?.group || 'support'];

  customerData.value = options?.customerData;

  identityProvider.value = options?.identityProvider;

  if (TmlStorage.local.get('live-chat-opened')) {
    widget.value = createWidget(
      liveChatState.activeGroup,
      options?.customerData,
      options?.identityProvider
    );

    widget.value?.init();
  }
};

const startChatting = () => {
  if (!widget.value) {
    widget.value = createWidget(
      liveChatState.activeGroup || liveChatGroupMap['support'],
      customerData.value,
      identityProvider.value
    );

    widget.value.init();
  }

  widget.value.updateVisibility('maximized');
};

const updateGroup = (group: LiveChatGroup) => {
  liveChatState.activeGroup = liveChatGroupMap[group];
  liveChatState.groupHasChanged = true;
};

const notify = async (data: LiveSupportData) => {
  if (liveChatState.notified) return;

  const groupIsOnline =
    (await LiveChatProvider.getGroupStatus(
      liveChatState.activeGroup || liveChatGroupMap['support']
    )) === 'online';

  if (!groupIsOnline || TmlStorage.local.get('live-chat-opened')) {
    return;
  }

  const notification = useLiveSupport().show(data);
  liveChatState.notified = true;

  return notification;
};

const destroy = () => {
  Object.assign(liveChatState, {
    notified: false,
    activeGroup: undefined,
    groupHasChanged: false,
  });
  widget.value?.destroy();
  widget.value = undefined;
};

const createWidget = (
  group: string,
  customerData?: Partial<MutableCustomerData> & {
    sessionVariables?: Record<string, any>;
  },
  identityProvider?: LiveChatCustomIdentityProvider
): WidgetInstance => {
  return createLiveChatWidget({
    license: import.meta.env.VITE_LIVE_CHAT_LICENSE_ID,
    visibility: 'hidden',
    group: group,
    chatBetweenGroups: true,
    customerEmail: customerData?.email,
    customerName: customerData?.name,
    sessionVariables: customerData?.sessionVariables,
    customIdentityProvider: identityProvider
      ? identityProvider(group)
      : undefined,
    onAvailabilityChanged,
    onCustomerStatusChanged,
  });
};

const onAvailabilityChanged = (data: Pick<WidgetState, 'availability'>) => {
  if (data.availability === 'offline') {
    widget.value?.updateVisibility('hidden');
    dismissNotification();
  }
};

const onCustomerStatusChanged = async (data: Pick<CustomerData, 'status'>) => {
  if (data.status !== 'chatting') return;

  const chatId = getData('chat')?.chatId;

  if (!chatId) return;

  const activeWidgetGroup = await LiveChatProvider.getGroup(chatId);

  if (
    activeWidgetGroup !== liveChatState.activeGroup &&
    liveChatState.activeGroup &&
    liveChatState.groupHasChanged
  ) {
    await LiveChatProvider.transferChat(chatId, liveChatState.activeGroup);
    liveChatState.groupHasChanged = false;
  }
};

const dismissNotification = () => {
  const {remove, notifications} = useNotifications();

  const liveSupportNotification = notifications?.find(
    notification => notification.id === 'live-support'
  );

  if (liveSupportNotification) {
    remove(liveSupportNotification);
  }
};

watch(
  () => widgetState.value?.status,
  status => {
    if (status === 'chatting' || status === 'queued') {
      TmlStorage.local.set('live-chat-opened', true);
    }

    if (status === 'browsing') {
      TmlStorage.local.set('live-chat-opened', false);
    }
  }
);

watch(
  () => liveChatState.activeGroup,
  activeGroup => {
    if (!activeGroup) return;

    TmlStorage.local.set('live-chat-group', activeGroup);
  }
);

window.addEventListener('beforeunload', () => {
  if (
    widgetState.value?.status === 'chatting' ||
    widgetState.value?.status === 'queued'
  )
    return;

  TmlStorage.local.set('live-chat-opened', false);
});

export const useLiveChat = () => {
  return {
    init,
    startChatting,
    updateGroup,
    updateSessionVariables: (sessionVariables?: Record<string, string>) =>
      widget.value?.updateSessionVariables(sessionVariables),
    notify,
    destroy,
    dismissNotification,
  };
};
