import {formatUrl} from '@teemill/common/helpers';

import {BlockFactorySource} from '../../classes/blockFactorySource';

export const product = new BlockFactorySource<
  {
    urlName: string;
    name: string;
    image: string;
    price: number;
    salePrice: number;
    manufacturer?: {
      name: string;
      urlName: string;
    };
  },
  {
    limit: number;
    collection: string;
  }
>({
  name: 'product',
  request: async ({limit, collection}) => {
    const divisionId =
      // @ts-expect-error Convert vuex to ts
      $store.state.store?.active?.id || $store.state.subdomain?.division;

    // @ts-expect-error Convert axios to ts
    return $axios
      .get(formatUrl(`/omnis/v3/division/${divisionId}/products/`), {
        params: {
          limit,
          collection,
          sort: 'new-in',
        },
      })
      .success((products: any[]) => products)
      .any(() => [])
      .output() as any;
  },
  parser: rawData =>
    rawData.map(({urlName, name, image, price, salePrice, manufacturer}) => ({
      title: name,
      images: [image],
      href: `/product/${urlName}/`,
      author: manufacturer?.name,
      authorHref: manufacturer
        ? `/creator/${manufacturer.urlName}/`
        : undefined,
      price,
      salePrice,
    })),
  params: {
    limit: 1,
    collection: 'all-products',
  },
});
