<template>
  <tml-new-popup
    name="remill-return-popup"
    title="Return an item"
    layout="standard"
    size="normal"
    height="auto"
  >
    <template v-if="submittedRemill" #content>
      <p class="mb-4">Thank you, we sent you an email with instructions.</p>
    </template>
    <template v-else #content>
      <p>
        You can fill out this form to return any 100% cotton clothing to us from
        any brand, excluding denim and underwear (please make sure the care
        label is still visible on your items). It's free to do, and we pay the
        postage in the UK.
      </p>
      <br />
      <p>
        We'll recover and remanufacture the material into a new product, and
        we'll award you with a coupon that you can use to save money on your
        next purchase.
      </p>
      <p>
        <tml-anchor
          class="text-xs mb-4"
          href="/privacy-policy/"
          title="Link to view our privacy policy"
        >
          Privacy policy
        </tml-anchor>
      </p>
    </template>
    <template v-if="!submittedRemill" #footer>
      <tml-form ref="remillForm" form-name="remill-return">
        <tml-input
          v-model="email"
          placeholder="Email Address"
          type="text"
          field-name="email"
          :validation-rules="{required: true, email: true}"
          @on-submit="onSubmit"
        />
      </tml-form>
      <tml-button
        primary
        fill
        :class="{disabled: !$store.getters['forms/remill-return/isValid']}"
        text="Submit"
        href="#"
        :loading="submitLoading"
        @click="onSubmit"
      />
    </template>
  </tml-new-popup>
</template>
<script>
import {useGtm} from '@teemill/common/services';
import {formatUrl} from '@teemill/common/helpers';
import {tracker} from '@teemill/common/plugins';

export default {
  name: 'RemillReturnPopup',

  data() {
    return {
      email: null,
      submitLoading: false,
      submittedRemill: false,
    };
  },

  mounted() {
    $eventBus.on('anchor-remill-return', this.openRemillReturnPopup);
  },

  unmounted() {
    $eventBus.off('anchor-remill-return', this.openRemillReturnPopup);
  },

  methods: {
    /**
     *
     * @return void
     */
    onSubmit() {
      if (this.submitLoading === false) {
        this.$refs.remillForm.validateAll().then(success => {
          if (success) {
            this.submitLoading = true;

            this.axios
              .post(formatUrl('/omnis/v3/frontend/retex/remill/submit/'), {
                email: this.email,
              })
              .success(data => {
                this.submitLoading = false;
                this.$dialogs.showSnackBar(data);
                if (data.type === 'success') {
                  tracker.conversion('remill-submit');

                  useGtm().trackEvent({
                    event: 'onRemillSubmit',
                  });

                  this.$overlays.close('remill-return-popup');
                  this.submittedRemill = true;
                }
              })
              .any(() => {
                this.submitLoading = false;
                this.$dialogs.showSnackBar({
                  message:
                    "We're sorry, something went wrong. Please try again later.",
                  type: 'error',
                });
              });
          }
        });
      }
    },

    openRemillReturnPopup() {
      this.$overlays.open('remill-return-popup');
    },
  },
};
</script>
