import {merge} from 'lodash';

export default {
  namespaced: true,
  state: () => ({}),

  mutations: {
    install(state, value) {
      state = merge(state, value);
    },

    updateOrCreatePlugin(state, plugin) {
      if (state.plugin[plugin.name]) {
        state.plugin[plugin.name] = Object.assign(
          state.plugin[plugin.name],
          plugin
        );
      } else {
        state.plugin[plugin.name] = plugin;
      }
    },
  },

  getters: {
    logo(state) {
      return state.company?.logo;
    },

    getGlobalTrackingInfo(state) {
      return state.tracking;
    },

    getPlugin: state => pluginName => {
      if (pluginName && state.plugin) {
        return state.plugin[pluginName];
      }
      return null;
    },

    getPluginTrackingInfo(state, getters) {
      const trackingInfo = {};

      const subdomainGoogleAdWordsPlugin = getters.getPlugin('marketing');
      const subdomainFacebookPixelPlugin = getters.getPlugin(
        'facebook-tracking-pixel'
      );
      const subdomainGoogleAnalyticsPlugin = getters.getPlugin('analytics');
      const subdomainPinterestPlugin = getters.getPlugin('pinterest');

      if (subdomainGoogleAdWordsPlugin) {
        trackingInfo.subdomainAdWordsId =
          subdomainGoogleAdWordsPlugin.aw_conversion_id
            ? subdomainGoogleAdWordsPlugin.aw_conversion_id.split('/')[0]
            : undefined;
        trackingInfo.subdomainAdWordsConversionId =
          subdomainGoogleAdWordsPlugin.conversion_id;
        trackingInfo.subdomainAdWordsConversionLabel =
          subdomainGoogleAdWordsPlugin.conversion_label;
      }

      if (subdomainFacebookPixelPlugin) {
        trackingInfo.subdomainFbPixel = {
          id: subdomainFacebookPixelPlugin.pixel,
          accessToken: subdomainFacebookPixelPlugin.access_token,
        };
      }

      if (subdomainGoogleAnalyticsPlugin) {
        trackingInfo.subdomainGAId =
          subdomainGoogleAnalyticsPlugin.ga_tracking_id;
        trackingInfo.subdomainGAMeasurementId =
          subdomainGoogleAnalyticsPlugin.measurement_id;
      }

      if (subdomainPinterestPlugin) {
        trackingInfo.subdomainPinterestPixel = subdomainPinterestPlugin.pixel;
      }

      return trackingInfo;
    },

    currency: state => state.currency,
  },
};
