<template>
  <component
    :is="href ? 'tml-anchor' : 'div'"
    class="tml-new-pod relative flex flex-col overflow-hidden"
    :href="disabled ? '#' : href"
    :download="disabled ? '#' : download"
    :target="href ? target : undefined"
    :class="{
      faded,
      'cursor-pointer': hasHref || $attrs.onClick,
      'hover-effect': useHoverEffect,
    }"
  >
    <div
      class="card"
      :class="{disabled}"
      :style="{border: 'none', backgroundColor: 'transparent'}"
    >
      <div
        class="media overflow-hidden"
        :style="{borderRadius: getBorderRadius}"
      >
        <slot>
          <tml-icon-overlay
            :icon="overlayIcon"
            @click="$emit('overlay-icon-clicked')"
          >
            <tml-overlay
              class="h-full"
              :colors="overlayColors"
              :opacity="overlayOpacity"
              :direction="overlayDirection"
            >
              <tml-image
                v-if="image"
                class="image h-full"
                :src="image"
                :src-set="imageSrcSet"
                :alt="altText || title || copy || 'pod'"
                :ratio="ratio"
                no-min-height
                :lazy-load="lazyLoad"
                :style="{
                  backgroundSize: 'cover',
                  backgroundPositionX: 'center',
                }"
              />
              <tml-youtube-video
                v-else-if="video"
                width="100%"
                height="100%"
                :video-id="video"
                :ratio="ratio"
                :lazy-load="lazyLoad"
                :show-controls="!Boolean(autoplayInBackground)"
                :is-background="Boolean(autoplayInBackground)"
                :autoplay="Boolean(autoplayInBackground)"
                :loop="Boolean(autoplayInBackground)"
              />
              <tml-placeholder
                v-else
                :aspect-ratio="1 / ratio"
                :border-radius="borderRadius"
                :background-color="backgroundColor"
                :href="disabled ? '#' : href"
                :download="disabled ? '#' : download"
              />
            </tml-overlay>
          </tml-icon-overlay>
        </slot>
      </div>

      <div
        v-if="hasOverlayContent"
        class="card-img-overlay flex"
        :class="overlayAlignAttributes"
      >
        <div v-if="badge" class="badge">
          {{ badge }}
        </div>
        <div
          v-if="icon"
          class="w-full flex flex-col max-h-full"
          :class="align.iconHorizontal"
          :style="{
            filter: iconShadow,
            fontSize: getIconSize,
          }"
        >
          <font-awesome-icon
            class="max-h-full"
            :icon="icon"
            :size="icon[0] === 'fak' ? '1x' : undefined"
            :style="{
              color: iconColor,
            }"
            :spin="iconSpin"
          />
        </div>
        <component
          :is="getTitleTag"
          v-if="overlay && title"
          class="!m-0 w-full float-left"
          :class="{'ellipsis-overflow': title && copy}"
          :style="{
            textShadow: getTextShadow,
            textAlign: horizontalAlignment || 'center',
            color:
              textColor ||
              (!video && !image ? theme().get('text.color') : '#fff'),
          }"
        >
          {{ title }}
        </component>
        <component
          :is="getTitleTag"
          v-if="overlay && price"
          class="price !m-0 w-full"
          :style="{
            textAlign: horizontalAlignment || 'center',
          }"
        >
          <tml-product-price
            :style="{
              textShadow: getTextShadow,
            }"
            :price="price"
            :sale-price="salePrice"
            :stack-on-mobile="false"
            class="inline-block"
          />
        </component>
        <p
          v-if="overlay && copy"
          class="max-w-full"
          :class="{'ellipsis-overflow': title && copy}"
          :style="{textShadow: getTextShadow, color: getTextColor || '#fff'}"
        >
          {{ copy }}
        </p>
        <tml-button
          v-if="buttonText"
          :text="buttonText"
          :href="disabled ? '#' : href"
          v-bind="{
            small: true,
            noFullWidth: true,
            fill: false,
            ...buttonStyles,
          }"
          @click="$emit('click')"
        />
      </div>
    </div>

    <div
      v-if="hasFloatContent"
      class="inline-block text-break px-0 py-1"
      :class="{disabled}"
      :style="{
        textAlign: horizontalAlignment || 'left',
      }"
    >
      <slot name="title" />
      <component
        :is="getTitleTag"
        v-if="title"
        class="!m-0 pod-title"
        :style="{color: getTextColor, textShadow: getTextShadow}"
      >
        {{ title }}
      </component>
      <slot name="after-title" />
      <component
        :is="getTitleTag"
        v-if="price"
        class="price !m-0"
        :style="{textShadow: getTextShadow, fontWeight: 100}"
      >
        <tml-product-price
          v-if="price"
          :price="price"
          :sale-price="salePrice"
          :stack-on-mobile="false"
          class="inline-block"
        />
      </component>
      <p
        v-if="copy"
        :class="{['line-clamp-10']: maxDevice('md')}"
        :style="{color: getTextColor, textShadow: getTextShadow}"
      >
        {{ copy }}
      </p>

      <div v-if="buttonText" :class="{'mx-3': buttonStyles?.fill}">
        <tml-button
          class="mt-2"
          :text="buttonText"
          :href="disabled ? '#' : href"
          v-bind="{
            small: true,
            noFullWidth: true,
            fill: false,
            ...buttonStyles,
          }"
          @click="$emit('click')"
        />
      </div>
    </div>
  </component>
</template>

<script>
import {maxDevice} from '@teemill/common/helpers';
import TmlIconOverlay from '../overlay-containers/TmlIconOverlay.vue';
import TmlOverlay from '../effects/TmlOverlay.vue';
import TmlImage from '../images/TmlImage.vue';
import {default as overlayable} from '../../mixins/overlayable';

export default {
  name: 'TmlNewPod',

  compatConfig: {
    INSTANCE_LISTENERS: false,
  },

  components: {
    TmlIconOverlay,
    TmlOverlay,
    TmlImage,
  },

  mixins: [overlayable],

  inject: ['theme'],

  props: {
    /**
     * Text
     */
    title: String,
    altText: String,
    copy: String,
    textColor: String,
    textShadow: String,
    price: Number,
    salePrice: Number,
    badge: String,
    priceSuffix: String,

    titleSize: {
      type: Number,
      validator: size => [0, 1, 2, 3, 4].includes(size),
      default: 1,
    },

    /**
     * Button
     */
    buttonText: String,
    buttonStyles: {
      type: Object,
      default: () => ({}),
      validator: options => {
        const availableOptions = [
          'textColor',
          'backgroundColor',
          'borderColor',
          'borderRadius',
          'fill',
        ];
        return Object.keys(options).every(key =>
          availableOptions.includes(key)
        );
      },
    },

    /**
     * Media
     */
    image: String,
    imageSrcSet: Array,
    video: String,
    videoPlaylist: String,

    media: {
      type: String,
      default: 'image',
    },

    lazyLoad: {
      type: Boolean,
      default: true,
    },

    // embed autoplay video without sound or control options
    autoplayInBackground: {
      type: [Number, Boolean],
      default: 0,
    },

    /**
     * Icon
     */
    icon: [Object, Array],
    iconColor: String,
    iconSize: Number,
    iconShadow: String,
    iconSpin: Boolean,
    borderRadius: String,

    overlayIcon: Object,

    /**
     * Layout
     */
    height: String,
    horizontalAlignment: String,
    verticalAlignment: String,

    iconHorizontalAlignment: {
      type: String,
      default: 'center',
    },
    // not applicable in overlay style when text is also present
    iconVerticalAlignment: {
      type: String,
      default: 'center',
    },

    ratio: {
      type: Number,
      default: 1,
    },

    overlay: {
      type: [Number, Boolean],
      default: 0,
    },

    /**
     * Other
     */
    href: String,
    download: String,
    target: String,
    disabled: Boolean,
    faded: Boolean,
    backgroundColor: String,
  },

  computed: {
    useHoverEffect() {
      return (
        (this.hasHref || this.$attrs.onClick) && !this.overlay && !this.disabled
      );
    },

    hasHref() {
      return this.href && this.href !== '#';
    },

    hasText() {
      return (
        this.title ||
        this.copy ||
        this.price ||
        this.salePrice ||
        this.buttonText
      );
    },

    hasOverlayContent() {
      return (this.overlay && this.hasText) || this.icon || this.badge;
    },

    hasFloatContent() {
      return (
        !this.overlay && (this.hasText || this.$slots.title || this.buttonText)
      );
    },

    getTitleTag() {
      let tagNum = 6 - this.titleSize;

      if (this.overlay) {
        if (this.titleSize !== 0) {
          tagNum -= 1;
        }

        if (this.maxDevice('md')) {
          tagNum += 1;
        }
      }

      tagNum = Math.min(tagNum, 6);
      tagNum = Math.max(tagNum, 1);

      return `h${tagNum}`;
    },

    getIconSize() {
      if (!this.iconSize) {
        return '2em';
      }

      let size = this.iconSize;
      if (!this.maxDevice('md')) {
        size += 1;
      }

      return `${Math.max(size * 1.1, 1.25)}rem`;
    },

    getBorderRadius() {
      return this.borderRadius || this.theme().get('image.border.radius');
    },

    getTextColor() {
      return this.textColor || this.theme().get('text.color');
    },

    getTextShadow() {
      return this.textShadow || this.theme().get('text.shadow');
    },

    //class attributes for alignment values
    align() {
      return {
        textVertical: this.flexVerticalAlign(this.verticalAlignment),
        textHorizontal: this.flexHorizontalAlign(this.horizontalAlignment),
        iconVertical: this.flexVerticalAlign(this.iconVerticalAlignment),
        iconHorizontal: this.flexHorizontalAlign(this.iconHorizontalAlignment),
      };
    },

    overlayAlignAttributes() {
      if (this.icon && (!this.overlay || !this.hasText)) {
        return `${this.align.textHorizontal} ${this.align.iconVertical}`;
      }

      return `${this.align.textHorizontal} ${this.align.textVertical}`;
    },
  },

  methods: {
    maxDevice,

    flexVerticalAlign(value, flexRow = false) {
      const prefix = flexRow ? 'items-' : 'justify-';
      switch (value) {
        case 'top':
          return `${prefix}start`;

        default:
        case 'center':
          return `${prefix}center`;

        case 'bottom':
          return `${prefix}end`;
      }
    },

    flexHorizontalAlign(value, flexRow = false) {
      const prefix = flexRow ? 'justify-' : 'items-';

      switch (value) {
        case 'left':
          return `${prefix}start`;

        default:
        case 'center':
          return `${prefix}center`;

        case 'right':
          return `${prefix}end`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tml-new-pod {
  color: var(--tml-text-color);

  &.hover-effect:hover {
    .pod-title {
      color: var(--tml-primary-color) !important;
    }
  }

  .card-img-overlay {
    overflow: hidden;
    padding: 10%;
    gap: 0.5em;
    /** wrap contents that don't fit in a hidden column */
    flex-flow: column wrap;
    column-gap: 20%;

    .badge {
      position: absolute;
      top: 2.5em;
      right: 0;
      padding: 0.2em 0.5em;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      background-color: var(--tml-primary-color);
      color: #fff;
      font-size: 0.875rem;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .price {
    font-weight: 100;
  }

  @supports ((display: -webkit-box) and (-webkit-line-clamp: 1)) {
    .line-clamp-10 {
      display: -webkit-box;
      -webkit-line-clamp: 10;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

.faded {
  filter: grayscale(1);
  opacity: 0.5;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  > * {
    pointer-events: none;
  }
}
</style>
