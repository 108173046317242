import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const newsletterSubscribe = new BlockType({
  name: 'newsletterSubscribe',
  title: 'Newsletter Sign-up',
  compatibility: ['page'],
  component: () =>
    import(
      /* webpackChunkName: "NewsletterSubscribe" */ './NewsletterSubscribe.vue'
    ),
  builder: () =>
    BlockBuilder.create('newsletterSubscribe')
      .size(6)
      .property('title', "Don't Miss Out")
      .property(
        'copy',
        'Stay up to date on our latest product launches, offers and store updates. Subscribe to our newsletter now.'
      )
      .property('font', 'Lato')
      .property('backgroundColor', '#fff')
      .property('mailingList', undefined),
});
