<template>
  <tml-announcement-v2
    :icon="block.property('icon', 'json')"
    :text="block.property('text', 'string', null, bindingData)"
    :text-color="block.property('textColor', 'string')"
    :text-shadow="getTextShadow"
    :action="block.property('action', 'json', {})"
    :action-style="block.property('actionStyle', 'string', 'none')"
    :button-border-radius="
      block.property('buttonBorderRadius', 'string', '4px')
    "
    :alignment="block.property('alignment', 'string', 'left')"
    :border-radius="block.property('borderRadius', 'string', '4px')"
    :background-color="block.property('backgroundColor', 'string')"
    :overlay-colors="gradient.colors"
    :overlay-opacity="gradient.opacity"
  />
</template>
<script>
import {isTransparent} from '@teemill/utilities';

import {TmlAnnouncementV2} from '@teemill/components';
import blockMixin from '../blockMixin.js';

export default {
  name: 'PageBlockAnnouncementBlockV2',

  components: {
    TmlAnnouncementV2,
  },

  mixins: [blockMixin],

  computed: {
    gradient() {
      const start = this.block.property('overlayColorStart', 'string', '#0000');
      const end = this.block.property('overlayColorEnd', 'string', '#0000');

      const hasGradientColors = !isTransparent(start) || !isTransparent(end);

      return {
        colors: `${start}, ${end}`,
        opacity: hasGradientColors ? 1 : 0,
      };
    },
  },
};
</script>
