<template>
  <tml-bubble
    v-if="favourites.enabled"
    :icon="faHeart"
    icon-size="1.5em"
    :color="theme().get('menu.text.color')"
    bg-color="#fff0"
    aria-label="View favourites"
    @click="$router.push('/favourites/')"
  />
</template>

<script>
import {faHeart} from '@fortawesome/pro-light-svg-icons';
import {favourites} from '@teemill/common/classes';
import {TmlAnchor} from '@teemill/components';

export default {
  name: 'FavouritesHeaderItem',

  inject: ['theme'],

  data() {
    return {
      faHeart,

      favourites,

      TmlAnchor,
    };
  },

  mounted() {
    this.$nextTick(() => {
      if (this.favourites.shouldSeeConfetti) {
        this.showAppearConfetti();

        this.favourites.shouldSeeConfetti = false;
      }
    });
  },

  methods: {
    showAppearConfetti() {
      this.$dialogs.showConfetti({
        target: this.$el,
        spread: 360,
        startVelocity: 1.7,
        gravity: 0.2,
        scalar: 0.45,
        colors: [this.theme().get('menu.text.color')],
        decay: 0.9,
        ticks: 100,
        particleCount: 25,
        shapes: ['circle'],
      });
    },
  },
};
</script>
