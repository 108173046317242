import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const action = new BlockType({
  name: 'action',
  title: 'Action',
  compatibility: ['page'],
  component: () =>
    import(/* webpackChunkName: "TmlDashboardPage" */ './ActionBlock.vue'),
  builder: () =>
    BlockBuilder.create('action')
      .property('title', '')
      .property('copy', '')
      .property('icon', '')
      .property('href', '')
      .property('ratio', 'square'),
});
