import {http, useGtm} from '@teemill/common/services';
import {formatUrl} from '@teemill/common/helpers';
import {useNotifications, useAddToBag} from '@teemill/modules/notifications';
import {Store} from 'vuex';
import {Router} from 'vue-router';

interface CartItem {
  key: string;
  quantity: number;
  name: string;
  priceIncTax: number;
  image: string;
  options: {
    Colour: string;
    Size: string;
  }[];
  productUuid: string;
}

export const onReceiveAddToCartMessage = async (
  event: MessageEvent,
  store: Store<any>,
  router: Router
) => {
  if (event.data.data.variantId && event.data.data.items) {
    sendReplyMessage(
      event,
      'failed',
      'variantId and items cannot be used together'
    );
    return;
  }

  if (
    event.data.data.variantId &&
    typeof event.data.data.variantId !== 'string'
  ) {
    sendReplyMessage(event, 'failed', 'variantId must be a string if provided');
    return;
  }

  if (event.data.data.items && !Array.isArray(event.data.data.items)) {
    sendReplyMessage(event, 'failed', 'items must be an array if provided');
    return;
  }

  const existingCartItems = store.getters['cart/getItems'];
  let response = null;

  if (event.data.data.variantId) {
    response = await store.dispatch('cart/addItem', {
      item: {
        variantId: event.data.data.variantId,
      },
      name: 'main',
    });
  }

  if (event.data.data.items) {
    response = await store.dispatch('cart/bulkAddItems', {
      items: event.data.data.items,
      name: 'main',
    });
  }

  sendReplyMessage(
    event,
    response.message || (Array.isArray(response) && !response.length)
      ? 'failed'
      : 'success',
    response.message
  );

  afterAddToCart(existingCartItems);

  function sendReplyMessage(
    event: MessageEvent,
    status: 'failed' | 'success',
    message?: string
  ) {
    Array.from(document.getElementsByTagName('iframe')).forEach(iframe => {
      let iframeOrigin = null;

      try {
        iframeOrigin = new URL(iframe.src).origin;
      } catch (e) {
        return;
      }

      if (event.origin === iframeOrigin && iframe.contentWindow) {
        iframe.contentWindow.postMessage(
          {
            name:
              status === 'success'
                ? 'add-to-cart-complete'
                : 'add-to-cart-failed',
            ...(message && {message}),
          },
          event.origin
        );
      }
    });
  }

  function afterAddToCart(
    existingCartItems: {
      key: string;
      quantity: number;
    }[]
  ) {
    http
      .get(formatUrl('/omnis/v3/basket/main/list/'))
      .success((data: {items: CartItem[]}) => {
        const addedItems = data.items
          .map(item => {
            const existingItem = existingCartItems.find(
              i => i.key === item.key
            );

            if (!existingItem) {
              return item;
            }

            const quantityDifference = item.quantity - existingItem.quantity;
            return quantityDifference > 0
              ? {...item, quantity: quantityDifference}
              : null;
          })
          .filter((item): item is CartItem => item !== null);

        if (!addedItems.length) {
          return;
        }

        showAddedToBagNotification(addedItems);

        sendAddToCartEvent(addedItems);
      });
  }

  function showAddedToBagNotification(items: CartItem[]) {
    const {handle, notifications, removeAll} = useNotifications();

    const subscribeOfferNotification = notifications.find(
      notification => notification.id === 'subscriber-offer-discount'
    );

    if (subscribeOfferNotification) {
      handle(subscribeOfferNotification, {
        href: 'action://plugins/subscriber-offer/dismiss',
      });
    }

    removeAll();

    const {setRouter, show} = useAddToBag();
    setRouter(router);

    show({
      items: items.map(item => ({
        name: item.name,
        price: item.priceIncTax,
        image: item.image,
        options: [
          {
            color: item.options[0].Colour,
            size: item.options[0].Size,
          },
        ],
        quantity: item.quantity,
      })),
      bagCount: store.getters['cart/getQuantity'],
    });
  }

  function sendAddToCartEvent(items: CartItem[]) {
    useGtm().addToCart({
      contentType: 'product',
      contentIds: items.map(item => item.productUuid),
      items: items.map(item => ({
        id: item.productUuid,
        name: item.name,
        brand: JSON.stringify(store.state.subdomain.company.name),
        price: item.priceIncTax,
        currency: store?.state?.subdomain?.currency ?? 'GBP',
        dimension4: `teemill:${store.state.subdomain.divisionName}`,
        quantity: item.quantity,
        options: [
          {
            color: item.options[0].Colour,
            size: item.options[0].Size,
          },
        ],
      })),
      currency: store?.state?.subdomain?.currency ?? 'GBP',
      value: items.reduce(
        (acc, item) => acc + item.priceIncTax * item.quantity,
        0
      ),
      dimension4: `teemill:${store.state.subdomain.divisionName}`,
      userId: store.state.subdomain.sessionId,
    });
  }
};
