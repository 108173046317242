import {base} from './base';

export const tropic = base
  .extend('tropic')
  // Colors
  .property('primary.color', ' #3665ba')
  .property('primary.font', 'Caesar Dressing')

  // Text
  .property('text.font', 'Work Sans')
  .property('text.color', '#08223F')

  // Menu
  .property('menu.background.color', '#D4F9EF')
  .property('menu.text.font', 'Caesar Dressing')
  .property('menu.text.color', '#08223F')

  // Image
  .property('image.border.radius', '12px')
  .property(
    'image.default',
    'https://images.teemill.com/sxzyoqylaq7ll2tjtuhpzlvajdyn6rwxxkuqvzylvej5tdh5.jpeg'
  )

  // Buttons
  .property('button.border.radius', '12px')

  // Features
  .property('feature.border.radius', '12px')

  // Quote
  .property('quote.border.radius', '12px')
  .property('quote.text.color', '#FFFFFF')
  .property('quote.fullBackground.color', '#3665ba')

  // AnnouncementV2
  .property('announcement.v2.text.color', '#08223F')
  .property('announcement.v2.border.radius', '12px')
  .property('announcement.v2.background.color', '#fffb71')

  // Newsletter
  .property('newsletter.text.color', '#08223F')
  .property('newsletter.border.radius', '12px')
  .property('newsletter.fullBackground.color', '#D4F9EF')

  // Footer
  .property('footer.text.color', '#FFFFFF')
  .property('footer.background.color', '#3665ba');
