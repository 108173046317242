import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

import SlideshowBlock from './SlideshowBlock.vue';

export const slideshow = new BlockType({
  name: 'slideshow',
  title: 'Slideshow',
  compatibility: ['page'],
  component: SlideshowBlock,
  builder: () =>
    BlockBuilder.create('slideshow')
      .minSize(12)
      .property('title', '')
      .property('copy', '')
      .property('speed', 5000)
      .property('mode', 'slideshow')
      .property('compact', true)
      .property('primaryActions', 0),
});
