<template>
  <div class="relative h-full w-full px-4 py-2">
    <tml-overlay
      class="!absolute inset-0 overflow-hidden"
      :colors="overlayColors"
      :opacity="overlayOpacity"
      :direction="overlayDirection"
      :style="{
        borderRadius,
        backgroundColor,
      }"
    />
    <div
      class="relative h-full w-full flex flex-wrap items-center"
      :class="horizontalAlignment"
      :style="{gap: '0.5em'}"
    >
      <div
        class="shrink"
        :style="{
          gap: '0.5em',
          flexGrow: maxDevice('md') && actionStyle !== 'none' ? 3 : null,
        }"
      >
        <div v-if="icon || text" class="flex">
          <div class="shrink-0">
            <font-awesome-icon
              v-if="icon"
              :icon="icon"
              class="mr-2"
              :style="{
                color: iconColor || textColor,
              }"
            />
          </div>
          <p class="announcement-text" :style="textStyle" v-html="text" />
        </div>
      </div>

      <div
        v-if="filteredAction"
        class="action shrink-0"
        :class="{grow: maxDevice('md') && actionStyle !== 'none'}"
      >
        <tml-anchor
          v-if="actionStyle === 'none'"
          :href="filteredAction.href"
          :target="filteredAction.target"
          :text="filteredAction.text"
          :style="{
            color: textColor,
            fontWeight: 'bold',
            textDecoration: 'underline !important',
            textShadow,
          }"
        />
        <tml-button
          v-else
          :text="filteredAction.text"
          :href="filteredAction.href"
          :target="filteredAction.target"
          :icon="filteredAction.icon"
          :primary="!!filteredAction.primary"
          :border-radius="buttonBorderRadius"
          :text-color="buttonColor"
          :border-color="buttonColor"
          :fill="maxDevice('md')"
          small
          @click="$emit('click', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {maxDevice} from '@teemill/common/helpers';

import TmlButton from '../buttons/TmlButton.vue';
import TmlAnchor from '../links/TmlAnchor.vue';
import TmlOverlay from '../effects/TmlOverlay.vue';
import {default as overlayable} from '../../mixins/overlayable';

export default {
  name: 'TmlAnnouncementV2',

  components: {
    TmlButton,
    TmlAnchor,
    TmlOverlay,
  },

  mixins: [overlayable],

  props: {
    icon: Object,
    iconColor: String,

    text: String,
    textColor: String,

    backgroundColor: String,

    borderRadius: {
      type: String,
      default: '4px',
    },

    action: {
      type: Object,
    },

    buttonBorderRadius: {
      type: String,
      default: '4px',
    },

    actionStyle: {
      type: String,
      default: 'none',
      validator: style => ['primary', 'secondary', 'none'].includes(style),
    },

    alignment: {
      type: String,
      default: 'center',
      validator: value =>
        ['left', 'center', 'right', 'space-between'].includes(value),
    },

    textShadow: {
      type: String,
      default: 'none',
    },
  },

  computed: {
    textStyle() {
      return {
        '--announcement-text-color': this.textColor,
        '--announcement-text-shadow': this.textShadow,
      };
    },

    horizontalAlignment() {
      switch (this.alignment) {
        case 'left':
          return 'justify-start';
        default:
        case 'center':
          return 'justify-center';
        case 'right':
          return 'justify-end';
        case 'space-between':
          return 'justify-between';
      }
    },

    filteredAction() {
      const action = this.action;

      if (!action) {
        return null;
      }

      if (action.text && (action.href || action.action)) {
        action.primary = this.actionStyle === 'primary';
        return action;
      }

      return null;
    },

    buttonColor() {
      return this.filteredAction.primary ? undefined : this.textColor;
    },
  },

  methods: {
    maxDevice,
  },
};
</script>

<style lang="scss">
.announcement-text,
.announcement-text * {
  color: var(--announcement-text-color) !important;
  text-shadow: var(--announcement-text-shadow) !important;
}
</style>
