import {HexColor} from './colorTypes';
import {formatHex} from './formatHex';

/**
 * Todo: Expand usage to include other colour formats (rgb, hsv...)
 *
 * @name match
 */
export const match = (colorOne: HexColor, colorTwo: HexColor): boolean =>
  formatHex(colorOne).toLowerCase() === formatHex(colorTwo).toLowerCase();
