import {base} from './base';

export const wildWood = base
  .extend('wildWood')
  // Colors
  .property('primary.color', '#496257')
  .property('primary.font', 'Ubuntu')

  // Page
  .property('page.background.color', '#f7f7f7')

  // Text
  .property('text.color', '#3d3d3d')
  .property('text.font', 'Lora')
  .property('text.shadow', 'none')
  .property('text.background.color', '#F7F7F7')
  .property('text.fullBackground.color', '#F7F7F7')

  // Menu
  .property('menu.background.color', '#F7F7F7')
  .property('menu.text.color', '#3D3D3D')
  .property('menu.text.font', 'Lora')

  // Image
  .property('image.border.radius', '12px')
  .property(
    'image.default',
    'https://images.teemill.com/wfglwk5jmk6zi2htnmpgunh64dh9v7qupohtcyh50ehhkzpc.jpg'
  )

  // Banner
  .property('banner.fullBackground.color', '#0000')

  // Button
  .property('button.border.radius', '12px')

  // Quote
  .property('quote.text.color', '#FFFFFF')
  .property('quote.background.color', '#496257')
  .property('quote.fullBackground.color', '#496257')

  // AnnouncementV2
  .property('announcement.v2.text.color', '#FFFFFF')
  .property('announcement.v2.background.color', '#888888')
  .property('announcement.v2.fullBackground.color', '#0000')

  // Newsletter
  .property('newsletter.text.color', '#FFFFFF')
  .property('newsletter.background.color', '#888888')
  .property('newsletter.fullBackground.color', '#888888')

  //Footer
  .property('footer.text.color', '#3d3d3d')
  .property('footer.background.color', '#f7f7f7');
