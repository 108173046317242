import upperFirst from 'lodash/upperFirst';

export default [
  {
    name: 'page',

    get({route}) {
      let text = '';

      const routeInstance = $router.currentRoute.value.matched.find(
        matchedRoute => matchedRoute.name === route.name
      );

      if (
        routeInstance?.name === route.name &&
        routeInstance.instances?.default
      ) {
        text = routeInstance.instances.default.breadcrumbTitle;
      }

      return {
        text: text || route.meta.title || route.name,
        href: route.path,
      };
    },
  },

  {
    name: 'url-param',

    get({route, breadcrumbMeta}) {
      return {
        text: upperFirst(route.params[breadcrumbMeta.param].replace(/-/g, ' ')),
        href: route.path,
      };
    },
  },

  {
    name: 'collection',

    get({store, breadcrumbMeta}) {
      const collection = store.state.collection.active;

      if (collection) {
        return {
          text: collection.title,
          href: `/${breadcrumbMeta.pagePath}/${collection.urlName}/`,
        };
      }

      return null;
    },
  },

  {
    name: 'product',

    get({store}) {
      const product = store.state.product.active;

      if (!product) {
        return null;
      }

      let collection = product.collection;

      const lastVisitedCollection = store.state.collection.lastVisited;

      if (lastVisitedCollection) {
        if (lastVisitedCollection.products.includes(product.id)) {
          collection = lastVisitedCollection;
        }
      }

      const productCrumb = {
        text: product.name,
        href: `/product/${product.urlName}/`,
      };

      return collection
        ? [
            {
              text: collection.title,
              href: `/collection/${collection.urlName}/`,
            },
            productCrumb,
          ]
        : [productCrumb];
    },
  },

  {
    name: 'blog-post',

    get({store}) {
      const post = store.state.blog ? store.state.blog.post : '';
      const breadcrumbs = [];

      if (post) {
        if (post.firstTag) {
          breadcrumbs.push({
            text: upperFirst(post.firstTag.name),
            href: post.firstTag.url,
          });
        }

        breadcrumbs.push({
          text: post.title,
          href: post.url,
        });

        return breadcrumbs;
      }

      return null;
    },
  },

  {
    name: 'blog-tag',

    get({store}) {
      const tag = store.state.blog ? store.state.blog.tag : '';

      if (tag) {
        return {
          text: tag.name,
          href: tag.url,
        };
      }

      return null;
    },
  },

  {
    name: 'plugin',

    get({store}) {
      return [
        {
          text: 'Plugins',
          href: '/plugins/',
        },
        {
          text: store.state.plugin.active?.name,
          href: `/plugins/${store.state.plugin.active?.code || ''}`,
        },
      ];
    },
  },
];
