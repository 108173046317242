import {isBespoke} from '../../access';

export default {
  path: '/get-a-quote/',
  name: 'Get a quote',
  meta: {
    title: 'Get a quote',
    layout: 'standard',
    access: [isBespoke],
    showOnSiteMap: true,
    allowPwaReload: true,
  },
  component: () =>
    import(/* webpackChunkName: "PageQuote" */ './GetAQuote.vue'),
  // prefetchChunks: ['TmlGlobal', 'TmlContainers', 'TmlForms'],
};
