import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

import RowBlock from './RowBlock.vue';

export const row = new BlockType({
  name: 'row',
  title: 'Row',
  compatibility: ['page'],
  component: RowBlock,
  builder: () => BlockBuilder.create('row').property('direction', 'row'),
});
