import {base} from './base';
import {Theme} from '../classes/theme';

export const teemill = base
  .extend('teemill')
  .property('primary.color', '#86b302')
  .property('primary.font', 'Poppins')

  // Page
  .property('page.background.color', '#fff')

  // Text
  .property('text.font', 'Source Sans Pro')
  .property('text.color', '#2c2d2d')
  .property('text.shadow', 'none')
  .property('text.border.radius', '4px')
  .property('text.background.color', '#0000')
  .property('text.fullBackground.color', '#0000')

  // Menu
  .property('menu.background.color', '#fff')
  .property('menu.text.font', 'Source Sans Pro')
  .property('menu.text.color', '#2c2d2d')
  .property('menu.text.transform', 'uppercase')

  // Image
  .property('image.border.radius', '12px')
  .property(
    'image.default',
    'https://images.teemill.com/wn8sribf9hc5ffoer60koletlsowcraemgbludl90lgrn7gc.png.jpg'
  )

  // Image
  .property('video.default', 'R51EkLZ3qYE')

  // Banners
  .property('banner.text.shadow', 'none')
  .property('banner.title.color', '#fff')
  .property('banner.title.background.color', '#0000')
  .property('banner.copy.color', '#fff')
  .property('banner.copy.background.color', '#0000')
  .property('banner.fullBackground.color', '#0000')

  // Buttons
  .property('button.border.radius', '4px')
  .property('button.fullBackground.color', '#0000')

  // Features
  .property('feature.icon.color', '#fff')
  .property('feature.border.radius', '4px')
  .property('feature.background.color', '#0000')
  .property('feature.text.color', Theme.ref('text.color'))
  .property('feature.text.background.color', '#0000')
  .property('feature.fullBackground.color', '#0000')

  // Quote
  .property('quote.text.font', Theme.ref('heading2.font'))
  .property('quote.text.color', Theme.ref('text.color'))
  .property('quote.text.shadow', Theme.ref('text.shadow'))
  .property('quote.border.radius', '4px')
  .property('quote.background.color', '#0000')
  .property('quote.fullBackground.color', '#0000')

  // Announcement
  .property('announcement.text.font', Theme.ref('heading2.font'))
  .property('announcement.text.color', '#fff')
  .property('announcement.text.shadow', Theme.ref('text.shadow'))
  .property('announcement.border.radius', '4px')
  .property('announcement.fullBackground.color', '#0000')

  // Card
  .property('card.text.font', Theme.ref('heading3.font'))
  .property('card.text.color', '#fff')
  .property('card.text.shadow', Theme.ref('text.shadow'))
  .property('card.border.radius', '4px')
  .property('card.fullBackground.color', '#0000')

  // Newsletter
  .property('newsletter.text.color', Theme.ref('text.color'))
  .property('newsletter.title.font', Theme.ref('heading2.font'))
  .property('newsletter.border.radius', '4px')
  .property('newsletter.background.color', '#0000')
  .property('newsletter.fullBackground.color', '#0000')

  // Gate
  .property('gate.text.color', '#fff')
  .property('gate.text.shadow', Theme.ref('text.shadow'))
  .property('gate.border.radius', '4px')
  .property('gate.fullBackground.color', '#0000')

  // Footer
  .property('footer.visible', '1')
  .property('footer.text.color', '#222')
  .property('footer.background.color', '#fff')

  // Forms
  .property('form.border.color', '#c9c9c9')
  .property('form.border.focused.color', Theme.ref('primary.color'))

  // Misc
  .property('box.background.color', '#f4f4f4')
  .property('border.color', '#c9c9c9')

  // Chat
  .property(
    'chat.background.image',
    'https://images.teemill.com/n4a3ulh1lwrcbec8xvhchnlmitcwvthitmptb4u4r3kell7p.jpg.webp'
  )
  .property('chat.header.background.color', Theme.ref('menu.background.color'))
  .property('chat.header.color', '#fff')
  .property('chat.header.shadow', '0px 0px 5px 0px rgba(0, 0, 0, 0.1)')
  .property('chat.input.shadow', '0px 0px 5px 1px rgba(0, 0, 0, 0.1)')
  .property('chat.scrollbar.color', 'rgba(125, 125, 125, 0.5)')
  .property('chat.message.background.color', '#ffffff')
  .property('chat.message.own.color', '#f9ffe8')
  .property('chat.message.focused.color', '#fde4c2')
  .property('chat.message.hidden.color', '#fdecf3')

  /**
   * Status
   */
  .property('status.success.background.color', 'rgb(220, 252, 231)')
  .property('status.success.text.color', 'rgb(22, 101, 52)')
  .property('status.success.border.color', 'rgb(196, 250, 215)');
