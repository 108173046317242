import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const feature = new BlockType({
  name: 'feature',
  title: 'Feature',
  compatibility: ['mail'],
  component: () =>
    import(/* webpackChunkName: "TmlMailPage" */ './FeatureBlock.vue'),
  builder: () =>
    BlockBuilder.create('feature')
      .property('title', '')
      .property('copy', '')
      .property('copyTruncateLength', 0)
      .property('href', '#')
      .property('image', '')
      .property('borderRadius', '0px')
      .property('imageRatio', 1)
      .property('buttonText', 'Link')
      .property('primaryActions', 1),
});
