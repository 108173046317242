import {base} from './base';

export const neonCity = base
  .extend('neonCity')
  // Colors
  .property('primary.color', '#FF0887')
  .property('primary.font', 'Audiowide')

  // Page
  .property('page.background.color', '#fff')

  // Text
  .property('text.font', 'Work Sans')
  .property('text.color', '#000')

  // Menu
  .property('menu.background.color', '#000')
  .property('menu.text.color', '#FF0887')
  .property('menu.text.font', 'Audiowide')

  // Image
  .property('image.border.radius', '0px')
  .property(
    'image.default',
    'https://images.teemill.com/phvxdj8lq9mtoo8vuwd1ao1s1vdzbz6wltalbxvlmvynfpnq.png'
  )

  // Buttons
  .property('button.border.radius', '0px')

  // Banner
  .property('banner.title.background.color', '#000')

  // Quote
  .property('quote.text.color', '#FF0887')
  .property('quote.fullBackground.color', '#000')

  // AnnouncementV2
  .property('announcement.v2.text.color', '#FF0887')
  .property('announcement.v2.background.color', '#000')

  // Newsletter
  .property('newsletter.text.color', '#000')
  .property('newsletter.fullBackground.color', '#69EBE7')

  //Footer
  .property('footer.text.color', '#FF0887')
  .property('footer.background.color', '#000');
