<script setup lang="ts">
import {faBars} from '@fortawesome/pro-light-svg-icons';
import {inject} from 'vue';
import {viewportSize} from '@teemill/common/helpers';
import {ComputedLayoutSections, LayoutSections} from '../types/layoutOptions';

performance.mark('app-header-setup');

const layoutOptions =
  inject<ComputedLayoutSections<LayoutSections>>('layoutOptions');
const layoutToggleSidebar =
  inject<() => void>('layoutToggleSidebar') || (() => {});
</script>

<template>
  <div
    :style="{
      color: 'var(--tml-menu-text-color)',
      backgroundColor: !layoutOptions?.header.overlay
        ? 'var(--tml-menu-background-color)'
        : undefined,
    }"
  >
    <div
      class="grid grid-flow-col auto-rows-fr h-full m-auto transition-all px-0"
      :class="{container: viewportSize.isGreater('lg')}"
      :style="{
        padding: layoutOptions?.content.width !== 'container' ? '0' : undefined,
        height: layoutOptions?.header.height,
        maxWidth: layoutOptions?.header.width,
        gridAutoColumns: `${
          layoutOptions?.header.centerLogo ? '1fr' : 'auto'
        } auto 1fr`,
      }"
    >
      <div
        v-if="layoutOptions?.sidebar.show && !layoutOptions?.sidebar.fixed"
        class="col-start-1 flex items-center cursor-pointer -mr-4"
        @click="
          () => {
            layoutToggleSidebar();
          }
        "
      >
        <div
          class="flex justify-center items-center mx-2 w-10 h-10 rounded-full"
          :style="{
            backgroundColor: 'var(--tml-menu-background-color)',
          }"
        >
          <font-awesome-icon :icon="faBars" class="fa-lg" />
        </div>
      </div>
      <div
        class="col-start-2 px-4"
        :style="{
          maxWidth: layoutOptions?.header.logoMaxWidth || '220px',
        }"
      >
        <slot name="logo" />
      </div>
      <div class="col-start-3 flex items-center pr-4">
        <slot v-if="layoutOptions?.header.content" name="content" />
      </div>
    </div>
  </div>
</template>
