import {bus} from '../bus';

export default (title, text, enable2fa, enableRecoveryCode, actions) =>
  new Promise(resolve => {
    bus.emit('add-dynamic-overlay', {
      props: {
        text,
        title,
        enable2fa,
        enableRecoveryCode,
        actions,
      },
      component: 'tml-confirm-password-popup',
      events: {
        confirm(confirmed) {
          resolve(confirmed);
        },
        cancel() {
          resolve(false);
        },
        'after-leave': () => {
          resolve(false);
        },
      },
    });
  });
