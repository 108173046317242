import {base} from './base';

export const joy = base
  .extend('joy')
  // Colors
  .property('primary.color', '#F4D344')
  .property('primary.font', 'Koulen')

  // Page
  .property('page.background.color', '#FBFBFB')

  // Heading
  .property('heading1.transform', 'uppercase')
  .property('heading2.transform', 'uppercase')

  // Text
  .property('text.font', 'Barlow Condensed')
  .property('text.color', '#515150')

  // Menu
  .property('menu.background.color', '#F3F3F3')
  .property('menu.text.font', 'Barlow Condensed')
  .property('menu.text.color', '#515150')

  // Image
  .property(
    'image.default',
    'https://images.teemill.com/rtik66tfqlzo8vewslliqonxsnva9prlwg2mdqhlf1idhonh.jpeg'
  )

  // Banners
  .property('banner.title.color', '#F4D344')

  // Quote
  .property('quote.text.color', '#3E3F3E')
  .property('quote.fullBackground.color', '#F4D344')

  // AnnouncementV2
  .property('announcement.v2.text.color', '#FFFFFF')
  .property('announcement.v2.background.color', '#F4D344')

  // Newsletter
  .property('newsletter.text.color', '#3E3F3E')
  .property('newsletter.fullBackground.color', '#F4D344')

  // Footer
  .property('footer.text.color', '#515150')
  .property('footer.background.color', '#F3F3F3');
