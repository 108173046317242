export default {
  path: '/order-history/:customerHash',
  name: 'Order History',
  meta: {
    title: 'Order History',
    layout: 'standard',
    allowPwaReload: true,
  },
  component: () =>
    import(/* webpackChunkName: "PageReorder" */ './ReorderPage.vue'),
  // prefetchChunks: ['TmlGlobal', 'TmlContainers', 'TmlForms'],
};
