export default {
  path: '/receipt/:orderHash',
  name: 'Receipt',
  meta: {
    title: 'Receipt',
    layout: 'standard',
    shareToHomepage: true,
    allowPwaReload: true,
  },
  component: () => import('./StoreFrontReceiptPage.vue'),
};
