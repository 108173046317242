export default {
  /**
   * Get a list of the registered overlay elements
   *
   * @param { Store } state Vuex module state
   */
  registeredElements: state => state.elements,

  /**
   * Check if an overlay element is open by name
   *
   * @param { Store }  state   Vuex module state
   * @param { String } element The element name
   */
  isOpen: state => element => !!state.elements[element],

  /**
   * Get a list of open overlay elements
   *
   * @param { Store } state Vuex module state
   */
  openElements: state =>
    Object.keys(state).filter(
      elementName => state.elements[elementName] === true
    ),
};
