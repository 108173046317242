import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const gate = new BlockType({
  name: 'gate',
  title: 'Gate',
  compatibility: ['page'],
  component: () =>
    import(/* webpackChunkName: "GateBlock" */ './GateBlock.vue'),
  builder: () => BlockBuilder.create('gate'),
});
