import type {RouteLocation, RouteLocationNormalized, Router} from 'vue-router';

import type {Store} from 'vuex';

export interface AccessStatementResult {
  statement: AccessStatement | null;
  passed: boolean;
}

type Route = RouteLocation | RouteLocationNormalized;

type AccessHandler = (
  store: Store<any>,
  route?: Route,
  project?: string
) => boolean;

type RedirectHandler = (
  store: Store<any>,
  route: Route,
  router: Router
) => string;

export class AccessStatement {
  public static debug = false;
  public static store: Store<unknown>;
  public static router: Router;

  public name: string;

  private _handler: AccessHandler;
  private _redirect?: RedirectHandler | string;
  private children?: AccessStatement[];

  public constructor(
    name: string,
    handler: AccessHandler,
    redirect?: RedirectHandler | string,
    children?: AccessStatement[]
  ) {
    this.name = name;
    this._handler = handler;

    this.children = children;
    this._redirect = redirect;
  }

  public static run(
    statements: AccessStatement[] = [],
    route?: Route,
    project?: string
  ): AccessStatementResult {
    const result: AccessStatementResult = {
      statement: null,
      passed: true,
    };

    result.passed = statements.every(statement => {
      if (statement instanceof AccessStatement) {
        const statementResult = statement.run(route, project);

        result.statement = statementResult.statement;
        result.passed = statementResult.passed;
      }

      if (statement instanceof Array) {
        const groupResult = AccessStatement.run(statement, route, project);

        result.statement = groupResult.statement;
        result.passed = groupResult.passed;
      }

      return result.passed;
    });

    return result;
  }

  public run(route?: Route, project?: string): AccessStatementResult {
    if (!route) {
      route = AccessStatement.router.currentRoute.value;
    }

    const childrenResult = AccessStatement.run(this.children, route, project);

    if (!childrenResult.passed) {
      return childrenResult;
    }

    const result = !!this._handler(AccessStatement.store, route, project);

    if (AccessStatement.debug) {
      // eslint-disable-next-line
      console.log(`${result ? 'Passed' : 'Failed'} ${this.name}`);
    }

    return {
      statement: this,
      passed: result,
    };
  }

  public redirect(route: Route): string {
    switch (typeof this._redirect) {
      case 'string':
        return this._redirect;

      case 'function':
        return this._redirect(
          AccessStatement.store,
          route,
          AccessStatement.router
        );

      default:
        return '';
    }
  }

  public get passes(): boolean {
    return this.run().passed;
  }

  public get fails(): boolean {
    return !this.passes;
  }
}
