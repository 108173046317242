import {bus} from '../bus';

export default product =>
  new Promise(resolve => {
    bus.emit('add-dynamic-overlay', {
      props: {
        product,
      },
      component: 'tml-select-product-image-popup',
      events: {
        selected(result) {
          resolve(result);
        },
        close() {
          resolve(false);
        },
      },
    });
  });
