// @ts-expect-error No types
import Cookie from 'vue-cookie';
import {merge} from 'lodash';

export default {
  install(state, value) {
    state = merge(state, value, true);
  },

  markAsDiscovered(state, splitTestId) {
    const foundSplitTest = state.splitTests
      .filter(splitTest => splitTest)
      .find(splitTest => splitTest.code === splitTestId);

    foundSplitTest.discovered = true;

    let cookiesplitTests = JSON.parse(Cookie.get('discovered-split-tests'));

    if (!cookiesplitTests) {
      cookiesplitTests = {};
    }

    cookiesplitTests[foundSplitTest.id] = foundSplitTest.variation.id;

    Cookie.set('discovered-split-tests', JSON.stringify(cookiesplitTests));
  },
};
