import {App, LayoutSections} from '@teemill/modules/app';
import {maxDevice} from '@teemill/common/helpers';

export const mailConfig: LayoutSections = {
  announcement: false,
  header: {
    show: true,
    overlay: false,
    fixed: true,
    logo: true,
    logoMaxWidth: '220px',
    centerLogo: false,
    content: false,
    width: '520px',
    height: () => (maxDevice('md') ? '3.5rem' : '4rem'),
  },
  sidebar: false,
  content: {
    show: true,
    width: () => (maxDevice('md') ? 'container' : '520px'),
  },
  footer: {show: () => App.get('subdomain').isNative},
  subFooter: {show: () => App.get('subdomain').isNative},
  navBar: false,
};
