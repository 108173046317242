export default {
  REGISTER_ELEMENT: 'REGISTER_ELEMENT',
  OPEN_ELEMENT: 'OPEN_ELEMENT',
  CLOSE_ELEMENT: 'CLOSE_ELEMENT',
  TOGGLE_ELEMENT: 'TOGGLE_ELEMENT',
  CLOSE_ALL_ELEMENTS: 'CLOSE_ALL_ELEMENTS',
  ADD_TO_STACK: 'ADD_TO_STACK',
  REMOVE_FROM_STACK: 'REMOVE_FROM_STACK',
  EMPTY_STACK: 'EMPTY_STACK',
};
