import {App, Plugin} from 'vue';
import {Router} from 'vue-router';
import {init, setTag} from '@sentry/vue';
import isbot from 'isbot';

export const sentry: Plugin = {
  install(
    app: App,
    {router, version}: {router: Router; version: string}
  ): void {
    if (import.meta.env.VITE_SENTRY_DSN === undefined) {
      console.warn('No sentry dsn configured');
      return;
    }

    setTag('sw_version', 'v1');

    setTag('bot', isbot(navigator.userAgent) ? 'yes' : 'no');

    init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      environment: import.meta.env.PROD ? 'production' : 'development',
      release: version,
      ignoreErrors: [
        /Network Error/,
        /Failed to fetch/,
        /newestWorker is null/,
        /Importing a module script failed\./,
        /Unable to preload CSS for/,
        /An attempt was made to use an object that is not, or is no longer, usable/,
        /Failed to update a ServiceWorker for scope/,
      ],
      beforeSend: (event, hint) => {
        try {
          const exceptions = event.exception?.values || [];
          const firstException = exceptions[0];
          const firstExceptionStack = firstException?.stacktrace?.frames || [];

          /**
           * Exclude errors from unknown sources.
           */
          const firstTraceExclusions = [
            /<anonymous>/,
            /www\.paypal\.com\/sdk\/js/,
            /js\.stripe\.com\/v3/,
          ];
          if (
            firstTraceExclusions.some(m =>
              firstExceptionStack[0]?.filename?.match(m)
            )
          ) {
            return null;
          }

          return event;
        } catch (error) {
          /**
           * If exclusion filtering fails send error anyway.
           */
          return event;
        }
      },
    });
  },
};
