import {usePageLeave} from '@vueuse/core';
import {ref, watch, computed} from 'vue';
import {
  useNotifications,
  NotificationPosition,
} from '@teemill/modules/notifications';
import {useGtm} from '@teemill/common/services';
import {tracker} from '@teemill/common/plugins';
import {hasTouchScreen} from '@teemill/utilities';
import Cookies from 'js-cookie';

export interface SubscriberOfferData {
  title: string;
  body: string;
  image: string;
  mailingList: number;
  buttonText: string;
  division: number;
}

const getOfferState = () => {
  return Cookies.get('subscriber-offer');
};

const hasReceivedOffer = () => {
  return getOfferState() !== undefined;
};

const show = (offerData: SubscriberOfferData) => {
  const hasLeftScreen = usePageLeave();
  const sessionStart = ref(new Date().valueOf());
  const {create, removeAll, notifications} = useNotifications();

  const otherNotificationShowing = computed(() => {
    return notifications.length > 0;
  });

  const showSubscriberOffer = ({
    title,
    body,
    image,
    mailingList,
    buttonText,
    division,
  }: SubscriberOfferData) => {
    removeAll();

    create({
      id: 'subscriber-offer-discount',
      title: title,
      body: body,
      image: image,
      position: NotificationPosition.TOPRIGHT,
      template: 'Shove',
      form: {
        fields: {
          email: {
            type: 'text',
            rules: {required: true, email: true},
          },
          firstName: {
            type: 'text',
            rules: {required: true},
          },
        },
        actions: [
          {
            text: buttonText,
            href: 'action://plugins/subscriber-offer/subscribe',
          },
        ],
      },
      data: {
        division,
        mailingList,
      },
      dismissAction: {
        href: 'action://plugins/subscriber-offer/dismiss',
      },
      footerAction: {
        text: 'Privacy policy',
        href: '/privacy-policy/',
      },
    });

    useGtm().trackEvent({event: 'onSubscribeOfferView'});
    tracker.event('subscriber-offer-view');
  };

  if (
    hasTouchScreen() &&
    !hasReceivedOffer() &&
    !otherNotificationShowing.value
  ) {
    setTimeout(() => {
      showSubscriberOffer(offerData);
    }, 30_000);
  } else {
    watch(
      () => hasLeftScreen.value,
      () => {
        if (
          // Check they have not previously dismissed or accepted
          // the offer on the current device
          !hasReceivedOffer() &&
          hasLeftScreen.value === true &&
          new Date().valueOf() - sessionStart.value > 30_000 &&
          !otherNotificationShowing.value
        ) {
          showSubscriberOffer(offerData);
        }
      }
    );
  }
};

export function useOffer() {
  return {
    show,
    getOfferState,
    hasReceivedOffer,
  };
}
