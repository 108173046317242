import type {DirectiveDefinition} from './utilities/directiveHelpers';

/**
 * Directive to update the document title of the page whenever the bound
 * component renders or is updated
 */
export const DocumentTitle: DirectiveDefinition = {
  name: 'title',
  directive: {
    mounted: (el, binding) => {
      document.title = binding.value;
    },
    updated: (el, binding) => {
      document.title = binding.value;
    },
  },
};
