import {Fragment, VNodeArrayChildren, isVNode, Comment} from 'vue';

/**
 * @name unwrapSlotItems
 * @description Flatten components passed through slots
 */
export const unwrapSlotItems = (
  vNodeArray: VNodeArrayChildren
): VNodeArrayChildren => {
  const unwrapped = vNodeArray.flatMap(vNode => {
    if (Array.isArray(vNode)) {
      return unwrapSlotItems(vNode);
    }

    if (isVNode(vNode) && vNode.type === Fragment) {
      if (Array.isArray(vNode.children) && vNode.children.length) {
        return unwrapSlotItems(vNode.children);
      }
    }

    return vNode;
  });

  return unwrapped.filter(vNode =>
    isVNode(vNode) ? vNode.type !== Comment : vNode
  );
};
