import {merge, castArray} from 'lodash';

import type {TapFunction} from '@teemill/utilities';
import {Vector} from '@teemill/common/classes';

import {BlockFactory} from './blockFactory';
import {BlockFactorySource} from './blockFactorySource';
import {BlockTemplate} from './blockTemplate';
import {Property, PropertySet, PropertyValue} from './property';
import {Block, BlockLike, BlockFlag, BlockFlags} from './block';
import {BlockType, NoBuilderError} from './blockType';

class BuilderTypeNotDefined extends Error {
  constructor() {
    super('Unable to make block, no type was defined');
  }
}

interface BlockData {
  id?: number;
  uid?: string;
  type?: BlockType;
  size: number;
  minSize: number;
  order: number;
  items: BlockBuilder[];
  flags: BlockFlags;
  properties: PropertySet;
}

export class BlockBuilder {
  protected block: BlockData = {
    id: undefined,
    uid: undefined,
    type: undefined,
    size: 12,
    minSize: 3,
    order: Infinity,
    items: [],
    flags: new Set(),
    properties: {},
  };

  constructor(block?: Block) {
    if (block instanceof Block) {
      this.block.id = block.id;
      this.block.uid = block.uid;
      this.block.type = block.type;
      this.block.order = block.order;
      this.block.properties = block.properties;
      this.block.items = block.items.map((i: Block) => new BlockBuilder(i));
      this.block.flags = block.flags;
    }
  }

  get items(): BlockBuilder[] {
    return this.block.items;
  }

  get identifiers(): {
    id?: number;
    uid?: string;
  } {
    return {
      id: this.block.id,
      uid: this.block.uid,
    };
  }

  public static create(input: string): BlockBuilder;
  public static create(input: BlockType): BlockBuilder;
  public static create(input: BlockBuilder): BlockBuilder;
  public static create(input: any): BlockBuilder {
    if (input instanceof BlockBuilder) {
      return input;
    }

    return new BlockBuilder().type(input);
  }

  public static from(input: string): BlockBuilder;
  public static from(input: Block): BlockBuilder;
  public static from(input: BlockType): BlockBuilder;
  public static from(input: any): BlockBuilder {
    if (input instanceof Block) {
      return new BlockBuilder(input);
    }

    const type = BlockType.from(input);

    if (!type.builder) {
      throw new NoBuilderError();
    }

    const builder = type.builder();

    return builder;
  }

  public id(id?: number): BlockBuilder {
    this.block.id = id;

    return this;
  }

  public type(type: string): BlockBuilder;
  public type(type: BlockType): BlockBuilder;
  public type(type: any): BlockBuilder {
    this.block.type = BlockType.from(type);
    return this;
  }

  public order(value: number): BlockBuilder;
  public order(value: Property): BlockBuilder;
  public order(value: Property | number): BlockBuilder {
    if (value instanceof Property) {
      this.block.order = value.value as number;
      return this;
    }

    this.block.order = value;
    return this;
  }

  public property(name: string, value: Property): BlockBuilder;
  public property(name: string, value: PropertyValue): BlockBuilder;
  public property(name: string, value: any): BlockBuilder {
    this.block.properties[name] = Property.from(value);
    return this;
  }

  public flag(name: BlockFlag): BlockBuilder {
    this.block.flags.add(name);
    return this;
  }

  public when(condition: boolean, tap: TapFunction<BlockBuilder>): BlockBuilder;
  public when(
    condition: boolean,
    tap: TapFunction<BlockBuilder>,
    recursive: boolean
  ): BlockBuilder;
  public when(
    condition: boolean,
    tap: TapFunction<BlockBuilder>,
    recursive = false
  ): BlockBuilder {
    if (condition) {
      return this.tap(tap, recursive);
    }

    return this;
  }

  public propertyWhen(
    condition: boolean,
    name: string,
    value: Property
  ): BlockBuilder;
  public propertyWhen(
    condition: boolean,
    name: string,
    value: PropertyValue
  ): BlockBuilder;
  public propertyWhen(
    condition: boolean,
    name: string,
    value: any
  ): BlockBuilder {
    return this.when(condition, b => b.property(name, value));
  }

  public tap(tap: TapFunction<BlockBuilder>): BlockBuilder;
  public tap(tap: TapFunction<BlockBuilder>, recursive: boolean): BlockBuilder;
  public tap(tap: TapFunction<BlockBuilder>, recursive = false): BlockBuilder {
    if (recursive) {
      this.items.forEach(i => i.tap(tap, recursive));
    }

    return tap(this);
  }

  public item(...item: BlockBuilder[]): BlockBuilder;
  public item(when: boolean, ...item: BlockBuilder[]): BlockBuilder;
  public item(
    when: boolean | BlockBuilder,
    items: () => BlockBuilder
  ): BlockBuilder;
  public item(
    when: boolean | BlockBuilder,
    items: () => BlockBuilder[]
  ): BlockBuilder;
  public item(when: boolean | BlockBuilder, ...item: any[]): BlockBuilder {
    if (when instanceof BlockBuilder) {
      this.block.items.push(when, ...item);
    } else if (when) {
      if (item[0] instanceof Function) {
        this.block.items.push(...castArray(item[0]()));
      } else {
        this.block.items.push(...item);
      }
    }

    return this;
  }

  public itemFactory(factory: BlockFactory): BlockBuilder {
    // You can pass static data to an item factory to
    // create simple data inserts in page templates
    //
    // eg. .itemFactory(pod.using([{images: [story.image]}]))
    //
    // Therefore in theory we only need to set the itemFactory
    // property if its a "real" factory.
    //
    // Ideally however we would create an object here that
    // contains either a reference to the factory source,
    // OR the static data itself in object form. This needs
    // further testing...
    if (factory.source instanceof BlockFactorySource) {
      this.property('itemFactory', `${factory.name},${factory.source?.name}`);
    }

    return this;
  }

  public size(value: number): BlockBuilder {
    this.block.size = value;
    return this;
  }

  public minSize(value: number): BlockBuilder {
    this.block.minSize = value;
    return this;
  }

  public async make(options: Partial<BlockLike> = {}): Promise<Block> {
    if (!this.block.type) {
      throw new BuilderTypeNotDefined();
    }

    const properties = Property.map(this.block.properties, p => {
      if (p.value instanceof Function) {
        const value = p.value();

        if (value instanceof Promise) {
          return Property.from(value);
        }

        return Property.from(value);
      }

      return p;
    });

    await Promise.all(
      Object.values(properties).map(async property => {
        property.value = await property.value;
      })
    );

    return new Block(
      merge<BlockLike, Partial<BlockLike>>(
        {
          id: this.block.id,
          type: this.block.type,
          size: new Vector(Math.max(this.block.size, this.block.minSize), 0),
          properties,
          items: await Promise.all(
            this.block.items
              .sort((a, b) => a.block.order - b.block.order)
              .map<Promise<Block>>(async (builder, index) => {
                if (builder instanceof Function) {
                  builder = builder();
                }

                if (builder instanceof Block) {
                  return builder;
                }

                return builder.order(index).make();
              })
          ),
          flags: this.block.flags,
          order: this.block.order,
        },
        options
      )
    );
  }

  public flatten(): BlockBuilder[] {
    return [this, ...this.block.items.flatMap(i => i.flatten())];
  }

  public merge(builder: BlockBuilder): BlockBuilder {
    function matcher(type: BlockType, builders: BlockBuilder[]) {
      const index = builders.findIndex(b => b.block.type === type);
      const foundBlock = builders[index];

      builders.splice(index, 1);

      return foundBlock;
    }

    const fromBuilders = builder.flatten();

    this.flatten().forEach(baseBuilder => {
      const matchedBuilder = matcher(
        baseBuilder.block.type as BlockType,
        fromBuilders
      );

      Object.keys(baseBuilder.block.properties).forEach(propertyName => {
        const baseProperty = baseBuilder?.block?.properties[propertyName];
        const matchedProperty = matchedBuilder?.block?.properties[propertyName];

        if (matchedProperty) {
          baseProperty.id = matchedProperty.id;
          baseProperty.value = matchedProperty.value;
        }
      });
    });

    this.items.push(...fromBuilders);

    return this;
  }

  public itemsFromTemplate(template: BlockTemplate): BlockBuilder[] {
    return this.items.flatMap(item => {
      if (item.block.properties?.template?.get() === template.name) {
        return [item];
      }

      return item.itemsFromTemplate(template);
    });
  }
}
