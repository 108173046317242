import {ShippingState} from '@teemill/common/vuex';
import {dispatchModuleMutations} from '../root';
import {store} from '../../services/vuex';

export const useShippingStore = () => {
  return {
    get state(): ShippingState {
      return store.state.shipping;
    },
    mutation<T>(mutationName: string, params?: T): void {
      dispatchModuleMutations('shipping', mutationName, params);
    },
  };
};
