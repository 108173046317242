/**
 * @param {object} route The route that is being accessed
 * @param {object} store VueX store object (Use store.state to get data from the store)
 */
import {AccessStatement} from '../classes';
import {Manufacturer} from '../models';

export const isLoggedIn = new AccessStatement(
  'isLoggedIn',
  store => store.state.user,
  (store, route) => `/login/?redirect_uri=${route.fullPath}`
);

export const isLoggedOut = new AccessStatement(
  'isLoggedOut',
  store => !store.state.user,
  '/dashboard/'
);

export const isVerified = new AccessStatement(
  'isVerified',
  store => store.state.user.verified,
  '/sign-up/verify/',
  [isLoggedIn]
);

export const isNotVerified = new AccessStatement(
  'isNotVerified',
  store => !store.state.user.verified,
  '/dashboard/',
  [isLoggedIn]
);

export const hasStore = new AccessStatement(
  'hasStore',
  store => store.state.store && store.state.store.active,
  '/dashboard/'
);

export const doesntHaveStore = new AccessStatement(
  'doesntHaveStore',
  store => !store.state.store || !store.state.store.active,
  '/dashboard/'
);

export const hasSignEula = new AccessStatement(
  'hasSignEula',
  store => store.state.user.signedEula,
  '/sign-up/eula/',
  [isLoggedIn]
);

export const isPro = new AccessStatement(
  'isPro',
  store => store.state.store.active?.pro,
  '/teemill-plans/',
  [hasStore]
);

export const isInternalUser = new AccessStatement(
  'isInternalUser',
  store => store.state.user.internal,
  '/dashboard/',
  [isLoggedIn]
);

export const hasDashboardPermissions = new AccessStatement(
  'hasDashboardPermissions',
  (store, to) =>
    store.getters['auth/hasDashboardPermissions'](
      to?.meta.dashboardPermissions
    ),
  '/403/',
  [isLoggedIn]
);

export const hasDashboardAccess = [isLoggedIn, isVerified, hasSignEula];

/**
 * Removes the existing hardcoded route if the user's payment processor is not set to "direct".
 */
export const hasTeemillPaymentProcessor = new AccessStatement(
  'hasTeemillPaymentProcessor',
  store => {
    return store.state.paymentProcessor?.type !== 'direct';
  },
  (_, route, router) => {
    router.removeRoute(route.name as string);

    return route.path;
  }
);

export const hasDirectPaymentProcessor = new AccessStatement(
  'hasDirectPaymentProcessor',
  store => {
    return store.state.store?.active?.paymentProcessor?.type === 'direct';
  },
  '/'
);

export default {
  isLoggedIn,
  isLoggedOut,
  isVerified,
  isNotVerified,
  isPro,
  isInternalUser,

  hasStore,
  hasSignEula,
  hasDashboardAccess,

  hasDashboardPermissions,

  doesntHaveStore,
};
