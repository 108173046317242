import {bus} from '../bus';

export default props =>
  new Promise(resolve => {
    bus.emit('add-dynamic-overlay', {
      props,
      component: 'tml-cropper-popup',
      events: {
        done(image) {
          resolve(image);
        },
        cancel() {
          resolve(false);
        },
      },
    });
  });
