/**
 * @param {object} route The route that is being accessed
 * @param {object} store VueX store object (Use store.state to get data from the store)
 */

import {AccessStatement} from '@teemill/common/classes';
import {detectCountry} from '@teemill/common/helpers';
import {isEUCountryCode} from '@teemill/utilities';

export const internalUser = new AccessStatement(
  'internalUser',
  store =>
    store.state.context?.context === 'atlas' ||
    store.state.user?.internal ||
    (!!store.state.subdomain?.internalUser &&
      store.state.subdomain?.division !== 12),
  '/'
);

export const internalOrHasDirectPaymentProcessor = new AccessStatement(
  'hasDirectPaymentProcessor',
  store => {
    return (
      internalUser.passes ||
      store.state.store?.active?.paymentProcessor?.type === 'direct'
    );
  },
  '/'
);

export const canVisitStore = new AccessStatement(
  'canVisitStore',
  store => {
    if (store.state.subdomain) {
      return !!(
        !store.state.subdomain.passwordProtected ||
        store.state.subdomain.internalUser ||
        store.state.subdomain.allowedUser
      );
    }

    return true;
  },
  '/login/'
);

export const canVisitLogin = new AccessStatement(
  'canVisitLogin',
  () => canVisitStore.fails,
  '/'
);

export const canVisitUnpublishedPages = new AccessStatement(
  'canVisitUnpublishedPages',
  store => {
    if (store.state.subdomain) {
      return !!store.state.subdomain.internalUser;
    }

    if (store.state.user) {
      return store.state.user.internal;
    }

    return false;
  },
  '/404/'
);

export const isBespoke = new AccessStatement(
  'isBespoke',
  store => store.state.subdomain.company.id === 52,
  '/login/'
);

export const isPro = new AccessStatement(
  'isPro',
  store => {
    if (
      store.state.subdomain?.division === 12 ||
      store.state.context?.context === 'teemill'
    ) {
      return window?.$project?.active?.isPro;
    }

    return store.state.subdomain?.pro;
  },
  '/404/'
);

export const isNotPro = new AccessStatement(
  'isNotPro',
  store => {
    if (
      store.state.subdomain?.division === 12 ||
      store.state.context?.context === 'teemill'
    ) {
      return !window?.$project?.active?.isPro;
    }

    return !store.state.subdomain?.pro;
  },
  '/404/'
);

export const isLoggedIn = new AccessStatement(
  'isLoggedIn',
  store => store.state.user,
  (store, route) => `/login/?redirect_uri=${route.fullPath}`
);

export const isLoggedOut = new AccessStatement(
  'isLoggedOut',
  store => !store.state.user,
  '/dashboard/'
);

export const isAtlas = new AccessStatement(
  'isAtlas',
  store => store.state.context?.context === 'atlas'
);

export const isTeemill = new AccessStatement(
  'isTeemill',
  store => store.state.context?.context !== 'atlas'
);

export const completedSignUpBadges = new AccessStatement(
  'completedSignUpBadges',
  store => {
    const lockedBadges = window?.$project?.active?.badges
      .filter(badge => {
        const groupCodes = badge.groups.map(group => group.code);
        return groupCodes.includes('sign-up') && groupCodes.includes('teemill');
      })
      .filter(badge => !badge.unlocked);

    return lockedBadges?.length === 0;
  },
  '/404/'
);

export const notCompletedSignUpBadges = new AccessStatement(
  'notCompletedSignUpBadges',
  store => {
    const lockedBadges = window?.$project?.active?.badges
      .filter(badge => {
        const groupCodes = badge.groups.map(group => group.code);
        return groupCodes.includes('sign-up') && groupCodes.includes('teemill');
      })
      .filter(badge => !badge.unlocked);

    return lockedBadges?.length > 0;
  },
  '/404/'
);

export const completedShopifySignUpBadges = new AccessStatement(
  'completedShopifySignUpBadges',
  store => {
    const lockedBadges = window?.$project?.active?.badges
      .filter(badge => {
        const groupCodes = badge.groups.map(group => group.code);
        return groupCodes.includes('sign-up') && groupCodes.includes('shopify');
      })
      .filter(badge => !badge.unlocked);

    return lockedBadges?.length === 0;
  },
  '/404/'
);

export const notCompletedShopifySignUpBadges = new AccessStatement(
  'notCompletedShopifySignUpBadges',
  store => {
    const lockedBadges = window?.$project?.active?.badges
      .filter(badge => {
        const groupCodes = badge.groups.map(group => group.code);
        return groupCodes.includes('sign-up') && groupCodes.includes('shopify');
      })
      .filter(badge => !badge.unlocked);

    return lockedBadges?.length > 0;
  },
  '/404/'
);

export const completedSetupBillingBadge = new AccessStatement(
  'completedSetupBillingBadge',
  store =>
    window?.$project?.active?.badges.find(badge => badge.code === 'card')
      ?.unlocked,
  '/404/'
);

export const notCompletedSetupBillingBadge = new AccessStatement(
  'notCompletedSetupBillingBadge',
  store =>
    !window?.$project?.active?.badges.find(badge => badge.code === 'card')
      ?.unlocked,
  '/404/'
);

export const completedDesignerBadge = new AccessStatement(
  'completedDesignerBadge',
  store =>
    window?.$project?.active?.badges.find(badge => badge.code === 'designer')
      ?.unlocked,
  '/404/'
);

export const notCompletedDesignerBadge = new AccessStatement(
  'notCompletedDesignerBadge',
  store =>
    !window?.$project?.active?.badges.find(badge => badge.code === 'designer')
      ?.unlocked,
  '/404/'
);

export const completedProfileBadge = new AccessStatement(
  'completedProfileBadge',
  store =>
    window?.$project?.active?.badges.find(badge => badge.code === 'profile')
      ?.unlocked,
  '/404/'
);

export const notCompletedProfileBadge = new AccessStatement(
  'notCompletedProfileBadge',
  store =>
    !window?.$project?.active?.badges.find(badge => badge.code === 'profile')
      ?.unlocked,
  '/404/'
);

export const completedThemeBadge = new AccessStatement(
  'completedThemeBadge',
  store =>
    window?.$project?.active?.badges.find(badge => badge.code === 'theme')
      ?.unlocked,
  '/404/'
);

export const notCompletedThemeBadge = new AccessStatement(
  'notCompletedThemeBadge',
  store =>
    !window?.$project?.active?.badges.find(badge => badge.code === 'theme')
      ?.unlocked,
  '/404/'
);

export const completedHomepageBadge = new AccessStatement(
  'completedHomepageBadge',
  store =>
    window?.$project?.active?.badges.find(badge => badge.code === 'homepage')
      ?.unlocked,
  '/404/'
);

export const notCompletedHomepageBadge = new AccessStatement(
  'notCompletedHomepageBadge',
  store =>
    !window?.$project?.active?.badges.find(badge => badge.code === 'homepage')
      ?.unlocked,
  '/404/'
);

export const completedEntrepreneurBadge = new AccessStatement(
  'completedEntrepreneurBadge',
  store =>
    window?.$project?.active?.badges.find(
      badge => badge.code === 'entrepreneur'
    )?.unlocked,
  '/404/'
);

export const notCompletedEntrepreneurBadge = new AccessStatement(
  'notCompletedEntrepreneurBadge',
  store =>
    !window?.$project?.active?.badges.find(
      badge => badge.code === 'entrepreneur'
    )?.unlocked,
  '/404/'
);

export const isShopify = new AccessStatement(
  'isShopify',
  store => !!window?.$project?.active?.isNotTeemill(),
  '/404/'
);

export const isNotShopify = new AccessStatement(
  'isNotShopify',
  store => !window?.$project?.active?.isNotTeemill(),
  '/404/'
);

export const needsShopifyDeliveryProfile = new AccessStatement(
  'needsShopifyDeliveryProfile',
  store =>
    window?.$project?.active?.hasIntegration('shopify') &&
    !window?.$project?.active?.integrations.find(i => i.type === 'shopify')
      ?.config?.delivery_profile_id
);

export const oldTeemillHomepageIsActive = new AccessStatement(
  'oldTeemillHomepageIsActive',
  store => {
    if (!store.getters['splitTest/get']('teemill_homepage')?.discovered) {
      store.dispatch('splitTest/startSession', 'teemill_homepage');
    }

    return (
      store.getters['splitTest/getActiveVariation']('teemill_homepage') ===
      'Old'
    );
  },
  '/404/'
);

export const newTeemillHomepageIsActive = new AccessStatement(
  'newTeemillHomepageIsActive',
  store => {
    if (!store.getters['splitTest/get']('teemill_homepage')?.discovered) {
      store.dispatch('splitTest/startSession', 'teemill_homepage');
    }

    return (
      store.getters['splitTest/getActiveVariation']('teemill_homepage') ===
      'New'
    );
  },
  '/404/'
);

export const isTurbo = new AccessStatement(
  'isTurbo',
  store => window?.$project?.active?.isTurbo,
  '/404/'
);

export const isNotTurbo = new AccessStatement(
  'isNotTurbo',
  store => !window?.$project?.active?.isTurbo,
  '/404/'
);

export const isVerified = new AccessStatement(
  'isVerified',
  store => store.state.user.verified,
  '/sign-up/verify/',
  [isLoggedIn]
);

export const hasSignEula = new AccessStatement(
  'hasSignEula',
  store => store.state.user.signedEula,
  '/sign-up/eula/',
  [isLoggedIn]
);

export const isUK = new AccessStatement('isUK', () => {
  return detectCountry() === 'GB';
});

export const isEU = new AccessStatement('isEU', () => {
  const detectedCountry = detectCountry();

  return !!detectedCountry && isEUCountryCode(detectedCountry);
});

export const isRestOfWorld = new AccessStatement(
  'isRestOfWorld',
  () => !!detectCountry() && isUK.fails && isEU.fails
);

export const standardPageAccess = [canVisitStore];

export const hasDashboardAccess = [isLoggedIn, isVerified, hasSignEula];

export default {
  internalUser,
  canVisitLogin,
  canVisitStore,
  isBespoke,
  isPro,
  isNotPro,
  isLoggedIn,
  isLoggedOut,
  isAtlas,
  completedSignUpBadges,
  notCompletedSignUpBadges,
  completedShopifySignUpBadges,
  notCompletedShopifySignUpBadges,
  isShopify,
  isNotShopify,
  isTurbo,
  isNotTurbo,
  needsShopifyDeliveryProfile,
  standardPageAccess,
  completedSetupBillingBadge,
  notCompletedSetupBillingBadge,
  completedDesignerBadge,
  notCompletedDesignerBadge,
  completedThemeBadge,
  notCompletedThemeBadge,
  completedHomepageBadge,
  notCompletedHomepageBadge,
  hasDashboardAccess,
  oldTeemillHomepageIsActive,
  newTeemillHomepageIsActive,
  notCompletedProfileBadge,
  completedProfileBadge,
  completedEntrepreneurBadge,
  notCompletedEntrepreneurBadge,
  isUK,
  isEU,
  isRestOfWorld,
};
