import {insertElementIntoHead} from './insertElementIntoHead';

/**
 * Finds an existing element by id, or create a new one in the head, and sets the given attributes
 */
export function updateOrInsertHeadElement(
  id: string,
  tagName: string,
  attributes: Object
): void {
  const el = document.getElementById(id) || insertElementIntoHead(tagName, id);

  Object.entries(attributes).forEach(([key, value]) => {
    el.setAttribute(key, value);
  });
}
