import {bus} from '../bus';

export default (
  title?: string,
  text?: string,
  actions?: Record<string, string>
) =>
  new Promise<boolean>(resolve => {
    bus.emit('add-dynamic-overlay', {
      props: {
        text,
        title,
        actions,
      },
      component: 'tml-confirm-popup',
      events: {
        confirm() {
          resolve(true);
        },
        cancel() {
          resolve(false);
        },
        leave() {
          resolve(false);
        },
        'after-leave': () => {
          resolve(false);
        },
      },
    });
  });
