import {App, Plugin} from 'vue';
import {RouteLocationNormalized, RouteLocationRaw, Router} from 'vue-router';
import {createGtm, useGtm as vueUseGtm} from '@gtm-support/vue-gtm';
import {omit, startCase} from 'lodash';

interface PluginOptions {
  id: string;
  debug: boolean;
  router: Router;
  trackViewEventProperty: string;
  additionalEventData: (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized | null
  ) => Record<string, unknown>;
}

interface TrackEventOptions {
  [key: string]: any;
  event?: string | null;
  category?: string | null;
  action?: string | null;
  value?: number | null;
  totalBase?: number | null;
  originalLocation?: string | null;
}

interface RegisterEventOptions extends TrackEventOptions {
  integrationType: string;
}

interface EcommerceEventOptions extends TrackEventOptions {
  currency?: string | null;
  items?: EcommerceItemData[] | null;
  contentType?: string | null;
  userId?: string | null;
  coupon?: string | null;
}

interface EcommerceItemData {
  [key: string]: any;
  id?: number | string;
  name?: string;
  brand?: string;
  price?: number;
  currency?: string;
  discount?: number;
  quantity?: number;
  options?: EcommerceItemOption[];
}

interface EcommerceItemOption {
  [key: string]: any;
  size?: string;
  color?: string;
}

let missedFirstPageLoad = false;
let gtmInitialised = false;
let pendingEvents: TrackEventOptions[] = [];

export const originalLocation =
  document.location.protocol +
  '//' +
  document.location.hostname +
  document.location.pathname +
  document.location.search;

export const useGtm = () => ({
  trackEvent(options: TrackEventOptions) {
    const gtm = vueUseGtm();

    options.originalLocation = originalLocation;

    if (!gtm?.trackEvent) {
      pendingEvents.push(options);
    } else {
      gtm.trackEvent(this.formatEventOptions(options));
    }
  },

  formatEventOptions(options: TrackEventOptions): object {
    const formattedOptions: Record<string, any> = options;

    if (options.items) {
      formattedOptions.items = this.formatItems(options.items);
    }

    return formattedOptions;
  },

  formatItems(items: EcommerceItemData[]) {
    return items.map((item: EcommerceItemData) => {
      const formattedItem: Record<string, any> = item;

      if (item.options) {
        formattedItem.options = JSON.stringify(
          this.sortItemOptions(item.options)
        );
      }

      return formattedItem;
    });
  },

  sortItemOptions(options: EcommerceItemOption[]): EcommerceItemOption[] {
    return options?.map((option: EcommerceItemOption) => {
      const sortedOption: EcommerceItemOption = {};

      Object.keys(option)
        .sort()
        .forEach((key: keyof EcommerceItemOption) => {
          sortedOption[key] = option[key];
        });

      return sortedOption;
    });
  },

  retryEvents() {
    const events = pendingEvents;
    pendingEvents = [];

    events.forEach(event => {
      this.trackEvent(event);
    });
  },

  clearEcommerceDataLayer() {
    this.trackEvent({
      items: null,
      currency: null,
      value: null,
    });
  },

  viewItem(options: EcommerceEventOptions) {
    this.clearEcommerceDataLayer();
    this.trackEvent({
      event: 'onViewItem',
      category: 'engagement',
      action: 'view_item',
      ...options,
    });
  },

  addToCart(options: EcommerceEventOptions) {
    this.clearEcommerceDataLayer();
    this.trackEvent({
      event: 'onAddToCart',
      category: 'ecommerce',
      action: 'add_to_cart',
      ...options,
    });

    // UA ecommerce tracking
    this.trackEvent({ecommerce: null});
    this.trackEvent({
      event: 'addToCart',
      ecommerce: {
        currencyCode: options.currency,
        add: {
          products: options.items,
        },
      },
    });
  },

  viewCart(options: EcommerceEventOptions) {
    this.clearEcommerceDataLayer();
    this.trackEvent({
      event: 'viewCart',
      ...options,
    });
  },

  register(options: RegisterEventOptions) {
    this.trackEvent({
      event: 'TmlRegisterEvent',
      ...options,
    });
  },

  initiateCheckout(options: EcommerceEventOptions) {
    this.clearEcommerceDataLayer();
    this.trackEvent({
      event: 'InitiateCheckout',
      category: 'ecommerce',
      action: 'initiate_checkout',
      ...options,
    });
  },

  purchase(options: EcommerceEventOptions) {
    this.clearEcommerceDataLayer();
    this.trackEvent({
      event: 'purchase',
      ...options,
    });
  },

  enable() {
    const gtm = vueUseGtm();

    if (!gtm || gtm.enabled()) {
      return;
    }

    const loadGtm = () => {
      gtm.enable(true);

      gtmInitialised = true;

      if (missedFirstPageLoad) {
        // as the first page load event took place before GTM was initialised
        // we need to manually track it here
        this.trackEvent({
          event: gtm.options.trackViewEventProperty,
          ...omit(
            // @ts-expect-error property exists on the object but not defined on its type
            gtm.options.vueRouterAdditionalEventData(
              // @ts-expect-error property exists on the object but not defined on its type
              gtm.options.vueRouter.currentRoute.value,
              null
            ),
            ['pageUrl', 'pageName']
          ),
        });
      }

      this.retryEvents();
    };

    if ('requestIdleCallback' in window) {
      requestIdleCallback(
        () => {
          loadGtm();
        },
        {
          timeout: 10000,
        }
      );
    } else {
      loadGtm();
    }
  },
});

function installGtm(
  app: App,
  {
    id,
    debug,
    router,
    trackViewEventProperty,
    additionalEventData = () => ({}),
  }: PluginOptions
) {
  app.use(
    createGtm({
      id,
      defer: true,
      debug,
      vueRouter: router,
      trackViewEventProperty,
      vueRouterAdditionalEventData: (to, from) => {
        let pageName = undefined;

        if (to.meta.title) {
          pageName = to.meta.title;
        } else if (typeof to.name === 'string') {
          pageName = startCase(to.name);
        }

        return {
          pageUrl: to.fullPath,
          pageName,
          ...additionalEventData(to, from),
        };
      },
      enabled: false,
    })
  );
}

export const gtm: Plugin = {
  async install(app: App, options: PluginOptions): Promise<void> {
    // Because we load in GTM asynchronously it's possible that GTM
    // loads in before or after the initial page load event is triggered
    // in vue router. Here we listen for page events and we can check if
    // a page is loaded before GTM is initialised which we can then use later
    // to manually trigger the initial page view event
    options.router.afterEach((to, from) => {
      if (!gtmInitialised) {
        missedFirstPageLoad = true;
      }
    });

    if (options.debug) {
      console.log('installing GTM');
    }

    installGtm(app, options);
  },
};
