/**
 * @name apiHost
 * @description Returns a the valid host name for connecting to the api.
 */
export const apiHost = (): string => {
  const protocol =
    import.meta.env.VITE_CORE_API_PROTOCOL ||
    window.location.protocol.replace(/:/, '');

  const hostname =
    import.meta.env.VITE_CORE_API_HOSTNAME ||
    window.location.hostname.replace(/:[0-9]{1,5}$/, '');

  const port = import.meta.env.VITE_CORE_API_PORT || window.location.port;

  return `${protocol}://${hostname}${port ? ':' + port : ''}`;
};
