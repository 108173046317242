import {App} from 'vue';

import {EventBus} from '../classes/eventBus';

interface VueEventBus extends EventBus {
  install(app: App): void;
}

const bus = new EventBus() as VueEventBus;

export const events = bus;

export const plugin = {
  ...bus,

  install(app: App): void {
    app.config.globalProperties.$eventBus = bus;
  },
};
