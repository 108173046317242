import {formatUrl} from '@teemill/common/helpers';
import {http} from '@teemill/common/services';
import {NotificationHandler} from '../types/notificationHandler';

export const dismiss: NotificationHandler = {
  path: 'notifications/dashboard/dismiss',
  handler: async (notification, {dismiss}) => {
    dismiss();

    await http.post(
      formatUrl(
        `/omnis/v3/notifications/dashboard/mark-as-deleted/${notification.data.recipientId}/`
      )
    );
  },
};
