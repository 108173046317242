<script>
import {h} from 'vue';
import {faLayerPlus} from '@fortawesome/pro-light-svg-icons';

import {Block} from '../classes/block';
import {Theme} from '@teemill/modules/theme-builder';

export default {
  name: 'BlockRenderer',

  inject: ['contentWidth', 'showEditControls'],

  props: {
    block: {
      type: Block,
      required: true,
    },

    theme: {
      type: Theme,
      required: false,
    },

    beforeBlock: {
      type: Block,
      required: false,
    },

    afterBlock: {
      type: Block,
      required: false,
    },

    injectPadding: Boolean,
  },

  computed: {
    faded() {
      if (
        this.block.page?.mode !== 'preview' ||
        this.block.parent?.unpublished // prevent nested blocks being faded more than once
      ) {
        return false;
      }

      return this.block.unpublished && this.showEditControls?.();
    },
  },

  render() {
    if (!this.block?.type) {
      return h();
    }

    const block = this.block;

    if (this.theme instanceof Theme) {
      block.setTheme(this.theme);
    }

    if (block.type.name === '*' || block.flags.has('placeholder')) {
      return h('tml-placeholder', {
        style: {
          width: '100%',
        },
        props: {
          icon: faLayerPlus,
          text: `Add ${block.type.title.toLowerCase()}`,
          aspectRatio: block.size.x / block.size.y,
          additive: false,
        },
      });
    }

    const component = block.type.component;

    block.resolve().then(resolved => {
      if (resolved) {
        this.$emit('resolved', block);
      }
    });

    const padding = block.property('padding', 'number', 0);

    let width = '100%';
    let marginLeft = '0';

    if (
      block.parent === undefined &&
      block.property('fullWidth', 'number', 0)
    ) {
      width = 'max(var(--content-width), 100%)';
      marginLeft = 'calc((100% - var(--content-width)) / 2)';
    }

    const beforePadding =
      this.beforeBlock?.property('padding', 'number', 0) || 0;

    return h(
      'div',
      {
        class: {
          block: true,
          'show-background': !block.parent && block.size.x === 12,
        },
        style: {
          marginBottom: this.injectPadding ? `${padding}em` : null,
          width,
          marginLeft,
          transition: 'margin-bottom 0.2s',
          willChange: 'margin-bottom, opacity',
          flexShrink: '0',
          filter: this.faded ? 'grayscale(1)' : 'none',
          opacity: this.faded ? 0.5 : 1,
          '--full-background-color': block.property(
            'fullBackgroundColor',
            'string'
          ),
          '--before-block-padding': `${beforePadding / 2}em`,
          '--block-padding': `${padding / (this.afterBlock ? 2 : 1)}em`,
        },
      },
      [
        h(component, {
          block,
        }),
      ]
    );
  },
};
</script>

<style lang="scss" scoped>
.block {
  position: relative;

  &.show-background:before {
    content: '';
    position: absolute;
    background-color: var(
      --full-background-color,
      var(--tml-page-background-color)
    );
    top: calc(0px - var(--before-block-padding, 0px));
    left: 50%;
    transform: translateX(-50%);
    width: Max(var(--content-width, 100%), 100%);
    height: calc(
      100% + var(--before-block-padding, 0px) + var(--block-padding, 0px)
    );
    transition: height 0.2s, top 0.2s;
    will-change: height, top;
    pointer-events: none;
    z-index: -1;
  }
}
</style>
