import {BaseClass} from '../../classes/baseClass';

import Customer from '../customer/customer';
import Subdomain from '../subdomain/subdomain';
import DivisionDemographic from './divisionDemographic';
import {Manufacturer} from '../manufacturer/manufacturer';
import CompanyInformation from '../company/companyInformation';

/**
 * Division.js
 *
 * Defines the standard structure for a Division object.
 *
 * @author Aidan Brookes <aidan@teemill.com>
 */
export default class Division extends BaseClass {
  static modelName = 'division';

  constructor({
    id,
    name,
    code,
    disabled,
    subdomain,
    customers,
    companyInfo,
    demographic,
    manufacturer,
    logoThumbnail,
    activeDomainName,
    divisionCustomers,
  } = {}) {
    super();

    this.id = id;
    this.name = name;
    this.code = code;
    this.enabled = !disabled;
    this.logo = logoThumbnail;
    this.activeDomainName = activeDomainName;
    this.storeOwners = Customer.map(customers);
    this.subdomain = Subdomain.map(subdomain);
    this.demographic = DivisionDemographic.map(demographic);
    this.manufacturer = Manufacturer.map(manufacturer);
    this.customers = Customer.map(divisionCustomers);
    this.companyInfo = CompanyInformation.map(companyInfo);
  }

  get storeUrl() {
    return `https://${this.activeDomainName}/`;
  }

  get hasPro() {
    return this.subdomain ? this.subdomain.hasPro : null;
  }

  get isFraud() {
    return this.manufacturer ? this.manufacturer.isFraud : null;
  }
}
