/**
 * @name isLocalhost
 */
export const isLocalhost = (): boolean =>
  Boolean(
    window.location.hostname.match(/(?:(.+)\.)?(localhost)/) ||
      window.location.hostname === '[::1]' ||
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );
