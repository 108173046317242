export default [
  {
    path: '/search/',
    name: 'search',
    meta: {
      title: 'Search',
      layout: 'search',
      allowPwaReload: true,
    },
    component: () => import('./SearchPage.vue'),
  },
];
