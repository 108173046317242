/**
 * @name decorate
 * @description Decorate the provided object with a value or callback
 *
 * @deprecated
 */
export const decorate = <T>(
  object: T,
  decorator: string,
  value: unknown
): T => {
  if (object === undefined || object === null) {
    return object;
  }

  // @ts-expect-error Prototype manipulation
  object.__proto__[decorator] = value;

  return object;
};
