import {App} from 'vue';
import {LogConfig} from '@devanjs/log';

import {Theme} from './classes/theme';
import * as themes from './themes';

export * as themes from './themes';

export * from './classes/theme';
export * from './providers/themeProvider';
export * from './errors/themeErrors';

export * from './types/hasTheme';

export default {
  install(app: App) {
    LogConfig.defaults('theme-editor', log =>
      log.if(import.meta.env.DEV).green('Theme Editor')
    );

    Theme.themes.push(...Object.values(themes));

    app
      .component(
        'TmlThemeEditor',
        () =>
          import(
            /* webpackChunkName: "TmlThemeBuilder" */ './components/ThemeEditor.vue'
          )
      )
      .component(
        'TmlThemePreview',
        () =>
          import(
            /* webpackChunkName: "TmlThemeBuilder" */ './components/ThemePreview.vue'
          )
      )
      .component(
        'TmlThemeSelector',
        () =>
          import(
            /* webpackChunkName: "TmlThemeBuilder" */ './components/ThemeSelector.vue'
          )
      );
  },
};
