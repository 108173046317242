import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

import TextBlock from './TextBlock.vue';

export const text = new BlockType({
  name: 'textBlock',
  title: 'Text',
  compatibility: ['page'],
  component: TextBlock,
  builder: () =>
    BlockBuilder.create('textBlock')
      .property('title', '')
      .property('subTitle', '')
      .property('html', '')
      .property('paragraph1', '')
      .property('paragraph2', '')
      .property('paragraph3', '')
      .property('truncateHeight', ''),
});
