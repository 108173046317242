import {tracker} from '@teemill/common/plugins';

export default {
  async startSession(
    {state, getters, commit, dispatch, rootState},
    splitTestName
  ) {
    const splitTest = getters.get(splitTestName);

    if (splitTest && !splitTest.discovered) {
      commit('markAsDiscovered', splitTestName);

      const splitTests = {};
      state.splitTests
        .filter(item => item)
        .forEach(item => {
          splitTests[item.id] = item.variation.id;
        });

      if (rootState.internalUser) {
        // ! Testing log for debugging split tests
        // eslint-disable-next-line
        console.log(
          `Started Split Test: ${splitTest.name}:${splitTest.variation.name}`
        );
      }

      tracker.event('split-test:start', {
        splitTestId: splitTest.id,
        variationId: splitTest.variation.id,
        splitTests,
      });
    }
  },
};
