export type BreakpointSize = keyof typeof pageBreakpoints;
export type Breakpoints = Partial<Record<BreakpointSize, number>>;

/**
 * @name pageBreakpoints
 */
export const pageBreakpoints = {
  '2xl': 1536,
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 576,
  xs: 0,
};
