export default {
  path: '/advanced-quote/:order?/',
  name: 'Quote',
  meta: {
    title: 'Quote',
    layout: 'standard',
  },
  component: () =>
    import(/* webpackChunkName: "PageAdvancedQuote" */ './AdvancedQuote.vue'),
  // prefetchChunks: ['TmlGlobal', 'TmlContainers', 'TmlForms'],
};
