import {captureMessage} from '@sentry/vue';
import {AccessStatement} from '@teemill/common/classes';
import accessStatements from '../../store-front/access';
import {Block} from '../classes/block';

export function filterBlocks(blocks: Block[]): Block[] {
  return blocks.filter(block => {
    if (block.page?.mode === 'native' && !block.published) {
      return false;
    }

    const conditions = block.property(
      'conditions',
      'json'
    ) as (keyof typeof accessStatements)[];

    if (conditions) {
      const blockAccessStatements = conditions
        .map(condition => accessStatements[condition])
        .filter(c => !!c)
        .flat();

      if (conditions.length > blockAccessStatements.length) {
        const invalidConditions = conditions.filter(c => !accessStatements[c]);

        captureMessage(
          `Invalid block conditions: ${JSON.stringify(invalidConditions)}`
        );

        return false;
      }

      const result = AccessStatement.run(blockAccessStatements);

      if (!result.passed) {
        return false;
      }
    }

    if (block.items.length > 0) {
      return filterBlocks(block.items).length > 0;
    }

    return true;
  });
}
