import {base} from './base';

export const daze = base
  .extend('daze')
  // Colors
  .property('primary.color', '#001EB2')
  .property('primary.font', 'Bebas Neue')

  // Page
  .property('page.background.color', '#E8DFF5')

  // Text
  .property('text.font', 'Barlow Condensed')
  .property('text.color', '#001EB2')

  // Menu
  .property('menu.background.color', '#CCED43')
  .property('menu.text.font', 'Bebas Neue')
  .property('menu.text.color', '#0C1DEA')
  .property('menu.text.transform', 'none')

  // Image
  .property(
    'image.default',
    'https://images.teemill.com/b9ctaypdyioxzzekdrdzzbzod4bf6o89hwwqudqmniys6cfn.png'
  )

  // Banner
  .property('banner.title.color', '#001EB2')
  .property('banner.copy.color', '#001EB2')

  // Quote
  .property('quote.fullBackground.color', '#CCED43')

  // AnnouncementV2
  .property('announcement.v2.text.color', '#CCED43')
  .property('announcement.v2.background.color', '#001EB2')

  // Newsletter
  .property('newsletter.text.color', '#CCED43')
  .property('newsletter.fullBackground.color', '#001EB2')

  // Footer
  .property('footer.text.color', '#001EB2')
  .property('footer.background.color', '#D3F36E');
