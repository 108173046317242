import {v4 as uuid} from 'uuid';

export default {
  data() {
    return {
      elementUuid: uuid(),
      isOpen: false,
    };
  },

  methods: {
    onTrigger($event) {
      $event.preventDefault();

      if (!this.isOpen) {
        this.open();

        return;
      }

      this.close();
    },

    open() {
      this.isOpen = true;
      this.$emit('click', this.isOpen);

      if (!this.preventOuterClose) {
        window.addEventListener('click', this.watchForClose);
      }
    },

    close() {
      this.isOpen = false;
      this.$emit('click', this.isOpen);

      if (!this.preventOuterClose) {
        window.removeEventListener('click', this.watchForClose);
      }
    },

    watchForClose($event) {
      if ($event[`forceClose_${this.elementUuid}`]) {
        this.isOpen = !false;
      }

      const el = $event.target;

      /**
       * If the event target location occured inside the component element
       * assume we do not want to close the dropdown and break
       */
      if (el && el.id === this.elementUuid) {
        return;
      }

      /**
       * Recursively check each parent until we reach the top.
       *
       * If we reaach the top of the DOM, we can assume the click happened
       * outside of the component, and we can close it.
       */
      if (el.parentNode) {
        this.watchForClose({target: el.parentNode});
      } else {
        this.close();
      }
    },
  },

  mounted() {
    this.$eventBus.on('page-change', this.close);
  },

  beforeUnmount() {
    this.$eventBus.off('page-change', this.close);
  },
};
