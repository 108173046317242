import {formatUrl} from '@teemill/common/helpers';

import {BlockFactorySource} from '../../classes/blockFactorySource';

export const collection = new BlockFactorySource<
  {
    urlName: string;
    name: string;
    image: string;
  },
  {
    limit: number;
  }
>({
  name: 'collection',
  request: async ({limit}) => {
    const divisionId =
      // @ts-expect-error Convert vuex to ts
      $store.state.store?.active?.id || $store.state.subdomain?.division;

    // @ts-expect-error Convert axios to ts
    return $axios
      .get(formatUrl(`/omnis/v3/collections/get/${divisionId}/`))
      .success((collections: any[]) =>
        collections
          .reverse()
          .slice(0, limit as number)
          .map((collection: any) => ({
            name: collection.name,
            image: collection.banner.thumbnails.full,
            urlName: collection.urlName,
          }))
      )
      .any(() => [])
      .output() as any;
  },
  parser: rawData =>
    rawData.map(({urlName, name, image}) => ({
      title: name,
      images: [image],
      href: `/collection/${urlName}/`,
    })),
  params: {
    limit: 1,
  },
});
