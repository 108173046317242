import {useGtm} from '@teemill/common/services';
import {tracker} from '@teemill/common/plugins';
import {NotificationHandler} from '../types/notificationHandler';

export const dismissAddedToBag: NotificationHandler = {
  path: 'plugins/add-to-bag/dismiss',
  handler: async (_, {dismiss}) => {
    useGtm().trackEvent({event: 'onAddToBagDismiss'});
    tracker.event('add-to-bag-dismiss');

    dismiss();
  },
};

export const goToBag: NotificationHandler = {
  path: 'plugins/add-to-bag/go-to-bag',
  handler: async (_, {dismiss, navigate}) => {
    useGtm().trackEvent({event: 'onAddToBagGoToBag'});
    tracker.event('add-to-bag-go-to-bag');

    navigate('/basket');

    dismiss();
  },
};

export const goToCheckout: NotificationHandler = {
  path: 'plugins/add-to-bag/go-to-checkout',
  handler: async (_, {dismiss, navigate}) => {
    useGtm().trackEvent({event: 'onAddToBagGoToCheckout'});
    tracker.event('add-to-bag-go-to-checkout');

    navigate('/checkout?express=true');

    dismiss();
  },
};
