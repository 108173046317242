export default () => {
  /**
   * Pass variation IDs in the URL to force a variation
   * @example http://localhost:8080/?split-var=1,5,9
   */
  const urlVariationMatch = location.search.match(
    /(?:&|\?)split-var=(.+?)(?:&|$)/
  );
  let urlVariations = [];

  if (urlVariationMatch && urlVariationMatch[1]) {
    urlVariations = urlVariationMatch[1].split(',');
  }

  let splitTestData = {
    splitTests: [],
    forceVariations: [],
  };

  if (window.serverData && window.serverData.splitTest) {
    splitTestData = window.serverData.splitTest;
  }

  splitTestData.forceVariations = urlVariations;

  return splitTestData;
};
