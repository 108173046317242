export const toTextShadow = (
  name: 'none' | 'light' | 'medium' | 'heavy'
): string => {
  switch (name) {
    default:
      return '';
    case 'none':
      return '0 0px 0px #0000';
    case 'light':
      return '0 2px 4px #0004';
    case 'medium':
      return '0 3px 6px #0006';
    case 'heavy':
      return '0 4px 8px #0008';
  }
};

export const toIconShadow = (
  shadow: 'none' | 'light' | 'medium' | 'heavy',
  colour: string
): string => {
  switch (shadow) {
    default:
      return '';
    case 'none':
      return `drop-shadow(0 0px 0px ${colour})`;
    case 'light':
      return `drop-shadow(0 1px 2px ${colour})`;
    case 'medium':
      return `drop-shadow(0 3px 6px ${colour})`;
    case 'heavy':
      return `drop-shadow(0 4px 8px ${colour})`;
  }
};

export const toRatio = (
  name: 'wide' | 'short' | '4:3' | 'square' | 'tall'
): number => {
  switch (name) {
    case 'wide':
      return 0.36;

    case 'short':
      return 0.49;

    case '4:3':
      return 0.75;

    default:
    case 'square':
      return 1.026;

    case 'tall':
      return 1.5;
  }
};
