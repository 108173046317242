import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

/**
 * @deprecated
 * @todo: refactor progressBadges() in core-api and BadgeProgressBlock.vue
 * to remove this block
 */
export const badge = new BlockType({
  name: 'badge',
  title: 'Badge',
  compatibility: ['page'],
  builder: () => BlockBuilder.create('badge'),
});
