import {base} from './base';

export const charcoal = base
  .extend('charcoal')
  // Colors
  .property('primary.color', '#5D6A7A')
  .property('primary.font', 'Poiret One')

  // Page
  .property('page.background.color', '#EDEFF2')

  // Text
  .property('text.font', 'Acumin Pro')
  .property('text.color', '#0A1B2D')

  // Menu
  .property('menu.background.color', '#0A1B2D')
  .property('menu.text.font', 'Poiret One')
  .property('menu.text.color', '#EDEFF2')

  // Image
  .property('image.border.radius', '0px')
  .property(
    'image.default',
    'https://images.teemill.com/og6ut5ahtodxyrqvfd4hddtizx4si4qsirth6pqgrwosr392.png'
  )

  // Banners
  .property('banner.title.color', '#EDEFF2')
  .property('banner.copy.color', '#EDEFF2')

  // Buttons
  .property('button.border.radius', '0px')

  // Features
  .property('feature.border.radius', '0px')
  .property('feature.text.color', '#0A1B2D')

  // Quote
  .property('quote.text.color', '#EDEFF2')
  .property('quote.border.radius', '0px')
  .property('quote.fullBackground.color', '#323E49')

  // AnnouncementV2
  .property('announcement.v2.text.color', '#EDEFF2')
  .property('announcement.v2.border.radius', '0px')
  .property('announcement.v2.background.color', '#636363')

  // Newsletter
  .property('newsletter.text.color', '#EDEFF2')
  .property('newsletter.border.radius', '0px')
  .property('newsletter.fullBackground.color', '#5D6A7A')

  // Footer
  .property('footer.text.color', '#EDEFF2')
  .property('footer.background.color', '#0A1B2D');
