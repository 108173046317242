import {v4 as uuidV4} from 'uuid';

import {Vector} from '@teemill/common/classes';

import type {Theme} from '@teemill/modules/theme-builder';

import type {Page} from './page';
import type {BlockFactory} from './blockFactory';
import type {BlockFactorySource} from './blockFactorySource';

import {BlockType} from './blockType';
import {PageObject} from './pageObject';
import {Property, PropertySet} from './property';
import {filterBlocks} from '../utils/index';
import {cloneDeep} from 'lodash';

export type BlockFlag =
  | 'constructing'
  | 'created'
  | 'updated' // updated block properties
  | 'reordered' // updated block order
  | 'deleted'
  | 'placeholder';
export type BlockFlags = Set<BlockFlag>;

export interface BlockLike {
  id?: number;
  type: BlockType;
  size?: Vector;
  properties?: PropertySet;
  items?: Block[];
  flags?: BlockFlags | BlockFlag[];
  order?: number;
  uid?: string;
}

export class Block extends PageObject<Block> {
  public id?: number;
  public type: BlockType;
  public size: Vector;
  public properties: PropertySet;
  public items: Block[];
  public flags: BlockFlags;
  public order: number;
  public uid: string;

  public static factories: Record<string, BlockFactory>;
  public static sources: Record<string, BlockFactorySource<any, any>>;
  private static resolvedBlocks: string[] = [];

  public constructor({
    id,
    type,
    size = new Vector(12, 0),
    properties = {},
    items = [],
    flags = [],
    order = Infinity,
    uid = uuidV4(),
  }: BlockLike) {
    super();

    this.id = id;
    this.type = BlockType.from(type);
    this.size = new Vector(size);
    this.properties = Property.map(properties);
    this.items = items.map(i => new Block(i)).sort((a, b) => a.order - b.order);
    this.flags = new Set(flags);
    this.order = order === null ? Infinity : order; // ? JSON encoded Infinity is converted to null.
    this.uid = uid;

    Object.values(this.properties).forEach(p => p.assignTo(this));
  }

  public get filteredItems(): Block[] {
    if (this.page?.mode === 'preview') {
      return this.visibleItems;
    }

    return filterBlocks(this.visibleItems);
  }

  public get fixed(): boolean {
    return this.order === 0 && this.type.name === 'slideshow';
  }

  public get published(): boolean {
    return this.property('published') === undefined
      ? true
      : (this.property('published', 'boolean') as boolean);
  }

  public get unpublished(): boolean {
    if (!this.published) {
      return true;
    }

    if (this.items.length > 0) {
      const publishedItems = this.items.filter(item => item.published);

      return publishedItems.length === 0;
    }

    return false;
  }

  public async resolve(): Promise<boolean> {
    if (Block.resolvedBlocks.includes(this.uid)) {
      return false;
    }

    Block.resolvedBlocks.push(this.uid);

    if (this.property('itemFactory')) {
      const [factoryName, sourceName] = (
        this.property('itemFactory') as string
      ).split(',');
      const factory = Block.factories[factoryName];
      const source = Block.sources[sourceName];

      if (factory && source) {
        const builders = await factory
          .using(source)
          .run(Property.toValues(this.properties));

        const shadowItem = this.items.find(
          item => item.type.name === '_shadow_'
        );

        this.items = [
          ...(await Promise.all(builders.map(builder => builder.make()))),
          ...this.items.filter(item => item.type.name === '_shadow_'),
        ];

        this.items.forEach(item => {
          item.assignTo(this);

          if (
            shadowItem &&
            item.type.name === shadowItem.property('shadowFor')
          ) {
            item.fillPropertiesFrom(shadowItem);
          }
        });

        return true;
      }
    }

    return false;
  }

  /**
   * Gets a formatted version of a Block object that only contains
   * values relevant to user input.
   */
  public get state() {
    const clone = this.copy();

    clone.uid = '';

    clone.properties = Property.map(clone.properties, p => {
      const castToType = typeof p.value === 'number' ? 'string' : undefined;
      return new Property({value: p.get(castToType)});
    });

    // ignores 'updated' flag because it is not always added by user action
    clone.flags.forEach((value, key, flags) => {
      if (value !== 'deleted') {
        flags.delete(value);
      }
    });

    // ignores dynamic items
    if (clone.property('itemFactory')) {
      clone.items = [];
    }

    clone.items = clone.items.map(item => item.state);

    return clone;
  }

  public toObject(): Record<string, unknown> {
    const {id, uid, type, size, properties, items, flags, order} = this;

    return {
      id,
      uid,
      type: type.name,
      size: size.toObject(),
      properties: Property.map<Property>(properties, p => p.toObject()),
      items: items.map((i: Block) => i.toObject()),
      flags: Array.from(flags),
      order,
    };
  }

  public toString(): string {
    return JSON.stringify(this.toObject());
  }

  public setOrder(value: number): Block {
    if (this.order !== value) {
      this.flags.add('reordered');
    }

    this.order = value;

    return this;
  }

  public flatten(): Block[] {
    return [this, ...this.items.flatMap((i: Block) => i.flatten())];
  }

  public assignTo(block: Block): Block;
  public assignTo(page: Page): Block;
  public assignTo(object: any): Block {
    if (object instanceof Block) {
      this.parent = object;
      this.page = object.page;
    } else {
      this.page = object;
    }

    Object.values(this.properties).forEach(p => p.assignTo(this));

    this.items.forEach(item => item.assignTo(this));

    return this;
  }

  public copy(): Block {
    return new Block(cloneDeep(this));
  }

  public get theme(): Theme | undefined {
    if (this.overrideTheme !== undefined) {
      return this.overrideTheme;
    }

    if (this.parent !== undefined) {
      return this.parent.theme;
    }

    return this.page?.theme;
  }

  public get pseudoItems(): Block[] {
    return this.items.filter(
      item => item.type.name.startsWith('_') && item.type.name.endsWith('_')
    );
  }

  public get visibleItems(): Block[] {
    return this.items.filter(
      item => !item.type.name.startsWith('_') && !item.type.name.endsWith('_')
    );
  }
}
