<template>
  <div class="tml-split-test" :class="{invalid: !valid}">
    <slot />
    <div v-if="!valid" class="error">
      <b>ERROR</b><br />
      <small>Split Test <b>{{ name }}</b> doesn't exist or is no longer
        active.</small>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TmlSplitTest',

  provide() {
    return {
      splitTestName: this.name,
      loadVariation: this.loadVariation,
    };
  },

  props: {
    name: String,
  },

  data() {
    return {
      valid: true,
      loadedVariation: null,
    };
  },

  async created() {
    await this.$store.dispatch('splitTest/startSession', this.name);
  },

  methods: {
    loadVariation(variationName) {
      if (!this.loadedVariation) {
        this.loadedVariation = variationName;
      }

      return this.loadedVariation;
    },
  },
};
</script>

<style lang="scss" scoped>
.tml-split-test {
  &.invalid {
    padding: 0.25em;
    background-color: var(--tml-error-color);
    color: $background-color;
    border-radius: $border-radius;

    .error {
      padding: 0.25em;
    }
  }
}
</style>
