import {base} from './base';

export const simply = base
  .extend('simply')
  // Colors
  .property('primary.color', '#896D71')
  .property('primary.font', 'Varela')

  // Page
  .property('page.background.color', '#F6F6F6')

  // Text
  .property('text.font', 'Varela')
  .property('text.color', '#60574F')
  .property('text.border.radius', '0px')

  // Menu
  .property('menu.background.color', '#E0DFD9')
  .property('menu.text.font', 'Varela')
  .property('menu.text.color', '#60574F')
  .property('menu.text.transform', 'none')

  // Image
  .property('image.border.radius', '0px')
  .property(
    'image.default',
    'https://images.teemill.com/wmlrao5trtwujoo1wznskujaa6oyj1fqihxgi7kcepjpk3uw.jpeg'
  )

  // Buttons
  .property('button.border.radius', '0px')

  // Quote
  .property('quote.fullBackground.color', '#E0DFD9')

  // AnnouncementV2
  .property('announcement.v2.text.color', '#E0DFD9')
  .property('announcement.v2.background.color', '#896D71')

  // Newsletter
  .property('newsletter.text.color', '#E0DFD9')
  .property('newsletter.border.radius', '0px')
  .property('newsletter.fullBackground.color', '#896D71')

  // Footer
  .property('footer.text.color', '#60574F')
  .property('footer.background.color', '#E0DFD9');
