import {defineAsyncComponent} from 'vue';

export {default as TmlConfirmPasswordPopup} from './TmlConfirmPasswordPopup.vue';
export {default as TmlConfirmPopup} from './TmlConfirmPopup.vue';
export const TmlCookiePopup = defineAsyncComponent(
  () => import('./TmlCookiePopup.vue')
);
// export {default as TmlCookiePopup} from './TmlCookiePopup.vue';
export {default as TmlCropperPopup} from './TmlCropperPopup.vue';
export {default as TmlDashboardRoleMatrixPopup} from './TmlDashboardRoleMatrixPopup.vue';
export {default as TmlDriveFilePopup} from './TmlDriveFilePopup.vue';
export {default as TmlFeedbackPopup} from './TmlFeedbackPopup.vue';
export {default as TmlGotItPopup} from './TmlGotItPopup.vue';
export {default as TmlImagePreviewPopup} from './TmlImagePreviewPopup.vue';
export {default as TmlLoadingPopup} from './TmlLoadingPopup.vue';
// export {default as TmlMagicModelPopup} from './TmlMagicModelPopup.vue';
export {default as TmlPushNotSupportedPopup} from './TmlPushNotSupportedPopup.vue';
export {default as TmlRequestDashboardPermissionPopup} from './TmlRequestDashboardPermissionPopup.vue';
export {default as TmlRequestPermissionPopup} from './TmlRequestPermissionPopup.vue';
export {default as TmlSelectProductImagePopup} from './TmlSelectProductImagePopup.vue';
export {default as TmlConfirmCorrespondencePopup} from './TmlConfirmCorrespondencePopup.vue';
