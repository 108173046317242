<template>
  <a
    :href="ensureSafeUrl(href)"
    :title="title"
    :style="{color: textColor}"
    :target="target || (!isRouterLink($router, href) ? '_blank' : undefined)"
    rel="noopener"
    :download="download"
    :class="{
      disabled,
      'cursor-pointer': onClick,
      pro: forcePro,
    }"
    @click.stop="onClick"
    @mouseover="$emit('mouseover', $event)"
    @mouseleave="$emit('mouseleave', $event)"
  >
    <!-- @slot This default slot embeds content inside the link. Negates the icon and text props. -->
    <slot>
      <font-awesome-icon v-if="icon" class="icon mr-1" :icon="icon" />
      <span>{{ text }}</span>
    </slot>
  </a>
</template>

<script>
import kebabCase from 'lodash/kebabCase';
import {ensureSafeUrl, isRouterLink} from '@teemill/common';

/**
 * TmlAnchor is used for linking to another URL.
 * TmlAnchor will detect if the link's URL is a valid Vue page or not, and use an a tag or router-link tag accordingly
 *
 * @example <tml-anchor href="https://en.wikipedia.org/wiki/Lewis_Hamilton" target="_blank">Read more</tml-anchor>
 * @example <tml-anchor href="https://en.wikipedia.org/wiki/Lewis_Hamilton" target="_blank" :icon="faStar" text="Read more"/>
 */
export default {
  name: 'TmlAnchor',

  props: {
    /**
     * The URL to link to. This can be an absolute or relative URL
     */
    href: String,
    /**
     * Optional. The text to include in the link. Alternatively you can use the default slot to add content.
     */
    text: String,
    /**
     * The link's title, for SEO
     */
    title: String,
    /**
     * A font awesome icon to prepend inside the link. Only valid if not using the slot method.
     */
    icon: Object,
    /**
     * anchor target attribute
     * @see https://www.w3schools.com/tags/att_a_target.asp
     */
    target: String,
    /**
     * Adds download attribute to anchor tag
     */
    download: String,
    /**
     *
     */
    disabled: Boolean,
    /**
     * Text color
     */
    textColor: String,
    /**
     * Make the button always pro.
     */
    forcePro: Boolean,
  },

  computed: {
    internalHref() {
      if (this.getActionLink(this.href)) {
        return '#';
      }

      return this.href;
    },
  },

  methods: {
    ensureSafeUrl,
    isRouterLink,

    onClick(event) {
      if (this.disabled) {
        event.preventDefault();

        this.$emit('disabled', event);

        return;
      }

      this.$emit('click', event);

      if (this.href) {
        if (this.href === '#') {
          event.preventDefault();

          return;
        }

        if (this.getActionLink(this.href)) {
          event.preventDefault();

          const actionName = this.getActionLink(this.href)[1];

          if (actionName) {
            $eventBus.emit(`anchor-${kebabCase(actionName)}`);
          }

          return;
        }

        if (isRouterLink(this.$router, this.href) && this.target !== '_blank') {
          event.preventDefault();

          this.$router.push(this.href);
        }
      }
    },

    getActionLink(url) {
      return url.match(/^@(.+)/);
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  &.pro {
    color: $primary-color-pro !important;
  }
}
</style>
