<template>
  <div class="editor-styling" v-html="html" />
</template>

<script>
/**
 * !This component should be reserved for rendering content defined by the
 * !TmlHtmlEditor component. Rendering html can be dangerous, and therefor the
 * !content should come from a trusted source.
 */
export default {
  name: 'TmlRenderHtmlEditorOutput',

  props: {
    html: {
      type: String,
      required: true,
    },
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss">
.editor-styling {
  p {
    margin-bottom: 1em;
  }

  h1,
  h2,
  h3 {
    margin-bottom: 0;
    line-height: 2em !important;
  }

  p {
    word-break: break-word;
  }

  li > p {
    margin-bottom: 0em;
  }

  li {
    margin-left: 1.5em;
    list-style-type: circle;
  }

  blockquote {
    margin: 1em 0;
    font-size: 1.25em;
    font-style: italic;

    p {
      margin: 0;
    }
  }

  img {
    width: 100%;
    margin: 1em auto;
  }

  iframe {
    margin-bottom: 1em;
    width: 100%;
  }

  div.custom-embed {
    border-radius: $border-radius;
    position: relative;
    overflow: hidden;
  }

  table {
    border-collapse: collapse;
    border-spacing: 2px;
    width: 100%;
    margin: 0 0 1em 0;
  }

  table,
  th,
  td {
    border: 1px solid black;
    padding: 3px;
  }

  th {
    font-weight: bold;
  }

  th,
  td {
    height: 20px;
  }
}
</style>
