import {intersection} from 'lodash';

import {Component} from 'vue';

import {TeemillError} from '@teemill/common/errors';

import {Block} from './block';
import {BlockBuilder} from './blockBuilder';
import {markRaw} from 'vue';

/**
 * @name BlockType
 * @description This class is used as a template class to Blocks. Blocks will be
 *              initialised from a block type.
 */

export class UnregisteredBlockTypeError extends TeemillError {
  constructor(name: string) {
    super(`Block type of ${name} hasn't been registered`);
  }
}

export class NoBuilderError extends TeemillError {
  constructor() {
    super('Tried to make type but no builder was defined');
  }
}

export class DuplicateBlockTypeError extends TeemillError {
  constructor() {
    super('Tried to register block type that conflicts with another');
  }
}

export type CompatibilityType = 'page' | 'mail' | '*';
export type CompatibilitySet = CompatibilityType[];

export class BlockType {
  static types: BlockType[] = [];

  name: string;
  title: string;

  component?: Component | (() => Promise<Component>);
  builder?: () => BlockBuilder;
  compatibility: Set<string>;

  constructor({
    name,
    title,
    component,
    builder,
    compatibility,
  }: {
    name: string;
    title: string;
    component?: Component | (() => Promise<Component>);
    builder?: () => BlockBuilder;
    compatibility: CompatibilitySet;
  }) {
    this.name = name;
    this.title = title;
    this.component = component !== undefined ? markRaw(component) : undefined;
    this.builder = builder !== undefined ? markRaw(builder) : undefined;
    this.compatibility = new Set(compatibility);
  }

  static register(type: BlockType): void {
    const existingType = BlockType.types.find(t => t.name === type.name);

    if (existingType) {
      throw new DuplicateBlockTypeError();
    }

    BlockType.types.push(type);
  }

  static from(input: string): BlockType;
  static from(input: Block): BlockType;
  static from(input: BlockType): BlockType;
  static from(input: any): BlockType {
    if (input instanceof BlockType) {
      return input;
    }

    let type: BlockType | undefined;

    if (typeof input === 'string') {
      type = BlockType.types.find(b => b.name === input);
    } else if (input instanceof Block) {
      type = BlockType.from(input.type.name);
    }

    if (type === undefined) {
      throw new UnregisteredBlockTypeError(input);
    }

    return type;
  }

  /**
   * @param {Array|Set} types
   *
   * @returns {Boolean}
   */
  compatibleWith(types: Set<string>) {
    return !!intersection(Array.from(this.compatibility), Array.from(types))
      .length;
  }
}
