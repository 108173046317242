/**
 * @name toUint8Array
 * @description Create a uint8Array from a string
 */
export const toUint8Array = (key: string): Uint8Array => {
  const padding = '='.repeat(((4 - key.length) % 4) % 4);
  const base64 = (key + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
};
