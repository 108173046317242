import {base} from './base';

export const vantage = base
  .extend('vantage')
  .deprecate()

  // Colors
  .property('primary.color', '#2A2A2A')
  .property('primary.font', 'Archivo Black')

  // Page
  .property('page.background.color', '#FAFAFA')

  // Headings
  .property('heading1.transform', 'uppercase')
  .property('heading2.transform', 'uppercase')
  .property('heading3.transform', 'uppercase')

  // Text
  .property('text.color', '#222222')
  .property('text.font', 'Open Sans')
  .property('text.shadow', 'none')

  // Menu
  .property('menu.background.color', '#2A2A2A')
  .property('menu.text.color', '#FFFFFF')
  .property('menu.text.font', 'Open Sans')

  // Image
  .property(
    'image.default',
    'https://images.teemill.com/gw43ks2mq7jrmbozht6zpguql58tf5urb0t1s0achhy9jrjh.jpg'
  )

  // Banners
  .property('banner.title.background.color', '#2a2a2a')

  // Feature
  .property('feature.text.color', '#2A2A2A')

  // Quote
  .property('quote.text.color', '#FFFFFF')
  .property('quote.background.color', '#2A2A2A')
  .property('quote.fullBackground.color', '#2A2A2A')

  // AnnouncementV2
  .property('announcement.v2.text.color', '#FFFFFF')
  .property('announcement.v2.background.color', '#2A2A2A')
  .property('announcement.v2.fullBackground.color', '#0000')

  // Newsletter
  .property('newsletter.text.color', '#FFFFFF')
  .property('newsletter.background.color', '#2a2a2a')

  //Footer
  .property('footer.text.color', '#222222')
  .property('footer.background.color', '#FAFAFA');
