import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const remillReturn = new BlockType({
  name: 'remillReturn',
  title: 'Remill Return',
  compatibility: ['page'],
  component: () =>
    import(
      /* webpackChunkName: "RemillReturnBlock" */ './RemillReturnBlock.vue'
    ),
  builder: () => BlockBuilder.create('remillReturn'),
});
