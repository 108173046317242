<template>
  <transition-group
    v-if="!page.isV1Page"
    :key="page.uuid"
    :name="modules.templateEditor || modules.blockEditor ? 'list' : 'none'"
    tag="div"
    class="grid relative"
    :class="
      app.isTeemill && page.layout === 'standard' ? 'container' : undefined
    "
  >
    <section
      v-for="(block, index) in blocks"
      :key="`block-${block.id || block.uid}`"
      class="page-section"
      :style="{
        backgroundColor: theme().get('page.background.color'),
        gridRow: index + 1,
        gridColumn: 1,
        display: shouldOnlyShowFirstBlock && index > 1 ? 'none' : undefined,
      }"
    >
      <block-renderer
        :block="block"
        :before-block="blocks[index - 1]"
        :after-block="blocks[index + 1]"
        inject-padding
        @resolved="resolved"
      />
      <div
        v-if="modules.improvedHitTarget"
        class="absolute cursor-pointer"
        :style="{
          width: 'var(--content-width)',
          marginLeft: 'calc((100% - var(--content-width)) / 2)',
          top: `-${
            blocks[index - 1]
              ? blocks[index - 1].property('padding', 'number', 0) / 2
              : 0
          }em`,
          bottom: `${block.property('padding', 'number', 0) / 2}em`,
        }"
        @click="onClick(block)"
      />

      <app-theme
        v-if="
          modules.templateEditor &&
            showEditControls &&
            blocks[index] &&
            blocks[index + 1] &&
            platformTheme
        "
        :theme="platformTheme"
      >
        <page-divide-edit-controls
          style="z-index: 1"
          :page="page"
          :before-block="blocks[index]"
          :after-block="blocks[index + 1]"
          @add="onAdd"
          @focus="onFocus"
        />
      </app-theme>
    </section>
  </transition-group>
  <tml-masonry-grid
    v-else
    ref="grid"
    class="container"
    :items="blocks"
    :columns="{
      xs: 6,
      lg: 12,
    }"
    :item-size="item => item.data.size"
    :editable="editable"
    :draggable="draggable && editable && !saving"
    :y-spacing="internalYSpacing"
    :end-of-row-placeholder="editable"
    @reordered="onReordered"
    @clicked-block="onClick"
    @add="onAdd"
  >
    <template #template="{item, before, after}">
      <tml-idle-renderer v-if="item.order !== 0 && shouldRenderWhenIdle">
        <div class="flex relative justify-center">
          <block-renderer
            :block="item"
            :before-block="before"
            :after-block="after"
            inject-padding
            @resolved="resolved"
          />
        </div>
      </tml-idle-renderer>

      <div v-else class="flex relative">
        <block-renderer
          :block="item"
          :before-block="before"
          :after-block="after"
          inject-padding
          @resolved="resolved"
        />
      </div>
    </template>
    <template v-if="modules.improvedHitTarget" #blockOverlay="{before, item}">
      <div
        class="absolute cursor-pointer"
        :style="{
          width: `${contentWidth()}px`,
          top: `-${before ? before.property('padding', 'number', 0) / 2 : 0}em`,
          bottom: `${item.property('padding', 'number', 0) / 2}em`,
        }"
        @click="onClick(item)"
      />
    </template>
    <template #naturalDivider="{before, after}">
      <page-divide-edit-controls
        v-if="showEditControls && before && after"
        :before-block="before ? before.data : undefined"
        :after-block="after ? after.data : undefined"
        :before-item="before"
        :after-item="after"
        @add="onAdd"
        @focus="onFocus"
      />
    </template>
  </tml-masonry-grid>
  <tml-placeholder
    v-if="modules.templateEditor || modules.blockEditor"
    text="Add new block"
    :icon="faLayerPlus"
    big-icon
    :background-color="addAlpha(theme().get('text.color'), 0.05)"
    :additive="false"
    :aspect-ratio="2"
    @click="pageBus.emit('add-block')"
  />
</template>

<script setup>
import {inject, nextTick} from 'vue';

const platformTheme = inject('platformTheme', () => undefined)();
</script>

<script>
import isbot from 'isbot';
import {Log} from '@devanjs/log';
import {addAlpha} from '@teemill/utilities';
import {faLayerPlus} from '@fortawesome/pro-light-svg-icons';

import {getBreakpoint, isLighthouse} from '@teemill/common/helpers';
import {AppTheme} from '@teemill/modules/app';

import {pageBus} from '../bus';

import {TmlIdleRenderer} from '@teemill/components';
import BlockRenderer from '../components/BlockRenderer.vue';

import {filterBlocks} from '../utils/index';

import {modules} from '../modules';

export default {
  name: 'PageRenderer',

  components: {
    TmlIdleRenderer,
    BlockRenderer,
    AppTheme,
    PageDivideEditControls: () =>
      import(
        /* webpackChunkName: "TmlPageTemplateBuilder" */ './PageDivideEditControls.vue'
      ),
  },

  inject: ['contentWidth', 'theme', 'app'],

  provide() {
    return {
      contentWidth: () => this.width || this.contentWidth(),
      showEditControls: () => this.showEditControls,
    };
  },

  props: {
    page: Object,

    width: Number,
  },

  data() {
    return {
      faLayerPlus,
      pageBus,

      saving: false,

      showEditControls: false,
      draggable: true,

      internalYSpacing: 24,

      largestContentfulPaintHasLoaded: false,
      modules,
    };
  },

  computed: {
    editable() {
      return this.page.mode === 'preview';
    },

    blocks() {
      Log.tag('pages-v')
        .orange('Renderer')
        .text('Rendering page')
        .lightBlue(this.page?.id)
        .lightBlue(this.page?.uuid)
        .lightGreen(this.page?.blocks.length)
        .info();

      if (!this.page) {
        return [];
      }

      let blocks = this.page.blocks.filter(b => !b.flags.has('deleted'));

      if (!this.editable) {
        blocks = filterBlocks(blocks);
      }

      if (this.shouldOnlyShowFirstBlock && this.page.isV1Page) {
        return blocks.slice(0, 1);
      }

      return blocks;
    },

    shouldOnlyShowFirstBlock() {
      return (
        !this.largestContentfulPaintHasLoaded &&
        this.firstBlockIsASlideshow &&
        (!isbot(navigator.userAgent) || isLighthouse(navigator.userAgent))
      );
    },

    firstBlockIsASlideshow() {
      const firstItem = this.page.blocks.find(b => b.order === 0);

      if (firstItem?.type?.name !== 'slideshow') {
        return false;
      }

      // ! Ensure slideshow has items.
      return firstItem.items.length > 0;
    },

    shouldRenderWhenIdle() {
      return (
        !this.editable &&
        this.allBlocksAreFullWidth &&
        !isbot(navigator.userAgent)
      );
    },

    allBlocksAreFullWidth() {
      return this.blocks.every(b => b.size.x === 12);
    },
  },

  watch: {
    'page.isSaving': {
      immediate: true,
      handler(value) {
        if (value) {
          this.saving = true;
        } else {
          // ! This gives the masonry grid enough time to re-render and sort it's
          // ! self out.
          setTimeout(() => {
            this.saving = false;
          }, 500);
        }
      },
    },
  },

  created() {
    performance.mark('page-renderer-created');

    pageBus.once('largest-contentful-paint-loaded', () => {
      performance.mark('largestContentfulPaintHasLoaded-loaded');
      this.largestContentfulPaintHasLoaded = true;
    });

    // ! Failover for LCP event.
    setTimeout(() => {
      performance.mark('largestContentfulPaintHasLoaded-timeout');
      this.largestContentfulPaintHasLoaded = true;
    }, 1000);

    // window.addEventListener('resize', this.onResize);

    pageBus.on('page-show-edit-controls', this.onShowEditControls);
    pageBus.on('page-focus-block', this.onFocusBlock);
    pageBus.on('page-not-found', this.onPageNotFound);

    this.page.setTheme(this.theme());

    if (this.modules.templateEditor) {
      this.showEditControls = true;
    }

    this.onResize();
  },

  unmounted() {
    // window.removeEventListener('resize', this.onResize);

    pageBus.off(
      'page-builder-page-show-edit-controls',
      this.onShowEditControls
    );
    pageBus.off('page-focus-block', this.onFocusBlock);

    pageBus.off('page-not-found', this.onPageNotFound);
  },

  methods: {
    resolved(value) {
      pageBus.emit('update-blocks', {
        page: this.page.toObject(),
        blocks: [value.toObject()],
      });
    },

    onAdd({size, order, before, after, block}) {
      pageBus.emit('add-block', {
        block: block?.toObject(),
        size: size?.toObject(),
        order,
        before: before?.toObject(),
        after: after?.toObject(),
      });
    },

    /**
     * @deprecated
     */
    onClick({uid}) {
      const block = this.page.blocks.find(b => b.uid === uid);

      if (block) {
        pageBus.emit('edit-block', {block: block.toObject()});
      }
    },

    onReordered(reorderedItems) {
      this.blocks.forEach(block => {
        const newOrder = reorderedItems.find(i => i.uid === block.uid)?.order;

        if (typeof newOrder === 'number') {
          block.setOrder(newOrder);
        }
      });
    },

    onResize() {
      const spacing = this.page.property('ySpacing', 'json', {xs: 24});

      this.internalYSpacing = getBreakpoint(spacing);
    },

    onFocus(item) {
      const parent = item.parentElement;
      const before = parent.offsetTop + parent.children[0].clientHeight / 2;

      setTimeout(() => {
        const after = parent.offsetTop + parent.children[0].clientHeight / 2;

        window.scrollTo({
          left: 0,
          top: document.documentElement.scrollTop + (after - before),
          behavior: 'smooth',
        });
      });
    },

    onFocusBlock(block) {
      const gridItem = this.$refs.grid?.internalItems.find(
        i => i.data.uid === block.uid
      );

      if (gridItem) {
        setTimeout(() => {
          const element = gridItem.vNode?.elm;

          if (element) {
            window.scrollTo({
              left: 0,
              top:
                element.offsetTop +
                element.clientHeight / 2 -
                window.innerHeight / 2,
              behavior: 'smooth',
            });
          }
        }, 100);
      }
    },

    onShowEditControls(value) {
      this.showEditControls = value;
      this.draggable = !value;
    },

    onPageNotFound() {
      this.$router.replace('/404/');
    },
  },
};
</script>

<style scoped lang="scss">
.page-section {
  position: relative;

  &.list-move {
    transition: transform 0.5s;
  }

  &.list-enter-active,
  &.list-leave-active {
    opacity: 1;
    transition: opacity 0.4s;
  }

  &.list-enter-from,
  &.list-leave-to {
    opacity: 0;
    transition: opacity 0.2s;
  }

  /* ensure leaving items are taken out of layout flow so that moving
    animations can be calculated correctly. */
  &.list-leave-active {
    position: absolute;
    left: 0px;
    right: 15px;
  }
}
</style>
