/**
 * @name Font
 * @description Standard font class
 */

export class Font {
  constructor({
    type = 'display',
    name = 'Lato',
    size = 16,
    defaultWeight = 400,
    source = 'google',
    capitalised = false,
    display = '',
    url = '',
  } = {}) {
    this.type = type;
    this.name = name;
    this.size = size;
    this.defaultWeight = defaultWeight;
    this.source = source;
    this.capitalised = capitalised;
    this.display = display;
    this.url = url;
  }

  get asString() {
    const weights = new Set([
      400, // Regular
      700, // Bold
    ]);

    if (this.defaultWeight) {
      weights.add(this.defaultWeight);
    }

    let fontString = this.name;

    if (weights.size > 0) {
      fontString = `${fontString}:${Array.from(weights).join(',')}`;
    }

    if (this.display) {
      fontString = `${fontString}&display=${this.display}`;
    }

    return fontString;
  }

  get displayWeight() {
    return {
      200: 'thin',
      300: 'light',
      400: '', // regular
      500: 'medium',
      600: 'semi-bold',
      700: 'bold',
      800: 'extra-bold',
      900: 'black',
    }[this.defaultWeight];
  }

  get displayName() {
    if (this.displayWeight) {
      return `${this.name} ${this.displayWeight}`;
    }

    return this.name;
  }
}
