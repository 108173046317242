import {RgbColor} from './colorTypes';

/**
 * @name getRgbLuminance
 * @description Calculates the luminance of an RGB colour. Luminance is a measurement of how
 * bright a colour appears to the human eye, where #ffffff has a luminance of 1
 * and #000000 has a luminance of 0.
 *
 * #ff0000, #00ff00 and #0000ff all have the same brightness. The monitor emits
 * equal amounts of light for each of them. However, to the human eye the green
 * appears ~ 3 times brighter than the red, while the red appears ~ 3 times
 * brighter than the blue. This is because the human eye is very good at seeing
 * green and not very good at seeing blue.
 *
 * @return {Number} A number between 0 and 1, where 0 is black and 1 is white.
 * Pure green is 0.7152, pure red is 0.2126 and pure blue is 0.722
 */
export const getRgbLuminance = (rgb: RgbColor): number =>
  (0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b) / 255;
