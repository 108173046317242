import {merge} from 'lodash';
import {formatCurrency} from '@teemill/utilities';
// @ts-expect-error No Types
import Cookie from 'vue-cookie';

import {Manufacturer} from '@teemill/common/models';

export default {
  namespaced: true,
  state: () => ({
    activeCurrency: null,
    currencies: {
      GBP: {
        conversionRate: 1,
        symbolAlign: 'left',
        symbol: '£',
        name: 'Pound',
      },
      USD: {
        conversionRate: 2,
        symbolAlign: 'left',
        symbol: '$',
        name: 'United States Dollar',
      },
      EUR: {
        conversionRate: 1,
        symbolAlign: 'left',
        symbol: '€',
        name: 'Euro',
        decimalPoint: ',',
        thousandsSeparator: '.',
      },
      CAD: {
        conversionRate: 1,
        symbolAlign: 'left',
        symbol: '$',
        subScript: 'C',
        name: 'Canadian Dollar',
      },
      AUD: {
        conversionRate: 1,
        symbolAlign: 'left',
        symbol: '$',
        subScript: 'A',
        name: 'Australian Dollar',
      },
      JPY: {
        conversionRate: 1,
        symbolAlign: 'right',
        symbol: '¥',
        name: 'Japanese Yen',
      },
      KRW: {
        conversionRate: 1,
        symbolAlign: 'right',
        symbol: '₩',
        name: 'South Korean Won',
      },
      NOK: {
        conversionRate: 1,
        symbolAlign: 'right',
        symbol: 'kr',
        subScript: 'N',
        name: 'Norwegian Krone',
      },
      SEK: {
        conversionRate: 1,
        symbolAlign: 'right',
        symbol: 'kr',
        subScript: 'S',
        name: 'Swedish Krona',
      },
      DKK: {
        conversionRate: 1,
        symbolAlign: 'right',
        symbol: 'kr',
        subScript: 'D',
        name: 'Danish Krone',
      },
      BGN: {
        symbolAlign: 'right',
        symbol: 'лв',
        name: 'Bulgarian lev',
      },
      BRL: {
        symbolAlign: 'right',
        symbol: 'R$',
        name: 'Brazilian real',
      },
      CNY: {
        symbolAlign: 'right',
        symbol: '¥',
        name: 'Renminbi',
      },
      NZD: {
        symbolAlign: 'left',
        symbol: '$',
        name: 'New Zealand dollar',
        subScript: 'N',
      },
      CZK: {
        symbolAlign: 'left',
        symbol: 'Kč',
        name: 'Czech koruna',
      },
      HKD: {
        symbolAlign: 'left',
        symbol: 'HK$',
        name: 'Hong Kong dollar',
      },
      HRK: {
        symbolAlign: 'left',
        symbol: 'kn',
        name: 'Croatian kuna',
      },
      HUF: {
        conversionRate: 1,
        symbolAlign: 'left',
        symbol: 'Ft',
        name: 'Hungarian forint',
      },
      IDR: {
        symbolAlign: 'left',
        symbol: 'Rp',
        name: 'Indonesian rupiah',
      },
      INR: {
        symbolAlign: 'left',
        symbol: 'Rp',
        name: 'Indian rupee',
      },
      ISK: {
        symbolAlign: 'left',
        symbol: 'kr',
        name: 'Icelandic króna',
      },
      ILS: {
        symbolAlign: 'left',
        symbol: '₪',
        name: 'Israeli new shekel',
      },
      CHF: {
        symbolAlign: 'left',
        symbol: 'fr.',
        name: 'Swiss franc',
      },
      MXN: {
        symbolAlign: 'left',
        symbol: '$',
        name: 'Mexican peso',
      },
      MYR: {
        symbolAlign: 'left',
        symbol: 'RM',
        name: 'Malaysian ringgit',
      },
      PHP: {
        symbolAlign: 'left',
        symbol: '₱',
        name: 'Philippine peso',
      },
      PLN: {
        symbolAlign: 'left',
        symbol: 'zł',
        name: 'Polish złoty',
      },
      RON: {
        symbolAlign: 'left',
        symbol: 'lei',
        name: 'Romanian leu',
      },
      RUB: {
        symbolAlign: 'left',
        symbol: 'lei',
        name: 'Russian ruble',
      },
      SGD: {
        symbolAlign: 'left',
        symbol: '$',
        name: 'Singapore dollar',
      },
      THB: {
        symbolAlign: 'left',
        symbol: '฿',
        name: 'Thai baht',
      },
      TRY: {
        symbolAlign: 'left',
        symbol: '₺',
        name: 'Turkish lira',
      },
      ZAR: {
        symbolAlign: 'left',
        symbol: 'R',
        name: 'South African rand',
      },
    },
  }),

  getters: {
    /**
     * @name get
     * @description Get the currency with the currency code provided or return
     * the currently active currency.
     *
     * @param {string|null} currencyCode Currency code e.g. GBP
     * @return {object} Currency object
     */
    get: (state, getters, rootState, rootGetters) => currencyCode => {
      let activeCurrency = currencyCode;

      if (rootState.subdomain && rootGetters['subdomain/currency'] !== 'GBP') {
        // Currency conversion only works for stores where the base currency is GBP
        const currency = state.currencies[rootGetters['subdomain/currency']];

        return {
          code: rootGetters['subdomain/currency'],
          ...currency,
        };
      } else if (Manufacturer.active?.currency) {
        const currencyCode = Manufacturer.active.currency;
        const currency = state.currencies[currencyCode];

        return {
          code: currencyCode,
          ...currency,
        };
      }

      if (!activeCurrency) {
        if (state.activeCurrency) {
          activeCurrency = state.activeCurrency;
        } else if (rootGetters['subdomain/getPlugin']) {
          const currencyApp = rootGetters['subdomain/getPlugin']('currency');

          // If the currency app is enabled on the store then check if the
          // user already has a cookie set else use the default currency.
          if (currencyApp && currencyApp.enabled) {
            const cookieCurrency = Cookie.get('currency');

            if (state.currencies[cookieCurrency]) {
              activeCurrency = cookieCurrency;
            } else {
              activeCurrency = currencyApp.currency;
            }
          }
        }
      }

      if (!activeCurrency) {
        activeCurrency = 'GBP';
      }

      return {
        code: activeCurrency,
        ...state.currencies[activeCurrency],
      };
    },

    /**
     * @name getAll
     * @description Get all currencies.
     *
     * @return {array} All currencies
     */
    getAll(state) {
      return Object.entries(state.currencies).map(([key, value]) => ({
        ...value,
        code: key,
      }));
    },

    /**
     * @name getAvailable
     * @description Get a list of the currencies that are available to use.
     *
     * @return {array} Array of currency codes.
     */
    getAvailable(state, getters, rootState, rootGetters) {
      if (rootGetters['subdomain/getPlugin']) {
        const currencyApp = rootGetters['subdomain/getPlugin']('currency');

        if (currencyApp && currencyApp.enabled) {
          const currencies = new Set();

          currencies.add(currencyApp.currency);

          if (currencyApp.currencies) {
            const optionalCurrencies = currencyApp.currencies.split(',');

            for (let i = 0; i < optionalCurrencies.length; i++) {
              const currency = optionalCurrencies[i];
              currencies.add(currency);
            }
          }

          return Array.from(currencies);
        }
      }

      return [Object.keys(state.currencies)[0]];
    },

    /**
     * @name convert
     * @description Convert a value from GBP into the active currency equivalent.
     *
     * @param {number} value Value to be converted.
     * @return {number} Array of currency codes.
     */
    convert: (state, getters, rootState, rootGetters) => (value, currency) => {
      if (rootGetters['subdomain/currency'] !== 'GBP') {
        // if the starting currency is not GBP then currency conversion can't occur
        return value;
      }

      return getters.get(currency).conversionRate * value;
    },

    getLdJson: (state, getters) => product => {
      const validUntil = new Date();
      validUntil.setFullYear(validUntil.getFullYear() + 1);

      return getters.getAll.map(currency => {
        const offerMarkup = {
          '@type': 'Offer',
          priceCurrency: currency.code,
          price: formatCurrency(
            getters.convert(product.price, currency.code)
          ).replace(/,/g, ''),
          itemCondition: 'https://schema.org/NewCondition',
          url: product.url,
          priceValidUntil: validUntil.toISOString(),
        };

        if (product.inStock !== undefined) {
          Object.assign(offerMarkup, {
            availability: product.inStock ? 'InStock' : 'OutOfStock',
          });
        }

        return JSON.stringify(offerMarkup);
      });
    },
  },

  mutations: {
    install(state, value) {
      state = merge(state, value);
    },

    set(state, currencyCode) {
      Cookie.set('currency', currencyCode);
      state.activeCurrency = currencyCode;
    },
  },

  actions: {
    async set({commit}, currencyCode) {
      commit('set', currencyCode);
    },
  },
};
