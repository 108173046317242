import {base} from './base';

export const salt = base
  .extend('salt')
  // Colors
  .property('primary.color', '#347C80')
  .property('primary.font', 'Italiana')

  // Page
  .property('page.background.color', '#F7F7F7')

  // Text
  .property('text.font', 'Palanquin')
  .property('text.color', '#384947')

  // Menu
  .property('menu.background.color', '#263332')
  .property('menu.text.color', '#D1E1E8')
  .property('menu.text.font', 'Palanquin')

  // Image
  .property('image.border.radius', '0px')
  .property(
    'image.default',
    'https://images.teemill.com/n0fgxrpy5gauvn5sz56addbkyc0aqkie1n9aavkpvublbiyw.jpeg'
  )

  // Buttons
  .property('button.border.radius', '0px')

  // Quote
  .property('quote.text.color', '#FFFFFF')
  .property('quote.fullBackground.color', '#347C80')

  // AnnouncementV2
  .property('announcement.v2.background.color', '#D1E1E8')

  // Newsletter
  .property('newsletter.fullBackground.color', '#D1E1E8')

  //Footer
  .property('footer.text.color', '#D1E1E8')
  .property('footer.background.color', '#263332');
