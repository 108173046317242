import {addSecureProtocolToUrl} from './addSecureProtocolToUrl';
import {removeProtocolFromUrl} from './removeProtocolFromUrl';

/**
 * @name removeProtocolFromUrl
 * @description Ensures the returned url uses the 'https' protocol.
 */
export const ensureSecureUrl = (url: string): string => {
  url = removeProtocolFromUrl(url);
  return addSecureProtocolToUrl(url);
};
