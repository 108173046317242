import gotIt from './got-it';
import confirm from './confirm';
import loading from './loading';
import cropper from './cropper';
import magicModel from './magic-model';
import contentWallet from './content-wallet';
import requestPermission from './request-permission';
import requestDashboardPermission from './request-dashboard-permission';
import selectProductImage from './select-product-image';
import confirmCorrespondence from './confirm-correspondence';
import pushNotSupported from './push-not-supported';
import confirmPassword from './confirm-password';
import driveFile from './drive-file';
import imagePreview from './image-preview';
import selectLink from './select-link';

export default {
  gotIt,
  loading,
  confirm,
  cropper,
  magicModel,
  contentWallet,
  requestPermission,
  requestDashboardPermission,
  selectProductImage,
  confirmCorrespondence,
  pushNotSupported,
  confirmPassword,
  driveFile,
  imagePreview,
  selectLink,
};
