import {formatUrl} from '../helpers/url/formatUrl';
import isbot from 'isbot';
import type {SplitTest} from '../classes/splitTests';
import {captureException} from '@sentry/vue';
import {http} from '../services';

export type Event = {
  name: string;
  data: any;
};

export type Tracker = {
  events: Event[];
  queueEvent: (name: string, data: any) => void;
  clearQueue: () => void;
  event: (
    name: string,
    data?: TrackableEvent,
    immediateDispatch?: boolean
  ) => void;
  conversion: (name: string, value: number | null, items: any) => void;
  dispatchEvents: () => void;
  install: () => void;
};

type SplitTestEvent = {
  [id: string]: number;
};

type TrackableEvent = {
  value?: object | number | null;
  items?: number[];
  splitTests?: SplitTestEvent;
  name?: string;
  url?: string;
  searchTerm?: string;
};

const tracker: Tracker = {
  events: [],

  queueEvent(name, data) {
    this.events.push({
      name: name,
      data: JSON.stringify(data),
    });
  },

  clearQueue() {
    this.events = [];
  },

  async event(
    name: string,
    data: TrackableEvent = {
      value: null,
      items: [],
      splitTests: {},
    },
    immediateDispatch = true
  ) {
    data.splitTests = {};

    const store = window.$store;

    store.getters['splitTest/getDiscovered'].forEach((splitTest: SplitTest) => {
      if (data.splitTests) {
        data.splitTests[splitTest.id] = splitTest.variation.id;
      }
    });

    try {
      const collectorUrl = import.meta.env.DEV
        ? 'https://frontend-event-collector-b7gk3rxzfa-nw.a.run.app'
        : '/event-collector/';

      http.post(
        collectorUrl,
        {
          event: name,
          payload: data,
          metadata: {
            environment: import.meta.env.MODE,
            location: window.location.href,
            userAgent: navigator.userAgent,
            currency: store.state.currency?.activeCurrency,
            division: {
              id: store.state.subdomain?.division,
              name: store.state.subdomain?.divisionName,
            },
          },
        },
        {
          withCredentials: false,
        }
      );
    } catch (error) {
      captureException(error);
    }

    this.queueEvent(name, data);

    if (immediateDispatch) {
      this.dispatchEvents();
    }
  },

  conversion(name: string, value = null, items = []) {
    this.event(
      name,
      {
        value,
        items,
      },
      true
    );
  },

  dispatchEvents() {
    if (this.events.length && !isbot(navigator.userAgent)) {
      // @ts-expect-error Convert axios to ts
      $axios.post(
        formatUrl('/omnis/v3/events/logEvent/'),
        JSON.stringify({
          events: this.events,
        })
      );

      this.clearQueue();
    }
  },

  install() {
    setInterval(() => {
      tracker.dispatchEvents();
    }, 30000);

    window.addEventListener('beforeunload', () => {
      tracker.dispatchEvents();
    });
  },
};

export {tracker};
