import {standardPageAccess} from '../../access';

export default {
  path: '/product/:product/:colour?',
  name: 'product',
  meta: {
    access: [standardPageAccess],
    breadcrumb: {
      type: 'product',
      parent: 'home',
    },
    layout: 'standard',
    allowPwaReload: true,
  },
  component: () => import('./ProductPage.vue'),
};
