import {isStorageSupported} from '@teemill/utilities';

export class CoreStorageDriver {
  maxFieldLength = 256;
  maxValueLength = 16384; // 16 KiB

  validate(field, value) {
    if (field && field.length > this.maxFieldLength) {
      // eslint-disable-next-line
      console.error(
        `
          Attempted to store an invalid key to storage.
          Key length: ${field.length}
          Max allowed: ${this.maxFieldLength}
        `
      );
      return false;
    }

    if (value && value.length > this.maxValueLength) {
      // eslint-disable-next-line
      console.error(
        `
          Attempted to store an invalid value to storage.
          Value length: ${value.length}
          Max allowed: ${this.maxValueLength}
        `
      );
      return false;
    }

    return true;
  }

  set(field, value) {
    if (this.validate(field, value)) {
      this._store(field, value);
    }
  }

  _store(field, value) {
    const fieldName = `_tmlStorage_${field}`;
    window[fieldName] = value;
  }

  get(field, defaultValue = null) {
    const fieldName = `_tmlStorage_${field}`;

    return window[fieldName] || defaultValue;
  }

  remove(field) {
    const fieldName = `_tmlStorage_${field}`;
    window[fieldName] = null;
  }
}

export class LocalStorageDriver extends CoreStorageDriver {
  _store(field, value) {
    window.localStorage.setItem(field, JSON.stringify(value));
  }

  get(field, defaultValue = null) {
    const storedValue = window.localStorage.getItem(field);

    if (storedValue !== null) {
      return JSON.parse(storedValue);
    }

    return defaultValue;
  }

  remove(field) {
    window.localStorage.removeItem(field);
  }
}

export class SessionStorageDriver extends CoreStorageDriver {
  _store(field, value) {
    window.sessionStorage.setItem(field, value);
  }

  get(field, defaultValue = null) {
    return window.sessionStorage.getItem(field) || defaultValue;
  }

  remove(field) {
    window.sessionStorage.removeItem(field);
  }
}

export class CookieStorageDriver extends CoreStorageDriver {
  maxValueLength = 3584; // 3.5 KiB (most browsers allow ~4KB including key)
  _store(field, value) {
    document.cookie = `tmp_${field}=${value};`;
  }

  get(field, defaultValue = null) {
    const fieldName = `tmp_${field}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookies = decodedCookie.split(';');

    let returnValue = defaultValue;

    cookies.forEach(cookie => {
      if (cookie.trim().indexOf(fieldName) === 0) {
        returnValue = cookie.trim().substring(fieldName.length, cookie.length);
      }
    });

    return returnValue;
  }

  remove(field) {
    document.cookie = `tmp_${field}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
  }
}

/**
 * tempStorage is used to store values that persist across tabs and across page
 * reloads. Uses window.sessionStorage, window.localStorage or fallsback to cookies
 * if neither of those are available
 */

const coreDriver = new CoreStorageDriver();
const sessionDriver = new SessionStorageDriver();
const localDriver = new LocalStorageDriver();
const cookieDriver = new CookieStorageDriver();

export class TmlStorage {
  static get session() {
    return isStorageSupported('sessionStorage') ? sessionDriver : coreDriver;
  }

  static get local() {
    return isStorageSupported('localStorage') ? localDriver : coreDriver;
  }

  static get cookie() {
    return cookieDriver;
  }

  static get core() {
    return coreDriver;
  }

  static get driver() {
    if (typeof Storage === 'function') {
      if (typeof window.sessionStorage === 'object') {
        return sessionDriver;
      } else if (typeof window.localStorage === 'object') {
        return localDriver;
      }
    } else {
      return cookieDriver;
    }

    return coreDriver;
  }

  static set(field, value) {
    if (this.driver) {
      this.driver.setItem(field, value);
    }
    return value;
  }

  static get(field, defaultValue = null) {
    if (this.driver) {
      return this.driver.getItem(field, defaultValue);
    }
    return defaultValue;
  }

  static remove(field) {
    if (this.driver) {
      this.driver.removeItem(field);
      // deleted
      return true;
    }
    // not deleted / nothing to delete
    return false;
  }
}
