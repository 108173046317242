import {base} from './base';

export const outland = base
  .extend('outland')
  // Colors
  .property('primary.color', '#723A2B')
  .property('primary.font', 'Vibur')

  // Page
  .property('page.background.color', '#FFFEFD')

  // Text
  .property('text.font', 'Mitr')
  .property('text.color', '#372D28')

  // Menu
  .property('menu.background.color', '#372D28')
  .property('menu.text.font', 'Acumin Pro')
  .property('menu.text.color', '#FAF3E9')
  .property('menu.text.transform', 'none')

  // Image
  .property(
    'image.default',
    'https://images.teemill.com/qambcvajrfkvjaxgzlsg91yzrglwqvlfcjeoftfxckfkka0b.jpeg'
  )

  // Banner
  .property('banner.title.color', '#FAF3E9')
  .property('banner.copy.color', '#FAF3E9')

  // Quote
  .property('quote.text.color', '#FAF3E9')
  .property('quote.fullBackground.color', '#723A2B')

  // AnnouncementV2
  .property('announcement.v2.text.color', '#FAF3E9')
  .property('announcement.v2.background.color', '#723A2B')

  // Newsletter
  .property('newsletter.text.color', '#723A2B')
  .property('newsletter.fullBackground.color', '#FAF3E9')

  // Footer
  .property('footer.text.color', '#FAF3E9')
  .property('footer.background.color', '#372D28');
