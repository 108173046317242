import {Log} from '@devanjs/log';
import {events, usePush} from '@teemill/common/services';
import type {App} from '@teemill/modules/app';
import {overlays} from '@teemill/modules/overlays';
import {pageBus} from './bus';
import {Block, BlockLike} from './classes/block';
import {Page, PageLike} from './classes/page';
import {setVersion, modules} from './modules';

type OnConnectHandler = (options: {
  division: number;
  page: number;
  layout: 'mail' | 'standard';
  compatibility: Set<'mail' | 'page' | 'flow'>;
}) => Page | undefined | Promise<Page | undefined>;

export const install = ({app}: {app: App}) => {
  Log.tag('pages').orange('Interface').info('Installed');

  let onConnect: OnConnectHandler | undefined = undefined;

  const connect = async ({
    division,
    page,
    layout,
    compatibility,
  }: {
    division: number;
    page: number;
    layout: 'mail' | 'standard';
    compatibility: Set<'mail' | 'page'>;
  }) => {
    Log.tag('pages')
      .orange('Interface')
      .text('Editor Connected')
      .lightGreen(division)
      .lightBlue(page)
      .info();

    if (onConnect !== undefined) {
      return (
        await onConnect({division, page, layout, compatibility})
      )?.toObject();
    }

    return undefined;
  };

  const getPage = ({pageId}: {pageId: number}) => {
    Log.tag('pages')
      .orange('Interface')
      .text('Get Page')
      .lightBlue(pageId)
      .info();

    const page = app.page(pageId);

    if (page === undefined) {
      return undefined;
    }

    return page.toObject();
  };

  const updatePage = ({page: rawPage}: {page: PageLike}) => {
    Log.tag('pages')
      .orange('Interface')
      .text('Update Page')
      .lightBlue(rawPage.uuid)
      .info();

    const page = app.pages.find(p => p.uuid === rawPage.uuid);

    if (page === undefined) {
      throw new Error('Page not found');
    }

    page.updateFrom(new Page(rawPage));

    pageBus.emit('page-change', page.toObject());
  };

  const updateBlocks = ({
    page: rawPage,
    blocks: rawBlocks,
  }: {
    page: PageLike;
    blocks: BlockLike[];
  }) => {
    Log.tag('pages')
      .orange('Interface')
      .text('Update Blocks')
      .lightBlue(rawPage.uuid)
      .info();

    const page = app.pages.find(p => p.uuid === rawPage.uuid);

    if (page === undefined) {
      throw new Error('Page not found');
    }

    rawBlocks
      .map(block => new Block(block).assignTo(page))
      .forEach(block => {
        const blockIndex = page.blocks.findIndex(b => b.uid === block.uid);

        block.flags.add('updated');

        const blocks = page.blocks;

        if (blockIndex >= 0) {
          blocks.splice(blockIndex, 1, block);
        } else {
          blocks.splice(block.order, 0, block);
        }

        page.blocks
          .sort((a, b) => a.order - b.order)
          .forEach((b, i) => b.setOrder(i));
      });

    pageBus.emit('page-change', page.toObject());
  };

  const setModuleVersion = (name: keyof typeof modules) => {
    Log.tag('pages')
      .orange('Interface')
      .text('Set Module Version')
      .lightBlue(name)
      .info();

    setVersion(name, false);
  };

  const subscribeToPush = () => {
    if (usePush().isSupported()) {
      usePush().subscribe();
    } else {
      overlays.pushNotSupported();
    }
  };

  /**
   * Create Listeners
   */
  pageBus.reply('connect', connect);
  pageBus.on('update-page', updatePage);
  pageBus.on('update-blocks', updateBlocks);
  pageBus.reply('page', getPage);
  pageBus.on('set-module-version', setModuleVersion);
  events.on('anchor-subscribe-to-notifications', subscribeToPush);

  return {
    onConnect(callback: OnConnectHandler) {
      onConnect = callback;
    },
    ready: () => {
      Log.tag('pages').orange('Interface').info('Ready');

      pageBus.emit('ready');
    },
    uninstall: () => {
      Log.tag('pages').orange('Interface').info('Uninstalled');

      /**
       * Destroy Listeners
       */
      pageBus.offReply('connect', connect);
      pageBus.off('update-page', updatePage);
      pageBus.off('update-blocks', updateBlocks);
      pageBus.offReply('page', getPage);
      pageBus.off('set-module-version', setModuleVersion);
      events.off('anchor-subscribe-to-notifications', subscribeToPush);
    },
  };
};
