import type {ComponentPublicInstance, Directive, DirectiveBinding} from 'vue';

export type DirectiveDefinition = {
  name: string;
  directive: Directive;
};

export const getContext = (
  binding: DirectiveBinding
): ComponentPublicInstance => {
  if (!binding.instance) {
    throw new Error('No component instance found in directive binding.');
  }

  return binding.instance;
};
