import {base} from './base';

export const municipal = base
  .extend('municipal')
  // Colors
  .property('primary.color', '#000000')
  .property('primary.font', 'Staatliches')

  // Page
  .property('page.background.color', '#ffffff')

  // Text
  .property('text.color', '#000000')
  .property('text.font', 'Exo 2')
  .property('text.shadow', 'none')

  // Menu
  .property('menu.background.color', '#000000')
  .property('menu.text.color', '#EEB60C')
  .property('menu.text.font', 'Oswald')

  // Image
  .property('image.border.radius', '0px')
  .property(
    'image.default',
    'https://images.teemill.com/kts8agntalib1rbhndtqpmqjpsoyvzkitngvffan0pjlzimh.jpg'
  )

  // Banners
  .property('banner.title.color', '#000000')
  .property('banner.title.background.color', '#EEB60C')

  // Buttons
  .property('button.border.radius', '0px')

  // Quote
  .property('quote.text.font', 'Staatliches')
  .property('quote.text.color', '#EEB60C')
  .property('quote.border.radius', '0px')
  .property('quote.background.color', '#000000')
  .property('quote.fullBackground.color', '#000000')

  // AnnouncementV2
  .property('announcement.v2.background.color', '#EEB60C')
  .property('announcement.v2.fullBackground.color', '#0000')

  // Newsletter
  .property('newsletter.title.font', 'Staatliches')
  .property('newsletter.border.radius', '0px')
  .property('newsletter.fullBackground.color', '#EEB60C')

  //Footer
  .property('footer.text.color', '#000000');
