/**
 * ### IMPORTANT ###
 *
 * This class is mirrored in PHP
 * Make sure that any changes to this file are also applied to the PHP version
 *
 * @see Searchable.php
 * @see SearchableItem.php
 * @see SearchableHelpers.php
 */

function sanitiseQuery(query) {
  return query
    .replace(/([\w])[-+']([\w])/g, '$1$2')
    .replace(/(?:^|[\W])[\w{3,0}](?=[\W]|$)/g, '')
    .replace(/^[\W]+|[\W](?![\w])/g, '');
}

export const rules = [
  {
    weight: 1,
    name: 'partial',
    match: (query, content) =>
      content.match(new RegExp(query.replace(/[\W]/g, '|'), 'gi')),
  },
  {
    weight: 2,
    name: 'full',
    match: (query, content) =>
      content.match(
        new RegExp(
          `(?:[\\W]|^)(${query.replace(/[\W]/g, '|')})(?=[\\W]|$)`,
          'gi'
        )
      ),
  },
];

class SearchableItem {
  constructor(item, searchableProperties) {
    this.item = item;
    this.weight = 0;

    this.searchableProperties = searchableProperties;
  }

  query(query) {
    const results = this._calcWeight(query);

    this.weight = results.weight;
    this.weightBreakdown = results.breakdown;
  }

  _calcWeight(query) {
    const breakdown = {};
    let totalWeight = 0;

    Object.keys(this.item).forEach(propertyName => {
      if (Object.keys(this.searchableProperties).includes(propertyName)) {
        const property = this.item[propertyName];

        const weight = this._calcPropertyWeight(property, propertyName, query);

        totalWeight +=
          weight.totalWeight * this.searchableProperties[propertyName];
        breakdown[propertyName] = weight.breakdown;
      }
    });

    return {
      weight: totalWeight,
      breakdown,
    };
  }

  _calcPropertyWeight(property, propertyName, query) {
    const breakdown = {};
    let totalWeight = 0;

    rules.forEach(rule => {
      if (typeof property === 'string') {
        const stripedProperty = sanitiseQuery(property);

        if (rule.match) {
          const ruleMatches = rule.match(query, stripedProperty);

          if (ruleMatches) {
            const termLength = stripedProperty.split(' ').length;

            const weighting =
              ruleMatches.length *
              rule.weight *
              (termLength > 10 ? ruleMatches.length / termLength : 0.2);

            totalWeight += weighting;
            breakdown[rule.name] = weighting;
          }
        }
      } else if (typeof property === 'number') {
        totalWeight += property * rule.weight;
      }
    });

    return {
      totalWeight,
      breakdown,
    };
  }
}

export default class Searchable {
  constructor(items, searchableProperties) {
    const countOfItems = items.length;

    items.forEach(item => {
      Object.keys(item).forEach(propertyName => {
        if (propertyName.match(/Order$/)) {
          item[propertyName] =
            (countOfItems - item[propertyName]) / countOfItems;
        }
      });
    });

    this.items = items.map(
      item => new SearchableItem(item, searchableProperties)
    );

    this._query = null;
  }

  query(query, minWeight = 0, async = true) {
    const callback = () => {
      this._query = sanitiseQuery(query);

      this.items.forEach(item => item.query(this._query));

      this.results = this.items
        .filter(item => item.weight > minWeight)
        .sort((a, b) => b.weight - a.weight)
        .map(item => {
          item.item.weight = item.weight;
          item.item.breakdown = item.weightBreakdown;

          return item.item;
        });

      return this.results;
    };

    if (async) {
      return new Promise(resolve => {
        resolve(callback());
      });
    }

    return callback();
  }
}
