import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const socials = new BlockType({
  name: 'socials',
  title: 'Socials',
  compatibility: ['page'],
  component: () =>
    import(/* webpackChunkName: "SocialsBlock" */ './SocialsBlock.vue'),
  builder: () => BlockBuilder.create('socials'),
});
