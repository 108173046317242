import {base} from './base';

export const lux = base
  .extend('lux')
  // Colors
  .property('primary.color', '#071735')
  .property('primary.font', 'Prata')

  // Page
  .property('page.background.color', '#f7ddb7')

  // Headings
  .property('heading1.transform', 'none')
  .property('heading2.transform', 'none')
  .property('heading3.transform', 'none')

  // Text
  .property('text.font', 'Varela')
  .property('text.color', '#071735')

  // Menu
  .property('menu.background.color', '#071735')
  .property('menu.text.font', 'Prata')
  .property('menu.text.color', '#FFFFFF')
  .property('menu.text.transform', 'none')

  // Image
  .property('image.border.radius', '0px')
  .property(
    'image.default',
    'https://images.teemill.com/uwy9zglc0dpthkca3d2xsktsgeagfejans5naxnqoiofdgsh.jpeg'
  )

  // Buttons
  .property('button.border.radius', '0px')

  // Quote
  .property('quote.text.color', '#071735')
  .property('quote.fullBackground.color', '#b6622d')

  // Announcement
  .property('announcement.v2.text.color', '#071735')
  .property('announcement.v2.background.color', '#b6622d')

  // Newsletter
  .property('newsletter.text.color', '#F7DDB7')
  .property('newsletter.fullBackground.color', '#071735')

  // Footer
  .property('footer.text.color', '#f7ddb7')
  .property('footer.background.color', '#071735');
