import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const productMockup = new BlockType({
  name: 'productMockup',
  title: 'Product Suggestion',
  compatibility: ['page'],
  component: () =>
    import(
      /* webpackChunkName: "TmlDashboardPage" */ './ProductMockupBlock.vue'
    ),
  builder: () => BlockBuilder.create('productMockup'),
});
