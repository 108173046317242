<template>
  <div
    class="page-block-single-pod h-full overflow-hidden"
    :class="{
      clickable: $listeners.click,
    }"
    :style="{
      'flex-grow': 1,
    }"
    v-on="$listeners"
  >
    <tml-new-pod
      class="h-full"
      :title="block.property('title', 'string', null, bindingData)"
      :copy="block.property('copy', 'string', null, bindingData)"
      :text-color="block.property('textColor')"
      :text-shadow="getTextShadow"
      :title-size="block.property('titleSize', 'number')"
      :media="block.property('media')"
      :image="block.property('media') !== 'video' ? getImage : undefined"
      :image-src-set="srcSet"
      :video="block.property('video', 'string')"
      :video-playlist="block.property('videoPlaylist', 'string')"
      :lazy-load="app.mode !== 'preview'"
      :autoplay-in-background="
        block.property('autoplayInBackground', 'boolean')
      "
      :icon="getIcon"
      :icon-color="block.property('iconColour')"
      :icon-size="block.property('iconSize', 'number')"
      :icon-shadow="getIconShadow"
      :overlay-icon="overlayIcon"
      height="100%"
      :horizontal-alignment="horizontalAlignment"
      :vertical-alignment="block.property('verticalAlign', 'string')"
      :icon-horizontal-alignment="
        block.property('iconHorizontalAlign', 'string', 'center')
      "
      :icon-vertical-alignment="
        // not applicable in overlay style when text is also present
        block.property('iconVerticalAlign', 'string', 'center')
      "
      :button-text="block.property('buttonText', 'string')"
      :button-styles="{
        textColor: block.property('buttonTextColor', 'string'),
        backgroundColor: block.property('buttonBackgroundColor', 'string'),
        borderColor: block.property('buttonBorderColor', 'string'),
        borderRadius: block.property('buttonBorderRadius', 'string'),
        fill: block.property('buttonFill', 'number', 0),
      }"
      :ratio="getRatio"
      :border-radius="block.property('borderRadius', 'string')"
      :overlay="block.property('overlay', 'number')"
      :background-color="block.property('backgroundColor', 'string')"
      :overlay-opacity="block.property('overlayOpacity', 'number', 0)"
      :overlay-colors="block.property('overlayColors', 'string', '#0000')"
      :overlay-direction="`${block.property('overlayDirection', 'number')}deg`"
      :href="
        /**
         * Binding data is used for a temporary fix,
         * please remove after Surprise Me feature has been changed to a frontend route,
         * @see blockMixin bindingData
         */
        block.property('href', 'string', null, bindingData)
      "
      :disabled="block.property('disabled')"
      :price="price"
      :sale-price="salePrice"
      :alt-text="imageAlt"
      v-on="events"
      @overlay-icon-clicked="attemptToFavourite"
    />
  </div>
</template>

<script>
import {formatUrl, image} from '@teemill/common/helpers';
import {favourites} from '@teemill/common/classes';

import blockMixin from '../blockMixin.js';

export default {
  name: 'PageBlockPod',

  mixins: [blockMixin],

  inject: ['app'],

  data() {
    return {
      favourites,
    };
  },

  computed: {
    price() {
      return this.block.property('showPrice', 'boolean')
        ? this.block.property('price')
        : undefined;
    },
    salePrice() {
      return this.block.property('showPrice', 'boolean')
        ? this.block.property('salePrice')
        : undefined;
    },
    getImage() {
      const imageSrc = this.block.property('image')?.src;

      if (!imageSrc) {
        return null;
      }

      let width = 1110;

      const parentBreakpoints = this.block.parent?.property(
        'breakpoints',
        'json'
      );
      if (parentBreakpoints) {
        width /= Math.min(...Object.values(parentBreakpoints));
      }

      return image(
        formatUrl(imageSrc),
        width,
        Math.floor(width * this.getRatio)
      );
    },

    imageAlt() {
      return this.block.property('image')?.alt;
    },

    srcSet() {
      return [
        {width: 480, height: Math.round(480 * this.getRatio)},
        {width: 640, height: Math.round(640 * this.getRatio)},
        {width: 960, height: Math.round(960 * this.getRatio)},
        {width: 1280, height: Math.round(1280 * this.getRatio)},
        {width: 1920, height: Math.round(1920 * this.getRatio)},
      ];
    },

    getIcon() {
      if (this.block.property('icon') && this.block.property('icon') !== '[]') {
        return this.block.property('icon', 'json');
      }

      return null;
    },

    horizontalAlignment() {
      if (this.block.property('horizontalAlign')) {
        return this.block.property('horizontalAlign', 'string');
      } else if (this.block.property('textAlign', 'string') === 'center') {
        return 'center';
      }

      return null;
    },

    overlayIcon() {
      if (this.linksToProduct) {
        return this.favourites.getIconForProduct(this.urlForFavourites);
      }

      return null;
    },

    urlForFavourites() {
      return this.block
        .property('href', 'string', '')
        .replace('/product/', '')
        .replace('/', '');
    },

    linksToProduct() {
      return this.block.property('href', 'string', '').includes('/product/');
    },
  },

  methods: {
    async attemptToFavourite() {
      try {
        await this.favourites.add(this.urlForFavourites);
      } catch (error) {
        snackbar.error(error.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-block-single-pod {
  &.clickable {
    cursor: pointer;

    > * {
      pointer-events: none;
    }
  }

  .image-overlay-icon {
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      width: 1em;
      height: 1em;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
