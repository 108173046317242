import type {DirectiveDefinition} from './utilities/directiveHelpers';

const lazyloader: any = {};

lazyloader.elements = [];

// eslint-disable-next-line
lazyloader.observer = new IntersectionObserver(
  entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        for (let i = 0; i < lazyloader.elements.length; i++) {
          const element = lazyloader.elements[i];
          if (element.target === entry.target) {
            element.resolve();
            lazyloader.elements.splice(i, 1);
          }
        }
      }
    });
  },
  {
    root: null,
    rootMargin: '100px',
    threshold: 0,
  }
);

lazyloader.observe = (element: any) =>
  new Promise(resolve => {
    lazyloader.elements.push({
      target: element,
      resolve,
    });
    lazyloader.observer.observe(element);
  });

export const lazyload: DirectiveDefinition = {
  name: 'lazyload',
  directive: {
    mounted(el, binding) {
      el.dataset.src = binding.value;
      lazyloader.observe(el).then(() => {
        if (el.nodeName === 'IMG') {
          el.src = el.dataset.src;
        } else {
          el.style.backgroundImage = `url('${el.dataset.src}')`;
        }
      });
    },
  },
};
