import contentWallet from './content-wallet';

export default ({title = 'Link', division, exclude = []}) => {
  const categories = {
    products: (product, done) =>
      done({
        text: product.name,
        href: product.url,
        type: 'product',
      }),
    collections: (collection, done) =>
      done({
        text: collection.name,
        href: collection.url,
        type: 'collection',
      }),
    blogs: (blog, done) =>
      done({
        text: blog.title,
        href: blog.url,
        type: 'blog',
      }),
    pages: (page, done) =>
      done({
        text: page.properties.title.value,
        href: page.url,
        type: 'page',
      }),
    actions: (action, done) =>
      done({
        text: action.title,
        href: action.href,
        type: 'action',
      }),
    links: (link, done) =>
      done({
        text: 'Custom link',
        href: link,
        type: 'link',
      }),
    studio: (link, done) =>
      done({
        text: 'Studio link',
        href: link,
        type: 'studio',
      }),
  };

  exclude.forEach(excludeCategory => {
    delete categories[excludeCategory];
  });

  return contentWallet(categories, {title, division});
};
