import {Log} from '@devanjs/log';

import {formatUrl, ensureEncapsulatingSlashes} from '@teemill/common/helpers';

import {
  PageNotFoundError,
  PagePermissionDeniedError,
  PageProUpgradeRequiredError,
  PageServerError,
  PageValidationError,
} from '../errors/pageErrors';

import {Page, PageLike} from '../classes/page';
import {ApiError} from '@teemill/common/errors';

export class PageProvider {
  public static async get(id: number, division: number): Promise<Page> {
    performance.mark(`page-provider-get-${id}-start`);

    Log.tag('pages')
      .orange('Provider')
      .text('Getting Page')
      .lightBlue(id)
      .info();

    return fetch(
      formatUrl(
        `/omnis/v3/divisions/${division}/pages/${id}/?imageType=object`
      ),
      {
        credentials: 'include',
        mode: 'no-cors',
      }
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        }

        if (response.status === 404) {
          throw new PageNotFoundError();
        }

        if (response.status === 403) {
          throw new PagePermissionDeniedError();
        }

        if (response.status >= 500 && response.status <= 599) {
          throw new PageServerError();
        }

        throw new ApiError();
      })
      .then((data: PageLike) => {
        const page = new Page(data);
        // ensure unique order numbers to handle existing page blocks with
        // duplicated order numbers stored in DB.
        page.blocks
          .sort((a, b) => a.order - b.order)
          .forEach((b, i) => b.setOrder(i));

        if (data.url === '/') {
          page.flags.add('homepage');
        }

        Log.tag('pages-v')
          .orange('Provider')
          .text('Got Page')
          .lightBlue(page.id, page.uuid)
          .lightGreen(page.blocks.length)
          .info();

        performance.mark(`page-provider-get-${id}-end`);
        performance.measure(
          `Page Provider Get ${id}`,
          `page-provider-get-${id}-start`,
          `page-provider-get-${id}-end`
        );

        return page;
      });
  }

  public static async save(page: Page, division: number): Promise<Page> {
    Log.tag('pages').orange('Provider').info('Saving Page');

    page.blocks
      .sort((a, b) => a.order - b.order)
      .forEach((block, index) => {
        block.setOrder(index);

        // ? If a blocks items are provided by a factory don't save them.
        // TODO - Move to api side
        if (block.property('itemFactory')) {
          block.items = block.pseudoItems;
        } else {
          block.items = block.visibleItems;
        }
      });

    page.url = ensureEncapsulatingSlashes(page.url || '');

    const url = page.id
      ? `/omnis/v3/divisions/${division}/pages/${page.id}/`
      : `/omnis/v3/divisions/${division}/pages/`;

    const method = page.id ? 'patch' : 'post';

    // @ts-expect-error Convert axios to ts
    return await $axios[method](
      formatUrl(url + '?imageType=object'),
      page.toObject()
    )
      .success((data: PageLike) => {
        page.updateFrom(new Page(data));

        if (data.url === '/') {
          page.flags.add('homepage');
        }

        return page;
      })
      .throw(402, () => new PageProUpgradeRequiredError())
      .throw(403, () => new PagePermissionDeniedError())
      .throw(404, () => new PageNotFoundError())
      .throw('5xx', () => new PageServerError())
      .throwValidation((message: string, data: any) => {
        const fields = Object.keys(data.errors);

        return new PageValidationError(message, fields);
      })
      .output();
  }

  public static async delete(page: Page, division: number): Promise<boolean> {
    Log.tag('pages').orange('Provider').info('Deleting Page');

    // @ts-expect-error Convert axios to ts
    return await $axios
      .delete(formatUrl(`/omnis/v3/divisions/${division}/pages/${page.id}/`))
      .success((deleted: boolean) => deleted)
      .throw(403, () => new PagePermissionDeniedError())
      .throw(404, () => new PageNotFoundError())
      .throw('5xx', () => new PageServerError())
      .output();
  }
}
