/**
 * @name ensureLeadingSlash
 */
export const ensureLeadingSlash = (url: string): string => {
  if (url && !url.startsWith('/')) {
    return `/${url}`;
  }

  return url;
};
