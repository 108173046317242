import {merge} from 'lodash';

export type Seasons = 'christmas';

export interface CutoffDates {
  eu?: string;
  firstClass?: string;
  international?: string;
  internationalTracked?: string;
  internationalTrackedEu?: string;
  us?: string;
  usTracked?: string;
  specialNextDay?: string;
  fedexPriority?: string;
  fedexStandard?: string;
  standard?: string;
}

export interface ShippingState {
  christmas: {
    cutoffDates: CutoffDates;
    showContent: boolean;
  };
}

export const shipping = {
  namespaced: true,
  state: (): ShippingState => ({
    christmas: {cutoffDates: {}, showContent: false},
  }),
  getters: {},
  mutations: {
    install: (state: ShippingState, value: ShippingState) => {
      state = merge(state, value);
    },
    setCutoffDates: (
      state: ShippingState,
      {season, dates}: {season: Seasons; dates: CutoffDates}
    ) => {
      state[season].cutoffDates = dates;
    },
  },
  actions: {},
};
