import {base} from './base';

export const good = base
  .extend('good')
  // Colors
  .property('primary.color', '#f7bb59')
  .property('primary.font', 'DM Serif Text')

  // Page
  .property('page.background.color', '#FFFBF6')

  // Text
  .property('text.font', 'DM Serif Text')
  .property('text.color', '#44331B')

  // Menu
  .property('menu.background.color', '#f7bb59')
  .property('menu.text.font', 'DM Serif Text')
  .property('menu.text.color', '#FFFBF6')
  .property('menu.text.transform', 'none')

  // Image
  .property('image.border.radius', '12px')
  .property(
    'image.default',
    'https://images.teemill.com/ogqgzvl9tbi4dcseg5a7se9vaicjou1jst1fvmug5h1hfjol.png'
  )

  // Buttons
  .property('button.border.radius', '12px')

  // Quote
  .property('quote.text.color', '#FFFBF6')
  .property('quote.fullBackground.color', '#fbcfc2')

  // AnnouncementV2
  .property('announcement.v2.text.color', '#FFFBF6')
  .property('announcement.v2.background.color', '#FBCFC2')

  // Newsletter
  .property('newsletter.text.color', '#FFFBF6')
  .property('newsletter.fullBackground.color', '#4f9698')

  // Footer
  .property('footer.text.color', '#FFFBF6')
  .property('footer.background.color', '#F4BD64');
