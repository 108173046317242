import {BreakpointSize} from './pageBreakpoints';
import {viewportSize} from './viewportSize';
import {DeviceCheck} from './types/deviceCheck';

/**
 * @deprecated use viewportSize.isGreater instead
 * @name minDevice
 */
export const minDevice: DeviceCheck = (device: BreakpointSize) =>
  viewportSize.isGreater(device);
