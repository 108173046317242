import {BaseClass} from '../../classes/baseClass';
import Division from '../division/division';

/**
 * Subdomain.js
 *
 * Defines the standard structure for a Subdomain object.
 *
 * @author Aidan Brookes <aidan@teemill.com>
 */
export default class Subdomain extends BaseClass {
  static modelName = 'subdomain';

  constructor({id, isPro, division, approvalItem} = {}) {
    super();

    this.id = id;
    this.hasPro = isPro;
    this.division = Division.map(division);
    this.approvalItem = approvalItem;
  }

  get approvalStatus() {
    if (this.approvalItem) {
      if (this.approvalItem.approved === 1) {
        return 'Approved';
      } else if (this.approvalItem === 0) {
        return 'Denied';
      }

      return 'Not modded yet';
    }

    return 'No approval item';
  }
}
