/**
 * @name allowedProtocols
 */
export const allowedProtocols = [
  'https',
  'http',
  'wss',
  'ws',
  'mqtts',
  'mqtt',
  'mailto',
  'tel',
  '//',
  'data',
  'dtg',
];
