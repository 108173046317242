<template>
  <div class="gap-2 flex">
    <template v-for="(item, index) in breadcrumbs">
      <div
        v-if="item"
        :key="item.href"
        class="flex gap-2 flex-no-wrap items-center last-of-type:opacity-60"
      >
        <tml-anchor
          class="font-bold text-sm whitespace-nowrap hover:opacity-60"
          :href="item.href"
          :text="item.text"
        />
        <font-awesome-icon
          v-if="index + 1 !== breadcrumbs.length"
          class="opacity-30 text-xs text-[--tml-text-color]"
          :icon="faChevronRight"
        />
      </div>
    </template>
    <div v-if="hasActionsSlot" class="ml-4 space-x-2">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
import {faChevronRight} from '@fortawesome/pro-solid-svg-icons/faChevronRight';

import TmlAnchor from '../links/TmlAnchor.vue';

export default {
  name: 'TmlBreadcrumb',

  components: {TmlAnchor},

  props: {
    overrideBreadcrumbs: Array,
  },

  data() {
    return {
      faChevronRight,

      breadcrumbs: [],
    };
  },

  computed: {
    hasActionsSlot() {
      return !!this.$slots.actions;
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        this.onComputeBreadcrumbs();
      },
    },
  },

  created() {
    this.onComputeBreadcrumbs();

    this.$eventBus.on('tml-breadcrumbs-update', this.onComputeBreadcrumbs);
  },

  beforeUnmount() {
    this.$eventBus.off('tml-breadcrumbs-update', this.onComputeBreadcrumbs);
  },

  methods: {
    onComputeBreadcrumbs() {
      let breadcrumbs = null;

      if (this.overrideBreadcrumbs) {
        breadcrumbs = this.overrideBreadcrumbs;
      } else {
        breadcrumbs = this.$breadcrumbs.get();
      }

      this.breadcrumbs = breadcrumbs;
    },
  },
};
</script>
