import {LayoutSections} from '@teemill/modules/app';
import {maxDevice} from '@teemill/common/helpers';

export const toolConfig: LayoutSections = {
  announcement: false,
  header: {
    show: true,
    fixed: true,
    overlay: false,
    height: '5rem',
    width: '100%',
    logo: true,
    logoMaxWidth: '220px',
    centerLogo: () => maxDevice('md'),
    content: true,
  },
  sidebar: {show: true, fixed: true, full: true, width: '220px'},
  content: {show: true, width: '100%'},
  footer: false,
  subFooter: false,
  navBar: false,
};
