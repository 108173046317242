export interface SplitTestVariation {
  id: number;
}

export interface SplitTest {
  id: number;
  variation: SplitTestVariation;
}

export class SplitTests {
  private activeVariations: Record<string, string> = {};

  private startSession(splitTestName: string): void {
    // @ts-expect-error Deprecated global store
    $store.dispatch('splitTest/startSession', splitTestName);
  }

  private fetchActiveVariation(splitTestName: string): void {
    this.activeVariations[splitTestName] =
      // @ts-expect-error Deprecated global store
      $store.getters['splitTest/getActiveVariation'](splitTestName);
  }

  /**
   * Returns the active variation for the given split test. Will start session if not already started.
   * If no active variation is found, the default variation will be returned.
   *
   * @param splitTestName The name of the split test
   * @param defaultVariation The default variation to return if no active variation is found
   *
   * @return The active variation for the given split test, or the default variation if no active variation is found
   */
  public getActiveVariationFor(
    splitTestName: string,
    defaultVariation?: string
  ): string | undefined {
    if (this.activeVariations[splitTestName] === undefined) {
      this.startSession(splitTestName);

      this.fetchActiveVariation(splitTestName);
    }

    if (this.activeVariations[splitTestName] === null) {
      return defaultVariation;
    }

    return this.activeVariations[splitTestName];
  }

  /**
   * Returns true if the given variation is the active variation for the given split test.
   *
   * @param splitTestName The name of the split test
   * @param variation The variation to check
   *
   * @return True if the given variation is the active variation for the given split test
   */
  public isActiveVariation(splitTestName: string, variation: string): boolean {
    return this.getActiveVariationFor(splitTestName) === variation;
  }
}

export const splitTests = new SplitTests();
