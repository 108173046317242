import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const quote = new BlockType({
  name: 'quote',
  title: 'Quote',
  compatibility: ['page'],
  component: () =>
    import(/* webpackChunkName: "QuoteBlock" */ './QuoteBlock.vue'),
  builder: () =>
    BlockBuilder.create('quote')
      .size(6)
      .property('quote', '')
      .property('author', '')
      .property('font', 'Lato')
      .property('fontSize', 24)
      .property('bold', 0)
      .property('italic', 0)
      .property('align', 'right')
      .property('textColor', '#222')
      .property('backgroundColor', '#fff')
      .property('borderRadius', '4px')
      .property('textShadow', 'none'),
});
