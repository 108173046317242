<template>
  <tml-anchor
    :key="block.property('text', 'string', '')"
    :href="block.property('href', 'string', '')"
  >
    <tml-tag 
      class="!m-0 p-0"
      :text-color="block.property('tagTextColor', 'string', undefined)"
      :background="block.property('tagBackgroundColor', 'string', undefined)"
    >
      {{ block.property('text', 'string', '') }}
    </tml-tag>
  </tml-anchor>
</template>

<script>
import blockMixin from '../blockMixin.js';

export default {
  name: 'PageBlockTagBlock',

  mixins: [blockMixin],
};
</script>