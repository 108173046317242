export default [
  {
    path: '/sitemap/',
    name: 'sitemap',
    meta: {
      title: 'Sitemap',
      breadcrumb: {
        type: 'page',
        parent: 'home',
      },
      layout: 'standard',
      allowPwaReload: true,
    },
    component: () =>
      import(/* webpackChunkName: "PageSitemap" */ './SitemapPage.vue'),
    // prefetchChunks: ['TmlGlobal'],
  },
];
