<script setup lang="ts">
import {v4 as uuid} from 'uuid';
import {provide, computed, watch, onUnmounted, onMounted} from 'vue';
import {transform, kebabCase, each} from 'lodash';
import {addAlpha} from '@teemill/utilities';
import type {Theme} from '@teemill/modules/theme-builder';
import {fontLoader, generateFontFamily} from '@teemill/common/helpers';

const props = defineProps<{
  theme: Theme;
}>();

provide('theme', () => props.theme);

const legacyCssVariables = computed(() => ({
  '--legacy-primary-hover-color': addAlpha(
    props.theme.get('primary.color') as string,
    0.6
  ) as string,
}));

const fontProperties = [
  'primary.font',
  'menu.text.font',
  'text.font',
  'heading1.font',
  'heading2.font',
  'heading3.font',
];

const cssVariables = computed(() => {
  return transform(
    props.theme.all() as Record<string, string | number>,
    (mapped, value, key) => {
      // If a string property begins with a http:// or https://
      // we can assume that it's an image we'll be using on
      // the frontend. We need to wrap that url in the CSS
      // `url` method to ensure that it gets interpolated correctly
      if (typeof value === 'string' && value.match(/^https?:\/\//)?.length) {
        value = `url('${value}')`;
      }

      // TODO: Generate tml- prefixes for variables derived from other props (see --legacy-primary-hover-color)
      mapped[`--tml-${kebabCase(key)}`] =
        fontProperties.includes(key) && typeof value === 'string'
          ? generateFontFamily(value)
          : value;
    },
    {} as Record<string, string | number>
  );
});

const cssString = computed(() => {
  let css = '';

  each(
    {
      ...legacyCssVariables.value,
      ...cssVariables.value,
    },
    (value, key) => {
      css += `${key}: ${value};\n`;
    }
  );

  return css;
});

watch(
  () => props.theme,
  () => {
    fontLoader(fontProperties.map(property => props.theme.get(property)));
  },
  {immediate: true}
);

/**
 * Create a unique scope of the theme so we can link the style sheet
 * and component together.
 */
const themeScope = `t${uuid()}`;

/**
 * ? Create a style tag in the head to avoid costly performance issues when
 * ? setting large numbers of inline css variables.
 */
const style = document.createElement('style');
style.type = 'text/css';
style.className = `tml-theme-style`;
style.appendChild(document.createTextNode(''));
document.head.appendChild(style);

/**
 * Update the attached style sheet when the theme changes.
 */
watch(
  () => cssString.value,
  css => {
    style.childNodes[0].textContent = `
      .tml-theme.${themeScope} { 
        ${css}
      }
    `;
  },
  {
    immediate: true,
  }
);

onMounted(() => {
  document.head.appendChild(style);
});

onUnmounted(() => {
  document.head.removeChild(style);
});
</script>

<template>
  <div
    class="tml-theme"
    :class="{
      [themeScope]: true,
      pro: theme.name?.includes('teemillPro'),
    }"
  >
    <slot />
  </div>
</template>

<style lang="scss">
.tml-theme {
  background-color: var(--tml-page-background-color);
  color: var(--tml-text-color);
  font-family: var(--tml-text-font);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: var(--tml-text-color);
  }

  a {
    color: var(--tml-primary-color);

    &:hover {
      color: var(--legacy-primary-hover-color);
    }

    &.plain {
      color: var(--tml-text-color);
    }
  }

  h1 {
    font-family: var(--tml-heading-1-font);
    text-transform: var(--tml-heading-1-transform);
  }

  h2 {
    font-family: var(--tml-heading-2-font);
    text-transform: var(--tml-heading-2-transform);
  }

  h3 {
    font-family: var(--tml-heading-3-font);
    text-transform: var(--tml-heading-3-transform);
  }
}

/* Disable overriding font when inside tmlFont (tmlFont should have the priority) */
.tml-theme {
  .tml-font {
    h1 {
      font-family: inherit;
    }

    h2 {
      font-family: inherit;
    }

    h3 {
      font-family: inherit;
    }
  }
}
</style>
