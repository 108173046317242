import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

import BannerBlock from '../banner/BannerBlock.vue';

export const slide = new BlockType({
  name: 'slide',
  title: 'Slide',
  compatibility: ['page'],
  component: BannerBlock,
  builder: () =>
    BlockBuilder.create('slide')
      .property('title', 'Banner title')
      .property('image', '')
      .property('gradient', 15)
      .property('textShadow', 30)
      .property('letterSpacing', -4)
      .property('font', 'Lato')
      .property('copy', 'This is an awesome subtitle')
      .property('align', 'center')
      .property('compact', false)
      .property('bannerRatio', 0.4)
      .property('actions', [
        {
          text: 'Cool Button',
          href: '#',
        },
      ]),
});
