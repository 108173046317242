import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const banner = new BlockType({
  name: 'banner',
  title: 'Banner',
  compatibility: ['page'],
  component: () =>
    import(/* webpackChunkName: "TmlPage" */ './BannerBlock.vue'),
  builder: () =>
    BlockBuilder.create('banner')
      .minSize(12)
      .property('title', '')
      .property('copy', '')
      .property('primaryActions', false),
});
