import {HexColor} from './colorTypes';
import {stripHash} from './stripHash';

/**
 * @name stripAlpha
 * @description Strips the alpha from a hex code
 */
export const stripAlpha = (hex: HexColor): HexColor => {
  if (typeof hex !== 'string') {
    return hex;
  }
  const hexLength = stripHash(hex).length;
  if (hexLength === 8) {
    return hex.substring(0, hex.length - 2);
  }
  if (hexLength === 4) {
    return hex.substring(0, hex.length - 1);
  }
  return hex;
};
