import {Theme} from '../classes/theme';

export const base = Theme.create('base')
  // Colors
  .property('primary.color', '#86b302')
  .property('primary.contrastColor', '#fff')
  .property('primary.font', 'Lato')

  // Logo
  .property('logo.text.color', '#2D2A26')

  // Page
  .property('page.background.color', '#fff')
  .property('page.product.taglets.visible', '0')

  // Headings
  .property('heading1.font', Theme.ref('primary.font'))
  .property('heading1.transform', 'none')
  .property('heading2.font', Theme.ref('primary.font'))
  .property('heading2.transform', 'none')
  .property('heading3.font', Theme.ref('primary.font'))
  .property('heading3.transform', 'none')

  // Text
  .property('text.font', 'Lato')
  .property('text.color', '#222')
  .property('text.shadow', 'none')
  .property('text.border.radius', '4px')
  .property('text.background.color', '#0000')
  .property('text.fullBackground.color', '#0000')

  // Menu
  .property('menu.background.color', '#fff')
  .property('menu.text.font', 'Lato')
  .property('menu.text.color', '#222')
  .property('menu.text.transform', 'uppercase')

  // Image
  .property('image.border.radius', '4px')
  .property(
    'image.default',
    'https://images.teemill.com/wn8sribf9hc5ffoer60koletlsowcraemgbludl90lgrn7gc.png.jpg'
  )

  // Image
  .property('video.default', 'R51EkLZ3qYE')

  // Banners
  .property('banner.text.shadow', 'none')
  .property('banner.title.color', '#fff')
  .property('banner.title.background.color', '#0000')
  .property('banner.copy.color', '#fff')
  .property('banner.copy.background.color', '#0000')
  .property('banner.fullBackground.color', '#0000')

  // Buttons
  .property('button.primary.color', Theme.ref('primary.color'))
  .property('button.secondary.color', Theme.ref('text.color'))
  .property('button.border.radius', '4px')
  .property('button.fullBackground.color', '#0000')

  // Features
  .property('feature.icon.color', '#fff') // !deprecated
  .property('feature.border.radius', '4px')
  .property('feature.background.color', '#0000')
  .property('feature.text.color', Theme.ref('text.color'))
  .property('feature.text.background.color', '#0000') // !deprecated
  .property('feature.fullBackground.color', '#0000')

  // Quote
  .property('quote.text.font', Theme.ref('heading2.font'))
  .property('quote.text.color', Theme.ref('text.color'))
  .property('quote.text.shadow', Theme.ref('text.shadow'))
  .property('quote.border.radius', '4px')
  .property('quote.background.color', '#0000')
  .property('quote.fullBackground.color', '#0000')

  // !deprecated
  // Announcement
  .property('announcement.text.font', Theme.ref('heading2.font'))
  .property('announcement.text.color', '#fff')
  .property('announcement.text.shadow', Theme.ref('text.shadow'))
  .property('announcement.border.radius', '4px')
  .property('announcement.fullBackground.color', '#0000')

  // AnnouncementV2
  .property('announcement.v2.text.color', Theme.ref('text.color'))
  .property('announcement.v2.text.shadow', 'none')
  .property('announcement.v2.border.radius', '4px')
  .property('announcement.v2.background.color', '#0000')
  .property('announcement.v2.fullBackground.color', '#0000')

  // !deprecated
  // Card
  .property('card.text.font', Theme.ref('heading3.font'))
  .property('card.text.color', '#fff')
  .property('card.text.shadow', Theme.ref('text.shadow'))
  .property('card.border.radius', '4px')
  .property('card.fullBackground.color', '#0000')

  // Newsletter
  .property('newsletter.text.color', Theme.ref('text.color'))
  .property('newsletter.title.font', Theme.ref('heading2.font'))
  .property('newsletter.border.radius', '4px')
  .property('newsletter.background.color', '#0000')
  .property('newsletter.fullBackground.color', '#0000')

  // Gate
  .property('gate.text.color', '#fff')
  .property('gate.text.shadow', Theme.ref('text.shadow'))
  .property('gate.border.radius', '4px')
  .property('gate.fullBackground.color', '#0000')

  // Footer
  .property('footer.visible', '1')
  .property('footer.text.color', '#222')
  .property('footer.background.color', '#fff')

  // Forms
  .property('form.background.primary.color', Theme.ref('page.background.color'))
  .property('form.background.secondary.color', '#eee')
  .property('form.border.color', '#dfdfdf')
  .property('form.border.radius', '4px')
  .property('form.border.focused.color', Theme.ref('form.border.color'))
  .property('form.border.error.color', Theme.ref('error.color'))
  .property('form.text.primary.color', Theme.ref('text.color'))
  .property('form.text.secondary.color', '#767676')
  .property('form.text.error.color', Theme.ref('error.color'))

  // Tag
  .property('tag.background.color', Theme.ref('box.background.color'))
  .property('tag.border.color', Theme.ref('box.background.color'))
  .property('tag.text.color', Theme.ref('text.color'))

  // Misc
  .property('box.background.color', '#f3f3f3')
  .property('border.color', '#dfdfdf')

  // Indicators
  .property('error.color', '#dd176d')
  .property('error.color.dark', '#6a0b34')
  .property('warning.color', '#f89815')
  .property('warning.color.dark', '#714203')
  .property('success.color', '#86b302')
  .property('success.color.dark', '#3a4e01')

  //Product page
  .property('productPage.ugc.title.text', 'From the community')
  .property('productPage.ugc.subtitle.text', '');
