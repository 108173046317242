<template>
  <div
    class="container max-w-full page-block-row-block"
    :style="{
      backgroundColor: block.property('backgroundColor', 'string', '#fff0'),
      borderRadius: block.property('borderRadius'),
    }"
  >
    <div class="row">
      <div
        v-if="block.property('backgroundImage')"
        class="p-0"
        :style="{aspectRatio: 1 / getRatio}"
        :class="{
          [maxDevice('md') ? 'col-12' : 'col-6']: true,
          'order-2': backgroundImage.isAfterBlocks,
        }"
      >
        <tml-image
          class="h-full"
          :src="getBackgroundImage"
          :overlay-colors="overlayColors"
          :overlay-opacity="block.property('overlayOpacity', 'number')"
          :overlay-direction="`to ${backgroundImage.position}`"
          alt="background image"
        />
      </div>
      <div
        class="w-full"
        :class="{
          [maxDevice('md') ? 'col-12' : 'col-6']:
            block.property('backgroundImage'),
        }"
      >
        <div
          class="row"
          :style="{
            alignItems: verticalAlign,
            margin: `0 calc(-${block.property(
              'spacing',
              'number',
              '1'
            )}rem * 0.5)`,
          }"
          :class="{
            [block.property('reversed', 'boolean')
              ? 'reversed'
              : 'not-reversed']: true,
            'sm:flex-row-reverse flex-col-reverse': block.property(
              'reversed',
              'boolean'
            ),
            'p-4':
              block.property('backgroundColor', 'string', '#0000') !== '#0000',
            'h-full': block.property('backgroundImage', 'string'),
          }"
        >
          <div
            v-if="block.property('title')"
            class="col-12 mb-2"
            :class="{
              'col-sm': block.property('direction', 'string', 'row') === 'row',
            }"
            :style="{
              padding: `0 calc(${block.property(
                'spacing',
                'number',
                '1'
              )}rem * 0.5)`,
              '--row-block-color': block.property('textColor'),
            }"
          >
            <tml-object-summary
              v-if="block.property('image')"
              :thumbnail="block.property('image').src"
              icon-size="1em"
              avatar-width="26px"
              avatar-height="26px"
            >
              <template #title>
                <p>
                  <b class="mr-1">{{
                    block.property('title', 'string', null, bindingData)
                  }}</b>
                  <font-awesome-icon
                    v-if="block.property('icon')"
                    :icon="block.property('icon')"
                    class="pro-text-force"
                    :style="{
                      height: '1em',
                      width: '1em',
                    }"
                  />
                </p>
              </template>
            </tml-object-summary>
            <p v-else>
              <font-awesome-icon
                v-if="block.property('icon')"
                :icon="block.property('icon', 'json')"
                class="mr-2"
              />
              {{ block.property('title', 'string', null, bindingData) }}
            </p>
          </div>
          <div
            v-for="item in block.filteredItems"
            :key="item.uuid"
            class="item col-12"
            :style="{
              padding: `0 calc(${block.property(
                'spacing',
                'number',
                '1'
              )}rem * 0.5)`,
              marginBottom: `calc(${block.property(
                'spacing',
                'number',
                '1'
              )}rem)`,
              zIndex: item.property('onTop', 'boolean', false) ? 1 : undefined,
            }"
            :class="{
              'col-sm md:mb-0':
                block.property('direction', 'string', 'row') === 'row',
            }"
          >
            <block-renderer :block="item" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {formatUrl, image, maxDevice} from '@teemill/common/helpers';
import {formatHex} from '@teemill/utilities';

import blockMixin from '../blockMixin.js';

import BlockRenderer from '../../components/BlockRenderer.vue';

export default {
  name: 'PageBlockRow',

  components: {
    BlockRenderer,
  },

  mixins: [blockMixin],

  computed: {
    verticalAlign() {
      switch (this.block.property('align')) {
        default:
        case 'top':
          return 'flex-start';

        case 'center':
          return 'center';

        case 'bottom':
          return 'flex-end';
      }
    },

    overlayColors() {
      const color = formatHex(
        this.block.property('backgroundColor', 'string', '#000'),
        true
      );
      const alpha = 'CC'; // hex code for 80% opacity, @see https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4

      return `${color} 5%, ${color + alpha} 15%, #0000 50%`;
    },

    getBackgroundImage() {
      if (!this.block.property('backgroundImage')) {
        return null;
      }

      const width = 1110;

      return image(
        formatUrl(this.block.property('backgroundImage', 'string')),
        width,
        Math.floor(width * this.getRatio)
      );
    },

    backgroundImage() {
      const position = maxDevice('md')
        ? this.block.property('directionOnMobile', 'string', 'top')
        : this.block.property('directionOnDesktop', 'string', 'left');

      const isAfterBlocks = maxDevice('md')
        ? position === 'bottom'
        : position === 'right';

      return {
        position,
        isAfterBlocks,
      };
    },
  },

  methods: {
    maxDevice,
  },
};
</script>

<style lang="scss" scoped>
.page-block-row-block {
  p {
    color: var(--row-block-color);
  }

  .not-reversed {
    .item:last-child {
      margin-bottom: 0 !important;
    }
  }

  .reversed {
    .item:first-child {
      margin-bottom: 0 !important;
    }
  }
}
</style>
