import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

import TagletsBlock from './TagletsBlock.vue';

export const taglets = new BlockType({
  name: 'taglets',
  title: 'taglets',
  compatibility: ['page'],
  component: TagletsBlock,
  builder: () => BlockBuilder.create('taglets'),
});
