import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const affiliateSettings = new BlockType({
  name: 'affiliateSettings',
  title: 'affiliate settings',
  compatibility: ['page'],
  component: () =>
    import(
      /* webpackChunkName: "AffiliatesBlocks" */ './AffiliateSettingsBlock.vue'
    ),
  builder: () => BlockBuilder.create('affiliateSettings'),
});
