import {Theme} from '../classes/theme';
import {base} from './base';

export const deepSpace = base
  .extend('deepSpace')
  .deprecate()
  // Colors
  .property('primary.color', '#BB0F78')
  .property('primary.font', 'Audiowide')

  // Page
  .property('page.background.color', '#000000')

  // Headings
  .property('heading1.transform', 'uppercase')

  // Text
  .property('text.color', '#FFFFFF')
  .property('text.font', 'Montserrat')
  .property('text.shadow', 'none')

  // Menu
  .property('menu.background.color', '#000000')
  .property('menu.text.color', '#FFFFFF')
  .property('menu.text.font', 'Montserrat')

  // Image
  .property(
    'image.default',
    'https://images.teemill.com/2vn5kxfvijg2jkorzbjplfd34xsumr8xiosn32dwmxie0ap4.jpg'
  )

  // Buttons
  .property('button.border.radius', '0px')

  // Features
  .property('feature.background.color', '#0000')
  .property('feature.text.color', '#FFFFFF')
  .property('feature.fullBackground.color', '#0000')

  //Footer
  .property('footer.text.color', '#FFFFFF')
  .property('footer.background.color', '#000000')

  // Forms
  .property(
    'form.background.secondary.color',
    Theme.ref('page.background.color')
  )
  .property('form.text.secondary.color', '#c9c9c9')
  .property('form.border.color', '#c9c9c9')

  // Misc
  .property('box.background.color', '#1f1f1f')
  .property('border.color', '#c9c9c9');
