<script setup lang="ts">
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {ref} from 'vue';

const props = defineProps<{text: string}>();
const opened = ref(false);

const toggleOpened = () => {
  opened.value = !opened.value;
};
</script>

<template>
  <div class="relative cursor-pointer" @click="toggleOpened">
    <p class="collapsible-text" :class="{opened}">
      {{ text }}
    </p>
    <font-awesome-icon
      class="absolute top-1 right-1"
      :icon="faCaretDown"
      :class="{'rotate-180': opened}"
    />
  </div>
</template>

<style scoped lang="scss">
.collapsible-text {
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  max-width: calc(100% - 20px);

  &.opened {
    height: unset;
    white-space: unset;
  }
}
</style>