import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

import PodBlock from './PodBlock.vue';

export const pod = new BlockType({
  name: 'pod',
  title: 'Content',
  compatibility: ['page'],
  component: PodBlock,
  builder: () =>
    BlockBuilder.create('pod')
      .size(3)
      .property('title', '')
      .property('copy', '')
      .property('href', '')
      .property('badge', '')
      .property('icon', '')
      .property('overlay', 0)
      .property('ratio', 'square')
      .property('textAlign', 'center'),
});
