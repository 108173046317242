import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const interactions = new BlockType({
  name: 'interactions',
  title: 'Interactions',
  compatibility: ['page'],
  component: () =>
    import(
      /* webpackChunkName: "InteractionsBlock" */ './InteractionsBlock.vue'
    ),
  builder: () =>
    BlockBuilder.create('Interactions').minSize(12).property('href', ''),
});
