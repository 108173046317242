import {base} from './base';

export const popsicle = base
  .extend('popsicle')
  // Colors
  .property('primary.color', '#5ED1E6')
  .property('primary.font', 'Corben')

  // Page
  .property('page.background.color', '#FFFDF1')

  // Headings
  .property('heading1.transform', 'uppercase')
  .property('heading2.transform', 'uppercase')
  .property('heading3.transform', 'uppercase')

  // Text
  .property('text.font', 'Source Sans Pro')
  .property('text.color', '#000')

  // Menu
  .property('menu.background.color', '#5ED1E6')
  .property('menu.text.font', 'Source Sans Pro')
  .property('menu.text.color', '#FFFDF1')

  // Image
  .property('image.border.radius', '4px')
  .property(
    'image.default',
    'https://images.teemill.com/0d3wfojyfyctey2q8sgmq6m4initdtuurzkc4znnwhmbivw9.png'
  )

  // Buttons
  .property('button.border.radius', '4px')

  // Quote
  .property('quote.text.color', '#FFFDF1')
  .property('quote.fullBackground.color', '#FF9C07')

  // AnnouncementV2
  .property('announcement.v2.text.color', '#FFFDF1')
  .property('announcement.v2.background.color', '#FF99A5')

  // Newsletter
  .property('newsletter.text.color', '#FFFFFF')
  .property('newsletter.fullBackground.color', '#FF99A5')

  // Footer
  .property('footer.text.color', '#FFFDF1')
  .property('footer.background.color', '#5ED1E6');
