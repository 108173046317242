import {reactive} from 'vue';
import {pageBus} from './bus';

export interface Version {
  name: string;
  title: string;
  modules: string[];
}

export const enableModule = (name: string): boolean => (modules[name] = true);
export const disableModule = (name: string): boolean => (modules[name] = false);
export const setVersion = (name: string, emit = true): void => {
  if (emit) {
    pageBus.emit('set-module-version', name);
  }

  const version = versions.find(v => v.name === name);

  if (!version) {
    return;
  }

  Object.keys(modules).forEach(module => {
    (version.modules.includes(module) ? enableModule : disableModule)(module);
  });
};

export const modules: Record<string, boolean> = reactive({
  blockEditor: false,
  templateEditor: false,
  historyEditor: false,
  improvedHitTarget: false,
});

export const versions: Version[] = [
  {
    name: 'v1',
    title: 'v1',
    modules: ['blockEditor'],
  },
  {
    name: 'v2',
    title: 'v2',
    modules: ['templateEditor', 'improvedHitTarget'],
  },
  {
    name: 'v3Dev',
    title: 'v3 Dev',
    modules: ['templateEditor', 'historyEditor', 'improvedHitTarget'],
  },
  {
    name: 'native',
    title: 'Native',
    modules: [],
  },
];
