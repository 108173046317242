import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const youtube = new BlockType({
  name: 'youtube',
  title: 'Youtube',
  compatibility: ['page'],
  component: () =>
    import(/* webpackChunkName: "TmlPage" */ '../pod/PodBlock.vue'),
  builder: () =>
    BlockBuilder.create('youtube')
      .size(6)
      .property('title', '')
      .property('video', '')
      .property('copy', '')
      .property('href', '')
      .property('badge', '')
      .property('icon', '')
      .property('overlay', 0)
      .property('ratio', 'short')
      .property('textAlign', 'center'),
});
