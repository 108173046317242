export default {
  get: state => name => {
    if (state.splitTests) {
      return state.splitTests.find(
        splitTest => splitTest && splitTest.code === name
      );
    }

    return null;
  },

  getDiscovered(state) {
    return state.splitTests.filter(
      splitTest => splitTest && splitTest.discovered === true
    );
  },

  getActiveVariation: (state, getters) => splitTestName => {
    const splitTest = getters.get(splitTestName);

    if (!splitTest) {
      return null;
    }

    let variationName = splitTest.variation.name;

    state.forceVariations.forEach(variationId => {
      if (splitTest.availableVariations[variationId]) {
        variationName = splitTest.availableVariations[variationId];
      }
    });

    return variationName;
  },

  isActiveVariation: (state, getters) => (splitTestName, variationName) => {
    const activeVariation = getters.getActiveVariation(splitTestName);

    if (!activeVariation) {
      return false;
    }

    if (activeVariation === variationName) {
      return true;
    }

    return false;
  },
};
