import isArray from 'lodash/isArray';
import castArray from 'lodash/castArray';
import snakeCase from 'lodash/snakeCase';
import isPlainObject from 'lodash/isPlainObject';
import cloneDeep from 'lodash/cloneDeep';
import {formatUrl} from '../helpers/url/formatUrl';

export class BaseClass {
  /**
   * Fetches model data from the server, from an array of IDs.
   *
   * @example
   *
   * Division.fetch([123, 456, 789], ['manufacturers.customers'])
   *  .then((divisions) => {
   *    this.divisions = divisions;
   *  })
   *
   * @param  {Number[]} ids
   * @param  {String[]} relations
   */
  static async fetch(ids, relations = [], attributes = []) {
    const {data} = await $axios.post(formatUrl('/omnis/v3/models/fetch/'), {
      ids: castArray(ids),
      relations: castArray(relations),
      attributes: castArray(attributes),
      type: snakeCase(this.modelName),
    });

    const item = this.map(data);

    return isArray(ids) || !isArray(item) ? item : item[0];
  }

  /**
   * @name map
   * @description Attempts to map an array or object into the class it was called
   *              on.
   *
   * @param {Array|Instance|Object} array
   *
   * @return {Array|Instance}
   */
  static map(array) {
    if (array instanceof this) {
      return array;
    }

    if (isPlainObject(array)) {
      return new this(array);
    }

    if (isArray(array)) {
      return array.map(item => this.map(item));
    }

    return null;
  }

  /**
   * @name copy
   * @description Creates a clone of a model.
   *
   * @return {Model} Cloned model.
   */
  copy() {
    return new this.constructor(cloneDeep(this));
  }
}
