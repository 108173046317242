/**
 * @name ensureTrailingSlash
 */
export const ensureTrailingSlash = (url: string): string => {
  if (url && !url.endsWith('/')) {
    return `${url}/`;
  }

  return url;
};
