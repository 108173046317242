import {RouterHistory, RouterScrollBehavior} from 'vue-router';

import {createPausableRouter} from '@teemill/common/helpers';
import {sleep} from '@teemill/utilities';

import * as routes from '../pages';

const scrollBehavior: RouterScrollBehavior = async (
  to,
  from,
  savedPosition
) => {
  if (to.path.replace(/\/+$/, '') === from.path.replace(/\/+$/, '')) {
    return;
  }

  if (!savedPosition) {
    return {left: 0, top: 0};
  }

  await sleep(300);

  return savedPosition;
};

export const createRouter = (history: RouterHistory) => {
  return createPausableRouter({
    history,
    routes: Object.values(routes).flat(),
    scrollBehavior,
  });
};
