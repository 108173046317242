import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const iframe = new BlockType({
  name: 'iframe',
  title: 'Iframe',
  compatibility: ['page'],
  component: () => import('./IframeBlock.vue'),
  builder: () =>
    BlockBuilder.create('iframe').property('src', '').property('height', 1000),
});
