import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const expandable = new BlockType({
  name: 'expandable',
  title: 'Expandable',
  compatibility: ['page'],
  component: () =>
    import(/* webpackChunkName: "ExpandableBlock" */ './ExpandableBlock.vue'),
  builder: () => BlockBuilder.create('expandable'),
});
