import {
  IconDefinition,
  faHeart as faHeartEmpty,
} from '@fortawesome/pro-light-svg-icons';
import {faHeart as faHeartFull} from '@fortawesome/pro-solid-svg-icons';
import {formatUrl} from '../helpers/url/formatUrl';
import {ApiError, ServerError, ValidationError} from '../errors';
import {ApiResponse} from './apiResponse';
import {splitTests} from './splitTests';

type ProductIdentifier = string | number;

export class Favourites {
  public items: any[] = [];
  private prelimItems: ProductIdentifier[] = [];

  public enabled = false;
  public shouldSeeConfetti = false;

  static favouriteIcon: IconDefinition = faHeartFull;
  static defaultIcon: IconDefinition = faHeartEmpty;

  public applySplitTestVariation() {
    this.enabled = splitTests.isActiveVariation('favourite_products', 'show');
  }

  /**
   * Sets the favourited items sorted so that items with more options (bundles) appear last
   */
  public setItems(items: any[]): void {
    items.sort((a, b) => a.options?.length - b.options?.length);

    this.items = items;

    if (items.length === 0) {
      this.shouldSeeConfetti = true;
    }
  }

  public get count(): number {
    return this.items.length;
  }

  /**
   * Returns an item found with the given identifier (url or ID)
   */
  public getItemByIdentifier(identifier: ProductIdentifier): any {
    const itemFound = this.items.find(item => {
      // straight forward match
      if ([item.productId, item.productUrl].includes(identifier)) {
        return true;
      }

      // match against a URL which has the manufacturer ID appended to the end
      const productUrlWords = String(identifier).split('-');

      if (productUrlWords.length > 1) {
        const productUrlWordsWithoutId = productUrlWords.slice(
          0,
          productUrlWords.length - 1
        );

        const productUrlWithoutId = productUrlWordsWithoutId.join('-');

        return item.productUrl === productUrlWithoutId;
      }

      return false;
    });

    return itemFound;
  }

  /**
   * Returns true if the item (by identifier) is favourited, else false
   */
  public isFavourited(identifier: ProductIdentifier): boolean {
    return (
      !!this.getItemByIdentifier(identifier) ||
      this.prelimItems.includes(identifier)
    );
  }

  /**
   * Gets the appropriate icon for the given product
   */
  public getIconForProduct(
    identifier: ProductIdentifier
  ): IconDefinition | undefined {
    if (!this.enabled) {
      return undefined;
    }

    return this.isFavourited(identifier)
      ? Favourites.favouriteIcon
      : Favourites.defaultIcon;
  }

  /**
   * Adds an item (by identifier) with the given options to the favourites, only if
   * it is not already added.
   */
  public async add(
    identifier: ProductIdentifier,
    colour: string,
    size: string
  ): Promise<boolean> {
    if (this.isFavourited(identifier)) {
      return false;
    }

    this.addPrelim(identifier);

    const isUrl = isNaN(Number(identifier));

    const response = await this.handleItemsRequest(
      // @ts-expect-error Convert axios to ts
      $axios.post(formatUrl('/omnis/v3/frontend/product/addItemToCart/'), {
        item: {
          productId: isUrl ? null : identifier,
          productUrl: isUrl ? identifier : null,
          productColor: colour,
          productSize: size,
        },
        name: 'favourites',
      })
    );

    this.removePrelim(identifier);

    return response;
  }

  private addPrelim(identifier: ProductIdentifier): void {
    this.prelimItems.push(identifier);
  }

  private removePrelim(identifier: ProductIdentifier): void {
    this.prelimItems.splice(this.prelimItems.indexOf(identifier), 1);
  }

  /**
   * Adds the item found (by identifier) into the basket
   */
  public async addToBasket(
    identifier: ProductIdentifier,
    size: string,
    colour: string,
    bundleOptions: Array<any>
  ): Promise<any> {
    const item = this.getItemByIdentifier(identifier);

    // @ts-expect-error Deprecated global store
    const addResponse = await $store.dispatch('cart/addItem', {
      item: {
        productId: item.productId,
        productColor: colour,
        productSize: size,
        bundleOptions,
      },
      name: 'main',
    });

    if (!addResponse.items?.length) {
      throw new Error('No items were added to basket');
    }

    return addResponse;
  }

  /**
   * Removes the item found (by identifier) from favourites
   */
  public async remove(productIdentifier: ProductIdentifier): Promise<boolean> {
    const item = this.getItemByIdentifier(productIdentifier);

    return this.handleItemsRequest(
      // @ts-expect-error Convert axios to ts
      $axios.post(formatUrl('/omnis/v3/basket/remove/'), {
        key: item.key,
        name: 'favourites',
      })
    );
  }

  /**
   * Fetches the favourites
   */
  public async fetch(): Promise<boolean> {
    return this.handleItemsRequest(
      // @ts-expect-error Convert axios to ts
      $axios.get(formatUrl('/omnis/v3/basket/favourites/list/'))
    );
  }

  /**
   * Recovers the favourites from the given hash
   */
  public async recover(recoveryHash: string): Promise<boolean> {
    return this.handleItemsRequest(
      // @ts-expect-error Convert axios to ts
      $axios.post(formatUrl('/omnis/v3/basket/favourites/recover/'), {
        br: recoveryHash,
      })
    );
  }

  /**
   * Sends an email to the given address, containing the contents of the favourites
   */
  public async sendToEmail(email: string): Promise<boolean> {
    return this.handleAxios(
      // @ts-expect-error Convert axios to ts
      $axios.post(formatUrl('/omnis/v3/basket/favourites/email-for-later'), {
        email,
      })
    );
  }

  /**
   * Handles requests which return a new set of items
   */
  private async handleItemsRequest(
    axiosRequest: ApiResponse
  ): Promise<boolean> {
    const {items} = await this.handleAxios(axiosRequest);

    this.setItems(items);

    return items;
  }

  /**
   * Handles axios requests and throws ts errors
   */
  private async handleAxios(axiosRequest: ApiResponse): Promise<any> {
    const response = await axiosRequest
      .success((data: any) => data)
      .throwValidation(() => new ValidationError())
      .throw('4xx', () => new ApiError())
      .throw('5xx', () => new ServerError())
      .output();

    return response;
  }
}

export const favourites = new Favourites();
