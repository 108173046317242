import {base} from './base';

export const atlantic = base
  .extend('atlantic')
  // Colors
  .property('primary.color', '#EDAD30')
  .property('primary.font', 'Oswald')

  // Page
  .property('page.background.color', '#FAFAFA')

  // Headings
  .property('heading1.transform', 'uppercase')
  .property('heading2.transform', 'uppercase')

  // Text
  .property('text.font', 'Nunito')
  .property('text.color', '#222222')
  .property('text.shadow', 'none')
  .property('text.background.color', '#FAFAFA')
  .property('text.fullBackground.color', '#FAFAFA')

  // Menu
  .property('menu.background.color', '#32496F')
  .property('menu.text.color', '#E2E2E2')
  .property('menu.text.font', 'Oswald')

  // Image
  .property('image.border.radius', '12px')
  .property(
    'image.default',
    'https://images.teemill.com/dfv8vmzupn7zrottyqcshuaonpkh2ifocou2baklw1dkn4vv.jpg'
  )

  // Banners
  .property('banner.fullBackground.color', '#0000')

  // Buttons
  .property('button.border.radius', '12px')
  .property('button.fullBackground.color', '#FAFAFA')

  // Feature
  .property('feature.fullBackground.color', '#fafafa')

  // Quote
  .property('quote.text.color', '#FFFFFF')
  .property('quote.background.color', '#131313')
  .property('quote.fullBackground.color', '#131313')

  // !deprecated
  // Announcement
  .property('announcement.fullBackground.color', '#FAFAFA')

  // AnnouncementV2
  .property('announcement.v2.text.color', '#131313')
  .property('announcement.v2.background.color', '#EDAD30')
  .property('announcement.v2.fullBackground.color', '#0000')

  // Card
  .property('card.fullBackground.color', '#FAFAFA')

  // Newsletter
  .property('newsletter.text.color', '#131313')
  .property('newsletter.background.color', '#EDAD30')
  .property('newsletter.fullBackground.color', '#EDAD30')

  //Footer
  .property('footer.text.color', '#222222')
  .property('footer.background.color', '#FAFAFA');
