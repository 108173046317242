import type {DirectiveDefinition} from './utilities/directiveHelpers';

import {getContext} from './utilities/directiveHelpers';

export const infiniscroll: DirectiveDefinition = {
  name: 'infiniscroll',
  directive: {
    beforeMount(el, binding) {
      if (!el.infiniScrollInitialised) {
        const limit = binding.value || 100;
        el.$infiniScroll = () => {
          const offset = el.scrollHeight - el.offsetHeight - el.scrollTop;
          if (Math.abs(offset) < limit) {
            const event = new CustomEvent('infiniscroll');
            el.dispatchEvent(event);
            getContext(binding).$emit('infiniscroll', offset);
          }
        };
        el.addEventListener('scroll', el.$infiniScroll, {passive: true});
      }
      el.infiniScrollInitialised = true;
    },
    unmounted(el) {
      el.removeEventListener('scroll', el.$infiniScroll);
      delete el.$infiniScroll;
    },
  },
};
