import {configureCompat} from 'vue';
import {createStoreFrontApp} from '@teemill/modules/store-front';

import './index.css';
import {createWebHistory} from 'vue-router';

const surpressedWarnings: Parameters<typeof configureCompat>[0] = {
  GLOBAL_MOUNT: 'suppress-warning',
  GLOBAL_EXTEND: 'suppress-warning',
  GLOBAL_PROTOTYPE: 'suppress-warning',
  GLOBAL_SET: 'suppress-warning',
  GLOBAL_DELETE: 'suppress-warning',
  GLOBAL_OBSERVABLE: 'suppress-warning',
  CONFIG_KEY_CODES: 'suppress-warning',
  CONFIG_WHITESPACE: 'suppress-warning',
  INSTANCE_SET: 'suppress-warning',
  INSTANCE_DELETE: 'suppress-warning',
  INSTANCE_EVENT_EMITTER: 'suppress-warning',
  INSTANCE_EVENT_HOOKS: 'suppress-warning',
  INSTANCE_CHILDREN: 'suppress-warning',
  INSTANCE_LISTENERS: 'suppress-warning',
  INSTANCE_SCOPED_SLOTS: 'suppress-warning',
  INSTANCE_ATTRS_CLASS_STYLE: 'suppress-warning',
  OPTIONS_DATA_FN: 'suppress-warning',
  OPTIONS_DATA_MERGE: 'suppress-warning',
  OPTIONS_BEFORE_DESTROY: 'suppress-warning',
  OPTIONS_DESTROYED: 'suppress-warning',
  WATCH_ARRAY: 'suppress-warning',
  V_ON_KEYCODE_MODIFIER: 'suppress-warning',
  CUSTOM_DIR: 'suppress-warning',
  ATTR_FALSE_VALUE: 'suppress-warning',
  ATTR_ENUMERATED_COERCION: 'suppress-warning',
  TRANSITION_GROUP_ROOT: 'suppress-warning',
  COMPONENT_ASYNC: 'suppress-warning',
  COMPONENT_FUNCTIONAL: 'suppress-warning',
  COMPONENT_V_MODEL: 'suppress-warning',
  RENDER_FUNCTION: 'suppress-warning',
  FILTERS: 'suppress-warning',
};

configureCompat({
  MODE: 2,
  ...surpressedWarnings,

  V_ON_KEYCODE_MODIFIER: false, // affects TmlInput
  RENDER_FUNCTION: false, // affects slots, e.g. use cases of TmlStages and TmlNewSelect
  COMPONENT_V_MODEL: true,
  INSTANCE_LISTENERS: true,
  INSTANCE_EVENT_EMITTER: true,
});

createStoreFrontApp({
  mode: 'native',
  history: createWebHistory(),
}).mount('#app');
