import {BaseClass} from '../../classes/baseClass';

import {dayjs} from '../../services/dayjs';

/**
 * OrderPaymentAttempt.js
 *
 * Defines the standard structure for a OrderPaymentAttempt object.
 *
 * @author Aidan Brookes <aidan@teemill.com>
 */
export default class OrderPaymentAttempt extends BaseClass {
  static modelName = 'orderPaymentAttempt';

  constructor({id, message, createdAt, isSuccessful, paymentMethodName} = {}) {
    super();

    this.id = id;
    this.message = message;
    this.successful = isSuccessful;
    this.paymentMethodName = paymentMethodName;
    this.createdAt = dayjs(createdAt).format('DD/MM/YY hh:mm a');
  }
}
