import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const proBenefits = new BlockType({
  name: 'proBenefits',
  title: 'Pro Benefits Comparison',
  compatibility: ['page'],
  component: () =>
    import(/* webpackChunkName: "ProBenefitsBlock" */ './ProBenefitsBlock.vue'),
  builder: () => BlockBuilder.create('proBenefits'),
});
