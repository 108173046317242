export default [
  {
    path: '/404/',
    name: '404',
    meta: {
      title: '404',
      layout: 'standard',
      allowPwaReload: true,
    },
    component: () => import('./StoreFront404Page.vue'),
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'routeNotFound',
    meta: {
      allowPwaReload: true,
    },
    component: () => import('./StoreFront404Page.vue'),
    // redirect: '/404/', // can switch to use redirect if page is broken when it should 404
  },
];
