export default {
  path: '/pay/:order/',
  name: 'Pay',
  meta: {
    title: 'Pay',
    layout: 'cart',
    shareToHomepage: true,
  },
  component: () => import(/* webpackChunkName: "PagePay" */ './PayPage.vue'),
};
