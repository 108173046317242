<script>
import {useLiveChat, chatSupportImage} from '@teemill/modules/live-chat';

import blockMixin from '../blockMixin.js';

export default {
  name: 'PageBlockLiveChatBlock',

  mixins: [blockMixin],

  mounted() {
    const {updateGroup, notify} = useLiveChat();

    updateGroup(this.block.property('group', 'string', 'general'));

    setTimeout(() => {
      notify({
        title: this.block.property('title', 'string'),
        body: this.block.property('body', 'string'),
        image: this.block.property('image', 'json')?.src || chatSupportImage,
        buttonStyle: this.block.property('buttonStyle', 'string', 'default'),
        buttonText: this.block.property('buttonText', 'string'),
      });
    }, this.block.property('showAfterSeconds', 'number', 0) * 1000);
  },
};
</script>
