import rules from './rules';

class Validator {
  constructor() {
    this._rules = rules;
  }

  /**
   * Run the validator
   *
   * @param { * }      value           The value to test
   * @param { Object } validationRules The rules to validate against
   *
   * @return { Object }
   */
  validate(value, validationRules) {
    return this._runValidationRules(
      value,
      this._findAppliedRules(validationRules)
    );
  }

  /**
   * Gets a list of the applicable validation rules.
   *
   * A validation rule must have a handler, or must be included in the list
   * of rules in /vue/global/helpers/validator/Rules.js
   *
   * @param { Object } validationRules The rules passed through from the form
   *                                   field component props
   *
   * @return { Object }
   */
  _findAppliedRules(validationRules) {
    const rulesToApply = {};
    const availableRules = Object.keys(this._rules);
    const ruleKeys = Object.keys(validationRules);

    ruleKeys.forEach(rule => {
      // only enforce the rule if its value is NOT false
      if (validationRules[rule] !== false) {
        if (availableRules.includes(rule)) {
          rulesToApply[rule] = this._rules[rule];
        }

        if (validationRules[rule].handler) {
          rulesToApply[rule] = validationRules[rule];
        }
      }
    });

    return rulesToApply;
  }

  /**
   * Iterate through each validation rule and test the value against each rule
   *
   * If validation fails, add the failure to an object of failed responses
   *
   * @param { * }      value       Any value passed from form field
   * @param { Object } validations The validation objects to run
   *
   * @return { Object }
   */
  _runValidationRules(value, validations) {
    const validationExceptions = {};

    Object.keys(validations).forEach(rule => {
      let passed;

      if (validations[rule].regex) {
        passed = this._testRegex(value, validations[rule].regex);
      }

      if (validations[rule].handler) {
        passed = this._testMethod(value, validations[rule].handler);
      }

      if (!passed) {
        validationExceptions[rule] = [];

        let message = validations[rule].message;

        message = message instanceof Function ? message(value) : message;

        validationExceptions[rule].push(message);
      }
    });

    return validationExceptions;
  }

  /**
   * Test the value against a passed regex string
   *
   * @param { * }      value     The value to test against regex
   * @param { RegExp } regexRule Regex string
   *
   * @return { Boolean }
   */
  _testRegex(value, regexRule) {
    if (value === null || typeof value === 'undefined') {
      value = '';
    }

    const matches = String(value).match(regexRule);

    return !!(matches && matches.length);
  }

  /**
   * Run a custom method on the value to perform validation
   *
   * @param { * }        value   A value to test against custom method
   * @param { Function } handler A method to test the value against
   *
   * @return { Boolean }
   */
  _testMethod(value, handler) {
    return !!handler(value);
  }
}

export default Validator;

export const validator = new Validator();
