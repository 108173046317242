import {get, isEmpty} from 'lodash';

/**
 * @name compileTemplate
 * @description Replace mustache templates in a string with variable values
 */
export const compileTemplate = (
  string: string,
  bindings: Record<string, any> | null = null
): string => {
  const mustacheTemplates = /{{([\s\S]+?)}}/g;

  if (!bindings || isEmpty(bindings)) {
    return string.replace(mustacheTemplates, '');
  }

  return string.replace(mustacheTemplates, template => {
    let value = get(bindings, template.replace(/[ {}]/g, ''));

    if (typeof value === 'number') {
      value = value.toString();
    }

    return typeof value === 'string' ? value : '';
  });
};
