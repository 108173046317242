import {default as srcDayjs} from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/isoWeek';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import updateLocale from 'dayjs/plugin/updateLocale';

declare module 'dayjs' {
  interface Dayjs {
    toLocalTime(): srcDayjs.Dayjs;
    /**
     * Temporarily update the global locale configuration for the given callback.
     *
     * @param customConfig The custom locale configuration to temporarily use.
     * @param callback The callback to execute with the custom locale configuration.
     *
     * @returns The result of the callback.
     */
    withLocaleConfig(
      customConfig: Partial<ILocale>,
      callback: (dayjsInstance: srcDayjs.Dayjs) => string
    ): string;
  }
}

srcDayjs.extend(utc);
srcDayjs.extend(isoWeek);
srcDayjs.extend(timezone);
srcDayjs.extend(duration);
srcDayjs.extend(relativeTime);
srcDayjs.extend(advancedFormat);
srcDayjs.extend(customParseFormat);
srcDayjs.extend(updateLocale);
srcDayjs.extend((option, dayjsClass) => {
  dayjsClass.prototype.toLocalTime = function () {
    return this.tz(srcDayjs.tz.guess());
  };
});
srcDayjs.extend((option, dayjsClass) => {
  dayjsClass.prototype.withLocaleConfig = function (
    customConfig: Partial<ILocale>,
    callback: (dayjsInstance: srcDayjs.Dayjs) => string
  ) {
    const currentLocale = this.locale();
    const oldConfig = {...srcDayjs.Ls[currentLocale]};
    srcDayjs.updateLocale(currentLocale, {
      ...oldConfig,
      ...customConfig,
    });
    const result = callback(this);
    srcDayjs.updateLocale(currentLocale, oldConfig);
    return result;
  };
});

const dayjs = srcDayjs;
export {dayjs};
