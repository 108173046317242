import {App, Plugin} from 'vue';
import {Store} from 'vuex';
import {merge, keys} from 'lodash';
import {
  WebsitePaymentProcessor,
  WebsitePaymentProcessorType,
} from '../../models/payment/websitePaymentProcessor';
import {WebsitePaymentAccountMethod} from '../../models/payment/websitePaymentAccount';

export type PaymentProcessorState = WebsitePaymentProcessor<any>;

type PaymentProcessorStore = Store<{paymentProcessor: PaymentProcessorState}>;

let store: PaymentProcessorStore | undefined;

export const paymentProcessorState = {
  namespaced: true,
  state: (): PaymentProcessorState => ({
    type: 'teemill',
    accounts: {},
  }),
  getters: {
    paymentProcessorType: (
      state: PaymentProcessorState
    ): WebsitePaymentProcessorType => {
      return state?.type || 'teemill';
    },

    paymentAccountTypes: (
      state: PaymentProcessorState
    ): WebsitePaymentAccountMethod[] => {
      if (!state || state.type === 'teemill') return ['stripe', 'paypal'];

      return keys(state.accounts || {}) as WebsitePaymentAccountMethod[];
    },

    stripeAccountId: (state: PaymentProcessorState): string | undefined => {
      return state?.accounts?.stripe?.config?.stripeAccountId;
    },

    stripeAccount: (
      state: PaymentProcessorState
    ): 'teemill' | 'podos' | undefined => {
      return state?.accounts?.stripe?.config?.account;
    },
  },
  mutations: {
    install: (state: PaymentProcessorState, value: PaymentProcessorState) => {
      state = merge(state, value);
    },
  },
  actions: {},
};

export function dispatchModuleMutations<T>(
  moduleName: string,
  mutationName: string | number | symbol,
  params?: T
): void {
  store?.commit(`${moduleName}/${String(mutationName)}`, params);
}

export const usePaymentProcessorStore = () => {
  return {
    get state(): PaymentProcessorState | undefined {
      return store?.state.paymentProcessor;
    },
    getter<K extends keyof typeof paymentProcessorState.getters>(
      name: K
    ): ReturnType<typeof paymentProcessorState.getters[K]> | undefined {
      return store?.getters[`paymentProcessor/${name}`] || undefined;
    },
    mutation<
      K extends Extract<keyof typeof paymentProcessorState.mutations, string>,
      T extends typeof paymentProcessorState.mutations
    >(mutationName: K, params: Parameters<T[K]>[1]): void {
      dispatchModuleMutations('paymentProcessor', mutationName, params);
    },
  };
};

export const paymentProcessorStore: Plugin = {
  async install(app: App, _store: PaymentProcessorStore): Promise<void> {
    store = _store;
  },
};
