import {base} from './base';

export const gallery = base
  .extend('gallery')
  // Colors
  .property('primary.color', '#1E1E1E')
  .property('primary.font', 'Kumbh Sans')

  // Page
  .property('page.background.color', '#f7f4f0')

  // Headings
  .property('heading1.transform', 'uppercase')
  .property('heading2.transform', 'uppercase')
  .property('heading3.transform', 'uppercase')

  // Text
  .property('text.font', 'Kumbh Sans')
  .property('text.color', '#1E1E1E')

  // Menu
  .property('menu.background.color', '#f7f4f0')
  .property('menu.text.color', '#1E1E1E')
  .property('menu.text.font', 'Kumbh Sans')

  // Image
  .property('image.border.radius', '0px')
  .property(
    'image.default',
    'https://images.teemill.com/77bssbfoqfvicwob733uiozhnnmikumghgatwv2ay15c6xv9.png'
  )

  // Buttons
  .property('button.border.radius', '0px')

  // Quote
  .property('quote.text.color', '#f7f4f0')
  .property('quote.fullBackground.color', '#1E1E1E')

  // AnnouncementV2
  .property('announcement.v2.text.color', '#f7f4f0')
  .property('announcement.v2.background.color', '#1E1E1E')

  // Newsletter
  .property('newsletter.text.color', '#f7f4f0')
  .property('newsletter.fullBackground.color', '#1E1E1E')

  //Footer
  .property('footer.text.color', '#1E1E1E')
  .property('footer.background.color', '#f7f4f0');
