import {HexColor} from './colorTypes';
import {getAlpha} from './getAlpha';

/**
 * @name getAlpha
 * @description Checks if a hex colour will be transparent
 */
export const isTransparent = (color: HexColor): boolean => {
  const alpha = getAlpha(color);

  return typeof alpha === 'number' && alpha === 0;
};
