import {BaseClass} from '../../classes/baseClass';

/**
 * OrderStatus.js
 *
 * Defines the standard structure for a OrderStatus object.
 *
 * @author Aidan Brookes <aidan@teemill.com>
 */
export default class OrderStatus extends BaseClass {
  static modelName = 'orderStatus';
  public id: number;
  public name?: string;
  public code?: string;
  public color?: string;

  constructor({
    id,
    name,
    code,
    color,
  }: {
    id: number;
    name?: string;
    code?: string;
    color?: string;
  }) {
    super();

    this.id = id;
    this.name = name;
    this.code = code;
    this.color = color;
  }
}
