<template>
  <tml-anchor
    class="tml-button"
    :class="{
      primary: primary,
      warning: warning,
      small: small,
      large: large,
      disabled: disabled,
      pro: forcePro,
      loading: loading,
      'no-full-width': noFullWidth,
      fill: fill,
      'icon-only': iconOnly,
      light: light,
    }"
    :style="internalStyles"
    :text-color="internalTextColor"
    :href="href"
    :target="target"
    :download="download"
    @click="$emit('click', $event)"
  >
    <tml-spinner v-if="loading" :light="primary" absolute />
    <span class="content">
      <font-awesome-icon
        v-if="icon && iconPosition === 'left'"
        class="icon icon-left"
        :class="{
          'text-spacing': text,
          'no-icon-margin': noIconMargin,
        }"
        :style="{
          height: iconSize,
          width: iconSize,
        }"
        :icon="icon"
      />
      {{ text }}
      <font-awesome-icon
        v-if="icon && iconPosition === 'right'"
        class="icon icon-right"
        :class="{
          'text-spacing': text,
          'no-icon-margin': noIconMargin,
        }"
        :style="{
          height: iconSize,
          width: iconSize,
        }"
        :icon="icon"
      />
    </span>
  </tml-anchor>
</template>

<script>
import {formatUrl} from '@teemill/common';
import {getRgbLuminance, hexToRgb} from '@teemill/utilities';
import {omitBy, isNil} from 'lodash';

import TmlAnchor from '../links/TmlAnchor.vue';
import TmlSpinner from '../loaders/TmlSpinner.vue';
/**
 * <b>Primary Button</b>
 * <b>Important: </b> You must never have 2 or more TmlButton Primary elements on screen at once, and you must always have at least one available per page.
 *
 * <tml-code-block code="<tml-button primary />" :codeHighlight="true" />
 * TmlButton Primary is a button that allows for user actions. This differs to TmlButton Secondary as it is reserved for primary actions.
 * The colour of a TmlButton Primary colour will differ depending on whether the user is on Teemill Pro or not.
 *
 * <b>Secondary Button</b>
 * <tml-code-block code="<tml-button />" :codeHighlight="true" />
 * TmlButton Secondary is a button that allows for user actions. This differs to TmlButton Primary as it is reserved for minor actions
 * and can be used more liberally.
 *
 * @example <tml-button primary text="I'm a primary button" />
 * @example <tml-button primary text="I'm an icon button" :icon="faStar" />
 * @example <tml-button primary loading text="I'm a primary button" />
 * @example <tml-button primary disabled text="I'm a disabled primary button" />
 * @example <tml-button primary forcePro text="This is a pro button" />
 *
 * @example <tml-button text="I'm a button" />
 * @example <tml-button text="I'm an icon button" :icon="faStar" />
 * @example <tml-button loading text="I'm a button" />
 * @example <tml-button disabled text="I'm a disabled button" />
 *
 * @example <tml-button forcePro text="This is a pro button" />
 */

export default {
  name: 'TmlButton',

  components: {TmlAnchor, TmlSpinner},

  inject: ['theme'],

  props: {
    /**
     * Is the button primary or secondary.
     */
    primary: Boolean,

    /**
     * Should the button be white
     */
    white: Boolean,

    /**
     * typically red, button for deleting
     */

    warning: Boolean,

    /**
     * Is it a small button?
     */
    small: Boolean,

    /**
     * Is it a large button?
     */
    large: Boolean,

    /**
     * Display the button as disabled (Will still function).
     */
    disabled: Boolean,

    /**
     * Make the button always pro.
     */
    forcePro: Boolean,

    /**
     * Text to display inside the button.
     */
    text: String,

    /**
     * Url of the page the buttons links to.
     * If the button triggers an @click action use href="#"
     */
    href: {
      default: '#',
      type: String,
    },

    /**
     * Icon to display on the button.
     */
    icon: Object,

    /**
     * Display a loading spinner over the button.
     * Set to true while the action for the button is begin processed.
     */
    loading: Boolean,

    /**
     * On mobile devices stop button going full width.
     */
    noFullWidth: Boolean,

    /**
     * Force full width at all times
     */
    fill: Boolean,

    /**
     * Adds download attribute to anchor tag
     */
    download: String,

    /**
     * Display a basic font awesome icon button
     */
    iconOnly: Boolean,

    /**
     * Font awesome icon size
     */
    iconSize: {
      type: String,
      default: '1em',
    },

    iconPosition: {
      type: String,
      default: 'left',
      validator: value => ['left', 'right'].indexOf(value) !== -1,
    },

    /**
     * Remove the margin surrounding button icons
     */
    noIconMargin: Boolean,

    /**
     * Href target
     *
     * @example <tml-button target="_blank" />
     */
    target: String,

    /**
     * Border radius
     */
    borderRadius: String,

    /**
     * Border color
     */
    borderColor: String,

    /**
     * Text color
     */
    textColor: String,

    /**
     * Background color
     */
    backgroundColor: String,
  },

  data() {
    return {
      isChildOfImage: false,
    };
  },

  computed: {
    internalStyles() {
      return omitBy(
        {
          borderRadius: this.borderRadius,
          borderColor: this.borderColor,
          backgroundColor: this.internalBackgroundColor,
          color: this.textColor,
        },
        isNil
      );
    },

    internalTextColor() {
      if (this.textColor) {
        return this.textColor;
      }

      if (this.primary) {
        return '#fff';
      }

      const themeColor = this.theme().get('text.color');

      if (this.light && getRgbLuminance(hexToRgb(themeColor)) > 0.9) {
        return '#000';
      }

      return themeColor;
    },

    internalBackgroundColor() {
      if (this.backgroundColor) {
        return this.backgroundColor;
      }

      if (this.iconOnly) {
        return 'transparent';
      }

      if (this.forcePro && this.primary) {
        return '#0aadff'; // primary colour of teemillPro theme
      }

      if (this.warning && !this.primary) {
        return 'var(--tml-error-color)';
      }

      if (this.light && !this.primary) {
        return '#fff';
      }

      return this.primary ? 'var(--tml-button-primary-color)' : undefined;
    },

    light() {
      return this.isChildOfImage || this.isChildOfVideo || this.white;
    },
  },

  created() {
    this.isChildOfImage = this.hasParentImage(this);
    this.isChildOfVideo = this.hasParentVideo(this);
  },

  methods: {
    hasParentImage(vm) {
      if (vm.$parent && vm.$parent.$options) {
        if (vm.$options.name === 'tml-image') {
          return true;
        }

        return this.hasParentImage(vm.$parent);
      }

      return false;
    },

    hasParentVideo(vm) {
      if (vm.$parent && vm.$parent.$options) {
        if (vm.$options.name === 'tml-youtube-video') {
          return true;
        }
        return this.hasParentVideo(vm.$parent);
      }

      return false;
    },

    formatUrl,
  },
};
</script>

<style scoped lang="scss">
.tml-button {
  display: inline-block;
  position: relative;
  width: 100%;
  padding: calc(0.75em - 1px) calc(1.15em - 1px);
  border: 1px solid var(--tml-text-color);
  color: var(--tml-text-color);
  text-transform: capitalize;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  border-radius: $border-radius;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    background-color: #000;
    border-radius: $border-radius;
    pointer-events: none;
    transition: opacity 0.15s;
  }

  &:hover,
  &:focus {
    filter: brightness(1.15);
  }

  &.pro {
    &.primary {
      color: $background-color !important;
    }
  }

  &.light:not(.primary) {
    border: none;
    // Adjust for border being removed
    padding: 0.75em 1.12em;
  }

  &.disabled:not(.primary) {
    border-color: $secondary-color !important;
    color: $secondary-color !important;
  }

  &.warning:not(.primary) {
    border: none;
    // Adjust for border being removed
    padding: 0.75em 1.12em;

    color: $background-color !important;
  }

  &.primary {
    border: none;
    // Adjust for border being removed
    padding: 0.75em 1.12em;
  }

  .icon {
    &:not(.no-icon-margin) {
      margin: 0 1em;
    }

    &.icon-left {
      &.text-spacing {
        margin-left: 0;
        margin-right: 0.5em;
      }
    }

    &.icon-right {
      &.text-spacing {
        margin-left: 0.5em;
        margin-right: 0em;
      }
    }
  }

  &.large {
    padding: 0.75em 2em;
    font-size: 1.5em;
    line-height: 1.5em;

    &:not(.primary) {
      padding: calc(0.75em - 1px) calc(2em - 1px);
    }
  }

  &.small {
    padding: 0.5em 1em;
    font-size: 0.875rem;

    &:not(.primary) {
      padding: calc(0.5em - 1px) calc(1em - 1px);
    }
  }

  &.loading {
    .content {
      opacity: 0;
    }
  }

  &.no-full-width {
    width: auto;
  }

  &.fill {
    width: 100%;
  }

  &.icon-only {
    border-color: transparent;

    padding: 0.5em 0.66em;

    .icon {
      margin: 0;
      transform: translateY(2px);
    }
  }
}

@media (min-width: 576px) {
  .tml-button:not(.fill):not(.w-full) {
    width: auto;
  }
}
</style>

<style lang="scss">
.tml-banner {
  .tml-button {
    border-width: 2px;
  }
}
</style>
