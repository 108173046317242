/* eslint prettier/prettier: 0 */

import {defineAsyncComponent, defineComponent} from 'vue';

export {default as TmlBubble} from './misc/TmlBubble.vue';
export {default as TmlStage} from './containers/TmlStage.vue';

// misc
export const TmlActiveTimestamp = defineAsyncComponent(
  () => import('./misc/TmlActiveTimestamp.vue')
);
// export const TmlBubble = defineAsyncComponent(() => import('./misc/TmlBubble.vue'));
export const TmlBadge = defineAsyncComponent(
  () => import('./misc/TmlBadge.vue')
);
export const TmlCodeBlock = defineAsyncComponent(
  () => import('./misc/TmlCodeBlock.vue')
);
export const TmlColorSquare = defineAsyncComponent(
  () => import('./misc/TmlColorSquare.vue')
);
export const TmlIdleRenderer = defineAsyncComponent(
  () => import('./misc/TmlIdleRenderer.vue')
);
export const TmlEditableOverlay = defineAsyncComponent(
  () => import('./misc/TmlEditableOverlay.vue')
);
export {default as TmlPlaceholder} from './misc/TmlPlaceholder.vue';

export const TmlMagicModel = defineAsyncComponent(
  () => import('./misc/TmlMagicModel.vue')
);
export {default as TmlBreadcrumb} from './misc/TmlBreadcrumb.vue';
export const TmlGoogleSearchPreview = defineAsyncComponent(
  () => import('./misc/TmlGoogleSearchPreview.vue')
);
export const TmlReassurance = defineAsyncComponent(
  () => import('./misc/TmlReassurance.vue')
);
export {default as TmlRenderHtmlEditorOutput} from './misc/TmlRenderHtmlEditorOutput.vue';
export const TmlRetry = defineAsyncComponent(
  () => import('./misc/TmlRetry.vue')
);

// banners
export const TmlAdvertBanner = defineAsyncComponent(
  () => import('./banners/TmlAdvertBanner.vue')
);
export {default as TmlBanner} from './banners/TmlBanner.vue';
// export const TmlBanner = defineAsyncComponent(
//   () => import('./banners/TmlBanner.vue')
// );
export {default as TmlCompactBanner} from './banners/TmlCompactBanner.vue';
export const TmlFullPageBanner = defineAsyncComponent(
  () => import('./banners/TmlFullPageBanner.vue')
);
export {default as TmlSlimBanner} from './banners/TmlSlimBanner.vue';

// buttons
export {default as TmlButton} from './buttons/TmlButton.vue';
// export const TmlButton = defineAsyncComponent(
//   () => import('./buttons/TmlButton.vue')
// );
export const TmlCloseButton = defineAsyncComponent(
  () => import('./buttons/TmlCloseButton.vue')
);
export const TmlGoogleSignInButton = defineAsyncComponent(
  () => import('./buttons/TmlGoogleSignInButton.vue')
);
export const TmlIconButton = defineAsyncComponent(
  () => import('./buttons/TmlIconButton.vue')
);
export const TmlShareButton = defineAsyncComponent(
  () => import('./buttons/TmlShareButton.vue')
);
export const TmlShareButtons = defineAsyncComponent(
  () => import('./buttons/TmlShareButtons.vue')
);

// cards
export const TmlCard = defineAsyncComponent(
  () => import('./cards/TmlCard.vue')
);
export const TmlCardDeck = defineAsyncComponent(
  () => import('./cards/TmlCardDeck.vue')
);
export const TmlCustomerCard = defineAsyncComponent(
  () => import('./cards/TmlCustomerCard.vue')
);
export const TmlNewCard = defineAsyncComponent(
  () => import('./cards/TmlNewCard.vue')
);
export const TmlQuoteCard = defineAsyncComponent(
  () => import('./cards/TmlQuoteCard.vue')
);
export const TmlUserCard = defineAsyncComponent(
  () => import('./cards/TmlUserCard.vue')
);

// charts
// export const TmlBubbleChart = defineAsyncComponent(() => import('./charts/TmlBubbleChart.vue'));
export const TmlBarChart = defineAsyncComponent(
  () => import('./charts/TmlBarChart.vue')
);
export const TmlDoughnutChart = defineAsyncComponent(
  () => import('./charts/TmlDoughnutChart.vue')
);
export const TmlGaugeChart = defineAsyncComponent(
  () => import('./charts/TmlGaugeChart.vue')
);
export const TmlKpiIcon = defineAsyncComponent(
  () => import('./charts/TmlKpiIcon.vue')
);
export const TmlLineChart = defineAsyncComponent(
  () => import('./charts/TmlLineChart.vue')
);
export const TmlWordCloud = defineAsyncComponent(
  () => import('./charts/TmlWordCloud.vue')
);
export const TmlGeoChart = defineAsyncComponent(
  () => import('./charts/TmlGeoChart.vue')
);
export const TmlNoDataSkeleton = defineAsyncComponent(
  () => import('./charts/TmlNoDataSkeleton.vue')
);
export const TmlPerformanceIndicator = defineAsyncComponent(
  () => import('./charts/TmlPerformanceIndicator.vue')
);
export const TmlStat = defineAsyncComponent(
  () => import('./charts/TmlStat.vue')
);

// containers
export const TmlIframe = defineAsyncComponent(
  () => import('./containers/TmlIframe.vue')
);
export const TmlCarousel = defineAsyncComponent(
  () => import('./containers/TmlCarousel.vue')
);
export const TmlDetails = defineAsyncComponent(
  () => import('./containers/TmlDetails.vue')
);
export const TmlDevicePreviewContainer = defineAsyncComponent(
  () => import('./containers/TmlDevicePreviewContainer.vue')
);
export const TmlDismissibleContainer = defineAsyncComponent(
  () => import('./containers/TmlDismissibleContainer.vue')
);
export {default as TmlExpandableContainer} from './containers/TmlExpandableContainer.vue';
export const TmlFilterable = defineAsyncComponent(
  () => import('./containers/tml-filterable/TmlFilterable.vue')
);
export const TmlFixedSidebar = defineAsyncComponent(
  () => import('./containers/TmlFixedSidebar.vue')
);
export const TmlFloatingContainer = defineAsyncComponent(
  () => import('./containers/TmlFloatingContainer.vue')
);
export {default as TmlGrid} from './containers/TmlGrid.vue';
export const TmlHelpDesk = defineAsyncComponent(
  () => import('./containers/TmlHelpDesk.vue')
);
export const TmlIconTray = defineAsyncComponent(
  () => import('./containers/TmlIconTray.vue')
);
export const TmlInfiniteScrollContainer = defineAsyncComponent(
  () => import('./containers/TmlInfiniteScrollContainer.vue')
);
export {default as TmlItemTray} from './containers/TmlItemTray.vue';
export const TmlLinkTray = defineAsyncComponent(
  () => import('./containers/TmlLinkTray.vue')
);
export const TmlMasonryGrid = defineAsyncComponent(
  () => import('./containers/tml-masonry-grid/TmlMasonryGrid.vue')
);
export const TmlPseudoKeyboard = defineAsyncComponent(
  () => import('./containers/TmlPseudoKeyboard.vue')
);
export {default as TmlRatio} from './containers/TmlRatio.vue';
// export const TmlRatio = defineAsyncComponent(
//   () => import('./containers/TmlRatio.vue')
// );
export const TmlSearchable = defineAsyncComponent(
  () => import('./containers/TmlSearchable.vue')
);
export const TmlSearchableGrid = defineAsyncComponent(
  () => import('./containers/TmlSearchableGrid.vue')
);
export const TmlSingleColumnContainer = defineAsyncComponent(
  () => import('./containers/TmlSingleColumnContainer.vue')
);
export {default as TmlSlideShow} from './containers/TmlSlideShow.vue';
// export const TmlSlideShow = defineAsyncComponent(
//   () => import('./containers/TmlSlideShow.vue')
// );
export const TmlSnackbarContainer = defineAsyncComponent(
  () => import('./containers/TmlSnackbarContainer.vue')
);
export const TmlSortableList = defineAsyncComponent(
  () => import('./containers/TmlSortableList.vue')
);
// export const TmlStage = defineAsyncComponent(() => import('./containers/TmlStage.vue'));
export const TmlStages = defineAsyncComponent(
  () => import('./containers/TmlStages.vue')
);
export const TmlStatTray = defineAsyncComponent(
  () => import('./containers/TmlStatTray.vue')
);
export const TmlTabbedContainer = defineAsyncComponent(
  () => import('./containers/TmlTabbedContainer.vue')
);
export const TmlTag = defineAsyncComponent(
  () => import('./containers/TmlTag.vue')
);
export const TmlStatus = defineAsyncComponent(
  () => import('./containers/TmlStatus.vue')
);
// export const TmlWormhole = defineAsyncComponent(() => import('./containers/TmlWormhole.vue'));
export const TmlDropdownContainer = defineAsyncComponent(
  () => import('./containers/TmlDropdownContainer.vue')
);
export const TmlDropdownContainerItem = defineAsyncComponent(
  () => import('./containers/TmlDropdownContainerItem.vue')
);
export {default as TmlCollapsibleText} from './containers/TmlCollapsibleText.vue';
export const TmlStickyContainer = defineAsyncComponent(
  () => import('./containers/TmlStickyContainer.vue')
);

// currency
export const TmlCurrency = defineAsyncComponent(
  () => import('./currencies/TmlCurrency.vue')
);
export const TmlCurrencySymbol = defineAsyncComponent(
  () => import('./currencies/TmlCurrencySymbol.vue')
);
export const TmlProductPrice = defineAsyncComponent(
  () => import('./currencies/TmlProductPrice.vue')
);

// effects
export const TmlAnimatedNumber = defineAsyncComponent(
  () => import('./effects/TmlAnimatedNumber.vue')
);
export const TmlFont = defineAsyncComponent(
  () => import('./effects/TmlFont.vue')
);
export const TmlHatching = defineAsyncComponent(
  () => import('./effects/TmlHatching.vue')
);
export const TmlOverlay = defineAsyncComponent(
  () => import('./effects/TmlOverlay.vue')
);
export const TmlPingEffect = defineAsyncComponent(
  () => import('./effects/TmlPingEffect.vue')
);
export const TmlPip = defineAsyncComponent(
  () => import('./effects/TmlPip.vue')
);
export const TmlScale = defineAsyncComponent(
  () => import('./effects/TmlScale.vue')
);

// forms
export const TmlNewSelect = defineAsyncComponent(
  () => import('./forms/tml-new-select/TmlNewSelect.vue')
);
export type {TmlNewSelectOption} from './forms/tml-new-select/type';

export const TmlAddressForm = defineAsyncComponent(
  () => import('./forms/TmlAddressForm.vue')
);
export const TmlAutocomplete = defineAsyncComponent(
  () => import('./forms/TmlAutocomplete.vue')
);
export const TmlColorPalette = defineAsyncComponent(
  () => import('./forms/TmlColorPalette.vue')
);
export const TmlColorPicker = defineAsyncComponent(
  () => import('./forms/TmlColorPicker.vue')
);
export const TmlColorInput = defineAsyncComponent(
  () => import('./forms/TmlColorInput.vue')
);
export const TmlColorSelector = defineAsyncComponent(
  () => import('./forms/TmlColorSelector.vue')
);
export const TmlSizeSelector = defineAsyncComponent(
  () => import('./forms/TmlSizeSelector.vue')
);
export const TmlContactForm = defineAsyncComponent(
  () => import('./forms/TmlContactForm.vue')
);
export const TmlCustomEmbedField = defineAsyncComponent(
  () => import('./forms/TmlCustomEmbedField.vue')
);
export const TmlCountryPicker = defineAsyncComponent(
  () => import('./forms/TmlCountryPicker.vue')
);
export const TmlDatePicker = defineAsyncComponent(
  () => import('./forms/TmlDatePicker.vue')
);
export const TmlDivisionSelector = defineAsyncComponent(
  () => import('./forms/TmlDivisionSelector.vue')
);
export const TmlFileUploader = defineAsyncComponent(
  () => import('./forms/TmlFileUploader.vue')
);
export const TmlFileUploadWrapper = defineAsyncComponent(
  () => import('./forms/TmlFileUploadWrapper.vue')
);
export const TmlFileBrowser = defineAsyncComponent(
  () => import('./forms/TmlFileBrowser.vue')
);
export const TmlFilterSearch = defineAsyncComponent(
  () => import('./forms/TmlFilterSearch.vue')
);
export const TmlFontsList = defineAsyncComponent(
  () => import('./forms/TmlFontsList.vue')
);
export const TmlForm = defineAsyncComponent(
  () => import('./forms/TmlForm.vue')
);
export const TmlGradientEditor = defineAsyncComponent(
  () => import('./forms/TmlGradientEditor.vue')
);
export const TmlIconSelector = defineAsyncComponent(
  () => import('./forms/TmlIconSelector.vue')
);
export const TmlInput = defineAsyncComponent(
  () => import('./forms/TmlInput.vue')
);
export const TmlMetaEditor = defineAsyncComponent(
  () => import('./forms/TmlMetaEditor.vue')
);
export const TmlNewForm = defineAsyncComponent(
  () => import('./forms/TmlNewForm.vue')
);
export const TmlNewProductColorSelector = defineAsyncComponent(
  () => import('./forms/TmlNewProductColorSelector.vue')
);
export const TmlNewSegmentedControl = defineAsyncComponent(
  () => import('./forms/TmlNewSegmentedControl.vue')
);
export const TmlPaypalInput = defineAsyncComponent(
  () => import('./forms/TmlPaypalInput.vue')
);
export const TmlPhoneNumberInput = defineAsyncComponent(
  () => import('./forms/TmlPhoneNumberInput.vue')
);
export const TmlPriceSlider = defineAsyncComponent(
  () => import('./forms/TmlPriceSlider.vue')
);
export const TmlPriceInput = defineAsyncComponent(
  () => import('./forms/TmlPriceInput.vue')
);
export const TmlSlider = defineAsyncComponent(
  () => import('./forms/TmlSlider.vue')
);
export const TmlProductColorManager = defineAsyncComponent(
  () => import('./forms/TmlProductColorManager.vue')
);
export const TmlFileManager = defineAsyncComponent(
  () => import('./forms/TmlFileManager.vue')
);
export const TmlRadioButton = defineAsyncComponent(
  () => import('./forms/TmlRadioButton.vue')
);
export const TmlRadioButtonGroup = defineAsyncComponent(
  () => import('./forms/TmlRadioButtonGroup.vue')
);
export const TmlSegmentedControl = defineAsyncComponent(
  () => import('./forms/TmlSegmentedControl.vue')
);
export const TmlTagInput = defineAsyncComponent(
  () => import('./forms/TmlTagInput.vue')
);

// export const TmlSegmentedDatePicker = defineAsyncComponent(() => import('./forms/TmlSegmentedDatePicker.vue'));
export const TmlSelect = defineAsyncComponent(
  () => import('./forms/TmlSelect.vue')
);
export const TmlStatePicker = defineAsyncComponent(
  () => import('./forms/TmlStatePicker.vue')
);
export const TmlTabbedColorPalette = defineAsyncComponent(
  () => import('./forms/TmlTabbedColorPalette.vue')
);
export const TmlTextarea = defineAsyncComponent(
  () => import('./forms/TmlTextarea.vue')
);
export const TmlToggle = defineAsyncComponent(
  () => import('./forms/TmlToggle.vue')
);
export const TmlUploader = defineAsyncComponent(
  () => import('./forms/TmlUploader.vue')
);
export const TmlUserSelector = defineAsyncComponent(
  () => import('./forms/TmlUserSelector.vue')
);
export const TmlCrossSellManager = defineAsyncComponent(
  () => import('./forms/TmlCrossSellManager.vue')
);

// icons
export const TmlIcon = defineAsyncComponent(
  () => import('./icons/TmlIcon.vue')
);
export {default as FontAwesomeIcon} from './icons/FontAwesomeIcon.vue';

// images
export const TmlDynamicMockup = defineAsyncComponent(
  () => import('./images/TmlDynamicMockup.vue')
);
export {default as TmlImage} from './images/TmlImage.vue';

export {default as TmlSimpleImage} from './images/TmlSimpleImage.vue';

export const TmlBarcode = defineAsyncComponent(
  () => import('./images/TmlBarcode.vue')
);
// export const TmlImage = defineAsyncComponent(
//   () => import('./images/TmlImage.vue')
// );
export const ImageFocalEditor = defineAsyncComponent(
  () => import('./images/ImageFocalEditor.vue')
);

export {default as TmlImageZoom} from './images/TmlImageZoom.vue';
// export const TmlNewEditableImage = defineAsyncComponent(() => import('./images/TmlNewEditableImage.vue'));
export const TmlProductMockup = defineAsyncComponent(
  () => import('./images/TmlProductMockup.vue')
);

// // links
export {default as TmlAnchor} from './links/TmlAnchor.vue';
// export const TmlAnchor = defineAsyncComponent(
//   () => import('./links/TmlAnchor.vue')
// );

// lists
export const TmlList = defineAsyncComponent(
  () => import('./lists/TmlList.vue')
);
export const TmlAccordion = defineAsyncComponent(
  () => import('./lists/TmlAccordion.vue')
);
export const TmlSteps = defineAsyncComponent(
  () => import('./lists/tml-steps/TmlSteps.vue')
);

// loaders
export {default as TmlLoader} from './loaders/TmlLoader.vue';
// export const TmlLoader = defineAsyncComponent(
//   () => import('./loaders/TmlLoader.vue')
// );
export const TmlLoadingBar = defineAsyncComponent(
  () => import('./loaders/TmlLoadingBar.vue')
);
export const TmlSpinner = defineAsyncComponent(
  () => import('./loaders/TmlSpinner.vue')
);

// // logos
export {default as TmlLogo} from './logos/TmlLogo.vue';
// export const TmlLogo = defineAsyncComponent(
//   () => import('./logos/TmlLogo.vue')
// );
export const TmlAtlasLogo = defineAsyncComponent(
  () => import('./logos/TmlAtlasLogo.vue')
);
export const TmlNodeLogo = defineAsyncComponent(
  () => import('./logos/TmlNodeLogo.vue')
);
export const TmlTeemillLogo = defineAsyncComponent(
  () => import('./logos/TmlTeemillLogo.vue')
);
export const TmlTeemillProLogo = defineAsyncComponent(
  () => import('./logos/TmlTeemillProLogo.vue')
);

// messages
export const TmlAlertMessage = defineAsyncComponent(
  () => import('./messages/TmlAlertMessage.vue')
);
export const TmlAnnouncement = defineAsyncComponent(
  () => import('./messages/TmlAnnouncement.vue')
);
export {default as TmlAnnouncementV2} from './messages/TmlAnnouncementV2.vue';
export const TmlDismissibleTip = defineAsyncComponent(
  () => import('./messages/TmlDismissibleTip.vue')
);
export const TmlInfoTooltip = defineAsyncComponent(
  () => import('./messages/TmlInfoTooltip.vue')
);
export const TmlMessage = defineAsyncComponent(
  () => import('./messages/TmlMessage.vue')
);

// navigation
export const TmlMobileNavBar = defineAsyncComponent(
  () => import('./navigation/TmlMobileNavBar.vue')
);
export const TmlTabs = defineAsyncComponent(
  () => import('./navigation/TmlTabs.vue')
);

// notifications
export const TmlDashboardNotification = defineAsyncComponent(
  () => import('./notifications/TmlDashboardNotification.vue')
);
export const TmlNotificationsPopup = defineAsyncComponent(
  () => import('./notifications/TmlNotificationsPopup.vue')
);
export const TmlNotificationsWallet = defineAsyncComponent(
  () => import('./notifications/TmlNotificationsWallet.vue')
);

// overlay-containers
export const TmlIconOverlay = defineAsyncComponent(
  () => import('./overlay-containers/TmlIconOverlay.vue')
);
export const TmlNewSidebar = defineAsyncComponent(
  () => import('./overlay-containers/TmlNewSidebar.vue')
);
export const TmlPopup = defineAsyncComponent(
  () => import('./overlay-containers/TmlPopup.vue')
);
export const TmlSidebar = defineAsyncComponent(
  () => import('./overlay-containers/TmlSidebar.vue')
);
export const TmlTooltip = defineAsyncComponent(
  () => import('./overlay-containers/TmlTooltip.vue')
);
export const TmlTray = defineAsyncComponent(
  () => import('./overlay-containers/TmlTray.vue')
);
export const TmlNewPopup = defineAsyncComponent(
  () => import('./overlay-containers/tml-new-popup/TmlNewPopup.vue')
);
export const TmlBannerPopup = defineAsyncComponent(
  () => import('./overlay-containers/tml-new-popup/TmlBannerPopup.vue')
);

// pods
export {default as TmlNewPod} from './pods/TmlNewPod.vue';
export const TmlPaletteTile = defineAsyncComponent(
  () => import('./pods/TmlPaletteTile.vue')
);

// progress-indicators
export const TmlProgressBar = defineAsyncComponent(
  () => import('./progress-indicators/TmlProgressBar.vue')
);
export const TmlProgressStepper = defineAsyncComponent(
  () => import('./progress-indicators/TmlProgressStepper.vue')
);

// renderless
export const TmlSegmentedSelect = defineAsyncComponent(
  () => import('./renderless/TmlSegmentedSelect.vue')
);

// selectors
export const TmlBlogSelector = defineAsyncComponent(
  () => import('./selectors/TmlBlogSelector.vue')
);
export const TmlCollectionSelector = defineAsyncComponent(
  () => import('./selectors/TmlCollectionSelector.vue')
);
export const TmlContentSelector = defineAsyncComponent(
  () => import('./selectors/TmlContentSelector.vue')
);
// export const TmlEmbedSelector = defineAsyncComponent(() => import('./selectors/TmlEmbedSelector.vue'));
export const TmlLinkSelector = defineAsyncComponent(
  () => import('./selectors/TmlLinkSelector.vue')
);
// export const TmlModelSelector = defineAsyncComponent(() => import('./selectors/TmlModelSelector.vue'));
export const TmlPageSelector = defineAsyncComponent(
  () => import('./selectors/TmlPageSelector.vue')
);
export const TmlProductColourSelector = defineAsyncComponent(
  () => import('./selectors/TmlProductColourSelector.vue')
);
export const TmlProductImageSelector = defineAsyncComponent(
  () => import('./selectors/TmlProductImageSelector.vue')
);
export const TmlProductSelector = defineAsyncComponent(
  () => import('./selectors/TmlProductSelector.vue')
);
export const TmlSocialPostSelector = defineAsyncComponent(
  () => import('./selectors/TmlSocialPostSelector.vue')
);
export const TmlSoundcloudSelector = defineAsyncComponent(
  () => import('./selectors/TmlSoundcloudSelector.vue')
);
export const TmlSpotifySelector = defineAsyncComponent(
  () => import('./selectors/TmlSpotifySelector.vue')
);
export const TmlVideoSelector = defineAsyncComponent(
  () => import('./selectors/TmlVideoSelector.vue')
);
export const TmlYoutubeSelector = defineAsyncComponent(
  () => import('./selectors/TmlYoutubeSelector.vue')
);
export const TmlModelSelector = defineAsyncComponent(
  () => import('./selectors/TmlModelSelector.vue')
);
// export const TmlUnlockableSelector = defineAsyncComponent(() => import('./selectors/TmlUnlockableSelector.vue'));

// split-test
export {default as TmlSplitTest} from './split-test/TmlSplitTest.vue';
export {default as TmlSplitTestVariation} from './split-test/TmlSplitTestVariation.vue';

// summaries
export const TmlAddress = defineAsyncComponent(
  () => import('./summaries/TmlAddress.vue')
);
export const TmlObjectInteractions = defineAsyncComponent(
  () => import('./summaries/TmlObjectInteractions.vue')
);
export const TmlObjectSummary = defineAsyncComponent(
  () => import('./summaries/TmlObjectSummary.vue')
);
export const TmlSocialPreview = defineAsyncComponent(
  () => import('./summaries/TmlSocialPreview.vue')
);
export const TmlStarRating = defineAsyncComponent(
  () => import('./summaries/TmlStarRating.vue')
);
export const TmlReview = defineAsyncComponent(
  () => import('./summaries/TmlReview.vue')
);
export const TmlReviewReply = defineAsyncComponent(
  () => import('./summaries/TmlReviewReply.vue')
);

// // tables
export const TmlDataTable = defineAsyncComponent(
  () => import('./tables/tml-data-table/TmlDataTable.vue')
);
export const TmlDataTableBodyCell = defineAsyncComponent(
  () => import('./tables/tml-data-table/TmlDataTableBodyCell.vue')
);
export const TmlDataTableFooter = defineAsyncComponent(
  () => import('./tables/tml-data-table/TmlDataTableFooter.vue')
);
export const TmlDataTableHeadCell = defineAsyncComponent(
  () => import('./tables/tml-data-table/TmlDataTableHeadCell.vue')
);

export const TmlTable = defineAsyncComponent(
  () => import('./tables/tml-table/TmlTable.vue')
);

// tabs
export const TmlTabBody = defineAsyncComponent(
  () => import('./tabs/TmlTabBody.vue')
);
export const TmlTabBodyItem = defineAsyncComponent(
  () => import('./tabs/TmlTabBodyItem.vue')
);
export const TmlTabHeader = defineAsyncComponent(
  () => import('./tabs/TmlTabHeader.vue')
);
export const TmlTabHeaderItem = defineAsyncComponent(
  () => import('./tabs/TmlTabHeaderItem.vue')
);

// third-party
export const TmlEditableYoutubeVideo = defineAsyncComponent(
  () => import('./third-party/TmlEditableYoutubeVideo.vue')
);
export const TmlReCaptcha = defineAsyncComponent(
  () => import('./third-party/TmlReCaptcha.vue')
);
export const TmlSoundcloudEmbed = defineAsyncComponent(
  () => import('./third-party/TmlSoundcloudEmbed.vue')
);
export const TmlYoutubeVideo = defineAsyncComponent(
  () => import('./third-party/TmlYoutubeVideo.vue')
);

// Video
export const TmlVideo = defineAsyncComponent(
  () => import('./video/TmlVideo.vue')
);

// Description list
export const TmlDescriptionList = defineAsyncComponent(
  () => import('./description-list/TmlDescriptionList.vue')
);

export const TmlEmbroideryApplicationEditor = defineAsyncComponent(
  () => import('./forms/TmlEmbroideryApplicationEditor.vue')
);
