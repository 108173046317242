import {inViewport} from '@teemill/common/helpers';
import type {DirectiveDefinition} from './utilities/directiveHelpers';

export const vpshow: DirectiveDefinition = {
  name: 'vpshow',
  directive: {
    beforeMount(el, binding, vnode) {
      el.classList.add('fade-enter-from');
      el.$onScroll = () => {
        if (inViewport(el)) {
          el.classList.add('fade-enter-active');
          el.classList.remove('fade-enter-from');
          if (binding.dir.unmounted) {
            binding.dir.unmounted(el, binding, vnode, null);
          }
        }
      };
      document.addEventListener('scroll', el.$onScroll);
    },

    mounted(el) {
      if (el.$onScroll instanceof Function) {
        el.$onScroll();
      }
    },

    unmounted(el) {
      document.removeEventListener('scroll', el.$onScroll);

      delete el.$onScroll;
    },
  },
};
