import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const affiliateStats = new BlockType({
  name: 'affiliateStats',
  title: 'affiliate stats',
  compatibility: ['page'],
  component: () =>
    import(
      /* webpackChunkName: "AffiliatesBlocks" */ './AffiliateStatsBlock.vue'
    ),
  builder: () => BlockBuilder.create('affiliateStats'),
});
