import {handleFailedChunk} from '@teemill/common/helpers';

export default {
  path: '/basket/',
  name: 'Basket',
  meta: {
    title: 'Basket',
    layout: 'cart',
    shareToHomepage: true,
  },
  component: () =>
    import('./StoreFrontBasketPage.vue').catch(handleFailedChunk),
};
