import {BlockBuilder} from '../classes/blockBuilder';

import {BlockFactory} from '../classes/blockFactory';
import {BlockSourceData} from '../classes/blockFactorySource';

export const slide = new BlockFactory({
  name: 'slide',
  builder: (data: BlockSourceData) => {
    return data.map(({title, copy, images}, index) => {
      return BlockBuilder.create('banner')
        .order(index)
        .property('title', title)
        .property('copy', copy)
        .property('image', images ? images[0] : undefined);
    });
  },
});
