import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const summary = new BlockType({
  name: 'summary',
  title: 'Summary',
  compatibility: ['page'],
  component: () =>
    import(/* webpackChunkName: "SummaryBlock" */ './SummaryBlock.vue'),
  builder: () =>
    BlockBuilder.create('Summary')
      .minSize(12)
      .property('image', '')
      .property('title', '')
      .property('description', '')
      .property('icon', ''),
});
