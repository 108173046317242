export const parseImageProperty = (image: string) => {
  try {
    const parsedImage = JSON.parse(image);

    if (parsedImage?.src) {
      return {
        src: parsedImage.src,
        alt: parsedImage.alt || '',
      };
    }
  } catch {
    return {
      src: image,
      alt: '',
    };
  }

  throw new Error('No src found in image property value');
};
