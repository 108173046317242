<template>
  <component
    :is="bannerType"
    v-if="block"
    :title="block.property('title', 'string', null, bindingData)"
    :copy="block.property('copy', 'string', null, bindingData)"
    :media="block.property('media')"
    :video="block.property('video')"
    :video-thumbnail="getVideoThumbnail(block)"
    :image="getImage(block)"
    :font="block.property('font')"
    :vertical-alignment="
      block.property('verticalAlignment', 'string', 'center')
    "
    :horizontal-alignment="block.property('align', 'string', 'center')"
    :actions="actions"
    :min-height="
      block.property(
        'minHeight',
        'string',
        parentBlock ? parentBlock.property('minHeight', 'string') : defaultVh
      )
    "
    :title-type="shouldUseH1Title ? 'h1' : 'h2'"
    :overlay-opacity="block.property('overlayOpacity', 'number', 0.15)"
    :overlay-colors="gradientColours"
    :overlay-direction="`${block.property(
      'overlayDirection',
      'string',
      '90'
    )}deg`"
    :title-color="block.property('titleColor')"
    :title-background="block.property('titleBackground')"
    :copy-color="block.property('copyColor')"
    :copy-background="block.property('copyBackground')"
    :text-shadow="getTextShadow"
    :border-radius="block.property('borderRadius', 'string')"
    :button-border-radius="block.property('buttonBorderRadius', 'string')"
    :button-background-color="block.property('buttonBackgroundColor', 'string')"
    :button-size="block.property('buttonSize', 'string')"
    :content-width="block.property('contentWidth', 'string', '95%')"
    :lazy-load="block.parent ? block.parent.order !== 0 : block.order !== 0"
    :src-set="[]"
    :alt-text="imageAlt"
    @loaded="onLoad"
    v-on="events"
  />
</template>

<script>
import {formatUrl, image, viewportSize} from '@teemill/common/helpers';
import blockMixin from '../blockMixin';
import {filterBlocks} from '../../utils';

export default {
  name: 'PageBlockBannerBlock',

  mixins: [blockMixin],

  computed: {
    bannerType() {
      if (this.block.property('compact', 'boolean', false)) {
        return 'tml-compact-banner';
      }

      switch (this.block.property('type')) {
        default:
        case 'standard':
          return 'tml-banner';

        case 'slim':
          return 'tml-slim-banner';

        case 'compact':
          return 'tml-compact-banner';
      }
    },

    actions() {
      const actions = this.block.property('actions', 'json', []);

      // If an action has no link and no functional action,
      // assume that we should fire a regular click event
      // from the TmlBanner that wraps it - allowing us to
      // effectively handle a "click" event on the banner
      return actions.map((action, index) => {
        if (action) {
          if (
            typeof action.action !== 'function' &&
            (action.href === '#' || typeof action.href === 'undefined')
          ) {
            action.action = event => event.vm.$emit('click');
          }

          // ! Deprecated property
          action.primary = !!this.block.property(
            'primaryActions',
            'boolean',
            true
          );

          // ! actionStyle is a deprecated property
          switch (
            this.block.property('buttonStyle') ||
            this.block.property('actionStyle')
          ) {
            case 'primary':
              action.primary = true;
              action.warning = false;
              break;
            case 'secondary':
              action.primary = false;
              action.warning = false;
              break;
            case 'warning':
              action.primary = false;
              action.warning = true;
              break;
            case 'primary|secondary':
              action.primary = index === 0;
              action.warning = false;
              break;
            default:
              break;
          }
        }

        return action;
      });
    },

    gradientColours() {
      return `${this.block.property(
        'overlayColourStart',
        'string',
        '#000000'
      )}, ${this.block.property('overlayColourEnd', 'string', '#000000')}`;
    },

    defaultVh() {
      return viewportSize.isSmaller('md') ? '70vh' : undefined;
    },

    imageAlt() {
      return this.block.property('image')?.alt;
    },

    shouldUseH1Title() {
      return this.block.parent
        ? this.isFirstBannerOnPage(this.block.parent) &&
            this.isFirstBannerInSlideshow
        : this.isFirstBannerOnPage(this.block);
    },

    isFirstBannerInSlideshow() {
      if (!this.block.parent?.items) {
        return false;
      }

      const slideshowBanners = filterBlocks(this.block.parent.items);

      return !!(
        slideshowBanners.length &&
        this.block.order === slideshowBanners[0].order
      );
    },
  },

  methods: {
    isFirstBannerOnPage(block) {
      const pageBanners = filterBlocks(block.page.blocks).filter(block =>
        ['banner', 'slideshow'].includes(block.type.name)
      );

      return pageBanners.length && block.order === pageBanners[0].order;
    },

    getImage(block) {
      const imageSrc = block.property('image')?.src;

      if (imageSrc) {
        return image(formatUrl(imageSrc), 1920, 768);
      }

      return '';
    },

    getVideoThumbnail(block) {
      if (block.property('videoThumbnail')) {
        return image(
          formatUrl(block.property('videoThumbnail')),
          1920,
          768
        ).webp();
      }

      return null;
    },

    onLoad() {
      if (this.block.parent?.order === 0) {
        this.emitPageBusEvent('largest-contentful-paint-loaded');
      }
    },
  },
};
</script>
