import {LogConfig} from '@devanjs/log';
import {App} from 'vue';

/**
 * Errors
 */
export * from './errors/pageErrors';

/**
 * Classes
 */
export * from './classes/property';
export * from './classes/block';
export * from './classes/blockBuilder';
export * from './classes/blockCategory';
export * from './classes/blockFactory';
export * from './classes/blockFactorySource';
export * from './classes/blockTemplate';
export * from './classes/blockTemplateGroup';
export * from './classes/blockType';
export * from './classes/pageTemplate';
export * from './classes/legacyPageTemplate';
export * from './classes/menu';
export * from './classes/footer';
export * from './classes/menuItem';
export * from './classes/footerItem';
export * from './classes/page';

/**
 * Providers
 */
export * from './providers/pageProvider';

/**
 * Factories
 */
export * as factories from './factories';
export * as sources from './factories/sources';

/**
 * Components
 */
export {default as PageRenderer} from './components/PageRenderer.vue';
export {default as BlockRenderer} from './components/BlockRenderer.vue';

/**
 * Other
 */
export * as blocks from './blocks';
export {install as editorInterface} from './interface-v2';
export * from './styleConversion';
export * from './modules';
export * from './bus';

/**
 * Plugin
 */
import * as blocks from './blocks';
import * as factories from './factories';
import * as sources from './factories/sources';

import {BlockType} from './classes/blockType';
import {Block} from './classes/block';

export default {
  install(app: App) {
    BlockType.types = Object.values(blocks);

    Block.factories = factories;
    Block.sources = sources;

    LogConfig.defaults('pages', log =>
      log.if(import.meta.env.DEV).blue('Pages')
    );

    LogConfig.defaults('pages-v', log => log.if(false).blue('Pages'));
  },
};
