import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const announcement = new BlockType({
  name: 'announcement',
  title: 'Announcement',
  compatibility: ['page'],
  component: () =>
    import(
      /* webpackChunkName: "AnnouncementBlock1" */ './AnnouncementBlock.vue'
    ),
  builder: () => BlockBuilder.create('announcement'),
});
