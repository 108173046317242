import {formatUrl} from '@teemill/common/helpers';

import {BlockFactorySource} from '../../classes/blockFactorySource';

export const blog = new BlockFactorySource<
  {
    url: string;
    title: string;
    image: string;
  },
  {
    limit: number;
  }
>({
  name: 'blog',
  request: async ({limit}) => {
    const divisionId =
      // @ts-expect-error Convert vuex to ts
      $store.state.store?.active?.id || $store.state.subdomain?.division;

    // @ts-expect-error Convert axios to ts
    return $axios
      .get(formatUrl('/omnis/v3/blog/paginate/'), {
        params: {
          paginate: limit,
          divisions: divisionId,
          lite: true,
        },
      })
      .success((blogs: any) =>
        blogs.data.slice(0, limit as number).map((blog: any) => ({
          url: blog.url,
          title: blog.title,
          image: blog.metaImage.banner,
        }))
      )
      .any(() => [])
      .output() as any;
  },
  parser: rawData =>
    rawData.map(({url, title, image}) => ({
      title,
      images: [image],
      href: url,
    })),
  params: {
    limit: 1,
  },
});
