export default {
  setActive(state, collection) {
    state.active = collection;
  },

  clearActive(state) {
    state.active = null;
  },

  setLastVisited(state, collection) {
    state.lastVisited = collection;
  },

  clearLastVisited(state) {
    state.lastVisited = null;
  },
};
