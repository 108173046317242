import type {DirectiveDefinition} from './utilities/directiveHelpers';
import type {ComponentPublicInstance} from 'vue';
import {overlays} from '@teemill/modules/overlays';

import {getContext} from './utilities/directiveHelpers';

/**
 * @name tmlRequestPermission
 */
export const requestPermission: DirectiveDefinition = {
  name: 'request-permission',
  directive: {
    mounted(el, binding) {
      const context = getContext(binding) as ComponentPublicInstance;

      if (binding.value) {
        const permissions = binding.value.split(',');

        if (el) {
          const missingPerms: string[] = [];

          permissions.forEach((permission: string) => {
            // @ts-expect-error !Deprecated
            if (!context.hasPermission(permission)) {
              missingPerms.push(permission);
            }
          });

          const requestPerms = missingPerms.join(',');

          if (missingPerms.length) {
            el.addEventListener('click', (e: Event) => {
              e.preventDefault();
              e.stopPropagation();

              overlays
                .requestPermission(requestPerms)
                .then((reason: string) => {
                  window.$axios
                    .post(
                      `/omnis/v3/atlas/permissions/request/${requestPerms}/`,
                      {
                        reason,
                      }
                    )
                    .then(({response}: any) => {
                      if (!response) {
                        context.$dialogs.showSnackBar({
                          message: 'Permission request successfully submitted',
                        });
                      }
                    });
                });
            });
          }
        }
      }
    },
  },
};
