import {RgbColor} from './colorTypes';
import {formatHex} from './formatHex';

/**
 * @name hexToRgb
 * @description Converts a hex code into an RGB object
 */
export const hexToRgb = (hexIn: string): RgbColor => {
  const hex = formatHex(hexIn);
  const hexParts = hex.match(/.{1,2}/g);

  if (!hexParts) {
    throw new Error(`${hexIn} is not a valid hex string`);
  }

  const rgb: RgbColor = {
    r: parseInt(hexParts[0], 16),
    g: parseInt(hexParts[1], 16),
    b: parseInt(hexParts[2], 16),
  };
  if (typeof hexParts[3] !== 'undefined') {
    rgb.a = parseInt(hexParts[3], 16);
  }
  return rgb;
};
