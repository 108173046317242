/**
 * A Vector is a 2D coordinate in space [x, y]
 * All management of positioning and repositioning runs through the Vector system
 */

import {clamp} from 'lodash';

export interface VectorLike {
  x: number;
  y: number;
}

export class Vector {
  private static readonly degree2rad = 0.017453292519943295;
  private static readonly rad2degree = 57.29577951308232;

  public x = 0;
  public y = 0;

  /**
   * Creates a new Vector with (x, y) coordinates
   */
  constructor();
  constructor(vector: Vector);
  constructor(vector: VectorLike);
  constructor(x: number, y: number);
  constructor(...args: [Vector | VectorLike | number | void, number | void]) {
    if (typeof args[0] === 'object') {
      this.x = args[0].x || 0;
      this.y = args[0].y || 0;
    } else if (typeof args[0] === 'number' && typeof args[1] === 'number') {
      this.x = args[0] || 0;
      this.y = args[1] || 0;
    }
  }

  /**
   * degree2rad can be used to convert degrees to radians
   * 180 * degree2rad === 3.14
   *
   * @deprecated since 04/06/2021, use static property instead
   */
  private get degree2rad(): number {
    return Vector.degree2rad;
  }

  /**
   * rad2degree can be used to convert radians to degrees
   * 180 * rad2degree === 3.14
   *
   * @deprecated since 04/06/2021, use static property instead
   */
  private get rad2degree(): number {
    return Vector.rad2degree;
  }

  /**
   * Creates a fresh instance of a vector.
   */
  public copy(): Vector {
    return new Vector(this.x, this.y);
  }

  /**
   * Adds another vector to this vector.
   *
   * @param {Vector|Number} vector Vector to add.
   * @return {Vector}
   */
  public add(number: number): Vector;
  public add(vector: Vector): Vector;
  public add(vector: Vector | number): Vector {
    if (vector instanceof Vector) {
      this.x += vector.x;
      this.y += vector.y;
    } else {
      this.x += vector;
      this.y += vector;
    }

    return this;
  }

  /**
   * Subtracts another vector to this vector.
   *
   * @param {Vector|Number} vector Vector to subtract.
   * @return {Vector}
   */
  public subtract(number: number): Vector;
  public subtract(vector: Vector): Vector;
  public subtract(vector: Vector | number): Vector {
    if (vector instanceof Vector) {
      this.x -= vector.x;
      this.y -= vector.y;
    } else {
      this.x -= vector;
      this.y -= vector;
    }

    return this;
  }

  /**
   * Multiplies vector by another vector.
   *
   * @param {Vector|Number} vector Vector to multiply.
   * @return {Vector}
   */
  public multiply(number: number): Vector;
  public multiply(vector: Vector): Vector;
  public multiply(vector: Vector | number): Vector {
    if (vector instanceof Vector) {
      this.x *= vector.x;
      this.y *= vector.y;
    } else {
      this.x *= vector;
      this.y *= vector;
    }

    return this;
  }

  /**
   * Divides vector by another vector.
   *
   * @param {Vector|Number} vector Vector to divide.
   * @return {Vector}
   */
  public divide(number: number): Vector;
  public divide(vector: Vector): Vector;
  public divide(vector: Vector | number): Vector {
    if (vector instanceof Vector) {
      this.x /= vector.x;
      this.y /= vector.y;
    } else {
      this.x /= vector;
      this.y /= vector;
    }

    return this;
  }

  /**
   * @name equal
   * @description Checks if the vector is equal to another.
   *
   * @param {Vector} vector Vector to compare with.
   */
  public equal(vector: Vector): boolean {
    return this.x === vector.x && this.y === vector.y;
  }

  /**
   * @name clamp
   * @description Clamps a vector with a box defined by two other vectors.
   *
   * @param {Vector} topLeftVector     Vector defining top left of the box.
   * @param {Vector} bottomRightVector Vector defining bottom right of the box.
   */
  clamp(topLeftVector: Vector, bottomRightVector: Vector): Vector {
    return new Vector(
      clamp(this.x, topLeftVector.x, bottomRightVector.x),
      clamp(this.y, topLeftVector.y, bottomRightVector.y)
    );
  }

  /**
   * Returns a normalized version of this vector
   * That is a vector with the same direction and angle, but a magnitude of 1
   *
   * @return {Vector} Returns a copy of this Vector scaled to a magnitude of 1
   */
  public normalize(): Vector {
    const mgn = this.magnitude();
    if (mgn === 0) {
      return new Vector(0, 0);
    }
    return new Vector(this.x / mgn, this.y / mgn);
  }

  /**
   * Inverts the vector
   *
   * @return {Vector} Returns an inverted copy of this Vector
   */
  public invert(): Vector {
    return new Vector(this.x * -1, this.y * -1);
  }

  /**
   * Returns the magnitude of this vector.
   * The magnitude is the distance between (0,0) and (x,y)
   * Or the diagonal size of a quadrilateral with width x and height y
   *
   * @return {float} The magnitude of this Vector
   */
  public magnitude(): number {
    return Math.sqrt(this.squareMagnitude());
  }

  /**
   * This returns the squared magnitude of the Vector.
   * This is significantly faster than magnitude()
   * and is perfect for when the exact magnitude isn't required.
   *
   * @return {float} The squared magnitude of this Vector
   */
  public squareMagnitude(): number {
    return this.x ** 2 + this.y ** 2;
  }

  /**
   * Scales a Vector by a factor
   *
   * @param {Number} scale how much to scale by, where 1 is no scaling at all
   *
   * @return {Vector} Returns a copy of this Vector, after scaling has been applied
   */
  public scale(scale: number): Vector {
    return new Vector(this.x * scale, this.y * scale);
  }

  /**
   * Scales a Vector by a factor but only in the x axis
   *
   * @param {Number} scale a ratio of how much to scale in the x-axis, where 1 is no scaling at all
   *
   * @return {Vector} Returns a copy of this Vector, after scaling has been applied
   */
  public scaleX(scale: number): Vector {
    return new Vector(this.x * scale, this.y);
  }

  /**
   * Scales a Vector by a factor but only in the y axis
   *
   * @param {Number} scale a ratio of how much to scale in the y-axis, where 1 is no scaling at all
   *
   * @return {Vector} Returns a copy of this Vector, after scaling has been applied
   */
  public scaleY(scale: number): Vector {
    return new Vector(this.x, this.y * scale);
  }

  /**
   * Translates a Vector by {x}, {y}
   *
   * @param {Number} x the distance to translate the Vector by in the x-axis
   * @param {Number} y the distance to translate the Vector by in the y-axis
   *
   * @return {Vector} Returns a copy of this Vector, after translation has been applied
   */
  public translate(x: number, y: number): Vector {
    return new Vector(this.x + x, this.y + y);
  }

  /**
   * Rotates a vector about origin (0,0) by {angle}
   * @param {Number} angle the angle in degrees to rotate the Vector by
   *
   * @return {Vector} Returns a copy of this Vector, after rotation has been applied
   */
  public rotate(angle: number): Vector {
    return this.rotateAround(0, 0, angle);
  }

  /**
   * Rotates a Vector about ({x}, {y}) by {angle}
   * @param {Number} x the rotation origin x coordinate
   * @param {Number} y the rotation origin y coordinate
   * @param {Number} angle the angle in degrees to rotate the Vector by
   *
   * @return {Vector} Returns a copy of this Vector, after rotation has been applied
   */
  public rotateAround(x: number, y: number, angle: number): Vector {
    const radians = (Math.PI / 180) * angle;
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);
    const nx = cos * (this.x - x) - sin * (this.y - y) + x;
    const ny = cos * (this.y - y) + sin * (this.x - x) + y;
    return new Vector(nx, ny);
  }

  /**
   * Calculates the distance between this Vector and another
   *
   * @param {Vector} vector the target vector
   *
   * @return {float} the distance between two Vectors
   */
  public distanceTo(vector: Vector): number {
    return Math.sqrt((this.x - vector.x) ** 2 + (this.y - vector.y) ** 2);
  }

  /**
   * Returns the squared distance between this Vector and another
   * This is significantly faster than checking the exact distance.
   * Perfect for when the exact distance isn't required
   * (such as when finding the closest Vector from an array)
   *
   * @param {Vector} vector the target vector
   *
   * @return {float} the squared distance between two Vectors
   */
  public squareDistanceTo(vector: Vector): number {
    return (this.x - vector.x) ** 2 + (this.y - vector.y) ** 2;
  }

  /**
   * Get the delta between two Vectors
   * That is, the distance in the x-axis and the distance in the y-axis
   *
   * @param {Vector} vector the target vector
   *
   * @return {Vector} a new Vector where the coordinates relate to the
   * x and y distance between two Vectors
   */
  public deltaTo(vector: Vector): Vector {
    return new Vector(this.x - vector.x, this.y - vector.y);
  }

  /**
   * Calculates the angle between this Vector and another
   *
   * @param {Vector} vector the target vector
   *
   * @return {float} the angle between this Vector and another, where 0 is directly upwards
   */
  public angleTo(vector: Vector): number {
    return Math.atan2(this.y - vector.y, this.x - vector.x);
  }

  /**
   * Returns an array of Vectors between this Vector and target Vector
   *
   * @param {Vector} target the target Vector
   * @param {Number} spacing the distance between each returned Vector
   *
   * @return {Vector[]} an array of Vectors between this Vector and target Vector
   */
  public vectorsBetween(target: Vector, spacing: number): Vector[] {
    const distance = this.distanceTo(target);
    const pointCount = distance / spacing;
    const points = [];

    for (let i = 0; i < pointCount; ++i) {
      points.push(
        new Vector(
          this.x + (target.x - this.x) / (pointCount * i),
          this.y + (target.y - this.y) / (pointCount * i)
        )
      );
    }

    return points;
  }

  /**
   * Creates a new Vector from an angle
   *
   * @param {Number} angle the angle in degrees
   *
   * @return {Vector} the new Vector from angle
   */
  public fromAngle(angle: number): Vector {
    return new Vector(
      Math.cos(angle * this.degree2rad),
      Math.sin(angle * this.degree2rad)
    );
  }

  /**
   * Creates a copy of this Vector, rounded to the nearest pixel
   *
   * @return {Vector} a copy of this Vector, with rounded (x, y) coordinates
   */
  public snapToPixel(): Vector {
    return new Vector(Math.round(this.x), Math.round(this.y));
  }

  public toObject(): VectorLike {
    return {
      x: this.x,
      y: this.y,
    };
  }

  public toString(): string {
    return JSON.stringify(this.toObject());
  }

  public static midPoint(...vectors: Array<Vector>): Vector {
    let sumX = 0;
    let sumY = 0;

    vectors.forEach(vector => {
      sumX += vector.x;
      sumY += vector.y;
    });

    return new Vector(sumX / vectors.length, sumY / vectors.length);
  }
}

export default Vector;
