<template>
  <div
    class="page-block-button w-full flex items-center"
    :class="alignmentClass"
  >
    <component
      :is="`tml-button`"
      :text="block.property('text', 'string', null, bindingData)"
      :href="block.property('href')"
      fill
      :style="{
        maxWidth: '300px',
      }"
      :primary="isPrimary"
      :small="block.property('size') === 'small'"
      :large="block.property('size') === 'large'"
      :border-radius="block.property('borderRadius', 'string')"
      :background-color="isPrimary ? block.property('primaryColor') : null"
      :border-color="isPrimary ? null : block.property('secondaryColor')"
      :text-color="isPrimary ? null : block.property('secondaryColor')"
    />
  </div>
</template>

<script>
import blockMixin from '../blockMixin';

export default {
  name: 'PageBlockButton',

  mixins: [blockMixin],

  computed: {
    isPrimary() {
      return this.block.property('primary', 'boolean');
    },

    alignmentClass() {
      switch (this.block.property('align', 'string', 'center')) {
        default:
        case 'left':
          return 'justify-start';

        case 'center':
          return 'justify-center';

        case 'right':
          return 'justify-end';
      }
    },
  },
};
</script>
