import {IconDefinition} from '@fortawesome/pro-light-svg-icons';
import {captureException, configureScope} from '@sentry/vue';
import {HasTheme, Theme} from '@teemill/modules/theme-builder';
import {cloneDeep} from 'lodash';
import {parseImageProperty} from '../utils/parseImageProperty';

import type {Block} from './block';
import type {BlockTemplate} from './blockTemplate';
import type {Page} from './page';
import type {PageObject} from './pageObject';

export type PropertyValue =
  | string
  | number
  | boolean
  | undefined
  | Array<any>
  | Record<string, unknown>
  | IconDefinition
  | ((...args: any) => PropertyValue)
  | ((...args: any) => Promise<PropertyValue>);

export type PropertyValueType = 'string' | 'number' | 'json' | 'boolean';

export interface PropertyLike {
  id?: number;
  value: PropertyValue;
}

export type PropertySet = Record<string, Property>;

export type PropertyWatcher = {
  (to: PropertyValue, from: PropertyValue): void;
  context: unknown;
};

export type PropertyPostProcessor<T = PropertyValue> = (value: T) => T;

export class Property implements PropertyLike, HasTheme {
  public id?: number;
  public value: PropertyValue;
  public parent?: PageObject<Page | Block | Theme>;

  private watchers: PropertyWatcher[] = [];
  private postProcessors: PropertyPostProcessor<any>[] = [];

  public constructor(data: PropertyLike) {
    if (data instanceof Property) {
      this.watchers = [...data.watchers];
      this.postProcessors = [...data.postProcessors];
    }

    this.id = data.id;
    this.value = data.value;
  }

  public get isRef(): boolean {
    if (typeof this.value !== 'string') {
      return false;
    }

    return !!this.value?.match(/#{([\w.]+)}/);
  }

  public get refName(): string | undefined {
    if (typeof this.value !== 'string') {
      return undefined;
    }

    const refMatch = (this.value as string).match(/#{([\w.]+)}/) || [];

    return refMatch[1];
  }

  public static from(value: Property): Property;
  public static from(value: PropertyValue): Property;
  public static from(value: Promise<PropertyValue>): Property;
  public static from(value: any): Property {
    if (value instanceof Property) {
      return value;
    }

    return new Property({
      value,
    });
  }

  public async resolve(): Promise<Property> {
    if (this.value instanceof Function) {
      this.value = await this.value();
    }

    return this;
  }

  public equals(comparator: unknown): boolean;
  public equals(comparator: unknown, type: PropertyValueType): boolean;
  public equals(comparator: unknown, type?: any): boolean {
    return comparator === this.get(type);
  }

  public doesntEqual(comparator: unknown): boolean;
  public doesntEqual(comparator: unknown, type: PropertyValueType): boolean;
  public doesntEqual(comparator: unknown, type?: any): boolean {
    return !this.equals(comparator, type);
  }

  public copy(context: unknown) {
    const property = Property.from(this.value);

    this.watchers.forEach(w => {
      w.context = context;
    });

    property.watchers = [...this.watchers];
    property.postProcessors = [...this.postProcessors];

    return property;
  }

  public static map<T extends PropertyLike = PropertyLike>(
    properties: Record<string, T>,
    transformer: (property: T) => any = p => new Property(p)
  ) {
    const mappedProperties: PropertySet = {};

    Object.keys(properties).forEach(key => {
      mappedProperties[key] = transformer(properties[key]);
    });

    return mappedProperties;
  }

  public static toValues(properties: PropertySet) {
    const mappedProperties: Record<string, unknown> = {};

    Object.keys(properties).forEach(key => {
      mappedProperties[key] = properties[key]?.value;
    });

    return mappedProperties;
  }

  public toObject(): PropertyLike {
    const {id, value} = this;

    return {
      id,
      value: cloneDeep(value),
    };
  }

  public set(value: Property): Property;
  public set(value: PropertyLike): Property;
  public set(value: PropertyValue): Property;
  public set(value: any): Property {
    const from = this.value;

    if (value === undefined || value === null) {
      this.value = undefined;

      this.callWatchers(from, this.value);

      return this;
    }

    if (typeof value === 'object' && value !== null) {
      this.id = (value as PropertyLike).id;
      this.value = (value as PropertyLike).value;

      this.callWatchers(from, this.value);

      return this;
    }

    this.value = value;

    this.callWatchers(this.value, from);

    return this;
  }

  protected castTo(
    value: PropertyValue,
    type: PropertyValueType
  ): PropertyValue {
    if (typeof value === 'string') {
      switch (type) {
        case 'json':
          return JSON.parse(value);
        case 'boolean':
          return Boolean(JSON.parse(value));
        case 'number':
          return Number(JSON.parse(value));

        default:
          return value;
      }
    }

    if (typeof value === 'object') {
      switch (type) {
        default:
        case 'json':
          return JSON.parse(JSON.stringify(value));

        case 'string':
          return JSON.stringify(value);
      }
    }

    if (type === 'string' && typeof value === 'number') {
      return value.toString();
    }

    return value;
  }

  public get(type?: PropertyValueType): PropertyValue {
    let value = this.value;

    if (value === '' || value === undefined || value === null) {
      value = undefined;
    }

    if (this.isRef && this.theme) {
      value = this.theme.get(this.refName as string);
    }

    if (type) {
      value = this.castTo(value, type);
    }

    return this.postProcessors.reduce((acc, process) => process(acc), value);
  }

  public static set(
    properties: PropertySet,
    name: string,
    value: PropertyValue
  ): Property {
    const property = properties[name];

    if (property) {
      return property.set(value);
    }

    properties[name] = new Property({
      value,
    });

    return properties[name];
  }

  public static get(
    properties: PropertySet,
    name: string,
    fallback?: PropertyValue,
    type?: PropertyValueType
  ): PropertyValue {
    const property = properties[name];

    if (!property) {
      return fallback;
    }

    const value = Property.from(property).get(type);
    // const value = new Property(properties[name]).get(type);

    if (value === undefined || value === null) {
      return fallback;
    }

    if (name === 'image' && typeof value === 'string') {
      try {
        return parseImageProperty(value);
      } catch (error) {
        configureScope(scope => {
          scope.setExtra('value', value);
        });
        captureException(error);

        return fallback;
      }
    }

    return value;
  }

  public watch(handler: PropertyWatcher): void {
    this.watchers.push(handler);
  }

  public postProcess<T extends PropertyValue>(
    callback: PropertyPostProcessor<T>
  ): Property {
    this.postProcessors.push(callback);

    return this;
  }

  private callWatchers(to: PropertyValue, from: PropertyValue): void {
    this.watchers.forEach(watcher => {
      watcher.apply(watcher.context || this, [to, from]);
    });
  }

  public get theme(): Theme | undefined {
    return this.parent?.theme;
  }

  public assignTo(block: PageObject<any>): Property;
  public assignTo(block: BlockTemplate): Property;
  public assignTo(block: Theme): Property;
  public assignTo(object: any): Property {
    this.parent = object;

    return this;
  }
}
