import {base} from './base';

export const mocha = base
  .extend('mocha')
  .deprecate()

  // Colors
  .property('primary.color', '#C19E8E')
  .property('primary.font', 'Caveat Brush')

  // Page
  .property('page.background.color', '#ffffff')

  // Text
  .property('text.color', '#645954')
  .property('text.font', 'Comfortaa')
  .property('text.shadow', 'none')

  // Menu
  .property('menu.background.color', '#E4D2D2')
  .property('menu.text.color', '#645954')
  .property('menu.text.font', 'Comfortaa')

  // Image
  .property('image.border.radius', '12px')
  .property(
    'image.default',
    'https://images.teemill.com/gmbuuudoqeyo2drkezyiwaemmmph4cks5e0odfbea7hphrls.jpg'
  )

  // Button
  .property('button.border.radius', '12px')

  // Feature
  .property('feature.text.color', '#645954')

  // Quote
  .property('quote.text.color', '#645954')
  .property('quote.background.color', '#ECE3DA')
  .property('quote.fullBackground.color', '#ECE3DA')

  // AnnouncementV2
  .property('announcement.v2.text.color', '#645954')
  .property('announcement.v2.background.color', '#ECE3DA')
  .property('announcement.v2.fullBackground.color', '#0000')

  // Newsletter
  .property('newsletter.text.color', '#645954')
  .property('newsletter.background.color', '#ECE3DA')
  .property('newsletter.fullBackground.color', '#ECE3DA')

  //Footer
  .property('footer.text.color', '#645954');
