<template>
  <div>
    <slot />
    <div v-if="icon" class="icon-overlay" :class="alignmentClass">
      <tml-bubble
        class="icon-inner"
        element="div"
        expand-hit-target
        disable-border
        :icon="icon"
        @click.stop.prevent="$emit('click')"
      />
    </div>
  </div>
</template>

<script>
import TmlBubble from '../misc/TmlBubble.vue';

export default {
  name: 'TmlIconOverlay',

  components: {
    TmlBubble,
  },

  props: {
    icon: Object,

    verticalAlignment: {
      type: String,
      default: 'bottom',
    },

    horizontalAlignment: {
      type: String,
      default: 'right',
    },
  },

  computed: {
    alignmentClass() {
      return `align-${this.verticalAlignment} align-${this.horizontalAlignment}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-overlay {
  position: absolute !important;

  .icon-inner {
    height: 2.25em !important;
    min-width: 2.25em !important;
    z-index: 1;
    cursor: pointer !important;
  }

  &.align-bottom {
    bottom: 10px;
  }

  &.align-top {
    top: 10px;
  }

  &.align-left {
    left: 10px;
  }

  &.align-right {
    right: 10px;
  }
}
</style>
