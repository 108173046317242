import {reactive} from 'vue';
import {merge} from 'lodash';
import {useBreakpoints} from '@vueuse/core';
import {pageBreakpoints} from './pageBreakpoints';

/**
 * @name viewportSize
 * @description a breakpoint provider with helper functions.
 *
 * @todo: Return value of `useBreakpoints` should be reactive by default.
 *
 *        Using `reactive()` wrapper and `updateViewportSize` is a temporary solution
 *        to this value not being reactive in options API.
 *        They need to be removed when issue is resolved.
 */
export const viewportSize = reactive(useBreakpoints(pageBreakpoints));

export const updateViewportSize = () =>
  merge(viewportSize, useBreakpoints(pageBreakpoints));
