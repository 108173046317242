import {base} from './base';

export const bubble = base
  .extend('bubble')
  // Colors
  .property('primary.color', '#f2abcd')
  .property('primary.font', 'Shrikhand')

  // Page
  .property('page.background.color', '#f7f2f2')

  // Text
  .property('text.font', 'Acumin Pro')
  .property('text.color', '#DD393C')

  // Menu
  .property('menu.background.color', '#f2abcd')
  .property('menu.text.font', 'Acumin Pro')
  .property('menu.text.color', '#f7f2f2')

  // Image
  .property(
    'image.default',
    'https://images.teemill.com/k9mm1gk4svghib2i5z8sxci0lqpd7w6f0dvfewptf9wtcdf4.jpeg'
  )

  // Quote
  .property('quote.text.color', '#f3e1df')
  .property('quote.fullBackground.color', '#DD393C')

  // AnnouncementV2
  .property('announcement.v2.text.color', '#f3e1df')
  .property('announcement.v2.background.color', '#DD393C')

  // Newsletter
  .property('newsletter.text.color', '#DD393C')
  .property('newsletter.fullBackground.color', '#f2abcd')

  // Footer
  .property('footer.text.color', '#DD393C')
  .property('footer.background.color', '#f3e1df');
