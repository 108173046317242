import {HexColor} from './colorTypes';
import {decimalToHex} from './decimalToHex';
import {hexToDecimal} from './hexToDecimal';
import {stripHash} from './stripHash';

/**
 * @name mix
 * @description Mixes two hex colours together, in the same way as the sass function
 */
export const mix = (colourA: HexColor, colourB: HexColor, weight = 50) => {
  colourA = stripHash(colourA);
  colourB = stripHash(colourB);

  let colour = '#';

  // loop through each of the 3 hex pairs—red, green, and blue
  for (let i = 0; i < 5; i += 2) {
    const valueA = hexToDecimal(colourA.substring(i, i + 2));
    const valueB = hexToDecimal(colourB.substring(i, i + 2));

    // combine the current pairs from each source colour, according to the specified weight
    let val = decimalToHex(
      Math.round(valueB + (valueA - valueB) * (weight / 100.0))
    );

    // prepend a '0' if val results in a single digit
    if (val.length === 1) {
      val = `0${val}`;
    }

    colour += val;
  }

  return colour;
};
