import {BaseClass} from '../../classes/baseClass';

import Customer from '../customer/customer';
import Division from '../division/division';
import {Manufacturer} from '../manufacturer/manufacturer';
import OrderItem from './orderItem';
import OrderStatus from './orderStatus';
import OrderPaymentAttempt from './orderPaymentAttempt';
import OrderStatusLog from './orderStatusLog';

import {dayjs} from '../../services/dayjs';

/**
 * Order.js
 *
 * Defines the standard structure for a Order object.
 *
 * @author Aidan Brookes <aidan@teemill.com>
 */
export default class Order extends BaseClass {
  static modelName = 'order';

  static statusFlow = {
    normal: [
      ['new', 'to_be_paid'],
      ['paid'],
      ['processing', 'label_printed', 'printed'],
      ['consolidated', 'packing'],
      ['disputed', 'issue'],
      ['complete', 'exchanged', 'refunded'],
    ],
    bespoke: [
      ['quote', 'bespoke_idle'],
      ['bespoke_requested_terms', 'bespoke_authorised_terms'],
      ['bespoke_mockup', 'bespoke_approved'],
      ['bespoke_paid', 'po_confirmed'],
      ['bespoke_production', 'bespoke_documents_printed'],
      ['bespoke_sorted'],
      ['bespoke_printing'],
      ['bespoke_packing'],
      ['bespoke_shipped', 'bespoke_refunded'],
    ],
    paper: [
      ['new', 'paid'],
      ['processing'],
      ['printed'],
      ['packing'],
      ['disputed', 'issue'],
      ['complete', 'exchanged', 'refunded'],
    ],
  };

  constructor({
    id,
    items,
    total,
    status,
    coupon,
    discount,
    subtotal,
    customer,
    goodsTax,
    deletedAt,
    xPriority,
    friskUser,
    pigeonhole,
    billingZip,
    shopReturns,
    billingCity,
    childOrders,
    parentOrder,
    shippingZip,
    shippingTax,
    taxDiscount,
    refundOrders,
    billingEmail,
    billingState,
    manufacturer,
    paidDivision,
    shippingCity,
    paymentMethod,
    orderDatetime,
    shippingQuote,
    shippingState,
    trackingCodes,
    childOrderType,
    exchangeOrders,
    billingCountry,
    shippingMethod,
    billingCompany,
    shippingCompany,
    paymentAttempts,
    createdDivision,
    shippingCountry,
    integrationType,
    billingLastName,
    xReprintReasonId,
    paymentProcessed,
    xDirectFromIndia,
    statusLogRecords,
    billingFirstName,
    shippingLastName,
    billingStreetAddr,
    shippingFirstName,
    fulfillmentFactory,
    shippingStreetAddr,
    shippingEoriNumber,
    paymentTransaction,
    fulfillmentFactoryId,
    factoryFulfillmentOrders,
    styles,
  } = {}) {
    super();

    this.id = id;
    this.coupon = coupon;
    this.priority = xPriority;
    this.pigeonhole = pigeonhole;
    this.friskCheck = !!friskUser;
    this.shopReturns = shopReturns;
    this.billingEmail = billingEmail;
    this.trackingCodes = trackingCodes;
    this.paymentMethod = paymentMethod;
    this.childOrderType = childOrderType;
    this.billingCompany = billingCompany;
    this.shippingMethod = shippingMethod;
    this.shippingCompany = shippingCompany;
    this.integrationType = integrationType;
    this.reprintReasonId = xReprintReasonId;
    this.directFromIndia = xDirectFromIndia;
    this.billingFirstName = billingFirstName;
    this.billingLastName = billingLastName;
    this.shippingFirstName = shippingFirstName;
    this.shippingLastName = shippingLastName;
    this.items = OrderItem.map(items);
    this.exchangeOrders = Order.map(exchangeOrders);
    this.refundOrders = Order.map(refundOrders);
    this.childOrders = Order.map(childOrders);
    this.parentOrder = Order.map(parentOrder);
    this.customer = Customer.map(customer);
    this.status = OrderStatus.map(status);
    this.manufacturer = Manufacturer.map(manufacturer);
    this.paidDivision = Division.map(paidDivision);
    this.createdDivision = Division.map(createdDivision);
    this.deletedAt = deletedAt ? dayjs(deletedAt) : null;
    this.statusLogRecords = OrderStatusLog.map(statusLogRecords);
    this.createdAt = orderDatetime
      ? dayjs(orderDatetime).format('DD/MM/YY hh:mm a')
      : null;
    this.paidAt = paymentProcessed
      ? dayjs(paymentProcessed).format('DD/MM/YY hh:mm a')
      : null;
    this.trackingCode =
      trackingCodes && trackingCodes.length ? trackingCodes[0].code : {};

    this.fulfillmentFactory = fulfillmentFactory;
    this.fulfillmentFactoryId = fulfillmentFactoryId;
    this.factoryFulfillmentOrders = Order.map(factoryFulfillmentOrders);

    this.total = parseFloat(total);
    this.subtotal = parseFloat(subtotal);
    this.goodsTax = parseFloat(goodsTax);
    this.discount = parseFloat(discount);
    this.shippingTax = parseFloat(shippingTax);
    this.taxDiscount = parseFloat(taxDiscount);
    this.shipping = parseFloat(shippingQuote);

    this.billingStreetAddress = billingStreetAddr;
    this.billingCity = billingCity;
    this.billingState = billingState;
    this.billingCountry = billingCountry;
    this.billingZip = billingZip;

    this.shippingStreetAddress = shippingStreetAddr;
    this.shippingCity = shippingCity;
    this.shippingState = shippingState;
    this.shippingCountry = shippingCountry;
    this.shippingZip = shippingZip;
    this.shippingEoriNumber = shippingEoriNumber;

    this.paymentAttempts = OrderPaymentAttempt.map(paymentAttempts);
    this.paymentTransaction = paymentTransaction;

    this.styles = styles;
  }

  get billingAddress() {
    return {
      name: `${this.billingFirstName} ${this.billingLastName}`,
      company: this.billingCompany,
      streetAddress: this.billingStreetAddress,
      city: this.billingCity,
      state: this.billingState,
      country: this.billingCountry,
      zip: this.billingZip,
    };
  }

  set billingAddress(address) {
    this.billingCompany = address.company;
    this.billingStreetAddress = address.streetAddress;
    this.billingCity = address.city;
    this.billingState = address.state;
    this.billingCountry = address.country;
    this.billingZip = address.zip;
  }

  get shippingAddress() {
    return {
      name: `${this.shippingFirstName} ${this.shippingLastName}`,
      company: this.shippingCompany,
      streetAddress: this.shippingStreetAddress,
      city: this.shippingCity,
      state: this.shippingState,
      country: this.shippingCountry,
      zip: this.shippingZip,
      eoriNumber: this.shippingEoriNumber,
    };
  }

  set shippingAddress(address) {
    this.shippingCompany = address.company;
    this.shippingStreetAddress = address.streetAddress;
    this.shippingCity = address.city;
    this.shippingState = address.state;
    this.shippingCountry = address.country;
    this.shippingZip = address.zip;
    this.shippingEoriNumber = address.eoriNumber;
  }

  setStatus(status) {
    this.status = OrderStatus.map(status);
  }

  get subtotalIncTax() {
    return this.subtotal + this.goodsTax;
  }

  get shippingIncTax() {
    return this.shipping + this.shippingTax;
  }

  get discountIncTax() {
    return this.discount + this.taxDiscount;
  }

  isBespoke() {
    return this.hasBeenIn('quote');
  }

  isDistro() {
    return this.fulfillmentFactory && this.fulfillmentFactory.code === 'distro';
  }

  isShipped() {
    return this.hasBeenIn('complete') || this.hasBeenIn('bespoke_shipped');
  }

  get products() {
    return this.items.map(item => item.product);
  }

  get hasTransactions() {
    const successfulAttempts = this.paymentAttempts.filter(
      attempt => attempt && attempt.is_successful
    );

    if (successfulAttempts && successfulAttempts.length) {
      return true;
    }

    return !!this.paymentTransaction;
  }

  get exchanges() {
    const exchanges = this.childOrders.filter(
      order => !order.reprintReasonId && !order.hasBeenIn('refunded')
    );

    return exchanges;
  }

  get hasExchanges() {
    return this.exchanges && this.exchanges.length;
  }

  get percentageComplete() {
    let type = this.hasBeenIn('quote') ? 'bespoke' : 'normal';

    if (this.fulfillmentFactory && this.fulfillmentFactory.code === 'newport') {
      type = 'paper';
    }

    const statusFlow = Order.statusFlow;

    const step =
      statusFlow[type].findIndex(statusCodes =>
        statusCodes.includes(this.status.code)
      ) + 1;

    const stepCount = statusFlow[type].length - 1;

    return (step / stepCount) * 100;
  }

  hasBeenIn(statusCode) {
    return this.statusLogRecords && this.statusLogRecords.length
      ? !!this.statusLogRecords.find(
          record => record.status.code === statusCode
        )
      : false;
  }

  isIn(statusCode) {
    return this.status.code === statusCode;
  }

  get refundedShippingOrders() {
    return this.refundOrders.filter(
      order =>
        order.items.filter(item => item.product.sku === 'EMB-39').length > 0
    );
  }

  get isExchange() {
    return this.childOrderType && this.childOrderType.code === 'exchange';
  }

  get division() {
    if (this.paidDivision) {
      return this.paidDivision;
    }

    return this.createdDivision;
  }

  get hasBespokeArtwork() {
    if (this.styles?.length) {
      return !!this.screenprintFiles.concat(this.aiFiles).concat(this.mockups)
        .length;
    }

    return false;
  }

  get mockups() {
    return this.applications.flatMap(application => application.mockups);
  }

  get aiFiles() {
    return this.applications.flatMap(application => application.aiFiles);
  }

  get screenprintFiles() {
    return this.applications.flatMap(
      application => application.screenprintFiles
    );
  }

  get applications() {
    return this.styles.flatMap(style => style.applications);
  }

  hasBeenResent() {
    return !!this.exchangeOrders.filter(
      exchange =>
        !!exchange.items.filter(
          item =>
            !!item.parentItem &&
            item.parentItem.returnLogs.filter(
              log => log.returnReason.code === 'failed_to_arrive'
            ).length
        ).length
    ).length;
  }

  isIntegration(integrationCode) {
    return this.integrationType.code === integrationCode;
  }
}
