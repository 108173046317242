import {LayoutSections} from '@teemill/modules/app';
import {maxDevice} from '@teemill/common/helpers';
import {computedEager, useWindowScroll} from '@vueuse/core';

const {y: scrollY} = useWindowScroll();

export const searchConfig: LayoutSections = {
  announcement: {show: true},
  header: {
    show: true,
    fixed: true,
    overlay: false,
    width: 'container',
    height: computedEager(() => {
      if (maxDevice('md')) {
        return '3.5rem';
      }

      if (scrollY.value === 0) {
        return '5rem';
      }

      return '4rem';
    }),
    logo: true,
    logoMaxWidth: '220px',
    centerLogo: () => maxDevice('md'),
    content: true,
  },
  sidebar: {
    show: () => maxDevice('md'),
    fixed: false,
    full: true,
    width: '220px',
  },
  content: {show: true, width: '1800px'},
  footer: {show: true},
  subFooter: {show: true},
  navBar: false,
};
