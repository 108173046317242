import {formatHex} from '@teemill/utilities';
import {difference, mapValues} from 'lodash';
import {v4 as uuidV4} from 'uuid';
import {HasTheme} from '../types/hasTheme';

/**
 * TODO: refactor theme builder to resolve circular dependencies among
 * theme, page-framework and page-builder modules.
 * Might require separating theme core from theme builder.
 */
import {
  Property,
  PropertySet,
  PropertyValue,
} from '../../page-framework/classes/property';

export interface ThemeLike {
  id?: number;
  uuid: string;
  name: string;
  properties: PropertySet;
  extends?: string;
}

export class Theme implements HasTheme {
  public static themes: Theme[] = [];

  public id?: number;
  public uuid: string;
  public name: string;

  public extends?: Theme;

  protected properties: PropertySet;

  protected deprecated = false;

  public constructor({
    id,
    uuid = uuidV4(),
    name,
    extends: extend,
    properties = {},
  }: {
    id?: number;
    uuid?: string;
    name: string;
    extends?: Theme | string;
    properties?: PropertySet;
  }) {
    this.id = id;
    this.uuid = uuid;
    this.name = name;
    this.properties = Property.map(properties);

    if (extend instanceof Theme) {
      this.extends = extend;
    } else {
      this.extends = Theme.themes.find(t => t.name === (extend || 'base'));
    }

    // ? Ensure theme has extended all missing properties
    difference(
      Object.keys(this.extends?.properties || {}),
      Object.keys(this.properties)
    ).forEach(name => {
      const value = this.extends?.properties[name].copy(this);

      if (value === undefined) {
        this.property(name, undefined);
      } else {
        this.property(name, value);
      }
    });

    Object.values(this.properties).forEach(p => p.assignTo(this));
  }

  get theme() {
    return this;
  }

  get legacy() {
    return {
      colours: {
        highlight: formatHex(this.get('primary.color') as string, true),
        background: formatHex(
          this.get('page.background.color') as string,
          true
        ),
        border: '#00000033',
        image: '#ffffff',
        text: formatHex(this.get('text.color') as string, true),
        box: formatHex(this.get('page.background.color') as string, true),

        header: {
          text: formatHex(this.get('menu.text.color') as string, true),
          background: formatHex(
            this.get('menu.background.color') as string,
            true
          ),
        },

        footer: {
          text: formatHex(this.get('text.color') as string, true),
          background: formatHex(
            this.get('page.background.color') as string,
            true
          ),
        },

        menu: {
          text: formatHex(this.get('menu.text.color') as string, true),
          background: formatHex(
            this.get('menu.background.color') as string,
            true
          ),
        },

        button: {
          primary: formatHex(this.get('button.primary.color') as string, true),
          secondary: formatHex(
            this.get('button.secondary.color') as string,
            true
          ),
        },

        icon: {
          primary: formatHex(this.get('primary.color') as string, true),
          secondary: '#c9c9c9',
        },
      },
      fonts: {
        base: {
          name: this.get('text.font'),
          size: 16,
        },
        menu: {
          name: this.get('menu.text.font'),
          size: 17,
          capitalised: this.get('menu.text.transform') === 'uppercase',
        },
      },
    };
  }

  public static create(name: string): Theme {
    const theme = new Theme({
      name,
    });

    Theme.themes.push(theme);

    return theme;
  }

  public extend(name: string): Theme {
    const theme = new Theme({
      name,
      extends: this,
    });

    theme.properties = Property.map(this.properties, p =>
      p.copy(theme).assignTo(theme)
    );

    Theme.themes.push(theme);

    return theme;
  }

  public static ref(name: string): Property {
    return Property.from(`#{${name}}`);
  }

  public property(name: string, value: Property): Theme;
  public property(name: string, value: PropertyValue): Theme;
  public property(name: string, value: any): Theme {
    const property = Property.from(value).assignTo(this);

    if (this.properties[name] === undefined) {
      this.properties[name] = property;
    } else {
      this.properties[name].value = property.value;
    }

    return this;
  }

  public all(): Record<string, PropertyValue> {
    return mapValues(this.properties, (_, name) => this.get(name));
  }

  public get(name: string): PropertyValue {
    let value = Property.get(this.properties, name);

    if (value === undefined && this !== this.extends) {
      value = this.extends?.get(name);
    }

    return value;
  }

  public toObject(): ThemeLike {
    const {id, uuid, name, properties, extends: themeExtends} = this;

    return {
      id,
      uuid,
      name,
      properties: Property.map<Property>(properties, p => p.toObject()),
      extends: themeExtends?.name,
    };
  }

  public updateFrom(theme: Theme) {
    const id = this.id;

    Object.assign(this, theme);

    this.id = id;
  }

  public deprecate(): Theme {
    this.deprecated = true;
    return this;
  }
}
