/**
 * @name truncateToEllipsis
 * @description Truncate string and add an ellipsis.
 */
export const truncateToEllipsis = (
  string: string,
  maxLength: number
): string => {
  const truncatedString = string.substring(
    0,
    Math.min(maxLength, string.length)
  );

  if (truncatedString.length !== string.length) {
    return `${truncatedString}...`;
  }

  return string;
};
