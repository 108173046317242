import {BlockBuilder} from './blockBuilder';
import {
  BlockFactorySource,
  BlockSourceData,
  SourceParams,
} from './blockFactorySource';
import {TapFunction} from '@teemill/utilities';

export type Transformer = (
  builder: BlockBuilder,
  index: number
) => BlockBuilder;

export class BlockFactory {
  public name: string;
  public builder: (data: BlockSourceData) => BlockBuilder[];
  public transformers: Transformer[] = [];

  public source?: BlockFactorySource<any, any> | BlockSourceData;

  public constructor({
    name,
    builder,
  }: {
    name: string;
    builder: (data: BlockSourceData) => BlockBuilder[];
  }) {
    this.name = name;
    this.builder = builder;
  }

  public transform(fn: Transformer): BlockFactory {
    this.transformers.push(fn);

    return this;
  }

  public async run<T extends SourceParams>(
    params: T,
    source?: BlockFactorySource<any, T> | BlockSourceData
  ): Promise<BlockBuilder[]> {
    source = source || this.source;

    if (source === undefined) {
      throw new Error('Factory has no source');
    }

    const data =
      source instanceof BlockFactorySource ? await source.get(params) : source;

    return this.builder(data).map((builder, i) =>
      this.transformers.reduce(
        (acc, transformer) => transformer(acc, i),
        builder
      )
    );
  }

  public using(source: BlockSourceData): BlockFactory;
  public using(source: BlockFactorySource<any, any>): BlockFactory;
  public using(
    source: BlockFactorySource<any, any> | BlockSourceData
  ): BlockFactory {
    const factory = new BlockFactory({
      name: this.name,
      builder: this.builder,
    });

    factory.source = source;

    return factory;
  }

  public when(
    condition: boolean | (() => boolean),
    tap: TapFunction<BlockFactory>
  ): BlockFactory {
    let passed = condition;

    if (typeof condition === 'function') {
      passed = Boolean(condition());
    }

    if (passed) {
      return this.tap(tap);
    }

    return this;
  }

  public tap(tap: TapFunction<BlockFactory>) {
    return tap(this);
  }
}
