<template>
  <tml-banner
    v-bind="{
      ...$props,
      padding: true,
      fontScale: 0.55,
      ...$attrs,
    }"
  >
    <template #content>
      <slot name="content" />
    </template>
  </tml-banner>
</template>

<script>
import {default as bannerProps} from './banner-props';

export default {
  name: 'TmlCompactBanner',

  compatConfig: {
    INSTANCE_LISTENERS: false,
  },

  mixins: [bannerProps],
};
</script>
