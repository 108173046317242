import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const priceBreakdown = new BlockType({
  name: 'priceBreakdown',
  title: 'price breakdown',
  compatibility: ['page'],
  component: () =>
    import(
      /* webpackChunkName: "ProfitabilityBlocks" */ './PriceBreakdownBlock.vue'
    ),
  builder: () =>
    BlockBuilder.create('priceBreakdown').property('chartType', 'storefront'),
});

export const profitPerMonth = new BlockType({
  name: 'profitPerMonth',
  title: 'profit per month',
  compatibility: ['page'],
  component: () =>
    import(
      /* webpackChunkName: "ProfitabilityBlocks" */ './ProfitPerMonthBlock.vue'
    ),
  builder: () => BlockBuilder.create('profitPerMonth'),
});
