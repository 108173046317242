<script setup lang="ts">
import {withDefaults, computed} from 'vue';

const props = withDefaults(
  defineProps<{
    direction?: string;
    colors?: string | string[];
    opacity?: number;
  }>(),
  {
    direction: 'to right',
    colors: '',
    opacity: 0.25,
  }
);

const backgroundImage = computed(() => {
  let colors = props.colors;

  if (Array.isArray(colors)) {
    colors = colors.join(', ');
  }

  return `linear-gradient(${props.direction}, ${colors})`;
});

const backgroundColor = computed(() => {
  if (!Array.isArray(props.colors)) {
    return undefined;
  }

  return props.colors[0];
});
</script>

<template>
  <div
    class="relative"
  >
    <slot />
    <div
      :style="{
        backgroundImage,
        backgroundColor,
        opacity,
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        pointerEvents: 'none',
      }"
    />
  </div>
</template>
