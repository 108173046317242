import {base} from './base';

export const quiet = base
  .extend('quiet')
  // Colors
  .property('primary.color', '#6B717D')
  .property('primary.font', 'Libre Baskerville')

  // Page
  .property('page.background.color', '#fafafa')

  // Text
  .property('text.font', 'Palanquin')
  .property('text.color', '#6B717D')

  // Menu
  .property('menu.background.color', '#fafafa')
  .property('menu.text.font', 'Libre Baskerville')
  .property('menu.text.color', '#6b717d')
  .property('menu.text.transform', 'none')

  // Image
  .property(
    'image.default',
    'https://images.teemill.com/crhjvzsiuyxfsg6t9lrzbczoxhgypvwtsxdqzbrx4arsmzja.jpeg'
  )

  // Quote
  .property('quote.text.color', '#FAFAFA')
  .property('quote.fullBackground.color', '#6B717D')

  // AnnouncementV2
  .property('announcement.v2.background.color', '#d1dcf0')

  // Newsletter
  .property('newsletter.text.color', '#{text.color}')
  .property('newsletter.fullBackground.color', '#D1DCF0')

  // Footer
  .property('footer.text.color', '#6B717D')
  .property('footer.background.color', '#fafafa');
