import {bus} from '../bus';

export default (images, start) =>
  new Promise(() => {
    bus.emit('add-dynamic-overlay', {
      props: {
        images,
        start,
      },
      component: 'tml-image-preview-popup',
    });
  });
