import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const mailContactForm = new BlockType({
  name: 'mailContactForm',
  title: 'Mail Contact Form',
  compatibility: ['page'],
  component: () =>
    import(/* webpackChunkName: "MailContactForm" */ './MailContactForm.vue'),
  builder: () =>
    BlockBuilder.create('mailContactForm')
      .size(6)
      .property('title', 'Contact us')
      .property('copy', 'Send us an email and tell us what you think.')
      .property('font', 'Lato')
      .property('backgroundColor', '#fff')
      .property('contactEmail', undefined),
});
