import {stripHtmlWhitespace} from '@teemill/utilities';
import {BlockBuilder} from '../classes/blockBuilder';
import {BlockFactory} from '../classes/blockFactory';
import {BlockSourceData} from '../classes/blockFactorySource';

export const mailFeature = new BlockFactory({
  name: 'feature',
  builder: (data: BlockSourceData) => {
    return data.map(({title, images = ['/'], copy, hrefText, href}) => {
      return BlockBuilder.create('row')
        .property('direction', 'column')
        .property('title', title)
        .property('padding', 3)
        .item(
          BlockBuilder.create('pod')
            .property('template', 'pod')
            .property('image', images[0])
            .property('ratio', 'square')
            .property('padding', 2)
            .property('href', href)
        )
        .item(
          BlockBuilder.create('textBlock').property(
            'html',
            stripHtmlWhitespace(copy || '')
          )
        )
        .item(
          href !== undefined,
          BlockBuilder.create('button')
            .property('text', hrefText)
            .property('href', href)
            .property('align', 'left')
        );
    });
  },
});
