<template>
  <div>
    <component
      :is="bannerComponent"
      class="tml-banner relative"
      :ratio="ratio"
      :lazy-load="lazyLoad"
      :min-height="minHeight"
      :disabled="disabled"
      :border-radius="borderRadius"
      :video="video"
      :is-background="true"
      :show-controls="false"
      :autoplay="true"
      :loop="true"
      :src="image"
      :video-thumbnail="videoThumbnail"
      :alt="altText || title || copy || 'Banner'"
      :src-set="srcSet"
      :style="bannerStyle"
      :object-position="imageAlignment"
      @loaded="$emit('loaded', $event)"
      v-on="$listeners.click ? {click: $event => $emit('click', $event)} : {}"
    >
      <tml-overlay
        class="!absolute top-0 left-0 bottom-0 right-0"
        :colors="overlayColors"
        :opacity="overlayOpacity"
        :direction="overlayDirection"
      />
      <div class="content h-full">
        <div
          class="body h-full flex"
          :style="{
            justifyContent: horizontalAlignmentValue,
            alignItems: verticalAlignmentValue,
            fontSize: `calc(1rem * ${fontScale})`,
            padding: padding ? 'max(2rem, 7%) 7%' : 'max(2rem, 4%) 4%',
          }"
        >
          <div
            class="inline-block"
            :style="{
              textAlign,
              width: contentWidth,
            }"
          >
            <tml-font :font="font">
              <slot
                name="content"
                :title-background="titleBackground"
                :title-color="titleColor"
                :text-shadow="textShadow"
                :text-align="textAlign"
              >
                <component
                  :is="titleType"
                  v-if="title"
                  class="title inline-block py-1"
                  :class="{'px-6': hasTextBackground}"
                  :style="{
                    backgroundColor: titleBackground,
                    wordBreak: 'break-word',
                    letterSpacing: '-0.4px',
                    color: titleColor,
                    textShadow,
                    textAlign,
                  }"
                >
                  {{ truncateToEllipsis(title, 50) }}
                </component>
              </slot>
            </tml-font>
            <slot
              v-if="copy"
              name="copy"
              :copy-background="copyBackground"
              :copy-color="copyColor"
              :text-shadow="textShadow"
              :text-align="textAlign"
            >
              <h4
                class="copy inline-block py-1"
                :class="{
                  'px-6': hasTextBackground,
                  '!mb-0': !filteredActions.length,
                }"
                :style="{
                  backgroundColor: copyBackground,
                  overflowWrap: 'break-word',
                  letterSpacing: '-0.4px',
                  color: copyColor,
                  textShadow,
                  textAlign,
                }"
              >
                {{ truncateToEllipsis(copy, 300) }}
              </h4>
            </slot>
            <div
              v-if="filteredActions.length"
              :class="{
                'px-6': hasTextBackground, // to align button with text
              }"
              class="row"
              :style="{
                justifyContent: horizontalAlignmentValue,
              }"
            >
              <div
                v-for="(action, key) in filteredActions"
                :key="key"
                class="mb-2"
                :class="[
                  filteredActions.length === 1 ? 'col-sm-12' : 'col-sm-6',
                ]"
                :style="{
                  maxWidth: '250px',
                  fontSize: '1rem',
                }"
              >
                <tml-button
                  :text="action.text"
                  :href="action.href"
                  :target="action.target"
                  :icon="action.icon"
                  :primary="!!action.primary"
                  :small="buttonSize === 'small'"
                  :large="buttonSize === 'large'"
                  :border-radius="buttonBorderRadius"
                  :background-color="
                    !!action.primary ? buttonBackgroundColor : null
                  "
                  fill
                  white
                  @click="handleButtonActionClick($event, action)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </component>
  </div>
</template>

<script>
import {truncateToEllipsis, isTransparent} from '@teemill/utilities';
import TmlOverlay from '../effects/TmlOverlay.vue';
import {default as TmlFont} from '../effects/TmlFont.vue';
import {default as TmlButton} from '../buttons/TmlButton.vue';
import {default as bannerProps} from './banner-props';
import {default as TmlVideo} from '../video/TmlVideo.vue';

export default {
  name: 'TmlBanner',

  components: {
    TmlOverlay,
    TmlFont,
    TmlButton,
    TmlVideo,
  },

  mixins: [bannerProps],

  props: {
    fontScale: {
      type: Number,
      default: 1,
      validator: value => value <= 1 && value > 0,
    },

    padding: {
      type: Boolean,
      default: true,
    },

    altText: String,
  },

  computed: {
    bannerComponent() {
      switch (this.media) {
        case 'video':
          return 'tml-video';

        case 'overlay':
          return 'tml-ratio';

        case 'image':
        default:
          return 'tml-image';
      }
    },

    bannerStyle() {
      if (this.media === 'overlay') {
        return {
          borderRadius: this.borderRadius,
          overflow: 'hidden',
        };
      }

      return null;
    },

    filteredActions() {
      if (this.actions) {
        return this.actions.filter(
          action => action && action.text && (action.href || action.action)
        );
      }

      return [];
    },

    verticalAlignmentValue() {
      switch (this.verticalAlignment) {
        default:
        case 'top':
          return 'flex-start';

        case 'center':
          return 'center';

        case 'bottom':
          return 'flex-end';
      }
    },

    horizontalAlignmentValue() {
      switch (this.horizontalAlignment) {
        default:
        case 'left':
          return 'flex-start';

        case 'center':
          return 'center';

        case 'right':
          return 'flex-end';
      }
    },

    textAlign() {
      switch (this.horizontalAlignment) {
        default:
        case 'left':
          return 'left';

        case 'center':
          return 'center';

        case 'right':
          return 'right';
      }
    },

    hasTextBackground() {
      if (!this.title && !this.copy) {
        return false;
      }

      return (
        (this.titleBackground && !isTransparent(this.titleBackground)) ||
        (this.copyBackground && !isTransparent(this.copyBackground))
      );
    },
  },

  methods: {
    handleButtonActionClick(event, {action}) {
      if (action) {
        // Assign this instance context to the event
        // Required by BannerBlock for default emit events
        event.vm = this;

        action.call(this, event);
      } else {
        this.$emit('click', event);
      }
    },

    truncateToEllipsis,
  },
};
</script>

<style lang="scss" scoped>
.tml-banner {
  .content {
    will-change: opacity;

    .body {
      will-change: opacity;

      .title {
        line-height: 125% !important;
        margin-bottom: 0;
        font-size: Max(2.5em, 28px);
      }

      .copy {
        margin-bottom: 1.5rem;
        line-height: 0.1em;
        font-size: Max(1em, 14px);
      }

      > div {
        flex-direction: column;
      }
    }
  }

  @media (min-width: theme('screens.sm')) {
    .content .body {
      .title {
        font-size: Max(4em, 38px);
      }
      .copy {
        font-size: Max(1.1em, 18px);
      }
    }
  }

  @media (min-width: theme('screens.md')) {
    .content .body {
      .title {
        font-size: Max(4.5em, 38px);
        line-height: 120% !important;
      }
      .copy {
        font-size: Max(1.25em, 18px);
      }
    }
  }

  @media (min-width: theme('screens.lg')) {
    .content .body {
      .title {
        font-size: Max(5em, 38px);
        line-height: 110% !important;
      }
      .copy {
        font-size: Max(1.25em, 18px);
      }
    }
  }

  @media (min-width: theme('screens.xl')) {
    .content .body {
      .title {
        font-size: Max(6em, 38px);
        line-height: 100% !important;
      }
      .copy {
        font-size: Max(1.5em, 18px);
      }
    }
  }
}
</style>
