import {LayoutSections} from '@teemill/modules/app';
import {maxDevice} from '@teemill/common/helpers';

export const studioConfig: LayoutSections = {
  announcement: {show: true},
  header: {
    show: true,
    width: 'container',
    height: '3.5rem',
    overlay: false,
    fixed: true,
    content: true,
    logo: true,
    logoMaxWidth: '220px',
    centerLogo: () => maxDevice('md'),
  },
  sidebar: {
    show: () => maxDevice('md'),
    full: true,
    fixed: false,
    width: '220px',
  },
  content: {
    show: true,
    width: '100%',
  },
  footer: false,
  subFooter: false,
  navBar: false,
};
