import {compileTemplate} from '@teemill/utilities';
import type {HasTheme, Theme} from '@teemill/modules/theme-builder';

import type {Page} from './page';

import {
  Property,
  PropertySet,
  PropertyValue,
  PropertyValueType,
} from './property';

export class PageObject<P extends HasTheme | void> implements HasTheme {
  public properties: PropertySet = {};

  public page?: Page;
  public parent?: P;

  protected overrideTheme?: Theme;

  public get theme(): Theme | undefined {
    if (this.overrideTheme !== undefined) {
      return this.overrideTheme;
    }

    return this.parent?.theme;
  }

  /**
   * @name property
   * @description Get the value of a block property.
   *
   * @param {String} name Name of the property
   * @param {String} type
   * @param {*}      fallback
   *
   * @return {*} Property value
   */
  property(
    name: string,
    type?: PropertyValueType,
    fallback?: PropertyValue,
    bindings?: Record<string, any>
  ): unknown {
    let value = Property.get(this.properties, name, fallback, type);

    if (typeof value === 'string' && bindings) {
      value = compileTemplate(value, bindings);
    }

    return value;
  }

  /**
   * @name setProperty
   * @description Create or Update a block properties value.
   *
   * @param {String} name Name of the property
   * @param {*} value Value the property should take
   *
   * @return {void}
   */
  setProperty(name: string, value: PropertyValue): Property {
    return Property.set(this.properties, name, value).assignTo(this);
  }

  setTheme(theme: Theme): PageObject<P> {
    this.overrideTheme = theme;

    return this;
  }

  fillPropertiesFrom(object: PageObject<P>): PageObject<P> {
    for (const property in object.properties) {
      this.setProperty(
        property,
        object.property(property) as PropertyValueType
      );
    }

    return this;
  }
}
