import {SrcSetDimensions} from '../types/srcSetDimensions';
import {calculateStepSize} from './calculateStepSize';
import {createDimensionsForEachStep} from './createDimensionsForEachStep';

export const generateSrcSetDimensions = (
  width: number,
  height: number,
  {defaultNumberOfSteps = 5, minimumStepSize = 256} = {}
): SrcSetDimensions => {
  if (!width) {
    return [];
  }

  return createDimensionsForEachStep(
    width,
    height,
    calculateStepSize(width, defaultNumberOfSteps, minimumStepSize)
  );
};
