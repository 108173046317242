import {Theme} from '../classes/theme';
import {base} from './base';

export const tokyo = base
  .extend('tokyo')
  .deprecate()
  // Colors
  .property('primary.color', '#FF05B0')
  .property('primary.font', 'Carter One')

  // Page
  .property('page.background.color', '#101010')

  // Text
  .property('text.color', '#FFFFFF')
  .property('text.font', 'Roboto')
  .property('text.background.color', '#0000')
  .property('text.fullBackground.color', '#0000')

  // Menu
  .property('menu.background.color', '#101010')
  .property('menu.text.color', '#00e4ff')
  .property('menu.text.font', 'Roboto')

  // Image
  .property(
    'image.default',
    'https://images.teemill.com/ygbpy6py1zrv6lqdjguknqwxgquxcnojlshjusw2c1ovez2v.jpg'
  )

  // Banners
  .property('banner.fullBackground.color', '#0000')

  // Buttons
  .property('button.fullBackground.color', '#0000')

  // Features
  .property('feature.background.color', '#0000')
  .property('feature.fullBackground.color', '#0000')

  // Quote
  .property('quote.text.shadow', 'none')
  .property('quote.background.color', '#0000')
  .property('quote.fullBackground.color', '#ff05b0')

  //AnnouncementV2
  .property('announcement.v2.text.color', '#101010')
  .property('announcement.v2.background.color', '#00e4ff')
  .property('announcement.v2.fullBackground.color', '#0000')

  // Newsletter
  .property('newsletter.text.color', '#101010')
  .property('newsletter.background.color', '#0000')
  .property('newsletter.fullBackground.color', '#00e4ff')

  //Footer
  .property('footer.text.color', '#FFFFFF')
  .property('footer.background.color', '#101010')

  // Forms
  .property(
    'form.background.secondary.color',
    Theme.ref('page.background.color')
  )
  .property('form.text.secondary.color', '#c9c9c9')
  .property('form.border.color', '#c9c9c9')

  // Misc
  .property('box.background.color', '#1f1f1f')
  .property('border.color', '#c9c9c9');
