export const calculateStepSize = (
  width: number,
  steps: number,
  minimumStepSize: number
) => {
  const stepSize = width / steps;

  if (stepSize < minimumStepSize) {
    return width / Math.floor(width / minimumStepSize);
  }

  return stepSize;
};
