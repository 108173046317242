import {App} from 'vue';
import {BLUE, GREEN, MAGENTA, YELLOW} from '../assets/color/brandColours';
import {IconDefinition} from '@fortawesome/pro-light-svg-icons';

import type {Module, Store} from 'vuex';
import {isCrossOriginFrame} from '../helpers/browser/isCrossOriginFrame';

type DialogType = 'standard' | 'error';

export interface DialogData {
  message: string;
  code?: null;
  icon?: IconDefinition;
  type?: DialogType;
  timeout?: number;
  actionIcon?: IconDefinition;
  actionText?: string;
  action?: Function;
  dismissable?: boolean;
}

type DialogState = {
  queue: DialogData[];
  currentItem?: DialogData | null;
  canShowNext: boolean;
  delayBetweenQueueItems: number;
  timeout: false | NodeJS.Timeout;
};

const defaultMessages: Partial<Record<DialogType, string>> = {
  error: 'Oops... looks like something went wrong. Please try again.',
};

const store: Module<DialogState, unknown> = {
  namespaced: true,
  state: {
    queue: [],
    currentItem: null,
    canShowNext: true,
    delayBetweenQueueItems: 300,
    timeout: false,
  } as DialogState,
  mutations: {
    open(state, data: DialogData) {
      if (state.queue.length < 4) {
        if (!data.message && data.type) {
          data.message = defaultMessages[data.type] || '';
        }

        /**
         * A 'code' can be used to group snackbars together. If there is already
         * an item in the queue with that code, don't add another one
         */
        if (data.code) {
          const itemsWithSameCode = state.queue.filter(
            item => item.code === data.code
          );
          if (itemsWithSameCode.length) {
            return;
          }
        }

        state.queue.push({
          code: data.code,
          icon: data.icon,
          message: data.message,
          type: data.type || 'standard',
          timeout: data.timeout || 3000,
          actionIcon: data.actionIcon,
          actionText: data.actionText,
          action: data.action,
          dismissable: data.dismissable || false,
        });

        if (state.canShowNext) {
          // @ts-expect-error Vuex typing doesn't support committing mutation inside mutations
          this.commit('snackbars/next');
        }
      }
    },
    close(state) {
      state.currentItem = null;
      if (state.timeout) {
        clearTimeout(state.timeout);
        state.timeout = false;
        setTimeout(() => {
          // @ts-expect-error Vuex typing doesn't support committing mutation inside mutations
          this.commit('snackbars/canShowNext', true);

          if (state.queue.length >= 1) {
            // @ts-expect-error Vuex typing doesn't support committing mutation inside mutations
            this.commit('snackbars/next');
          }
        }, state.delayBetweenQueueItems);
      }
    },
    next(state) {
      // @ts-expect-error Vuex typing doesn't support committing mutation inside mutations
      this.commit('snackbars/canShowNext', false);
      state.currentItem = state.queue.pop();

      state.timeout = setTimeout(() => {
        // @ts-expect-error Vuex typing doesn't support committing mutation inside mutations
        this.commit('snackbars/close');

        setTimeout(() => {
          // @ts-expect-error Vuex typing doesn't support committing mutation inside mutations
          this.commit('snackbars/canShowNext', true);

          if (state.queue.length >= 1) {
            // @ts-expect-error Vuex typing doesn't support committing mutation inside mutations
            this.commit('snackbars/next');
          }
        }, state.delayBetweenQueueItems);
      }, state.currentItem?.timeout);
    },
    canShowNext(state, value) {
      state.canShowNext = value;
    },
  },
};

let globalStore: Store<unknown>;

export const dialogs = {
  install(app: App, vueGlobalStore: Store<unknown>): void {
    globalStore = vueGlobalStore;
    globalStore.registerModule(['dialogs'], {
      state: {},
    });
    globalStore.registerModule(['dialogs', 'snackbars'], store);

    if (!isCrossOriginFrame() && parent.$dialogs) {
      app.config.globalProperties.$dialogs = parent.$dialogs;
      window.$dialogs = parent.$dialogs;
    } else {
      app.config.globalProperties.$dialogs = this;
      window.$dialogs = this;
    }
  },

  /**
   * @deprecated - use "snackbar" instead
   */
  showSnackBar(message: DialogData) {
    this.snackbar(message);
  },

  snackbar(message: DialogData) {
    globalStore.commit('snackbars/open', message);
  },

  /**
   * This makes use of canvas-confetti https://github.com/catdad/canvas-confetti
   * Refer to the documentation
   *
   * As of writing the canvas-confetti package doesn't feature a way to focus
   * the confetti on a particular element, so this adds that functionality by
   * accepting either a Vue ref, or accepting an object with a target
   * parameter (which is a Vue ref).
   *
   * @param {Object} options Either a VueComponent or ref to focus the
   * confetti on, or a set of options
   *
   * @examples
   * this.$dialogs.showConfetti(this.$refs.myRef);
   * this.$dialogs.showConfetti({ target: this.$refs.myRef, particleCount: 100 });
   * this.$dialogs.showConfetti({ origin: { x: 0.1, y: 0.7 }});
   */
  showConfetti(options: any = {}) {
    if (options && options.$el && !options.target) {
      options = {
        target: options.$el,
      };
    }

    if (options?.target?.$el) {
      options.target = options.target.$el;
    }

    // if the options contain an element parameter, convert that element into
    // an origin point for the canvas-confetti package
    if (options.target) {
      const rect = options.target.getBoundingClientRect();

      options.origin = {
        x: (rect.x + rect.width / 2) / window.innerWidth,
        y: (rect.y + rect.height / 2) / window.innerHeight,
      };
    }

    // Chromium browser throw errors when an object containing methods
    // is passed through window.postMessage, which canvas-confetti uses
    // so now that we are done with options.target we can unset it
    delete options.target;

    const defaultOptions = {
      particleCount: 40,
      spread: 50,
      startVelocity: 30,

      // ticks essentially sets the lifetime of the confetti before it fades away
      ticks: 70,

      // the origin point of confetti is relative to screen space
      // so origin { x: 1, y: 1 } would be the bottom right hand corner
      origin: {y: 0.6},

      colors: [GREEN, BLUE, YELLOW, MAGENTA],

      zIndex: 5000,

      // this disables confetti for people who opt for reduced motion in their
      // browser settings (accessibility option)
      disableForReducedMotion: true,
    };

    const confettiPackage = import('canvas-confetti');

    confettiPackage.then(confetti => {
      confetti.default(Object.assign(defaultOptions, options));
    });
  },

  next() {
    globalStore.commit('snackbars/next');
  },

  close() {
    globalStore.commit('snackbars/close');
  },
};

const snackbarHelper = (
  message: DialogData['message'],
  error?: boolean,
  timeout?: DialogData['timeout'],
  actionText?: DialogData['actionText'],
  action?: DialogData['action']
) => {
  dialogs.showSnackBar({
    message,
    type: error ? 'error' : undefined,
    timeout,
    actionText,
    action,
  });
};

const snackbarErrorHelper = (
  message: DialogData['message'],
  timeout?: DialogData['timeout'],
  actionText?: DialogData['actionText'],
  action?: DialogData['action']
) => {
  snackbar(message, true, timeout, actionText, action);
};

// @ts-expect-error Error is resolved after helper.error is defined
const snackbar: SnackbarHelper = snackbarHelper;
snackbar.error = snackbarErrorHelper;

/**
 * Helper functions
 * @example snackbar('message', false, 5000);
 *          snackbar.error('error message');
 */
export {snackbar};

export type SnackbarHelper = typeof snackbarHelper & {
  error: typeof snackbarErrorHelper;
};
