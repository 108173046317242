<template>
  <div
    class="tml-spinner w-full relative"
    :class="{
      'light': light,
      'absolute top-0 right-0 left-0 bottom-0': absolute,
      'align-center': alignCenter,
    }"
    :style="{
      'height': height,
      'padding-bottom': `${ratio * 100}%`
    }"
  >
    <div class="inner">
      <font-awesome-icon
        :class="{ 'mb-2': text }"
        :icon="faCircleNotch"
      />
      <span
        v-if="text"
        class="loading-text"
      >{{ text }}</span>
    </div>
  </div>
</template>

<script>
import {faCircleNotch} from '@fortawesome/pro-light-svg-icons';

/**
 * TmlSpinner is a spinning icon that can be used to show that something is being processed in the background, e.g. during an upload or other ajax request.
 * This can be used on TmlBtnPrimary using the is-loading prop.
 *
 * @see TmlButton
 *
 * @example <div class="w-full h-full" style="position: relative;">
 *    <tml-spinner height="60px"/>
 * </div>
 */
export default {
  name: 'TmlSpinner',
  props: {
    /**
     * Should the spinner be positioned absolutly and cover it's parent.
     * Parent should be position: relative;
     */
    absolute: Boolean,

    /**
     * Set the height of the spinner (Spinner wheel will stay centered).
     */
    height: String,

    /**
     * Set text to appear below the spinner
     */
    text: String,

    /**
     * Set the height of the spinner (Spinner wheel will stay centered).
     */
    alignCenter: {
      type: Boolean,
      default: false,
    },

    ratio: Number,

    light: Boolean,
  },
  data() {
    return {
      faCircleNotch,
    };
  },
};
</script>

<style scoped lang="scss">
.tml-spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  &.absolute {
    position: absolute;
  }

  &.align-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .inner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    svg {
      animation: fa-spin 1s infinite linear;
      width: 1.25em;
      height: 1.25em;
      color: var(--tml-text-color);

      &.centered {
        position: absolute;
        top: calc(50% - 0.625em);
        left: calc(50% - 0.625em);
      }
    }

    span {
      margin-top: 0.5em;
    }
  }

  &.light {
    svg {
      color: $background-color;
    }
  }
}
</style>
