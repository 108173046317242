import {bus} from '../bus';

export default (file, config) =>
  new Promise(resolve => {
    bus.emit('add-dynamic-overlay', {
      props: {
        file,
        config,
      },
      component: 'tml-drive-file-popup',
      events: {
        confirm() {
          resolve(true);
        },
        cancel() {
          resolve(false);
        },
        'after-leave': () => {
          resolve(false);
        },
      },
    });
  });
