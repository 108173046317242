import {Router} from 'vue-router';
import {ensureLeadingSlash} from '../url/ensureLeadingSlash';

/**
 * @name isRouterLink
 */
export const isRouterLink = (router: Router, url: string): boolean => {
  if (!url || url[0] === '#') {
    return false;
  }

  // Ensure the url does not replace current path in the output of $router.resolve()
  if (!router.currentRoute.value.fullPath.endsWith('/')) {
    url = ensureLeadingSlash(url);
  }

  if (
    router.resolve(url).matched.some(route => route.name !== 'routeNotFound')
  ) {
    return true;
  }

  return false;
};
