import {apiHost} from '../environment/apiHost';
import {ensureSafeUrl} from './ensureSafeUrl';

/**
 * @name formatUrl
 * @description Format a url to work with dev and production servers.
 */
export const formatUrl = (url: string, host = apiHost()): string => {
  if (!url) {
    throw new Error('Missing URL');
  }

  url = ensureSafeUrl(url) as string;

  /**
   * Is this a static build file url.
   */
  if (
    url.match(/^\/static\//) ||
    url.match(/^\/@fs\//) ||
    url.match(/^\/src\//)
  ) {
    return url;
  }

  /**
   * Is this an relative url.
   */
  if (url.match(/^\//)) {
    return `${host}${url}`;
  }

  return url;
};
