import {bus} from '../bus';

export default (filter, options, customCategories) =>
  new Promise(resolve => {
    bus.emit('add-dynamic-overlay', {
      props: {
        filter,
        options,
        customCategories,
      },
      component: 'tml-content-wallet',
      events: {
        selected(result) {
          resolve(result);
        },
      },
    });
  });
