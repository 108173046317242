import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';
import TagBlock from './TagBlock.vue';

export const tag = new BlockType({
  name: 'tag',
  title: 'tag',
  compatibility: ['page'],
  component: TagBlock,
  builder: () => BlockBuilder.create('tag'),
});
