<script setup lang="ts">
import type {Theme} from '@teemill/common/classes';
import {faSearch, faShoppingBag} from '@fortawesome/pro-light-svg-icons';
import {minDevice} from '@teemill/common/helpers';
import {favourites} from '@teemill/common/classes';
import {App} from '@teemill/modules/app';

import {TmlAnchor} from '@teemill/components';

import FavouritesHeaderItem from './StorefrontFavouritesHeaderItem.vue';
import {store} from '../services';

import {computed, reactive, inject, defineAsyncComponent} from 'vue';

const baseCurrency = computed(() => store?.state?.subdomain?.currency ?? 'GBP');

const StoreFrontCurrencySelect = defineAsyncComponent(
  () => import('./StoreFrontCurrencySelect.vue')
);

withDefaults(
  defineProps<{
    excluded?: 'search'[];
  }>(),
  {
    excluded: () => [],
  }
);

const state = reactive({
  favourites,
});

const shouldShowFavourites = computed(() => {
  return state.favourites.count > 0;
});

const app = inject<App>('app');

const shouldShowCurrencySelect = computed(() => {
  return (
    app?.isSubdomain &&
    baseCurrency.value === 'GBP' &&
    !!store.getters['subdomain/getPlugin']('currency')
  );
});

const theme = inject<() => Theme | undefined>('theme', () => undefined);
</script>

<template>
  <div class="flex">
    <store-front-currency-select
      v-if="minDevice('md') && shouldShowCurrencySelect"
      drop-down
    />
    <tml-bubble
      v-if="!excluded.includes('search')"
      :icon="faSearch"
      icon-size="1.5em"
      :color="theme()?.get('menu.text.color')"
      bg-color="#fff0"
      :component="TmlAnchor"
      href="/search/"
      aria-label="Search"
    />
    <favourites-header-item v-if="shouldShowFavourites" />
    <tml-bubble
      :icon="faShoppingBag"
      icon-size="1.5em"
      :color="theme()?.get('menu.text.color')"
      bg-color="#fff0"
      :component="TmlAnchor"
      href="/basket/"
      aria-label="View basket"
    >
      <div v-if="store.getters['cart/getQuantity']" class="icon-bubble">
        {{ store.getters['cart/getQuantity'] }}
      </div>
    </tml-bubble>
  </div>
</template>
