export default {
  getItems(state) {
    return state.items;
  },

  getItem: state => itemId => state.items[itemId],

  getQuantity(state) {
    let count = 0;
    for (let i = 0; i < state.items.length; i++) {
      const item = state.items[i];
      count += item.quantity;
    }
    return count;
  },
};
