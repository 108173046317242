import {useGtm, usePush} from '@teemill/common/services';
import {NotificationHandler} from '../types/notificationHandler';

export const pushSubscribeAccept: NotificationHandler = {
  path: 'notifications/push-subscribe/accept',
  handler: async (notification, {dismiss}) => {
    useGtm().trackEvent({event: 'onPushSubscribeAccept'});

    usePush().subscribe({
      type: 'customer',
      identifier: notification.data.customer as number,
    });

    dismiss();
  },
};

export const pushSubscribeDismiss: NotificationHandler = {
  path: 'notifications/push-subscribe/dismiss',
  handler: async (notification, {dismiss}) => {
    useGtm().trackEvent({event: 'onPushSubscribeDismiss'});

    dismiss();
  },
};
