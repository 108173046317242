import type {BreakpointSize, Breakpoints} from './pageBreakpoints';

import {pageBreakpoints} from './pageBreakpoints';
import {isArray} from 'lodash';

/**
 * @name getSmallestBreakpoint
 * @description Finds the smallest breakpoint from a list provided. If ['xl', 'ld', 'sm'] was
 *              provided then 'sm' would be returned.
 */
export const getSmallestBreakpoint = (
  breakpoints: Breakpoints | BreakpointSize[]
): BreakpointSize => {
  const sizeArray = isArray(breakpoints)
    ? breakpoints
    : Object.keys(breakpoints);

  const allSizes = Object.keys(pageBreakpoints) as BreakpointSize[];

  const smallest = allSizes
    .sort((key1, key2) => pageBreakpoints[key1] - pageBreakpoints[key2])
    .find(breakpoint => sizeArray.includes(breakpoint)) as BreakpointSize;

  return smallest;
};
