import {castArray} from 'lodash';

export default {
  /**
   * @name required
   * @desc Field must contain a value
   */
  required: {
    regex: /^(?!-1$)(?: *\S+ *)+/m,
    message: 'This field is required',
  },

  /**
   * @name email
   * @desc Field must contain a valid email in the format <local>@<domain>.<tld>
   *
   * Regex is taken from the WebKit HTML5 email field validation
   */
  email: {
    regex:
      /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+\s*$/,
    message: 'Please enter a valid email address',
  },

  url: {
    regex:
      /(^((https?:\/\/(www\.)?)|mailto:)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,63}\b([-a-zA-Z0-9()!@:%_+.~#?&/=]*)$)|(^\/([-a-zA-Z0-9()!@:%_+.~#?&/=]*)$)/,
    message: 'Please enter a valid url',
  },

  domain: {
    regex:
      /^(?!\\-)(?:[a-zA-Z0-9\\d\\-]{0,62}[a-zA-Z\d]\.){1,3}(?!\d+)[a-zA-Z\d]{1,63}(?:\.[^.][a-zA-Z\d]{2,3})?$/,
    message: 'Please enter a valid domain',
  },

  /**
   * @name password
   * @description A password field must contain a valid password.
   */
  password: {
    regex: /^ | $/,
    ifNotMatch: true,
    message: 'Password cannot start or end with spaces',
  },

  /**
   * @name app_2fa_code
   * @description A auth app 2fa code field must contain a valid code.
   */
  app_2fa_code: {
    regex: /^\d{3}\s?\d{3}$/,
    message: 'Invalid authentication code',
  },

  /**
   * @name app_2fa_code
   * @description A auth app 2fa code field must contain a valid code.
   */
  sms_2fa_code: {
    regex: /^\d{3}\s?\d{3}$/,
    message: 'Invalid authentication code',
  },

  /**
   * @name recovery_code
   * @description A recovery code field must contain a valid recovery code.
   */
  recovery_code: {
    regex: /^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{8}\s?$/,
    message: 'Invalid recovery code',
  },

  /**
   * @name charity
   * @description A charity number field must contain valid charity number.
   */
  charity: {
    regex: /^(?:[A-z]{0,3}[0-9]{5,9}(?:-[0-9]{0,3})?)?$/,
    message: 'Invalid Charity Number',
    allowEmpty: true,
  },

  /**
   * @name vat
   * @description A vat number field must contain valid vat number.
   */
  vat: {
    regex: /^(?:[A-z]{0,2}[0-9]?[A-z]{0,2}[0-9]{7,12}[A-z]{0,2}[0-9]{0,2})?$/,
    message: 'Invalid Vat Number',
    allowEmpty: true,
  },

  /**
   * @name company
   * @description A company number field must contain a valid company number.
   */
  company: {
    regex: /^(?:[A-z]{0,2}[0-9]{6,9})?$/,
    message: 'Invalid Company Number',
    allowEmpty: true,
  },

  /**
   * @name ga_measurement_id
   * @description Must match validation for a Google Analytics measurement id for tag manager
   */
  ga_measurement_id: {
    regex: /^G-[0-9A-Za-z]{7,12}$/,
    message: 'Invalid measurement ID',
    allowEmpty: true,
  },

  /**
   * @name ga_tracking_id
   * @description Must match validation for a Google Analytics tracking id for tag manager
   */
  ga_tracking_id: {
    regex: /\bUA-\d{4,10}-\d{1,4}\b/,
    message: 'Invalid tracking ID',
    allowEmpty: true,
  },

  /**
   * @name fb_pixel
   * @description Must match the standards for the Facebook Pixel e.g fbq('init', '123')
   */
  fb_pixel: {
    regex: /fbq\('init', '[0-9]{1,50}'\);/,
    message: 'Invalid Facebook Pixel',
    allowEmpty: true,
  },

  /**
   * @name gads_conversion_id
   * @description Must match validation for a Google Ads conversion id for tag manager
   */
  gads_conversion_id: {
    regex: /^\d{7,12}$/,
    message: 'Invalid conversion ID',
    allowEmpty: true,
  },

  /**
   * @name gads_conversion_label
   * @description Must match validation for a Google Ads conversion label for tag manager
   */
  gads_conversion_label: {
    regex: /^[a-zA-Z0-9-_]{18,22}$/,
    message: 'Invalid conversion label',
    allowEmpty: true,
  },

  /**
   * @name google_verification
   * @description Must match validation for a Google verification snippet
   */
  google_verification: {
    regex:
      /^<meta name="google-site-verification" content="([a-zA-Z0-9-_]{1,50})"?\s?\/>\s*$/,
    message: 'Invalid verification snippet',
    allowEmpty: true,
  },

  /**
   * @name pinterest_verification
   * @description Must match validation for a Pinterest verification snippet
   */
  pinterest_verification: {
    regex:
      /^<meta name="p:domain_verify" content="([a-z0-9]{1,50})"?\s?\/>\s*$/,
    message: 'Invalid verification snippet',
    allowEmpty: false,
  },

  /**
   * @name pinterest_pixel
   * @description Must match validation for a Pinterest Pixel snippet
   */
  pinterest_pixel: {
    regex: /pintrk\('load', '([0-9]{1,50})'/,
    message: 'Invalid Pinterest Pixel',
    allowEmpty: true,
  },

  /**
   * @name facebook_verification
   * @description Must match validation for a Facebook verification snippet
   */
  facebook_verification: {
    regex:
      /^<meta name="facebook-domain-verification" content="([a-z0-9]{1,50})"?\s?\/>\s*$/,
    message: 'Invalid verification snippet',
    allowEmpty: false,
  },

  /**
   * @name iframe
   * @description Checks if the passed string is a Iframe with a source.
   */
  iframe: {
    regex: /<iframe.*?\s*src="(http|https|\/\/)(.*?)"/,
    message: 'Invalid Embed Code',
    allowEmpty: true,
  },

  /**
   * @name alpha
   * @description A text field must contain only alpha numeric characters
   */
  alpha: {
    regex: /^[a-z0-9]*$/i,
    message: 'must contain only alphanumeric characters',
  },

  /**
   * @name alpha_dash
   * @description A text field must contain only alpha numeric characters or dashes
   */
  alpha_dash: {
    regex: /^[a-z0-9\-_]*$/i,
    message: 'must contain only alphanumeric characters or dashes',
  },

  /**
   * @name alpha_spaces
   * @description A text field must contain only alphanumeric characters, dashes and spaces
   */
  alpha_spaces: {
    regex: /^[a-z0-9\-_ ]*$/i,
    message: 'Must contain only alphanumeric characters, dashes or spaces',
  },

  /**
   * @name alpha spaces apostropehes
   * @description A text field must contain only alphanumeric characters, dashes, spaces and apostrophes
   */
  alpha_spaces_punctuation: {
    regex: /^[a-z0-9\-'\-!\-#\-;\-_ ]*$/i,
    message: "must contain only alphanumeric characters or '-;!#",
  },

  /**
   * @name numeric
   * @description A text field must contain only numeric characters
   */
  numeric: {
    regex: /^[0-9]*$/i,
    message: 'must contain only numeric characters',
  },

  /**
   * @name numeric
   * @description A text field must contain only numeric characters or a decimal point
   */
  numeric_float: {
    regex: /^[0-9]+(?:\.[0-9]+)?$/i,
    message: 'must contain only numeric characters or a decimal point',
  },

  /**
   * @name number_required
   * @description A text field must contain a least one number
   */
  number_required: {
    regex: /.*[0-9].*/,
    message: 'Must contain at least one number',
  },

  /**
   * @name two_decimal_places
   * @description A text field must contain only a number with up to two decimal places
   */
  two_decimal_places: {
    regex: /(^[0-9]+(?:\.[0-9]{1,2})?$)|^\s*$/,
    message: 'Must contain a number with up to two decimal places',
  },

  /**
   * @name positive_number
   * @description A text field must contain only positive numbers
   */
  positive_number: {
    regex: /^$|^([0-9]*[.])?[0-9]+/,
    message: 'must contain a positive number',
  },

  /**
   * @name uk_phone_number
   * @description A text field must contain a valid UK phone number
   */
  uk_phone_number: {
    regex:
      /^(^$|(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$)/,
    message: 'must contain a valid UK phone number',
  },

  /**
   * @name uk_sort_code
   * @description A text field must contain a valid UK sort code
   */
  uk_sort_code: {
    regex: /^(^$|[0-9]{2}[-][0-9]{2}[-][0-9]{2}$)/,
    message: 'must contain a valid sort code',
  },

  /**
   * @name uk_postcode
   * @description A text field must contain a valid UK postcode
   */
  uk_postcode: {
    regex:
      /^(^$|^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$)/i,
    message: 'must contain a valid postcode',
  },

  /**
   * @name uk_national_insurance_number
   * @description A text field must contain a valid UK National Insurance number
   */
  uk_national_insurance_number: {
    regex:
      /^(^$|^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\s*\d\s*){6}([A-D]|\s)$)/i,
    message: 'must contain a valid National Insurance number',
  },

  /**
   * @name uk_bank_account_number
   * @description A text field must contain a valid UK bank account number
   */
  uk_bank_account_number: {
    regex: /^$|^(\d){8}$/,
    message: 'must contain a valid bank account number',
  },

  /**
   * @name lowercase
   * @description A text field must contain no uppercase characters
   */
  lowercase: {
    regex: /^[^A-Z]*$/,
    message: 'must be lowercase',
  },

  /**
   * @name min_length
   * @desc A text field must contain at least x characters
   *
   * @example { min_length: 3 }
   */
  min_length: {
    test(limit, value) {
      if (typeof value === 'string' && value.length >= limit) {
        return true;
      }
      return false;
    },
    default: 3,
    message(rule) {
      if (typeof rule === 'undefined') {
        return `Must contain at least ${this.default} characters`;
      }
      return `Must contain at least ${rule} characters`;
    },
  },

  /**
   * @name max_length
   * @desc A text field must contain no more than x characters
   *
   * @example { max_length: 10 }
   */
  max_length: {
    test(limit, value) {
      if (!value) {
        return true;
      }

      if (typeof value === 'string' && value.length <= limit) {
        return true;
      }
      return false;
    },
    default: 10,
    message(rule) {
      if (typeof rule === 'undefined') {
        return `Must contain no more than ${this.default} characters`;
      }
      return `Must contain no more than ${rule} characters`;
    },
  },

  /**
   * @name ext
   * @desc Only upload a files with certain extensions
   *
   * @example { ext: ['jpeg', 'jpg'] }
   */
  ext: {
    test(extensions, files) {
      if (!files) {
        return true;
      }

      const regex = new RegExp(`.(${extensions.join('|')})$`, 'i');
      return castArray(files).every(file => regex.test(file.name));
    },
    message(rule) {
      return `Must upload the following formats: ${rule.join()}`;
    },
  },

  min_value: {
    test(limit, value) {
      if (!value) {
        return false;
      }

      return Number(value) >= limit;
    },
    default: 50,
    message(rule) {
      if (typeof rule === 'undefined') {
        return `Min ${this.default}`;
      }
      return `Min ${rule}`;
    },
  },

  max_value: {
    test(limit, value) {
      if (!value) {
        return true;
      }

      return Number(value) <= limit;
    },
    default: 50,
    message(rule) {
      if (typeof rule === 'undefined') {
        return `Max ${this.default}`;
      }
      return `Max ${rule}`;
    },
  },

  /**
   * @name regex
   * @desc Run a custom regular expression check against the value
   *
   * @example regex: {
   *   expression: /^[A-Z0-9\-_\s&!?#]*$/i,
   *   message   : 'Your store name contains invalid characters',
   * }
   */
  regex: {
    test: ({expression}, value) => expression.test(value),
    message: ({message}) => message,
  },
};
