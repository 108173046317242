<template>
  <div
    class="tml-grid"
    :style="{
      'grid-template-columns': columnFormula,
      gap: gridGap,
    }"
  >
    <slot />
  </div>
</template>

<script>
import {currentDevice} from '@teemill/common/helpers';

export default {
  name: 'TmlGrid',

  props: {
    breakpoints: {
      type: Object,
      default: () => ({
        xs: 2,
        md: 3,
        lg: 4,
      }),
    },

    spacing: {
      type: [String, Object],
      default: () => ({
        xs: '0.5em',
        sm: '1em',
      }),
    },

    fill: Boolean,

    minItemWidth: String,
  },

  computed: {
    columnFormula() {
      if (this.minItemWidth) {
        return `repeat(${this.fill ? 'auto-fit' : 'auto-fill'}, minmax(${
          this.minItemWidth
        }, 1fr))`;
      }

      return `repeat(${this.gridColumns}, minmax(0, 1fr))`;
    },

    gridColumns() {
      return this.breakpoints[currentDevice(Object.keys(this.breakpoints))];
    },

    gridGap() {
      if (typeof this.spacing === 'object') {
        return this.spacing[currentDevice(Object.keys(this.spacing))];
      } else {
        return this.spacing;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tml-grid {
  display: grid;

  grid-template-rows: auto;
  grid-auto-flow: row;
}
</style>
