import {base} from './base';

export const dunes = base
  .extend('dunes')
  // Colors
  .property('primary.color', '#9C5239')
  .property('primary.font', 'Rambla')

  // Page
  .property('page.background.color', '#F1F8FD')

  // Text
  .property('text.font', 'Montserrat')
  .property('text.color', '#23110E')

  // Menu
  .property('menu.background.color', '#9C5239')
  .property('menu.text.color', '#FFF')
  .property('menu.text.font', 'Rambla')

  // Image
  .property('image.border.radius', '12px')
  .property(
    'image.default',
    'https://images.teemill.com/ayj0cn2zo5qmeib6wwbpr1obdono9c9c40dsp94h8utfi3bm.jpeg'
  )

  // Buttons
  .property('button.border.radius', '999px')

  // Quote
  .property('quote.fullBackground.color', '#E0EBEF')

  // AnnouncementV2
  .property('announcement.v2.text.color', '#23110E')
  .property('announcement.v2.background.color', '#E8A981')

  // Newsletter
  .property('newsletter.text.color', '#23110E')
  .property('newsletter.fullBackground.color', '#E8A981')

  //Footer
  .property('footer.text.color', '#FFF')
  .property('footer.background.color', '#9C5239');
