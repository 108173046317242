import {BaseClass} from '../../classes/baseClass';

/**
 * TimeInterval.js
 *
 * Defines the standard structure for a TimeInterval object.
 *
 * @author Aidan Brookes <aidan@teemill.com>
 */
export default class TimeInterval extends BaseClass {
  static modelName = 'timeInterval';

  constructor({id, code, name, character} = {}) {
    super();

    this.id = id;
    this.code = code;
    this.name = name;
    this.character = character;
  }
}
