import {hasTeemillPaymentProcessor} from '@teemill/common/guards';

export default {
  path: '/help/:questionName?/',
  name: 'Help',
  meta: {
    title: 'Help',
    layout: 'standard',
    showOnSiteMap: true,
    access: [hasTeemillPaymentProcessor],
  },
  component: () => import(/* webpackChunkName: "PageHelp" */ './HelpPage.vue'),
  // prefetchChunks: ['TmlGlobal', 'TmlForms', 'TmlContainers'],
  children: [
    {
      path: 'reason/:reasonName?/',
      name: 'reason-name',
      meta: {
        title: 'Help',
        layout: 'standard',
        allowPwaReload: true,
      },
      component: () =>
        import(/* webpackChunkName: "PageHelp" */ './HelpPage.vue'),
    },
    {
      path: 'question/:questionName?/',
      name: 'question-name',
      meta: {
        title: 'Help',
        layout: 'standard',
        allowPwaReload: true,
      },
      component: () =>
        import(/* webpackChunkName: "PageHelp" */ './HelpPage.vue'),
    },
    {
      path: 'group/:groupCode?/',
      name: 'group-code',
      meta: {
        title: 'Help',
        layout: 'standard',
        allowPwaReload: true,
      },
      component: () =>
        import(/* webpackChunkName: "PageHelp" */ './HelpPage.vue'),
    },
  ],
};
