import {base} from './base';

export const nordic = base
  .extend('nordic')
  // Colors
  .property('primary.color', '#3E3E3E')
  .property('primary.font', 'Work Sans')

  // Page
  .property('page.background.color', '#ffffff')

  // Text
  .property('text.color', '#3E3E3E')
  .property('text.font', 'Work Sans')
  .property('text.shadow', 'none')

  // Menu
  .property('menu.background.color', '#FFFFFF')
  .property('menu.text.color', '#3E3E3E')
  .property('menu.text.font', 'Work Sans')
  .property('menu.text.transform', 'none')

  // Image
  .property(
    'image.default',
    'https://images.teemill.com/1rygfmzx6h7dsvdag8jtxilqfjjn8iu0rehukoxauzsp76sa.jpg'
  )

  // Buttons

  // Quote
  .property('quote.background.color', '#e3e3e3')
  .property('quote.fullBackground.color', '#E3E3E3')

  // AnnouncementV2
  .property('announcement.v2.background.color', '#e3e3e3')
  .property('announcement.v2.fullBackground.color', '#0000')

  // Newsletter
  .property('newsletter.background.color', '#e3e3e3')
  .property('newsletter.fullBackground.color', '#e3e3e3')

  //Footer
  .property('footer.text.color', '#3E3E3E');
