import {bus} from '../bus';

export default () =>
  new Promise(resolve => {
    bus.emit('add-dynamic-overlay', {
      props: {},
      component: 'tml-push-not-supported-popup',
      events: {
        'after-leave': () => {
          resolve(false);
        },
      },
    });
  });
