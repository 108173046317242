import type {BreakpointSize, Breakpoints} from './pageBreakpoints';

import {currentDevice} from './currentDevice';
import {getSmallestBreakpoint} from './getSmallestBreakpoint';

/**
 * @name getBreakpoint
 * @description Gets the value of the breakpoint from the object provided that best matches
 *              the current viewport size.
 */
export const getBreakpoint = (breakpoints: Breakpoints): number => {
  const keys = Object.keys(breakpoints) as BreakpointSize[];

  let value = breakpoints[currentDevice(keys)];

  if (!value) {
    value = breakpoints[getSmallestBreakpoint(keys)];
  }

  if (!value) {
    throw new Error(`Invalid breakpoints: ${JSON.stringify(breakpoints)}`);
  }

  return value;
};
