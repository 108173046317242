import {base} from './base';

export const naked = base
  .extend('naked')
  // Colors
  .property('primary.color', '#C28161')
  .property('primary.font', 'Aladin')

  // Page
  .property('page.background.color', '#FFF8F3')

  // Text
  .property('text.font', 'Acumin Pro')
  .property('text.color', '#454545')

  // Menu
  .property('menu.background.color', '#F3CAAE')
  .property('menu.text.font', 'Aladin')
  .property('menu.text.color', '#FFFFFF')
  .property('menu.text.transform', 'none')

  // Image
  .property(
    'image.default',
    'https://images.teemill.com/ydpyoioag0jlidtqx6msrq2fialrcrgyuw4pkgwgyqw59nkt.png'
  )

  // Quote
  .property('quote.text.color', '#C28161')
  .property('quote.fullBackground.color', '#F3CAAE')

  // AnnouncementV2
  .property('announcement.v2.text.color', '#E0DFD9')
  .property('announcement.v2.background.color', '#C28161')

  // Newsletter
  .property('newsletter.text.color', '#E0DFD9')
  .property('newsletter.fullBackground.color', '#C28161')

  // Footer
  .property('footer.text.color', '#FFFFFF')
  .property('footer.background.color', '#F3CAAE');
