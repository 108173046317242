import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const graph = new BlockType({
  name: 'graph',
  title: 'Graph',
  compatibility: ['page'],
  component: () =>
    import(/* webpackChunkName: "TmlDashboardPage" */ './GraphBlock.vue'),
  builder: () => BlockBuilder.create('graph'),
});
