<template>
  <div :id="elementUuid" class="tml-expandable-container">
    <slot
      name="header"
      :title="title"
      :is-open="isOpen"
      :on-trigger="onTrigger"
    >
      <div
        class="header py-6 cursor-pointer"
        :class="{'is-open': isOpen}"
        :style="{marginBottom: headerSpacing}"
        @click="onTrigger"
      >
        <h5 class="title mb-0 mr-4" :style="{paddingLeft: titlePadding}">
          <font-awesome-icon v-if="icon" class="mr-4" :icon="icon" />
          {{ title }}
        </h5>
        <slot name="button" />
        <h5 class="icon mb-0 mr-4">
          <font-awesome-icon :icon="faCaretDown" />
        </h5>
      </div>
    </slot>
    <transition :name="!noTransition ? 'open-down' : ''">
      <div
        v-if="onlyHide || isOpen"
        v-show="isOpen"
        :class="{
          'mb-4': !noBottomMargin,
        }"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import {OpenableMixin} from '../../mixins';

export default {
  name: 'TmlExpandableContainer',

  mixins: [OpenableMixin],

  model: {
    prop: 'open',
    event: 'click',
  },

  props: {
    title: {
      required: true,
      type: String,
    },

    icon: Object,

    startOpen: {
      type: Boolean,
      default: false,
    },

    titlePadding: {
      type: String,
      default: '0.75rem',
    },

    noBottomMargin: {
      type: Boolean,
      default: false,
    },

    headerSpacing: {
      type: String,
      default: '0.25rem',
    },

    noTransition: {
      type: Boolean,
      default: false,
    },

    preventOuterClose: {
      type: Boolean,
      default: true,
    },

    onlyHide: Boolean,
  },

  data() {
    return {
      faCaretDown,
    };
  },

  watch: {
    startOpen: {
      immediate: true,
      handler(value) {
        this.isOpen = value;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.tml-expandable-container {
  width: 100%;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid var(--tml-border-color);

    .title {
      flex-grow: 1;
    }

    .icon {
      flex-shrink: 0;
      transition: transform 0.3s ease;
    }
  }

  .is-open {
    .icon {
      transform: rotate(180deg);
    }
  }
}
</style>
