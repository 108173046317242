import {handleFailedChunk} from '@teemill/common/helpers';

export default {
  path: '/favourites/',
  name: 'Favourites',
  meta: {
    title: 'Favourites',
    layout: 'standard',
    shareToHomepage: true,
  },
  component: () =>
    import(
      /* webpackChunkName: "PageFavourites" */ './FavouritesPage.vue'
    ).catch(handleFailedChunk),
  prefetchChunks: ['TmlGlobal', 'TmlContainers', 'TmlForms'],
};
