import {BreakpointSize} from './pageBreakpoints';
import {viewportSize} from './viewportSize';
import {DeviceCheck} from './types/deviceCheck';

/**
 * @deprecated use viewportSize.isSmaller instead
 * @name maxDevice
 */
export const maxDevice: DeviceCheck = (device: BreakpointSize) =>
  viewportSize.isSmaller(device);
