import type {DirectiveDefinition} from './utilities/directiveHelpers';

import {viewportSize} from '@teemill/common/helpers';
import {getContext} from './utilities/directiveHelpers';
import {DirectiveBinding} from 'vue';
import {clamp} from 'lodash';
import {getTextMetrics} from '@teemill/utilities';

/**
 *
 * @example ** STRING MODE **
 * Basic tooltip
 * <div v-tooltip.hover="'Your tooltip here'" />
 *
 * @example ** OBJECT MODE **
 * <div v-tooltip="{
 *  message: 'Your tooltip here',
 * }" />
 *
 * @param {string} message this is the contents of the tooltip
 * @param {string} show this is the name of a property on the parent component.
 * If this property equates to true, the tooltip will be shown, else the tooltip will be hidden
 * @param {number|undefined} timeout this is the number of milliseconds to wait before hiding the tooltip on hover mobile
 *
 * @prop {modifier} hover this tooltip will automatically show and hide when hovering on/off
 *
 */

const tooltipDefaults = {
  viewportBounds: {
    top: 60,
    left: 0,
    bottom: 6,
    right: 0,
  },
  delay: 0,
};

class Tooltip {
  public el: HTMLDivElement | null;
  public opts: any = {};
  public target: any;

  private intervalLoop: false | NodeJS.Timer;
  private showTimeout?: false | NodeJS.Timer;
  private hoverTimeout?: NodeJS.Timer;

  //@ts-expect-error pointer is assigned in the constructor in createElement()
  private pointer: HTMLDivElement | null;

  constructor(target: any, opts: any) {
    if (typeof opts === 'string') {
      this.opts = {
        message: opts,
        modifiers: {},
      };
    } else {
      this.opts = opts;
    }

    if (!this.opts.viewportBounds) {
      this.opts.viewportBounds = {};
    }

    this.target = target;
    this.el = this.createElement();
    this.intervalLoop = false;

    // we have to bind the following methods to .this
    // so that they can be used in event listeners
    this.updateCss = this.updateCss.bind(this);
    this.hide = this.hide.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);

    window.addEventListener('scroll', this.updateCss);
    window.addEventListener('resize', this.updateCss);
  }

  createElement() {
    const el = document.createElement('div');
    el.classList.add('tml-tooltip');
    if (this.opts.classes) {
      for (let i = 0, l = this.opts.classes.length; i < l; ++i) {
        el.classList.add(this.opts.classes[i]);
      }
    }

    el.innerHTML = this.opts.message;

    this.pointer = document.createElement('div');
    this.pointer.classList.add('pointer');

    el.appendChild(this.pointer);

    return el;
  }

  render() {
    if (!this.el) {
      throw new Error('Trying to render undefined tooltip element.');
    }

    this.el.classList.remove('dead');
    if (!this.el.parentElement) {
      document.body.appendChild(this.el);
      this.updateCss();
    }
    this.intervalLoop = setInterval(() => {
      if (!this.target?.parentElement) {
        this.hide();

        if (this.intervalLoop) {
          clearInterval(this.intervalLoop);
          this.intervalLoop = false;
        }
      }
    }, 1000);
  }

  updateCss() {
    // if no target the tooltip is in the middle of being deleted
    if (!this.target || !this.el) {
      return;
    }

    if (!this.pointer) {
      throw new Error(
        'Pointer element has been removed or has not been created yet.'
      );
    }

    let yPos = this.getYPosition(this.opts.position);
    let yPosition = this.opts.position;
    let xPosition = this.opts.position;
    const limits = {
      xMin: this.opts.viewportBounds.left || 0,
      xMax:
        window.innerWidth -
        this.getElWidth() -
        (this.opts.viewportBounds.right || 0),
      yMin: this.opts.viewportBounds.top || 0,
      yMax:
        window.innerHeight -
        this.el.offsetHeight -
        (this.opts.viewportBounds.bottom || 0),
    };
    if (yPos < limits.yMin) {
      yPosition = this.inversePosition(this.opts.position);
      yPos = this.getYPosition(yPosition);
    }
    if (this.opts.sticky) {
      yPos = clamp(yPos, limits.yMin, limits.yMax - 6);
    }
    let xPos = this.getXPosition(this.opts.position);
    if (xPos < limits.xMin || xPos > limits.xMax) {
      xPosition = this.inversePosition(this.opts.position);
      xPos = this.getXPosition(xPosition);
    }
    const originalXPos = xPos;
    xPos = clamp(xPos, limits.xMin + 10, limits.xMax - 10);
    const xDelta = xPos - originalXPos;

    const yDelta = 0;

    const css: Pick<CSSStyleDeclaration, 'top' | 'left' | 'zIndex'> = {
      top: `${yPos}px`,
      left: `${xPos}px`,
      zIndex: '999',
    };

    Object.keys(css).forEach(i => {
      const key = i as keyof typeof css;
      (this.el as HTMLDivElement).style[key] = css[key];
    });

    this.pointer.style.top = '';
    this.pointer.style.bottom = '';
    this.pointer.style.left = '';
    this.pointer.style.right = '';
    this.pointer.style.transform = '';

    switch (xPosition) {
      case 'left':
        this.pointer.style.right = '-5px';
        this.pointer.style.transform = 'rotate(-45deg)';
        break;
      case 'right':
        this.pointer.style.left = '-5px';
        this.pointer.style.transform = 'rotate(135deg)';
        break;
      default:
        this.pointer.style.left = `calc(50% - ${15 + xDelta}px)`;
        break;
    }
    switch (yPosition) {
      case 'top':
        this.pointer.style.bottom = '-5px';
        this.pointer.style.transform = 'rotate(45deg)';
        break;
      case 'bottom':
        this.pointer.style.top = '-5px';
        this.pointer.style.transform = 'rotate(-135deg)';
        break;
      default:
        this.pointer.style.top = `calc(50% - ${5 + yDelta}px)`;
        break;
    }
  }

  updateMessage(newMessage: string) {
    if (this.opts) {
      this.opts.message = newMessage;
    }

    if (this.el) {
      this.el.innerHTML = newMessage;
    }
  }

  getElWidth() {
    if (!this.el) {
      throw new Error(
        'Tooltip element has been removed or has not been created yet.'
      );
    }

    if (!this.opts || !this.opts.message) {
      return 0;
    }

    const compStyle = window.getComputedStyle(this.el);
    const font = `${compStyle.fontSize} ${compStyle.fontFamily}`;
    const maxWidth = parseInt(compStyle.maxWidth, 10);

    const width = getTextMetrics(this.opts.message, font)?.width || 0;

    return clamp(width, 0, maxWidth);
  }

  getYPosition(pos: string | undefined) {
    if (!this.el) {
      throw new Error(
        'Tooltip element has been removed or has not been created yet.'
      );
    }

    if (this.target) {
      const domBounds = this.target.getBoundingClientRect();
      let yPos = 0;
      if (pos === 'top') {
        yPos = domBounds.top - this.el.offsetHeight - 6;
      } else if (pos === 'bottom') {
        yPos = domBounds.top + domBounds.height + 6;
      } else {
        yPos = domBounds.top + domBounds.height / 2 - this.el.offsetHeight / 2;
      }
      return yPos;
    }
    return 0;
  }

  getXPosition(pos: string | undefined) {
    if (this.target) {
      const domBounds = this.target.getBoundingClientRect();
      let xPos = 0;
      if (pos === 'left') {
        xPos = domBounds.left - this.getElWidth() - 6;
      } else if (pos === 'right') {
        xPos = domBounds.left + domBounds.width + 6;
      } else {
        xPos = domBounds.left + domBounds.width / 2 - this.getElWidth() / 2;
      }
      return xPos;
    }
    return 0;
  }

  getZIndex(el: any): number {
    if (el === document || !el || !el.nodeName) {
      return 1;
    }
    const z = window.document.defaultView
      ?.getComputedStyle(el)
      .getPropertyValue('z-index');

    if (!z || isNaN(parseInt(z, 10))) return this.getZIndex(el.parentNode);

    return parseInt(z, 10);
  }

  inversePosition(pos: string) {
    if (pos === 'top') {
      return 'bottom';
    }
    if (pos === 'bottom') {
      return 'top';
    }
    if (pos === 'left') {
      return 'right';
    }
    return 'left';
  }

  unrender() {
    let el: any;
    if (this.el) {
      el = this.el;
    } else {
      // eslint-disable-next-line
      el = this;
    }
    if (el.parentElement && el.classList.contains('dead')) {
      el.remove();
    }
  }

  show() {
    this.showTimeout = setTimeout(() => {
      // check that the tooltip still exists
      // after the timeout has been completed
      if (this.el) {
        this.render();

        window.addEventListener('scroll', this.hide, {
          capture: true,
          once: true,
        });
      }
    }, this.opts.delay);
  }

  hide() {
    if (!this.el) {
      return;
    }

    if (this.showTimeout) {
      clearTimeout(this.showTimeout);
      this.showTimeout = false;
    }

    if (!this.el.classList.contains('dead')) {
      this.el.classList.add('dead');
      this.el.addEventListener('animationend', this.unrender, {once: true});
    }

    if (this.intervalLoop) {
      clearInterval(this.intervalLoop);
      this.intervalLoop = false;
    }
  }

  destroy() {
    // remove all event listeners
    window.removeEventListener('scroll', this.updateCss);
    window.removeEventListener('scroll', this.hide, true);
    window.removeEventListener('resize', this.updateCss);

    this.hide();
    this.unrender();

    if (this.target) {
      this.target.removeEventListener('mousedown', this.onMouseDown);
      this.target.removeEventListener('mouseout', this.onMouseOut);
      this.target.removeEventListener('mouesenter', this.onMouseEnter);
    }

    // remove references to all content
    this.el = null;
    this.target = null;
    this.pointer = null;
  }

  attachMouseEvents(modifiers: DirectiveBinding['modifiers']) {
    if (this.target) {
      this.target.addEventListener('mouseenter', this.onMouseEnter);
      this.target.addEventListener('mouseout', this.onMouseOut);

      if (modifiers.dieonclick) {
        this.target.addEventListener('mousedown', this.onMouseDown);
      }
    }
  }

  onMouseEnter() {
    if (!this.opts?.modifiers?.nomobile || window.innerWidth >= 992) {
      this.show();

      // Disapear on mobile after tap
      if (viewportSize.isSmaller('md') && this.opts?.timeout !== undefined) {
        clearTimeout(this.hoverTimeout);
        this.hoverTimeout = setTimeout(() => {
          this.hide();
        }, this.opts?.timeout);
      }
    }
  }

  onMouseOut(e: MouseEvent) {
    if (
      this.target !== e.relatedTarget &&
      !this.target.contains(e.relatedTarget)
    ) {
      this.hide();
    }
  }

  onMouseDown() {
    this.hide();
  }
}

function getTooltipPosition(binding: DirectiveBinding) {
  if (binding.modifiers.right) {
    return 'right';
  }
  if (binding.modifiers.left) {
    return 'left';
  }
  if (binding.modifiers.bottom) {
    return 'bottom';
  }
  return 'top';
}

function formatOptions(el: any, binding: DirectiveBinding) {
  let opts = binding.value;
  if (typeof opts === 'string') {
    opts = {
      message: opts,
    };
  }
  opts = Object.assign({}, tooltipDefaults, opts);
  opts.target = opts.target ? getContext(binding).$refs[opts.target] : el;
  if (opts.target && opts.target.$el) {
    opts.target = opts.target.$el;
  }
  if (binding.modifiers.sticky) {
    opts.sticky = true;
  }
  opts.position = getTooltipPosition(binding);

  opts.modifiers = binding.modifiers;
  return opts;
}

// if hover mode enabled, listen for mouseenter and mouseout events
// show on mouseenter, hide on mouseout
function attachMouseEvents(el: any, modifiers: DirectiveBinding['modifiers']) {
  el.tmlTooltip.attachMouseEvents(modifiers);
}

export const tmlTooltip: DirectiveDefinition = {
  name: 'tml-tooltip',
  directive: {
    beforeMount(el, binding) {
      if (
        binding.value &&
        (typeof binding.value === 'string' || binding.value.message)
      ) {
        if (!el.tmlTooltip) {
          const opts = formatOptions(el, binding);
          el.tmlTooltip = new Tooltip(opts.target, opts);
          if (binding.modifiers.hover) {
            attachMouseEvents(el, binding.modifiers);
          }
        } else if (typeof binding.value === 'string') {
          el.tmlTooltip.updateMessage(binding.value);
        } else {
          el.tmlTooltip.updateMessage(binding.value.message);
        }
      }
    },

    updated(el, binding) {
      if (
        binding.value &&
        (typeof binding.value === 'string' || binding.value.message)
      ) {
        if (!el.tmlTooltip) {
          const opts = formatOptions(el, binding);
          el.tmlTooltip = new Tooltip(opts.target, opts);
          if (binding.modifiers.hover) {
            attachMouseEvents(el, binding.modifiers);
          }
        } else if (typeof binding.value === 'string') {
          el.tmlTooltip.updateMessage(binding.value);
        } else {
          el.tmlTooltip.updateMessage(binding.value.message);
        }
      }
    },

    unmounted(el) {
      if (el.tmlTooltip) {
        el.tmlTooltip.destroy();
      }
    },
  },
};
