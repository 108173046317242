import {pick, castArray, kebabCase} from 'lodash';

import {formatUrl} from '@teemill/common/helpers';
import {formatHex, match, isTransparent} from '@teemill/utilities';

import {pageBus} from '../bus';
import {Block} from '../classes/block';
import {toRatio, toTextShadow, toIconShadow} from '../styleConversion';

export default {
  props: {
    parentBlock: Object,
    block: Object,
    ratio: Number,
  },

  inject: ['theme'],

  data() {
    return {
      loadingAsyncItems: false,
      asyncItems: [],

      loadingAsyncData: true,
      asyncData: null,
    };
  },

  computed: {
    getRatio() {
      if (this.ratio) {
        return this.ratio;
      }

      const ratio = this.block.property('ratio', 'string');
      return toRatio(ratio);
    },

    getTextShadow() {
      const textShadow = this.block.property('textShadow', 'string');

      return toTextShadow(textShadow);
    },

    getIconShadow() {
      const iconShadow = this.block.property('iconShadow', 'string');

      return toIconShadow(iconShadow, this.block.property('iconColour'));
    },

    shouldApplyPadding() {
      if (this.block.property('fullWidth', 'number') === 1) {
        return true;
      }

      return !this.bgColourSameAsTheme && !this.bgColourTransparent;
    },

    bgColourSameAsTheme() {
      if (!this.theme) {
        return true;
      }

      return match(
        formatHex(this.theme().get('page.background.color'), true),
        this.block.property('backgroundColor', 'string', '#ffffff')
      );
    },

    bgColourTransparent() {
      return isTransparent(
        this.block.property('backgroundColor', 'string', '#0000')
      );
    },

    fullBgColourTransparent() {
      return isTransparent(
        this.block.property('fullBackgroundColor', 'string', '#0000')
      );
    },

    events() {
      const emits = this.block.property('emits', 'json');
      const events = {};

      if (!emits) {
        return events;
      }

      // Create emits for each of the `emits` defined as properties on the
      // page object
      Object.keys(emits).forEach(event => {
        events[event] = e => {
          castArray(emits[event]).forEach(handler => {
            pageBus.emit(kebabCase(handler), {block: this.block, event: e});
          });
        };
      });

      return events;
    },

    bindingData() {
      const store = pick(this.$store.state.store?.active, ['domain', 'name']);

      return {
        store,
        manufacturer: {
          hash: this.$project?.active?.hash || '${manufacturer.hash}',
          // used for a temporary fix,
          // please remove after Surprise Me feature has been changed to a frontend route
          id: this.$project?.active?.id || '${manufacturer.id}',
        },
      };
    },
  },

  methods: {
    emitPageBusEvent(name, data) {
      pageBus.emit(name, data);
    },
  },

  mounted() {
    const asyncItemsUrl = this.block.property('asyncItems', 'string', null);

    if (asyncItemsUrl) {
      this.loadingAsyncItems = true;

      this.axios
        .get(formatUrl(asyncItemsUrl))
        .success(data => {
          this.asyncItems = data.map(item => new Block(item));
        })
        .finally(() => {
          this.loadingAsyncItems = false;
        });
    }

    const asyncData = this.block.property('asyncData', 'string', null);

    if (asyncData) {
      this.loadingAsyncData = true;

      this.axios
        .get(formatUrl(asyncData))
        .success(data => {
          this.asyncData = data;
        })
        .validation(() => {
          this.asyncData = null;
        })
        .finally(() => {
          this.loadingAsyncData = false;
        });
    }
  },
};
