import type {DirectiveDefinition} from './utilities/directiveHelpers';

function touchMouseGenerateEvent(e: TouchEvent, eventType: string) {
  const me = new MouseEvent(eventType, e.touches[0]);
  me.preventDefault = () => {
    e.preventDefault();
  };
  return me;
}

export const tmlTouchMouse: DirectiveDefinition = {
  name: 'tml-touch-mouse',
  directive: {
    mounted: el => {
      if (!el.tmlTouchMouseInitialised) {
        el.$tmlTouchMouseDown = (e: TouchEvent) => {
          el.dispatchEvent(touchMouseGenerateEvent(e, 'mousedown'));
        };
        el.$tmlTouchMouseUp = (e: TouchEvent) => {
          el.dispatchEvent(touchMouseGenerateEvent(e, 'mouseup'));
        };
        el.$tmlTouchMouseMove = (e: TouchEvent) => {
          if (e.touches.length === 1) {
            el.dispatchEvent(touchMouseGenerateEvent(e, 'mousemove'));
          }
        };
        el.addEventListener('touchstart', el.$tmlTouchMouseDown);
        el.addEventListener('touchend', el.$tmlTouchMouseUp);
        el.addEventListener('touchmove', el.$tmlTouchMouseMove);
      }
      el.tmlTouchMouseInitialised = true;
    },
    unmounted: el => {
      el.removeEventListener('touchstart', el.$tmlTouchMouseDown);
      el.removeEventListener('touchend', el.$tmlTouchMouseUp);
      el.removeEventListener('touchmove', el.$tmlTouchMouseMove);
      delete el.$tmlTouchMouseDown;
      delete el.$tmlTouchMouseUp;
      delete el.$tmlTouchMouseMove;
    },
  },
};
