import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const storeHidden = new BlockType({
  name: 'storeHidden',
  title: 'Store Hidden',
  compatibility: ['page'],
  component: () =>
    import(/* webpackChunkName: "StoreHiddenBlock" */ './StoreHiddenBlock.vue'),
  builder: () => BlockBuilder.create('storeHidden'),
});
