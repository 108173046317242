import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const badgeProgress = new BlockType({
  name: 'badgeProgress',
  title: 'Badge Progress',
  compatibility: ['page'],
  component: () =>
    import(
      /* webpackChunkName: "BadgeProgressBlock" */ './BadgeProgressBlock.vue'
    ),
  builder: () => BlockBuilder.create('badgeProgress'),
});
