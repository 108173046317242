export const BlogAdvancedDashboard = () =>
  import('./BlogAdvancedDashboard.vue');
export const BlogDashboard = () => import('./BlogDashboardPage.vue');
export const BlogIndex = () => import('./BlogIndexPage.vue');
export const BlogPost = () => import('./BlogPostPage.vue');
export const BlogTag = () => import('./BlogTagPage.vue');
export const BlogSeriesIndex = () => import('./series/BlogSeriesIndex.vue');
export const BlogSeriesShowIndex = () =>
  import('./series/show/BlogSeriesShowIndex.vue');
export const BlogSeriesShow = () =>
  import('./series/show/BlogSeriesShowPage.vue');
