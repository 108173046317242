<template>
  <component
    :is="block.property('asyncItems', 'string', null)
      ? 'tml-loader'
      : 'div'
    "
    :loading="loadingAsyncItems"
    text="Loading..."
    :loading-ratio="1 / 6"
  >
    <h4 v-if="block.property('title', 'string')">
      {{ block.property('title', 'string', null, bindingData) }}
    </h4>
    <tml-grid
      :id="camelCase(block.property('title', 'string'))"
      class="mb-0"
      :breakpoints="block.property('breakpoints', 'json')"
      :spacing="block.property('spacing', 'json')"
    >
      <block-renderer
        v-for="item in items"
        :key="item.uid"
        :block="item"
      />
    </tml-grid>
  </component>
</template>

<script>
import {camelCase} from 'lodash';
import BlockRenderer from '../../components/BlockRenderer.vue';
import blockMixin from '../blockMixin';

export default {
  name: 'PageBlockGridBlock',

  components: {BlockRenderer},

  mixins: [blockMixin],

  computed: {
    items() {
      return [...this.block.filteredItems, ...this.asyncItems];
    },
  },

  methods: {camelCase},
};
</script>
