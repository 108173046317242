<script>
import {h, resolveComponent} from 'vue';
import {mix} from '@teemill/utilities';

export default {
  name: 'TmlBubble',

  props: {
    element: {
      type: String,
      default: 'div',
    },

    component: {
      type: Object,
    },

    size: {
      type: String,
      default: '1em',
    },

    iconSize: {
      type: String,
      default: '1em',
    },

    href: String,
    icon: Object,

    solid: Boolean,
    disabled: Boolean,
    loading: Boolean,

    showBorder: Boolean,
    showShadow: Boolean,

    expandHitTarget: Boolean,

    color: {
      type: String,
      default: '',
    },

    bgColor: {
      type: String,
      default: '',
    },

    spinTime: {
      type: String,
      default: '2s',
    },

    alignment: {
      type: String,
      default: 'detached',
    },
  },

  render() {
    const primaryColor = this.color || 'var(--tml-text-color)';

    // default to white background if colour given, but no background colour given
    const defaultBg = this.color
      ? '#ffffff'
      : 'var(--tml-box-background-color)';
    const secondaryColor = this.bgColor || defaultBg;

    const hover = this.color
      ? {
          '--hover-color': mix(this.bgColor || '#ffffff', this.color, 50),
          '--hover-opacity': 1,
        }
      : {
          '--hover-color': this.solid
            ? 'var(--tml-text-color)'
            : 'var(--tml-box-background-color)',
          '--hover-opacity': 0.8,
        };

    return h(
      this.component || this.element,
      {
        class: {
          bubble: true,
          solid: this.solid,
          disabled: this.disabled,
          'cursor-pointer': this.$listeners.click,
          expand: this.expandHitTarget,

          'shadow-md': this.showShadow,
          'show-border': this.showBorder,
          relative: true,
          [this.alignment]: true,
        },
        style: {
          fontSize: this.size,
          '--tml-primary-color-bubble': primaryColor,
          '--secondary-color-bubble': secondaryColor,
          ...hover,
        },
        onClick: this.$listeners.click,
        href: this.href,
      },
      [
        this.loading
          ? h(
              'svg',
              {
                viewBox: '0 0 36 36',
                class: {
                  absolute: true,
                  loader: true,
                },
              },
              [
                h('circle', {
                  r: 17,
                  cx: 18,
                  cy: 18,
                  style: {
                    'animation-duration': this.spinTime,
                  },
                }),
              ]
            )
          : undefined,
        this.icon
          ? h(resolveComponent('font-awesome-icon'), {
              icon: this.icon,
              style: {
                fontSize: this.iconSize,
              },
            })
          : undefined,
        this.$slots.default ? this.$slots.default() : undefined,
      ]
    );
  },
};
</script>

<style lang="scss" scoped>
.bubble {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 999px;

  min-width: 2.5em;
  height: 2.5em;

  background-color: var(--secondary-color-bubble);
  color: var(--tml-primary-color-bubble);

  transition: background-color 0.15s;

  svg {
    color: var(--tml-primary-color-bubble);
  }

  p {
    color: var(--tml-primary-color-bubble);
  }

  &.show-border {
    border: 2px solid;
    border-color: var(--tml-primary-color-bubble);
  }

  &.expand:after {
    content: '';
    position: absolute;
    top: -1em;
    left: -1em;
    bottom: -1em;
    right: -1em;
    border-radius: inherit;
  }

  &.cursor-pointer:hover {
    background-color: var(--hover-color) !important;
    opacity: var(--hover-opacity) !important;
  }

  .loader {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    max-width: 200%;
    stroke: var(--tml-primary-color-bubble);

    circle {
      fill: transparent;
      stroke-width: 2;

      transform: rotate(-90deg) translateX(-100%);
      transition: stroke-dasharray 0s linear;

      animation: loader 4s infinite;
      animation-timing-function: linear;
    }
  }

  &.solid {
    background-color: var(--tml-primary-color-bubble);
    color: var(--secondary-color-bubble);

    .loader {
      stroke: var(--secondary-color-bubble);
    }

    svg {
      color: var(--secondary-color-bubble);
    }
  }

  &.left {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:after {
      right: 0;
    }
  }

  &.middle {
    border-left: none;
    border-right: none;
    border-radius: 0;

    &:after {
      left: 0;
      right: 0;
    }
  }

  &.right {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:after {
      left: 0;
    }
  }
}

.bubble {
  &.disabled {
    border-color: $secondary-color;
    color: $secondary-color;

    &.cursor-pointer:hover {
      background-color: mix(white, $secondary-color-hover, 75%) !important;
      opacity: 1 !important;
    }

    &.solid {
      background-color: $secondary-color;
      color: $white-text;

      &.cursor-pointer:hover {
        background-color: $secondary-color-hover !important;
      }
    }

    svg {
      color: $secondary-color;
    }

    .loader {
      top: -2px;
      left: -2px;
      bottom: -2px;
      right: -2px;
    }
  }
}

@keyframes loader {
  0% {
    stroke-dasharray: 0 106;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 106 106;
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dasharray: 0 106;
    stroke-dashoffset: -106;
  }
}
</style>
