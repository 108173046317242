<template>
  <tml-banner
    v-bind="{
      ...$props,
      padding: false,
      fontScale: 0.3,
      minHeight: '300px',
      ...$attrs,
    }"
  >
    <template #content>
      <slot name="content" />
    </template>
  </tml-banner>
</template>

<script>
import {default as bannerProps} from './banner-props';

export default {
  name: 'TmlSlimBanner',

  compatConfig: {
    INSTANCE_LISTENERS: false,
  },

  mixins: [bannerProps],

  props: {
    ratio: {
      type: Number,
      default: 0.1,
    },
  },
};
</script>
