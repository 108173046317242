import {omitBy, isEmpty} from 'lodash';
import type {
  ImageFitType,
  ImageOptions,
  ImagePosition,
  ImageProvider,
  ImageType,
} from '../imageUrl';

export class ImageApiProvider implements ImageProvider {
  public supports(url: string): boolean {
    try {
      const parsedUrl = new URL(url);

      return [
        'https://images.teemill.com',
        'https://images.localhost:8080',
        new RegExp(/https:\/\/images-[0-9]{1,6}\.tunnel\.teemill\.com/),
      ].some(origin => {
        if (origin instanceof RegExp) {
          return origin.test(parsedUrl.origin);
        }

        return origin === parsedUrl.origin;
      });
    } catch {
      return false;
    }
  }

  public fromUrl(url: string): ImageOptions {
    const parsedUrl = new URL(url);

    return {
      origin: parsedUrl.origin,
      file: this.fileFromPath(parsedUrl.pathname),
      type: this.typeFromPath(parsedUrl.pathname),
      padding: {
        top: parsedUrl.searchParams.has('pt')
          ? parseFloat(parsedUrl.searchParams.get('pt') || '0')
          : undefined,
        right: parsedUrl.searchParams.has('pr')
          ? parseFloat(parsedUrl.searchParams.get('pr') || '0')
          : undefined,
        bottom: parsedUrl.searchParams.has('pb')
          ? parseFloat(parsedUrl.searchParams.get('pb') || '0')
          : undefined,
        left: parsedUrl.searchParams.has('pl')
          ? parseFloat(parsedUrl.searchParams.get('pl') || '0')
          : undefined,
      },
      position: parsedUrl.searchParams.has('pos')
        ? ((parsedUrl.searchParams.get('pos') || 'center') as ImagePosition)
        : undefined,
      fit: parsedUrl.searchParams.has('fit')
        ? ((parsedUrl.searchParams.get('fit') || 'contain') as ImageFitType)
        : undefined,
      width: parseInt(parsedUrl.searchParams.get('w') || '0'),
      height: parseInt(parsedUrl.searchParams.get('h') || '0'),
      zoom: parseFloat(parsedUrl.searchParams.get('z') || '1'),
      focal: {
        x: parseFloat(parsedUrl.searchParams.get('fx') || '0.5'),
        y: parseFloat(parsedUrl.searchParams.get('fy') || '0.5'),
      },
      mirror: {
        x: parsedUrl.searchParams.has('mx')
          ? !!(parsedUrl.searchParams.get('mx') === 'true')
          : undefined,
        y: parsedUrl.searchParams.has('my')
          ? !!(parsedUrl.searchParams.get('my') === 'true')
          : undefined,
      },
      rotate: parsedUrl.searchParams.has('r')
        ? parseInt(parsedUrl.searchParams.get('r') || '0')
        : undefined,
      reflect: parsedUrl.searchParams.has('reflect')
        ? parseInt(parsedUrl.searchParams.get('reflect') || '0')
        : undefined,
      extend: parsedUrl.searchParams.has('extend')
        ? parseInt(parsedUrl.searchParams.get('extend') || '0')
        : undefined,
      dpi: parsedUrl.searchParams.has('dpi')
        ? parseInt(parsedUrl.searchParams.get('dpi') || '0')
        : undefined,
      private: this.pathIsPrivate(parsedUrl.pathname),
      background: parsedUrl.searchParams.has('b')
        ? parsedUrl.searchParams.get('b') || '#FFFFFF'
        : undefined,
      project: parsedUrl.searchParams.has('project')
        ? parsedUrl.searchParams.get('project') || ''
        : undefined,
    };
  }

  protected pathIsPrivate(path: string): boolean {
    return !!path.match(/^\/private\//);
  }

  protected fileFromPath(path: string): string {
    let match;

    if (this.pathIsPrivate(path)) {
      match = path.match(/([^/.]+)($|\.|\/$)/);
    } else {
      match = path.match(/([^/]+?\.[^/.]{1,6})($|\.|\/$)/);
    }

    return (match || [])[1] || '';
  }

  protected typeFromPath(path: string): ImageType {
    const match = path.match(/\.([^/.]+)($|\/$)/);

    return ((match || [])[1] || 'jpg') as ImageType;
  }

  public toUrl(options: ImageOptions): string {
    const origin = this.origin(options);
    const path = this.path(options);

    return `${origin}${path}`;
  }

  protected origin(options: ImageOptions): string {
    const protocol = 'https';
    const hostname = 'images.teemill.com';

    return options.origin || `${protocol}://${hostname}`;
  }

  protected path(options: ImageOptions): string {
    const subPath = options.private ? '/private/' : '/';
    const file = options.file;
    const type = options.type;
    const query = this.query(options);

    return `${subPath}${file}.${type}${query}`;
  }

  protected query(options: ImageOptions): string {
    const map: Record<string, any> = {
      w: options.width.toString(),
      h: options.height.toString(),
      z: options.zoom.toString(),
      fx: options.focal.x.toString(),
      fy: options.focal.y.toString(),
      pt: options.padding.top?.toString(),
      pr: options.padding.right?.toString(),
      pb: options.padding.bottom?.toString(),
      pl: options.padding.left?.toString(),
      pos: options.position?.toString(),
      fit: options.fit?.toString(),
      my: options.mirror.y?.toString(),
      mx: options.mirror.x?.toString(),
      r: options.rotate?.toString(),
      reflect: options.reflect?.toString(),
      extend: options.extend?.toString(),
      dpi: options.dpi?.toString(),
      b: options.background?.toString(),
      project: options.project?.toString(),
      v: '2',
    };

    const defaults: Record<string, any> = {
      w: '0',
      h: '0',
      z: '1',
      fx: '0.5',
      fy: '0.5',
    };

    const params = omitBy(map, (v, k) => v === defaults[k]);

    if (isEmpty(params)) {
      return '';
    }

    return `?${new URLSearchParams(params).toString()}`;
  }
}
