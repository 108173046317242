import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: () => ({
    elements: {},
    stacked: [],
  }),
  getters,
  mutations,
  actions,
};
