import types from './types';

export default {
  async register({commit}, name) {
    await commit(types.REGISTER_ELEMENT, name);
  },

  async open({commit}, name) {
    await commit(types.OPEN_ELEMENT, name);
  },

  async close({commit}, params) {
    /**
     * If only a name is passed through to close
     */
    if (typeof params === 'string') {
      await commit(types.CLOSE_ELEMENT, params);
      return;
    }
    /**
     * Check if object passed through contains the $event object
     */
    if (!params.$event) {
      await commit(types.CLOSE_ELEMENT, params.name);
      return;
    }

    /**
     * If the $event does not prevent dismissal properties, then close
     * the element, else do nothing
     */
    if (!params.$event.preventClose && !params.$event.preventDismissal) {
      await commit(types.CLOSE_ELEMENT, params.name);
    }
  },

  async closeAll({commit}) {
    await commit(types.CLOSE_ALL_ELEMENTS);
  },

  async toggle({commit}, name) {
    await commit(types.TOGGLE_ELEMENT, name);
  },

  // adds to array of overlay element names used for stacked overlay elements
  async addToStack({commit}, name) {
    await commit(types.ADD_TO_STACK, name);
  },

  // removes name from array of overlay element names used for stacked overlay elements
  async removeFromStack({commit}, name) {
    await commit(types.REMOVE_FROM_STACK, name);
  },

  // clears array of overlay element names used for stacked overlay elements
  async emptyStack({commit}, name) {
    await commit(types.EMPTY_STACK, name);
  },
};
