import {defineAsyncComponent} from 'vue';

/**
 * Types
 */
export * from './types/layoutOptions';

/**
 * Classes
 */
export * from './classes/app';

/**
 * ServiceWorker
 */
export * from './usePwaRefresh';

/**
 * Components
 */
export {default as AppBase} from './components/AppBase.vue';
export {default as AppTheme} from './components/AppTheme.vue';
export {default as AppLayout} from './components/AppLayout.vue';
export {default as AppHeader} from './components/AppHeader.vue';

export const AppSidebar = defineAsyncComponent(
  () => import('./components/AppSidebar.vue')
);
export const AppFooter = defineAsyncComponent(
  () => import('./components/AppFooter.vue')
);
export const AppHeaderSearch = defineAsyncComponent(
  () => import('./components/AppHeaderSearch.vue')
);
// export {default as AppHeader} from './components/AppHeader.vue';
// export {default as AppSidebar} from './components/AppSidebar.vue';
// export {default as AppFooter} from './components/AppFooter.vue';
// export {default as AppHeaderSearch} from './components/AppHeaderSearch.vue';
