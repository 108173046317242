import {reactive, computed, ref} from 'vue';
import {Router} from 'vue-router';
import {transform} from 'lodash';
import type {Notification} from './types/notification';
import type {NotificationAction} from './types/notificationAction';
import type {NotificationQueue} from './types/notificationQueue';
import type {NotificationForm} from './types/notificationForm';
import {NotificationPosition} from './types/notificationPosition';

import * as handlers from './handlers';
import {http} from '@teemill/common/services';
import {NotificationDismissAction} from './types/notificationDismissAction';
import {NotificationTemplate} from './types/notificationTemplate';

const notifications = reactive<Notification[]>([]);

const navigationRouter = ref<Router | undefined>();

const create = ({
  id = Math.random().toString(),
  title,
  body,
  image,
  href,
  position,
  data = {},
  tag,
  form = {
    fields: {},
    actions: [],
  },
  actions = [],
  dismissAction,
  footerAction,
  createdAt = new Date(),
  scheduledAt,
  deletedAt,
  openedAt,
  replacePrevious,
  mergable = false,
  template,
}: {
  id?: string;
  title: string;
  body: string;
  image: string;
  href?: string;
  position: NotificationPosition;
  template: NotificationTemplate;
  createdAt?: Date;
  scheduledAt?: Date;
  deletedAt?: Date;
  openedAt?: Date;
  data?: Record<string, any>;
  tag?: string;
  form?: NotificationForm;
  actions?: NotificationAction[];
  dismissAction?: NotificationDismissAction;
  footerAction?: NotificationAction;
  replacePrevious?: boolean;
  mergable?: boolean;
}) => {
  const notification: Notification = {
    id,
    title,
    body,
    image,
    href,
    createdAt,
    scheduledAt: scheduledAt || createdAt,
    deletedAt,
    openedAt,
    data,
    tag,
    form,
    actions,
    dismissAction,
    footerAction,
    position,
    template,
    replacePrevious,
    mergable,
  };

  if (notification.replacePrevious || notification.mergable) {
    replacePreviousFor(notification);
  }

  const existingIndex = notifications.findIndex(n => n.id === id);

  if (existingIndex >= 0) {
    notifications.splice(existingIndex, 1, notification);
  } else {
    notifications.push(notification);
  }

  return notification;
};

const replacePreviousFor = (notification: Notification) => {
  const previousNotifications = notifications.filter(
    n =>
      n.tag === notification.tag &&
      n.createdAt.getTime() < notification.createdAt.getTime()
  );

  previousNotifications.forEach(notification => {
    remove(notification);
  });
};

const remove = ({id}: Notification) => {
  const index = notifications.findIndex(n => n.id === id);

  notifications.splice(index, 1);
};

const removeAll = () => {
  while (notifications.length > 0) {
    notifications.pop();
  }
};

const dismiss = (
  notification: Notification,
  position?: NotificationPosition
) => {
  if (position === undefined || position === notification.position) {
    remove(notification);
  } else {
    move(notification, position);
  }
};

const move = (notification: Notification, position: NotificationPosition) => {
  notification.position = position;
};

const navigate = (path: string) => {
  if (navigationRouter.value) {
    navigationRouter.value.push(path);
  }
};

const setRouter = (router?: Router): void => {
  navigationRouter.value = router;
};

const handle = async (
  notification: Notification,
  action?: NotificationAction | NotificationDismissAction
): Promise<void> => {
  const href = action?.href || notification.href;

  if (href === undefined) {
    dismiss(notification, NotificationPosition.HEADER);

    return;
  }

  // Split the provided href into its constituent parts so
  // we can determine what kind of action we need to perform,
  // then figure out the topic in the case that its an action
  //
  // Example
  // href     -> https://teemill.com/omnis/v3
  // protocol -> https:
  // topic    -> example/topic
  const [_, protocol, topic] = Array.from(href.match(/(.+)(?:\/\/)(.+)/) || []);

  switch (protocol) {
    case 'http:':
    case 'https:':
      await http.post(href, notification.data);
      break;

    case 'action:':
      await Promise.all(
        Object.values(handlers)
          // remove any trailing or prefixed slashes on the
          // action handler to ensure consistency
          .filter(
            handler =>
              handler.path.replace(/^\/+|\/+$/, '') ===
              topic.replace(/^\/+|\/+$/, '')
          )
          .map(h =>
            h.handler(notification, {
              dismiss: (position?: NotificationPosition) =>
                dismiss(notification, position),
              move: (position: NotificationPosition) =>
                move(notification, position),
              navigate: (path: string) => navigate(path),
            })
          )
      );
      break;

    default:
      break;
  }
};

export function useNotifications() {
  return {
    notifications,
    create,
    remove,
    removeAll,
    setRouter,
    handle,
    positions: computed(() =>
      transform<
        NotificationPosition,
        Record<NotificationPosition, NotificationQueue>
      >(NotificationPosition, (r, position) => {
        const positionNotifications: Notification[] = notifications
          .filter(n => n.position === position)
          .sort(
            (a, b) =>
              (b.createdAt ? b.createdAt.getTime() : Infinity) -
              (a.createdAt ? a.createdAt.getTime() : Infinity)
          );

        r[position] = {
          count: positionNotifications.length,
          notifications: positionNotifications,
        };
      })
    ),
  };
}
