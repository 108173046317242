import Log from '@devanjs/log';
import {castArray, cloneDeep} from 'lodash';

export type SourceParams = Record<string, unknown>;

export type BlockSourceItem = {
  id?: number;
  media?: 'image' | 'video';
  mediaRatio?: 'short' | '4:3' | 'square' | 'tall';
  images?: string[];
  videos?: string[];

  icon?: string;
  title?: string;
  copy?: string;
  href?: string;
  hrefText?: string;

  price?: number;
  salePrice?: number;

  summary?: {
    image: string;
    title: string;
    description?: string;
    icon?: string;
    href?: string;
  };

  commentable?: {
    id: number;
    type: string;
    parentId?: number;
  };

  social?: {
    showLike: boolean;
    likes?: number;
    hasLiked?: boolean;

    showReply: boolean;
    showReplyBubble?: boolean;
    replies?: number;
  };
};

export type BlockSourceData = BlockSourceItem[];

type SourceRequest<T, K extends SourceParams> = (params: K) => Promise<T[]>;
type SourceParser<T, K extends SourceParams> = (
  data: T[],
  params: K
) => BlockSourceData;

export class BlockFactorySource<T, K extends SourceParams> {
  public name: string;

  public request: SourceRequest<T, K>;
  public parser: SourceParser<T, K>;
  public params: SourceParams;

  protected cache: BlockSourceData = [];

  private dataRequest?: Promise<undefined>;

  constructor({
    name,
    request,
    parser,
    params,
  }: {
    name: string;
    request: SourceRequest<T, K>;
    parser: SourceParser<T, K>;
    params: SourceParams;
  }) {
    this.name = name;
    this.request = request;
    this.parser = parser;
    this.params = params;
  }

  public static static(
    data: BlockSourceData,
    repeat?: number
  ): BlockFactorySource<BlockSourceItem, BlockSourceItem>;
  public static static(
    data: BlockSourceItem,
    repeat?: number
  ): BlockFactorySource<BlockSourceItem, BlockSourceItem>;
  public static static(
    data: BlockSourceData | BlockSourceItem,
    repeat = 1
  ): BlockFactorySource<BlockSourceItem, BlockSourceItem> {
    return new BlockFactorySource<BlockSourceItem, BlockSourceItem>({
      name: 'static',
      request: async () => {
        const dataArray: BlockSourceData = [];

        for (let i = 0; i < repeat; i++) {
          dataArray.push(...castArray(data));
        }

        return dataArray;
      },
      parser: d => d,
      params: {},
    });
  }

  public async get(params: K, limit?: number): Promise<BlockSourceData> {
    if (this.dataRequest) {
      await this.dataRequest;
    }

    if (this.cache.length === 0) {
      const fullParams = {
        ...this.params,
        ...params,
      };

      Log.tag('pages')
        .purple('Factory')
        .info(`Requesting ${this.name} source data`);

      this.dataRequest = (async () => {
        const rawData = await this.request(fullParams);

        if (rawData) {
          this.cache.push(...this.parser(rawData, fullParams));
        }

        return undefined;
      })();

      this.dataRequest = await this.dataRequest;
    }

    if (limit === undefined) {
      limit = this.cache.length;
    }

    if (this.name === 'static') {
      return cloneDeep(this.cache).splice(0, limit);
    }

    return this.cache.splice(0, limit);
  }
}
