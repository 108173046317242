import mitt from 'mitt';
import {v4 as uuid} from 'uuid';

import {EventBusTimeoutError} from '../errors';
import {isCrossOriginFrame} from '../helpers/browser/isCrossOriginFrame';

export class EventBus {
  uuid;

  constructor(name = null, {crossFrame = false} = {}) {
    this.uuid = uuid();
    this.name = name;
    this.crossFrame = crossFrame;

    this._bus = mitt();
    this._busHistory = {};

    if (this.crossFrame) {
      this._registerFrameListeners();
    }
  }

  _registerFrameListeners() {
    const handler = event => {
      if (
        event.origin === window.location.origin &&
        event.data.uuid !== this.uuid &&
        event.data.name === this.name
      ) {
        this._emitInternal(event.data.event, event.data.data);
      }
    };

    window.addEventListener('message', handler);
  }

  /**
   * @name _setHistory
   * @description Sets the events history.
   * @param {String} event Name of the event.
   * @param {*} data Payload of the event.
   */
  _setHistory(event, payload) {
    if (!this._busHistory[event]) {
      this._busHistory[event] = {
        updates: 0,
        payload,
      };
    }

    this._busHistory[event].updates += 1;
    this._busHistory[event].payload = payload;
  }

  /**
   * @name _getHistory
   * @description Get the history for a give event.
   *
   * @param {String} event Name of the event.
   * @return {Object} Payload of the event history.
   */
  _getHistory(event) {
    if (!this._busHistory[event]) {
      return null;
    }

    return this._busHistory[event].payload;
  }

  /**
   * @name emit
   * @description Emit an event into the event bus.
   *
   * @param {string} event Name of event to emit.
   * @param {any} data Event payload.
   */
  emit(event, data = undefined) {
    this._emitInternal(event, data);

    if (this.crossFrame) {
      window.postMessage(
        {
          __bridgeEvent__: true,
          uuid: this.uuid,
          name: this.name,
          event,
          data,
        },
        '*'
      );
    }
  }

  _emitInternal(event, data) {
    this._setHistory(event, data);
    this._bus.emit(event, data);
  }

  /**
   * @name on
   * @description Listen for an event in the event bus.
   *
   * @param {String} event       Name of the event to listen to.
   * @param {Function} callback  Callback function thats called when
   *                             the event it triggered.
   * @param {Boolean} useHistory Should use the events history to trigger the callback.
   *                             ? If the event was triggered before the listener was created
   *                             ? return the last emitted value.
   */
  on(event, callback, useHistory = false) {
    if (useHistory) {
      const history = this._getHistory(event);

      if (history) {
        callback(history);
      }
    }

    if (callback) {
      this._bus.on(event, callback);
    }
  }

  /**
   * @name once
   * @description Listen for an event in the event bus but only once. Removed after
   *              first event.
   *
   * @param {String} event       Name of the event to listen to.
   * @param {Function} callback  Callback function thats called when
   *                             the event it triggered.
   * @param {Boolean} useHistory Should use the events history to trigger the callback.
   *                             ? If the event was triggered before the listener was created
   *                             ? return the last emitted value.
   */
  once(event, callback, useHistory = false) {
    if (useHistory) {
      const history = this._getHistory(event);

      if (history) {
        callback(history);

        return; // ? As this is "once" halt if history was found to avoid two callbacks.
      }
    }

    if (callback) {
      this._bus.on(event, callback);
      this._bus.on(event, this._bus.off.bind(this._bus, event, callback));
    }
  }

  /**
   * @name off
   * @description Remove an event listener from the event bus.
   *
   * @param {String} event Name of the event to remove.
   * @param {Function} callback Remove a specific listener by passing the callback
   *                            used to create it.
   */
  off(event, callback) {
    this._bus.off(event, callback);
  }

  /**
   * @name request
   * @description Emit an event and listen for a reply.
   *
   * @param {String} event Name of event to emit.
   * @param {*} data Event payload.
   *
   * @return {Promise} Promise is resolved when a response is received.
   */
  request(event, data = undefined) {
    const id = uuid();

    return new Promise((resolve, reject) => {
      const failureTimeout = setTimeout(() => {
        reject(new EventBusTimeoutError(event));
      }, 30000);

      this.once(`${event}-response-${id}`, (...args) => {
        clearTimeout(failureTimeout);

        resolve(...args);
      });

      this.once(`${event}-error-response-${id}`, (...args) => {
        clearTimeout(failureTimeout);

        reject(...args);
      });

      this.emit(`${event}-request`, {
        id,
        data,
      });
    });
  }

  /**
   * @name reply
   * @description Wait for a request event and send a reply.
   *
   * @param {String} event Name of event to listen to.
   * @param {Function} callback Callback function thats called when
   *                            the event request is received.
   */
  reply(event, callback) {
    this.off(`${event}-request`);

    return this.on(`${event}-request`, async ({id, data}) => {
      let response;

      try {
        response = await callback(data);
      } catch (error) {
        this.emit(`${event}-error-response-${id}`, error);
        return;
      }

      this.emit(`${event}-response-${id}`, response);
    });
  }

  /**
   * @name offreply
   * @description Stop listening a request event.
   *
   * @param {String} event Name of event to stop listening to.
   */
  offReply(event, callback) {
    this.off(`${event}-request`, callback);
  }
}

export const TmlEvents = {
  install() {
    /**
     * ? If this plugin was registered within an iframe then
     * ? connect to the parents EventBus.
     */
    if (!isCrossOriginFrame() && parent.$eventBus) {
      window.$eventBus = parent.$eventBus;
      // Vue.prototype.$eventBus = parent.$eventBus;
    } else {
      const eventBus = new EventBus();

      window.$eventBus = eventBus;
      // Vue.prototype.$eventBus = eventBus;
    }
  },
};
