import {base} from './base';

export const verde = base
  .extend('verde')
  // Colors
  .property('primary.color', '#82947f')
  .property('primary.font', 'Spectral')

  // Page
  .property('page.background.color', '#ffffff')

  // Text
  .property('text.font', 'Raleway')
  .property('text.color', '#313d17')

  // Menu
  .property('menu.background.color', '#82947F')
  .property('menu.text.color', '#313D17')
  .property('menu.text.font', 'Spectral')

  // Image
  .property('image.border.radius', '12px')
  .property(
    'image.default',
    'https://images.teemill.com/mepka0bezsbpbedpkzvyhlsewqtgdudvb8jqat3bedylbmtq.jpeg'
  )

  // Buttons
  .property('button.border.radius', '12px')

  // Quote
  .property('quote.fullBackground.color', '#82947f')

  // AnnouncementV2
  .property('announcement.v2.text.color', '#e2e3df')
  .property('announcement.v2.background.color', '#313D17')

  // Newsletter
  .property('newsletter.text.color', '#E2E3DF')
  .property('newsletter.fullBackground.color', '#313D17')

  //Footer
  .property('footer.text.color', '#313D17')
  .property('footer.background.color', '#82947f');
