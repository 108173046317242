import {configureScope, captureMessage, Severity} from '@sentry/vue';
import {allowedProtocols} from './allowedProtocols';

/**
 * @name ensureSafeUrl
 */
export const ensureSafeUrl = (url: string): string | null => {
  if (!url) {
    return null;
  }

  // Allow hash and @ url prefixes
  //
  // Examples
  //
  // #stats (common url id match pattern)
  // @subscribeToNotifications (commonly used in Pages)
  if (url.match(/^[#@][a-zA-Z0-9-_]*$/)) {
    return url;
  }

  const srcMatch = url.match(/^(.+?):/);
  const protocol = srcMatch ? srcMatch[1] : null;

  if (!protocol) {
    if (!url.match(/^\//)) {
      return processUnsafeUrl(url);
    }

    return url;
  }

  if (!allowedProtocols.includes(protocol)) {
    return processUnsafeUrl(url);
  }

  return url;
};

const processUnsafeUrl = (url: string): string => {
  // eslint-disable-next-line
  console.log('An unsafe url was blocked', { url });

  configureScope(scope => {
    scope.setLevel('warning');
    scope.setExtra('url', url);
  });

  captureMessage('An unsafe url was blocked');

  return 'https://teemill.com/404/';
};
