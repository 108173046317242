import {
  createRouter as vueCreateRouter,
  RouteLocationRaw,
  Router,
  RouterOptions,
} from 'vue-router';

export interface PausableRouter extends Router {
  pause(): void;
  resume(): void;
}

export const createPausableRouter = (options: RouterOptions) => {
  let pendingPush: RouteLocationRaw | undefined = undefined;
  let routerPausePromise: Promise<void> | undefined = undefined;
  let routerUnpauser = () => {};
  const createPauser = () =>
    new Promise<void>(resolve => {
      routerUnpauser = resolve;
    });

  const router = vueCreateRouter(options) as PausableRouter;

  /**
   * Extend the vue router with pause and resume methods
   */
  router.pause = () => {
    routerPausePromise = createPauser();
  };
  router.resume = () => {
    routerUnpauser();
  };

  /**
   * Wrap the native push method, this allows us to intercept any
   * router pushes that occur while the router is paused, we can
   * then ensure the correct route is loaded on resume.
   */
  const routerPush = router.push;
  router.push = async (to: RouteLocationRaw) => {
    if (routerPausePromise !== undefined) {
      pendingPush = to;
      return;
    }

    return routerPush(to);
  };

  /**
   * Pausable router works by forcing an await promise into the routers
   * beforeEach hook. This means that we can hang the routing logic until
   * we resolve the promise. This means that we can wait for async routes
   * to be loaded before proceeding.
   */
  router.beforeEach(async (to, from, next) => {
    if (routerPausePromise) {
      await routerPausePromise;
      routerPausePromise = undefined;

      return next(pendingPush || to.fullPath);
    }

    next();
  });

  return router;
};
