import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

import AnnouncementBlockV2 from './AnnouncementBlockV2.vue';

export const announcementV2 = new BlockType({
  name: 'announcementV2',
  title: 'announcementV2',
  compatibility: ['page'],
  component: AnnouncementBlockV2,
  builder: () => BlockBuilder.create('announcementV2'),
});
