import type {ImageOptions, ImageProvider} from '../imageUrl';

export class StandardProvider implements ImageProvider {
  public supports(url: string): boolean {
    return true;
  }

  public fromUrl(url: string): ImageOptions {
    return {
      origin: '',
      file: url,
      type: 'jpg',
      width: 0,
      height: 0,
      zoom: 1,
      focal: {
        x: 0.5,
        y: 0.5,
      },
      private: false,
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      mirror: {
        x: false,
        y: false,
      },
    };
  }

  public toUrl(options: ImageOptions): string {
    if (typeof options.file !== 'string') {
      return '';
    }

    return options.file;
  }
}
