<template>
  <picture>
    <source
      v-if="webPSrcSet"
      type="image/webp"
      :[srcsetAttributeName]="webPSrcSet"
      :data-sizes="lazyLoad && webPSrcSet ? 'auto' : undefined"
    />
    <img
      v-if="src"
      ref="image"
      :alt="alt"
      class="w-full h-full"
      :style="{objectFit, objectPosition}"
      :[srcAttributeName]="formatUrl(src)"
      :[srcsetAttributeName]="imgSrcSet"
      :data-sizes="lazyLoad && imgSrcSet ? 'auto' : undefined"
      @load="onImageLoad"
      @error="onImageFail"
    />
  </picture>
</template>

<script>
import 'lazysizes';

import {formatUrl, isImageApi, image} from '@teemill/common/helpers';

export default {
  name: 'TmlSimpleImage',

  compatConfig: {
    INSTANCE_LISTENERS: false,
  },
  props: {
    alt: {
      type: String,
      required: true,
    },

    src: {
      type: [Object, String],
      required: true,
    },

    objectFit: {
      type: String,
      default: 'cover',
    },

    objectPosition: {
      type: String,
      default: 'center',
    },

    lazyLoad: {
      type: Boolean,
      default: false,
    },

    srcSet: Array,
  },

  data() {
    return {
      loaded: false,
    };
  },

  computed: {
    webPSource() {
      if (this.isImageApi) {
        return image(this.src).webp();
      }

      return null;
    },

    isImageApi() {
      if (!this.src || typeof this.src.match !== 'function') {
        return false;
      }

      return isImageApi(this.src);
    },

    webPSrcSet() {
      return this.generateSrcSet(this.webPSource) || this.webPSource;
    },

    imgSrcSet() {
      return this.generateSrcSet(this.src);
    },

    srcAttributeName() {
      return this.lazyLoad ? 'data-src' : 'src';
    },

    srcsetAttributeName() {
      return this.lazyLoad ? 'data-srcset' : 'srcset';
    },
  },

  beforeMount() {
    /**
     * ? If the DOM exists before mount assume prerendered therefore loaded.
     */
    if (this.$el) {
      this.loaded = true;
    }
  },

  methods: {
    formatUrl,

    generateSrcSet(src) {
      if (this.isImageApi && (this.srcSet || this.lazyLoad)) {
        return image(src).srcSet(this.srcSet);
      }

      return null;
    },

    onImageLoad() {
      this.loaded = true;
      this.$emit('loaded', this.$refs.image);
    },

    onImageFail() {
      this.loaded = true;
      this.$emit('failed', this.$refs.image);
    },
  },
};
</script>
