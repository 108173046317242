import {HexColor} from './colorTypes';
import {formatHex} from './formatHex';

/**
 * @name getAlpha
 * @description Get the alpha from a hex code
 */
export const getAlpha = (hex: HexColor): number | null => {
  if (!hex || typeof hex !== 'string') {
    return null;
  }
  hex = formatHex(hex, true);

  return hex.length === 9 ? parseInt(hex.substring(hex.length - 2), 16) : null;
};
