import {StandardProvider} from '../../classes/images/providers/standardProvider';
import {
  ImageOptions,
  ImageUrl,
  ImageUrlString,
} from '../../classes/images/imageUrl';

/**
 * @name image
 */
export const image = <T extends String = ImageUrlString>(
  url: string,
  width?: number,
  height?: number,
  options: Partial<ImageOptions> = {}
): T => {
  if (!ImageUrl.supports(url)) {
    return new ImageUrl(
      url,
      {},
      new StandardProvider()
    ).toString() as unknown as T;
  }

  return new ImageUrl(url, {
    width,
    height,
    ...options,
  }).toString() as unknown as T;
};
