import {Store} from '../../classes/store';
import {BaseClass} from '../../classes/baseClass';
import Thumbnail from '../../classes/thumbnail';

import User from '../user/user';
import Order from '../order/order';
import Division from '../division/division';
import {Manufacturer} from '../manufacturer/manufacturer';

import {ensureSecureUrl, formatUrl} from '../../helpers';

/**
 * Customer.js
 *
 * Defines the standard structure for a Customer object.
 *
 * @author Aidan Brookes <aidan@teemill.com>
 */
export default class Customer extends BaseClass {
  static modelName = 'customer';

  constructor({
    id,
    avatar,
    company,
    firstName,
    jobTitle,
    lastName,
    username,
    email,
    phone,
    orders,
    internal,
    verified,
    divisions,
    signedEula,
    claimedUser,
    xWebsiteUrl,
    tiktokHandle,
    xMobileNumber,
    manufacturers,
    xPotentialValue,
    xTwitterUsername,
    xYoutubeUsername,
    xFacebookUsername,
    xLinkedinUsername,
    xInstagramUsername,
    shouldHaveStore = false,
    dashboardPermissions,
    role,
    notes,
    twoFactorAuthEnabled,
    requestTerms,
    statusJourney,
    leadStatus,
    tags = [],
  } = {}) {
    super();

    this.id = id;
    this.phone = phone;
    this.email = email;
    this.company = company;
    this.jobTitle = jobTitle;
    this.username = username;
    this.status = leadStatus;
    this.avatar = Thumbnail.map(avatar);
    this.firstName = firstName;
    this.lastName = lastName;
    this.internal = internal;
    this.verified = verified;
    this.signedEula = signedEula;
    this.value = xPotentialValue;
    this.website = xWebsiteUrl;
    this.mobile = xMobileNumber;
    this.twitter = xTwitterUsername;
    this.youtube = xYoutubeUsername;
    this.tiktok = tiktokHandle;
    this.orders = Order.map(orders);
    this.facebook = xFacebookUsername;
    this.linkedin = xLinkedinUsername;
    this.instagram = xInstagramUsername;
    this.claimedUser = User.map(claimedUser);
    this.divisions = Division.map(divisions);
    this.manufacturers = Manufacturer.map(manufacturers);
    this.dashboardPermissions = dashboardPermissions;
    this.role = role;
    this.twoFactorAuthEnabled = twoFactorAuthEnabled;
    this.notes = notes;

    this.shouldHaveStore = shouldHaveStore;
    this.requestTerms = requestTerms;
    this.statusJourney = statusJourney;

    this.tags = tags;
    this.services = tags.filter(t => t.type === 'services');
    this.industries = tags.filter(t => t.type === 'industries');
  }

  static get active() {
    if ($store.state?.user instanceof Customer) {
      return $store.state.user;
    }

    return null;
  }

  static create(email, password, recaptcha, options = {}) {
    const {shouldHaveStore = true, dashboardAccess = false} = options;

    return $axios
      .post(formatUrl('/omnis/v3/shop/customer/create/'), {
        email,
        password,
        v3captchaToken: recaptcha.v3Token,
        v2captchaToken: recaptcha.v2Token,

        should_have_store: shouldHaveStore ? 1 : 0,
        dashboard_access: dashboardAccess ? 1 : 0,
      })
      .success(data => {
        $store.dispatch('insertInitialState', data);
      }, false);
  }

  static login(email, password, recaptcha, twoFactorAuthCode, token) {
    let headers = {};

    if (token) {
      headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    return $axios
      .post(
        formatUrl('/omnis/v3/dashboard/secure/login/'),
        {
          email,
          password,
          v3captchaToken: recaptcha?.v3Token,
          v2captchaToken: recaptcha?.v2Token,
          twoFactorAuthCode,
          token,
        },
        {
          headers,
        }
      )
      .success(data => {
        $store.dispatch('insertInitialState', data);
      }, false);
  }

  static resetPassword(token, password, passwordConfirmation) {
    return $axios.post(
      formatUrl('/omnis/v3/dashboard/secure/reset-password/'),
      {
        token,
        password,
        password_confirmation: passwordConfirmation,
      }
    );
  }

  static recoveryLogin(email, password, recaptcha, recoveryCode) {
    return $axios
      .post(formatUrl('/omnis/v3/dashboard/secure/recovery-login/'), {
        email,
        password,
        v3captchaToken: recaptcha.v3Token,
        v2captchaToken: recaptcha.v2Token,
        recoveryCode,
      })
      .success(data => {
        $store.dispatch('insertInitialState', data);

        return data;
      }, false);
  }

  static logout() {
    return $axios
      .post(formatUrl('/omnis/v3/dashboard/secure/logout/'))
      .any(() => {
        $store.dispatch('wipeInitialState');
        $router.push('/login/');
      });
  }

  static fresh() {
    return $axios
      .get(formatUrl('/omnis/v3/dashboard/secure/get/'))
      .success(data => {
        $store.dispatch('insertInitialState', data);
      }, false);
  }

  get name() {
    return `${this.firstName ? this.firstName : 'Unknown'} ${
      this.lastName ? this.lastName : 'Unknown'
    }`;
  }

  get phoneNumbers() {
    const phoneNumbers = [];

    if (this.phone) {
      phoneNumbers.push(this.phone);
    }

    if (this.mobile) {
      phoneNumbers.push(this.mobile);
    }

    return phoneNumbers;
  }

  get socials() {
    const socials = [];

    if (this.twitter) {
      socials.push({
        href: ensureSecureUrl(this.twitter),
        type: 'twitter',
      });
    }

    if (this.facebook) {
      socials.push({
        href: ensureSecureUrl(this.facebook),
        type: 'facebook',
      });
    }

    if (this.tiktok) {
      socials.push({
        href: ensureSecureUrl(this.tiktok),
        type: 'tiktok',
      });
    }

    if (this.instagram) {
      socials.push({
        href: ensureSecureUrl(this.instagram),
        type: 'instagram',
      });
    }

    if (this.linkedin) {
      socials.push({
        href: ensureSecureUrl(this.linkedin),
        type: 'linkedin',
      });
    }

    if (this.youtube) {
      socials.push({
        href: ensureSecureUrl(this.youtube),
        type: 'youtube',
      });
    }

    if (this.website) {
      socials.push({
        href: ensureSecureUrl(this.website),
        type: 'website',
      });
    }

    return socials;
  }
}
