import {standardPageAccess} from '../../access';

export default {
  path: '/collection/:collection/',
  name: 'collection',
  meta: {
    access: [standardPageAccess],
    breadcrumb: {
      type: 'collection',
      parent: 'home',
      pagePath: 'collection',
    },
    layout: 'standard',
    allowPwaReload: true,
  },
  component: () => import('./CollectionPage.vue'),
};
