import {bus} from '../bus';

export default (message, icon, buttonText) =>
  new Promise(resolve => {
    bus.emit('add-dynamic-overlay', {
      props: {
        icon,
        message,
        buttonText,
      },
      component: 'tml-got-it-popup',
      events: {
        confirm() {
          resolve(true);
        },
        cancel() {
          resolve(false);
        },
      },
    });
  });
