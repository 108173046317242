<template>
  <div class="tml-slide-show" :style="{height: slideHeight}">
    <tml-bubble
      v-if="showNavigation"
      class="navigation-control previous"
      disable-border
      expand-hit-target
      :icon="faChevronLeft"
      @click="manualPreviousSlide"
    />
    <transition name="slide">
      <div :key="currentSlide" ref="slide">
        <slide :slide-items="getSlideItems(slidesPerPage)" />
      </div>
    </transition>
    <div
      v-if="preLoadNextSlide && showPreloadedSlide"
      :key="currentSlide"
      class="pre-loaded-slide"
    >
      <slide :slide-items="getSlideItems(slidesPerPage, 1)" />
    </div>
    <tml-bubble
      v-if="showNavigation"
      class="navigation-control next"
      disable-border
      expand-hit-target
      :icon="faChevronRight"
      @click="manualNextSlide"
    />
    <div
      v-if="showPosition"
      class="flex justify-center items-between absolute slide-position"
    >
      <span
        v-for="index in slideCount"
        :key="index"
        class="indicator"
        :class="{active: currentSlide === index - 1}"
      />
    </div>
  </div>
</template>

<script>
import {defineAsyncComponent} from 'vue';
import {faChevronRight, faChevronLeft} from '@fortawesome/pro-light-svg-icons';
import {unwrapSlotItems} from '@teemill/common/helpers';

const Slide = {
  name: 'TmlSlideShowSlide',

  functional: true,

  props: {
    slideItems: Array,
  },

  render(h, ctx) {
    return h(
      'div',
      {
        class: ['slide'],
      },
      [ctx.props.slideItems]
    );
  },
};

export default {
  name: 'TmlSlideShow',

  components: {
    Slide,
    TmlBubble: defineAsyncComponent(() => import('../misc/TmlBubble.vue')),
  },

  props: {
    preLoadNextSlide: Boolean,
    slidesPerPage: Number,
    pause: Boolean,
    showNavigation: {
      type: Boolean,
      default: false,
    },
    showPosition: {
      type: Boolean,
      default: false,
    },
    slideInterval: {
      type: Number,
      default: 6000,
    },
  },

  data() {
    return {
      faChevronRight,
      faChevronLeft,

      slideHeight: 'auto',
      currentSlide: 0,
      nextSlideInterval: 0,

      showPreloadedSlide: false,
    };
  },

  computed: {
    slides() {
      if (this.$slots.default === undefined) {
        return [];
      }

      const children = unwrapSlotItems(this.$slots.default());

      if (!Array.isArray(children)) {
        return [];
      }

      return children;
    },

    slideCount() {
      return this.slides.length;
    },
  },

  watch: {
    slideInterval: {
      immediate: true,
      handler() {
        this.resetSlideInterval();
      },
    },
  },

  unmounted() {
    clearInterval(this.autoSlide);
  },

  methods: {
    autoSlide() {
      if (!this.pause) {
        this.showPreloadedSlide = false;

        this.nextSlide();

        setTimeout(() => {
          this.showPreloadedSlide = true;
        }, Math.max(0, this.slideInterval - 2000));
      }
    },

    firstSlide() {
      this.currentSlide = 0;
    },

    nextSlide() {
      if (this.$refs.slide) {
        this.slideHeight = `${this.$refs.slide.clientHeight}px`;

        setTimeout(() => {
          if (this.$refs.slide) {
            this.slideHeight = `${this.$refs.slide.clientHeight}px`;

            setTimeout(() => {
              this.slideHeight = 'auto';
            }, 300);
          } else {
            this.slideHeight = 'auto';
          }
        }, 100);
      }

      if (this.currentSlide >= this.slides.length - 1) {
        this.currentSlide = 0;
      } else {
        this.currentSlide += 1;
      }
    },

    previousSlide() {
      if (this.currentSlide <= 0) {
        this.currentSlide = this.slides.length - 1;
      } else {
        this.currentSlide -= 1;
      }
    },

    manualNextSlide() {
      this.nextSlide();
      this.resetSlideInterval();
    },

    manualPreviousSlide() {
      this.previousSlide();
      this.resetSlideInterval();
    },

    resetSlideInterval() {
      clearInterval(this.nextSlideInterval);
      this.nextSlideInterval = setInterval(this.autoSlide, this.slideInterval);
    },

    getSlideItems(number, offset = 0) {
      const items = [];

      const slideCount = this.slides.length;
      for (let i = 0; i < number; ++i) {
        items.push(
          this.slides[(this.currentSlide + i + number * offset) % slideCount]
        );
      }

      return items;
    },
  },
};
</script>

<style lang="scss" scoped>
.tml-slide-show {
  position: relative;
  overflow: hidden;
  transition: height 0.3s;

  .slide {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .slide-enter-active {
    transition: opacity 1s;

    &.slide-enter-from {
      opacity: 0;
    }
    &.slide-enter-to {
      opacity: 1;
    }
  }
  .slide-leave-active {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: opacity 1s;

    &.slide-leave-from {
      opacity: 1;
    }
    &.slide-leave-to {
      opacity: 0;
    }
  }

  .pre-loaded-slide {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    opacity: 0;
  }

  .slide-position {
    z-index: 1;
    right: 50%;
    transform: translateX(50%);
    pointer-events: none;
    bottom: 1em;

    .indicator {
      transition: background-color 0.1s ease-in-out;

      margin: 0.25em;
      width: 0.5em;
      height: 0.5em;
      background-color: #fff;
      border: 2px solid $light-gray;
      border-radius: 100%;

      &.active {
        background-color: $text-color;
      }
    }
  }

  .navigation-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;

    &.next {
      right: 1em;
    }

    &.previous {
      left: 1em;
    }
  }
}
</style>
