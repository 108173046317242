import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const feed = new BlockType({
  name: 'feed',
  title: 'Feed',
  compatibility: ['page'],
  component: () =>
    import(/* webpackChunkName: "FeedBlock" */ './FeedBlock.vue'),
  builder: () => BlockBuilder.create('feed').minSize(12).property('title', ''),
});
