import {
  BlogDashboard,
  BlogIndex,
  BlogPost,
  BlogTag,
} from '@teemill/modules/blog/frontend/components/pages';

export default [
  {
    path: '/learn/:pathMatch(.*)',
    redirect: route => route.path.replace('/learn/', '/blog/'),
  },
  {
    path: '/blog/',
    meta: {
      breadcrumb: {
        type: 'page',
        parent: 'home',
      },
      allowPwaReload: true,
    },
    component: BlogIndex,
    children: [
      {
        path: '',
        name: 'blog.dashboard',
        meta: {
          title: 'Blog',
          layout: 'standard',
          showOnSiteMap: true,
        },
        component: BlogDashboard,
      },
      {
        path: 'tagged/:tag?/',
        name: 'blog-tag',
        meta: {
          title: 'Blog',
          breadcrumb: {
            type: 'blog-tag',
            parent: 'blog.dashboard',
          },
          layout: 'standard',
        },
        component: BlogTag,
      },
      {
        path: ':post/',
        name: 'blog-post',
        meta: {
          breadcrumb: {
            type: 'blog-post',
            parent: 'blog.dashboard',
          },
          layout: 'standard',
        },
        component: BlogPost,
      },
    ],
  },
];
