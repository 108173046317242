import {atlas} from './atlas';
import {Theme} from '../classes/theme';

export const atlasDark = atlas
  .extend('atlasDark')
  .property('primary.color', '#539bf5')
  .property('primary.font', 'Poppins')

  // Page
  .property('page.background.color', '#22272e')

  // Text
  .property('text.color', '#adbac7')

  // Menu
  .property('menu.background.color', '#2d333b')
  .property('menu.text.color', '#CDD9E5')

  // Forms
  .property(
    'form.background.secondary.color',
    Theme.ref('box.background.color')
  )
  .property('form.border.color', Theme.ref('border.color'))
  .property('form.border.focused.color', Theme.ref('primary.color'))
  .property('form.text.secondary.color', '#767676')

  // Tag
  .property('tag.background.color', '#454E58')
  .property('tag.border.color', '#454E58')
  .property('tag.text.color', '#fff')

  // Misc
  .property('error.color', '#e5534b')
  .property('box.background.color', '#30363d')
  .property('border.color', '#444c56')

  // Chat
  .property(
    'chat.background.image',
    'https://images.teemill.com/wrqdarxxckuxsbqgunbqtqggchujhtur74pyrgm6txiknpmn.jpg.webp'
  )
  .property('chat.header.shadow', '0px 0px 5px 0px rgba(0, 0, 0, 0.2)')
  .property('chat.input.shadow', '0px 0px 5px 1px rgba(0, 0, 0, 0.2)')
  .property('chat.scrollbar.color', Theme.ref('box.background.color'))
  .property('chat.message.own.color', '#222E25')
  .property('chat.message.focused.color', '#2E2B22')
  .property('chat.message.hidden.color', '#2E2222');
