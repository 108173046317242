import {stripHtmlWhitespace} from '@teemill/utilities';
import {BlockBuilder} from '../classes/blockBuilder';
import {BlockFactory} from '../classes/blockFactory';
import {BlockSourceData} from '../classes/blockFactorySource';

export const feature = new BlockFactory({
  name: 'feature',
  builder: (data: BlockSourceData) => {
    return data.map(
      ({
        icon,
        title,
        images = [],
        copy,
        hrefText,
        href,
        commentable,
        social,
      }) => {
        const builder = BlockBuilder.create('row')
          .property('direction', 'column')
          .property('icon', icon)
          .property('title', title)
          .property('padding', 3)

          .item(
            images.length === 1,
            BlockBuilder.create('pod')
              .property('template', 'pod')
              .property('image', images[0])
              .property('ratio', 'short')
              .property('padding', 2)
          )

          .item(
            images.length === 2,
            BlockBuilder.create('grid')
              .property('breakpoints', {xs: 2})
              .property('padding', 2)
              .item(
                ...images.map(image =>
                  BlockBuilder.create('pod')
                    .property('template', 'pod')
                    .property('image', image)
                    .property('ratio', 'square')
                )
              )
          )

          .item(
            images.length === 3,
            BlockBuilder.create('grid')
              .property('breakpoints', {
                xs: 1,
                sm: 3,
              })
              .property('padding', 2)
              .item(
                ...images.map(image =>
                  BlockBuilder.create('pod')
                    .property('template', 'pod')
                    .property('image', image)
                    .property('ratio', 'square')
                )
              )
          )

          .item(
            images.length === 4,
            BlockBuilder.create('grid')
              .property('breakpoints', {
                xs: 2,
                lg: 4,
              })
              .property('padding', 2)
              .item(
                ...images.map(image =>
                  BlockBuilder.create('pod')
                    .property('template', 'pod')
                    .property('image', image)
                    .property('ratio', 'square')
                )
              )
          )

          .item(
            images.length === 5,
            BlockBuilder.create('row')
              .property('padding', 2)
              .item(
                BlockBuilder.create('pod')
                  .property('template', 'pod')
                  .property('image', images[0])
                  .property('ratio', 'square')
              )
              .item(
                BlockBuilder.create('grid')
                  .property('breakpoints', {xs: 2})
                  .item(
                    ...images
                      .map(image =>
                        BlockBuilder.create('pod')
                          .property('template', 'pod')
                          .property('image', image)
                          .property('ratio', 'square')
                      )
                      .slice(1)
                  )
              )
          )

          .item(
            images.length > 5,
            BlockBuilder.create('grid')
              .property('padding', 2)
              .item(
                ...images.map(image =>
                  BlockBuilder.create('pod')
                    .property('template', 'pod')
                    .property('image', image)
                    .property('ratio', 'square')
                )
              )
          )

          .item(
            BlockBuilder.create('textBlock').property(
              'html',
              stripHtmlWhitespace(copy || '')
            )
          );

        builder.item(
          href !== undefined,
          BlockBuilder.create('button')
            .property('text', hrefText)
            .property('href', href)
            .property('align', 'left')
        );

        if (commentable !== undefined && social !== undefined) {
          builder.item(
            BlockBuilder.create('interactions')
              .property('padding', 2)
              .property('commentable', JSON.stringify(commentable))
              .property('parentId', commentable.parentId)
              .property('showLike', social.showLike)
              .property('liked', social.hasLiked)
              .property('likeCount', social.likes)
              .property('showReply', true)
              .property('showReplyBubble', false)
              .property('visibleReplyCount', social.replies)
          );
        }

        return builder;
      }
    );
  },
});
