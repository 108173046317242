import {BlockBuilder} from '../classes/blockBuilder';

import {BlockFactory} from '../classes/blockFactory';
import {BlockSourceData} from '../classes/blockFactorySource';

export const tag = new BlockFactory({
  name: 'tag',
  builder: (data: BlockSourceData) => {
    return data.map(({title, href}, index) => {
      return BlockBuilder.create('tag')
        .order(index)
        .property('text', title)
        .property('href', href);
    });
  },
});
