import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const appIcon = new BlockType({
  name: 'appIcon',
  title: 'App Icon',
  compatibility: ['page'],
  component: () =>
    import(/* webpackChunkName: "TmlDashboardPage" */ './AppIconBlock.vue'),
  builder: () => BlockBuilder.create('appIcon'),
});
