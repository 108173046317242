import {HexColor} from './colorTypes';
import {formatHex} from './formatHex';
import {stripAlpha} from './stripAlpha';
import {clamp} from 'lodash';

/**
 * @name getAlpha
 */
export const addAlpha = (hex: HexColor, opacity: number): HexColor => {
  const formattedHex = formatHex(hex, true);
  hex = stripAlpha(formattedHex);

  let hexOpacity = Math.round(clamp(opacity || 1, 0, 1) * 255)
    .toString(16)
    .toUpperCase();

  if (hexOpacity.length === 1) {
    hexOpacity = '0' + hexOpacity;
  }

  return hex + hexOpacity;
};
