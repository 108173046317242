import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const productList = new BlockType({
  name: 'productList',
  title: 'Product list',
  compatibility: ['page'],
  component: () =>
    import(/* webpackChunkName: "ProductList" */ './ProductListBlock.vue'),
  builder: () =>
    BlockBuilder.create('productList')
      .property('title', '')
      .property('sortBy', 'bestsellerOrder')
      .property('limit', 4),
});
