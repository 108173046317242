export default {
  state: () => ({
    viewportDevices: {
      xl: 1200,
      lg: 992,
      md: 768,
      sm: 576,
      xs: 0,
    },

    viewport: '',

    title: '',
  }),
  getters: {
    isDevice: state => device =>
      state.viewportDevices[state.viewport] <= state.viewportDevices[device],
  },
  mutations: {
    setViewportDevice(state, value) {
      state.viewport = value;
    },
  },
  actions: {
    async updateViewportDevice({commit, state}) {
      let currentDevice = '';
      const devices = Object.keys(state.viewportDevices);

      for (let i = 0, length = devices.length; i < length; ++i) {
        if (window.innerWidth >= state.viewportDevices[devices[i]]) {
          currentDevice = devices[i];
          break;
        }
      }

      if (state.viewport !== currentDevice) {
        commit('setViewportDevice', currentDevice);
      }
    },

    async updateDocumentTitle({commit, rootState}, route) {
      if (!route) {
        route = rootState.route;
      }

      if (route.name) {
        const companyName = rootState.subdomain.company.name;

        if (route.meta?.title && route.name !== 'home') {
          const unreadNotifications = rootState.notifications
            ? rootState.notifications.notificationUnreadCount
            : 0;

          const docTitlePrefix = route.meta.title;

          let docTitle = `${docTitlePrefix} | ${companyName}`;

          if (unreadNotifications) {
            docTitle = `(${unreadNotifications}) ${docTitle}`;
          }

          commit('setTitle', docTitle);
        } else {
          commit('setTitle', companyName);
        }
      }
    },

    async setTitle({commit, rootState}, title) {
      const unreadNotifications = rootState.notifications
        ? rootState.notifications.notificationUnreadCount
        : 0;

      const companyName = rootState.subdomain.company.name;

      const docTitlePrefix = title;
      let docTitle = `${docTitlePrefix} | ${companyName}`;

      if (unreadNotifications) {
        docTitle = `(${unreadNotifications}) ${docTitle}`;
      }

      commit('setTitle', docTitle);
    },
  },
};
