import {BlockBuilder} from '../classes/blockBuilder';
import {BlockFactory} from '../classes/blockFactory';
import {BlockSourceData} from '../classes/blockFactorySource';

export const card = new BlockFactory({
  name: 'pod',
  builder: (data: BlockSourceData) => {
    return data.map(({images}) => {
      return BlockBuilder.create('card').property(
        'image',
        images ? images[0] : undefined
      );
    });
  },
});
