import {bus} from '../bus';

export default permissions =>
  new Promise(resolve => {
    bus.emit('add-dynamic-overlay', {
      props: {
        permissions,
      },
      component: 'tml-request-permission-popup',
      events: {
        confirm(reason) {
          resolve(reason);
        },
        cancel() {
          resolve(false);
        },
      },
    });
  });
