import Vue from 'vue';

export default {
  setItems(state, items) {
    if (!items) {
      return;
    }

    Vue.set(state, 'items', items);
  },

  removeItem(state, key) {
    state.items = state.items.filter(i => i.key !== key);
  },

  updateItem(state, itemData) {
    const item = state.items.find(i => i.key === itemData.key);

    item.quantity = itemData.quantity;
  },

  clear(state) {
    state.items = [];
  },
};
