import {base} from './base';

export const academia = base
  .extend('academia')
  // Colors
  .property('primary.color', '#483D2F')
  .property('primary.font', 'La Belle Aurore')

  // Page
  .property('page.background.color', '#ffffff')

  // Text
  .property('text.font', 'Vollkorn')
  .property('text.color', '#1e1e1e')

  // Menu
  .property('menu.background.color', '#1E1E1E')
  .property('menu.text.color', '#d6d0c2')
  .property('menu.text.font', 'Vollkorn')

  // Image
  .property('image.border.radius', '4px')
  .property(
    'image.default',
    'https://images.teemill.com/1z0ow3rjf19uyry9u6t7b27dbo11tnlhbqlj8g0p8r0hfybw.png'
  )

  // Buttons
  .property('button.border.radius', '4px')

  // Quote
  .property('quote.text.color', '#D6D0C2')
  .property('quote.fullBackground.color', '#483D2F')

  // AnnouncementV2
  .property('announcement.v2.text.color', '#D6D0C2')
  .property('announcement.v2.background.color', '#483D2F')

  // Newsletter
  .property('newsletter.text.color', '#D6D0C2')
  .property('newsletter.fullBackground.color', '#483D2F')

  //Footer
  .property('footer.text.color', '#d6d0c2')
  .property('footer.background.color', '#1e1e1e');
