import {BaseClass} from '../../classes/baseClass';

/**
 * DivisionDemographic.js
 *
 * Defines the standard structure for a DivisionDemographic object.
 *
 * @author Aidan Brookes <aidan@teemill.com>
 */
export default class DivisionDemographic extends BaseClass {
  static modelName = 'divisionDemographic';

  constructor({id, name, code} = {}) {
    super();

    this.id = id;
    this.name = name;
    this.code = code;
  }
}
