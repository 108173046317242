export const isStorageSupported = (
  storageName: 'localStorage' | 'sessionStorage'
): boolean => {
  try {
    // To catch access denied error
    const storage = window[storageName];

    const testKey = '__test_supported__';

    storage.setItem(testKey, testKey);
    storage.removeItem(testKey);

    return true;
  } catch (error) {
    return false;
  }
};
