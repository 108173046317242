import UrlParser from 'url-parse';

/**
 * @name mergeQueryParams
 * @description Merge query params into a url
 */
export const mergeQueryParams = (url: string, query: {}): string => {
  if (!url) {
    throw new Error('Missing URL');
  }

  const parsedUrl = new UrlParser(url, '/', true);

  Object.assign(parsedUrl.query, {
    ...query,
  });

  return parsedUrl.toString();
};
