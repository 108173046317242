import {LayoutSections} from '@teemill/modules/app';

export const blankConfig: LayoutSections = {
  announcement: {show: false},
  header: false,
  sidebar: false,
  content: {
    show: true,
    width: '100%',
  },
  footer: false,
  subFooter: false,
  navBar: false,
};
