import {BaseClass} from '../../classes/baseClass';
import {dayjs} from '../../services/dayjs';

import User from '../user/user';
import OrderStatus from './orderStatus';

/**
 * OrderStatusLog.js
 *
 * Defines the standard structure for a OrderStatusLog object.
 *
 * @author Aidan Brookes <aidan@teemill.com>
 */
export default class OrderStatusLog extends BaseClass {
  static modelName = 'orderStatusLog';

  constructor({id, user, status, createdAt} = {}) {
    super();

    this.id = id;
    this.user = User.map(user);
    this.status = OrderStatus.map(status);
    this.createdAt = dayjs(createdAt).local().format('DD/MM/YY HH:mm');
  }
}
