import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const studioSuggestion = new BlockType({
  name: 'studioSuggestion',
  title: 'studio suggestions',
  compatibility: ['page'],
  component: () =>
    import(
      /* webpackChunkName: "StudioBlocks" */ './StudioSuggestionsBlock.vue'
    ),
  builder: () => BlockBuilder.create('studioSuggestion'),
});
