import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

import GridBlock from './GridBlock.vue';

export const grid = new BlockType({
  name: 'grid',
  title: 'Grid',
  compatibility: ['page'],
  component: GridBlock,
  builder: () => BlockBuilder.create('grid').minSize(12),
});
