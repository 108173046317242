import {base} from './base';

export const wanderlust = base
  .extend('wanderlust')
  // Colors
  .property('primary.color', '#CB916B')
  .property('primary.font', 'Playfair Display')

  // Page
  .property('page.background.color', '#FFFFFF')

  // Text
  .property('text.color', '4F4D4D')
  .property('text.font', 'Lora')

  // Menu
  .property('menu.text.transform', 'none')
  .property('menu.background.color', '#D3E6E4')
  .property('menu.text.color', '#324B48')
  .property('menu.text.font', 'Playfair Display')

  // Image
  .property(
    'image.default',
    'https://images.teemill.com/uuyld1evirva5qzhqu1tkujp6s40u9fgspuqwdmpwplygivz.jpg'
  )

  // Text
  .property('text.shadow', 'none')

  // Quote
  .property('quote.text.font', 'Playfair Display')
  .property('quote.text.color', '#4F4D4D')
  .property('quote.text.shadow', 'none')
  .property('quote.background.color', '#EEE4DD')
  .property('quote.fullBackground.color', '#EEE4DD')

  // AnnouncementV2
  .property('announcement.v2.text.font', 'Abril Fatface')
  .property('announcement.v2.text.color', '#4F4D4D')
  .property('announcement.v2.background.color', '#EEE4DD')
  .property('announcement.v2.fullBackground.color', '#0000')

  // Newsletter
  .property('newsletter.background.color', '#EEE4DD')
  .property('newsletter.fullBackground.color', '#EEE4DD')

  //Footer
  .property('footer.text.color', '4F4D4D');
