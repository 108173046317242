import {base} from './base';

export const boho = base
  .extend('boho')
  .deprecate()

  // Colors
  .property('primary.color', '#9CAC7F')
  .property('primary.font', 'Libre Baskerville')

  // Page
  .property('page.background.color', '#FCFCFC')

  // Text
  .property('text.color', '#053032')
  .property('text.font', 'Source Sans Pro')
  .property('text.shadow', 'none')
  .property('text.background.color', '#FCFCFC')
  .property('text.fullBackground.color', '#FCFCFC')

  // Menu
  .property('menu.background.color', '#F3C5B9')
  .property('menu.text.color', '#053032')
  .property('menu.text.font', 'Libre Baskerville')
  .property('menu.text.transform', 'none')

  // Image
  .property(
    'image.default',
    'https://images.teemill.com/vbzbmejcml9wbu66vu5ekmuk4anc5juqjudvl9wyrvhidazh.png'
  )

  // Banners
  .property('banner.fullBackground.color', '#0000')

  // Buttons
  .property('button.border.radius', '999px')
  .property('button.fullBackground.color', '#FCFCFC')

  // Features
  .property('feature.background.color', '#FCFCFC')
  .property('feature.fullBackground.color', '#FCFCFC')

  // Quote
  .property('quote.background.color', '#EEE7D9')
  .property('quote.fullBackground.color', '#EEE7D9')

  // !deprecated
  // Announcement
  .property('announcement.fullBackground.color', '#F8F8F8')

  // AnnouncementV2
  .property('announcement.v2.background.color', '#EEE7D9')
  .property('announcement.v2.fullBackground.color', '#0000')

  // Card
  .property('card.fullBackground.color', '#F8F8F8')

  // Newsletter
  .property('newsletter.background.color', '#EEE7D9')
  .property('newsletter.fullBackground.color', '#EEE7D9')

  //Footer
  .property('footer.text.color', '#053032')
  .property('footer.background.color', '#FCFCFC');
