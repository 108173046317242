import {standardPageAccess} from '../../access';

export default [
  {
    path: '/update-address/:orderHash?',
    name: 'Update shipping address',
    meta: {
      title: 'Update address',
      breadcrumb: {
        type: 'page',
        parent: 'home',
      },
      shareToHomepage: true,
      layout: 'standard',
      allowPwaReload: true,
    },
    access: [standardPageAccess],
    component: () =>
      import(
        /* webpackChunkName: "PageUpdateShippingAddress" */ './UpdateShippingAddressPage.vue'
      ),
    // prefetchChunks: ['TmlGlobal', 'TmlForms'],
  },
];
