import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const carousel = new BlockType({
  name: 'carousel',
  title: 'Carousel',
  compatibility: ['page'],
  component: () =>
    import(/* webpackChunkName: "CarouselBlock" */ './CarouselBlock.vue'),
  builder: () =>
    BlockBuilder.create('carousel')
      .minSize(12)
      .property('title', '')
      .property('copy', ''),
});
