import {BaseClass} from '../../classes/baseClass';

/**
 * Tag.js
 *
 * Defines the standard structure for a Tag object.
 *
 * @author Aidan Brookes <aidan@teemill.com>
 */
export default class Tag extends BaseClass {
  static modelName = 'tag';

  constructor({id, name} = {}) {
    super();

    this.id = id;
    this.name = name;
  }
}
