import {HexColor} from './colorTypes';

/**
 * @name formatHex
 * @description Formats a hex to a standardised format (6 or 8 digits)
 */
export const formatHex = (hexIn: HexColor, includeHash = false): HexColor => {
  let hex = hexIn.replace('#', '');
  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  } else if (hex.length === 4) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}`;
  }
  if (includeHash) {
    hex = `#${hex}`;
  }
  return hex;
};
