export default {
  path: '/invoice/:orderHash',
  name: 'Invoice',
  meta: {
    title: 'InvoicePage',
    layout: 'standard',
    allowPwaReload: true,
  },
  component: () =>
    import(/* webpackChunkName: "PageInvoice" */ './InvoicePage.vue'),
  // prefetchChunks: ['TmlGlobal', 'TmlContainers', 'TmlForms'],
};
