<template>
  <div
    v-if="showVariation"
    class="tml-split-test-variation"
    :class="{invalid: !valid}"
  >
    <slot />
    <div v-if="!valid" class="error">
      <b>ERROR</b>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TmlSplitTestVariation',

  inject: ['splitTestName', 'loadVariation'],

  props: {
    name: String,

    default: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      valid: true,
    };
  },

  computed: {
    showVariation() {
      if (!this.splitTestName) {
        // eslint-disable-next-line
        console.error(
          'tml-split-test-variation should be placed in the slot of a tml-split-test'
        );

        return false;
      }

      if (this.$route.query[this.splitTestName]) {
        if (this.$route.query[this.splitTestName] === this.name) {
          return true;
        }
        return false;
      }

      if (
        !$store.getters['splitTest/get'](this.splitTestName) &&
        this.default
      ) {
        return true;
      }

      if (
        this.$store.getters['splitTest/isActiveVariation'](
          this.splitTestName,
          this.name
        )
      ) {
        if (this.loadVariation(this.name) === this.name) {
          return true;
        }

        return false;
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.tml-split-test-variation {
  &.invalid {
    padding: 0.25em;
    background-color: var(--tml-error-color);
    color: $background-color;
    border-radius: $border-radius;

    .error {
      padding: 0.25em;
    }
  }
}
</style>
