import {base} from './base';

export const punch = base
  .extend('punch')
  // Colors
  .property('primary.color', '#F17289')
  .property('primary.font', 'Pacifico')

  // Page
  .property('page.background.color', '#FFFDF8')

  // Text
  .property('text.font', 'Vollkorn')
  .property('text.color', '#441920')

  // Menu
  .property('menu.background.color', '#FFFAEF')
  .property('menu.text.font', 'Vollkorn')
  .property('menu.text.color', '#F17289')
  .property('menu.text.transform', 'none')

  // Image
  .property('image.border.radius', '12px')
  .property(
    'image.default',
    'https://images.teemill.com/z77i8pv7sbjd23eh1ovvyt0yg4nspjtfey50txnjdad9mxpw.png'
  )

  // Quote
  .property('quote.text.color', '#FFFAEF')
  .property('quote.fullBackground.color', '#FF9064')

  // AnnouncementV2
  .property('announcement.v2.text.color', '#FFFAEF')
  .property('announcement.v2.background.color', '#F17289')

  // Newsletter
  .property('newsletter.text.color', '#FFFAEF')
  .property('newsletter.fullBackground.color', '#FF9064')

  // Footer
  .property('footer.text.color', '#F17289')
  .property('footer.background.color', '#FFFAEF');
