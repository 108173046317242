import {isPro} from '../../access';

export default [
  {
    path: '/studio/:customiseItUrl?/',
    name: 'Studio',
    alias: [
      '/design-your-own-t-shirt/:customiseItUrl?/',
      '/mens-custom-printed-t-shirts/',
      '/mens-custom-printed-sweaters/',
      '/mens-custom-printed-hoodies/',
      '/mens-custom-printed-baseball-tees/',
      '/mens-custom-printed-short-sleeve-baseball-tees/',
      '/mens-custom-printed-circular-economy-t-shirts/',
      '/womens-custom-printed-t-shirts/',
      '/womens-custom-printed-circular-economy-t-shirts/',
      '/womens-custom-printed-hoodies/',
      '/womens-custom-printed-vest-top/',
      '/womens-custom-printed-t-shirt-dress/',
      '/womens-custom-printed-sweaters/',
      '/womens-custom-printed-baseball-tees/',
      '/custom-printed-tote-bags/',
      '/kids-custom-printed-t-shirts/',
      '/custom-stickers/',
      '/custom-art-prints/',
      '/custom-landscape-art-prints/',
    ],
    meta: {
      title: 'Studio',
      layout: 'studio',
      access: [isPro],
      showOnSiteMap: true,
      allowPwaReload: true,
    },
    component: () => import('./StoreFrontStudioPage.vue'),
  },
  {
    path: '/custom/',
    redirect: '/design-your-own-t-shirt/',
    meta: {
      allowPwaReload: true,
    },
  },
];
