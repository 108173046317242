import {LayoutSections} from '@teemill/modules/app';
import {computedEager, useWindowScroll} from '@vueuse/core';
import {viewportSize} from '@teemill/common/helpers';

const {y: scrollY} = useWindowScroll();

export const cartConfig: LayoutSections = {
  announcement: {show: true},
  header: {
    show: true,
    fixed: true,
    overlay: false,
    width: 'container',
    height: computedEager(() => {
      if (viewportSize.isSmaller('md')) {
        return '3.5rem';
      }

      if (scrollY.value === 0) {
        return '5rem';
      }

      return '4rem';
    }),
    logo: true,
    logoMaxWidth: '220px',
    centerLogo: () => viewportSize.isSmaller('md'),
    content: true,
  },
  sidebar: {
    show: () => viewportSize.isSmaller('md'),
    fixed: false,
    full: true,
    width: '220px',
  },
  content: {show: true, width: '100%'},
  footer: {show: true},
  subFooter: {show: true},
  navBar: false,
};
