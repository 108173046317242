<script setup lang="ts">
import {provide, inject, ref, computed, watch} from 'vue';
import {useElementSize, useWindowSize} from '@vueuse/core';
import {faTimes} from '@fortawesome/pro-light-svg-icons/faTimes';
import {
  ComputedLayoutSections,
  LayoutSections,
  LayoutOption,
} from '../types/layoutOptions';
import {maxDevice, updateViewportSize} from '@teemill/common/helpers';
import {mapValues} from 'lodash';
import {useRouter} from 'vue-router';
import {App} from '../classes/app';
import {Ref} from 'vue';

performance.mark('app-layout-setup');

const windowSize = useWindowSize();
watch(windowSize.width, () => updateViewportSize());

const props = defineProps<{
  layout: LayoutSections;
}>();

/**
 * Layout Options
 */
const sidebarOpen = ref(false);
const contentElement = ref<HTMLElement | undefined>(undefined);
const contentSize = useElementSize(contentElement);

const options = computed(() => {
  const computedOptions: ComputedLayoutSections<LayoutSections> = mapValues<
    LayoutSections,
    LayoutOption<any>
  >(props.layout, section => {
    if (section === false) {
      return {
        show: false,
      };
    }

    return mapValues(section, option =>
      option instanceof Function ? option() : option
    );
  });

  if (maxDevice('lg') && computedOptions) {
    computedOptions.sidebar.fixed = false;
    sidebarOpen.value = false;
  }

  return computedOptions;
});

const router = useRouter();

watch(
  () => router.currentRoute.value,
  () => {
    if (!maxDevice('lg') || !options.value.sidebar.fixed) {
      sidebarOpen.value = false;
    }
  },
  {
    immediate: true,
  }
);

const visibleContentHeight = computed(() => {
  const hasHeader =
    !options.value.header.overlay &&
    options.value.header.height &&
    operatorMode?.value === 'default';

  return `calc(${windowSize.height.value}px - ${
    hasHeader ? options.value.header.height : '0px'
  })`;
});

provide('layoutOptions', options);
provide('layoutToggleSidebar', () => (sidebarOpen.value = !sidebarOpen.value));
provide('contentWidth', () => contentSize.width);

const app = inject<App>('app');
const operatorMode = inject<Ref<'default' | 'headless'>>('operatorMode');
</script>

<template>
  <div
    id="app-layout"
    class="layout"
    :style="{
      '--content-width': `${contentSize.width.value}px`,
      '--visible-content-height': visibleContentHeight,
    }"
  >
    <div
      v-if="options.announcement.show && operatorMode === 'default'"
      class="announcement"
      :class="{
        inset: options.sidebar.full && options.sidebar.fixed,
      }"
    >
      <slot name="announcement" v-bind="{options}" />
    </div>
    <header
      v-if="options.header.show && operatorMode === 'default'"
      class="header transition-all z-header"
      :class="{
        'sticky top-0': options.header.fixed,
      }"
      :style="{
        height: options.header.overlay ? '0' : options.header.height,
      }"
    >
      <div
        class="drop-shadow w-full"
        :class="{
          inset: options.sidebar.full && options.sidebar.fixed,
        }"
        :style="{
          paddingLeft:
            options.sidebar.fixed && options.sidebar.full
              ? options.sidebar.width
              : '',
        }"
      >
        <slot name="header" v-bind="{options}" />
      </div>
    </header>
    <aside
      v-if="options.sidebar.show && operatorMode === 'default'"
      class="sidebar fixed left-0 w-0"
      :class="{
        'z-sidebar-low': !options.sidebar.full,
        'z-sidebar-high': options.sidebar.full,
        full: options.sidebar.full,
        open: options.sidebar.fixed || sidebarOpen,
      }"
      :style="{
        top: options.sidebar.full ? '0' : options.header.height,
        bottom: options.navBar.show ? options.navBar.height : '0',
      }"
    >
      <transition name="fade">
        <div
          v-if="sidebarOpen && !options.sidebar.fixed"
          class="fixed top-0 left-0 right-0 bottom-0 opacity-20 bg-black transition-opacity"
          @click="sidebarOpen = false"
        />
      </transition>
      <div
        class="slide overflow-auto"
        :class="{shadow: app && !app?.isTeemill}"
        :style="{
          width: options.sidebar.width,
        }"
      >
        <div
          v-if="options.sidebar.full && !options.sidebar.fixed"
          class="cursor-pointer absolute top-0 right-0 p-2"
          @click="sidebarOpen = false"
        >
          <font-awesome-icon class="w-6 h-6" :icon="faTimes" />
        </div>
        <slot name="sidebar" v-bind="{options, open: sidebarOpen}" />
      </div>
    </aside>
    <main
      v-if="options.content.show"
      ref="contentElement"
      class="content flex"
      :style="{
        marginLeft:
          operatorMode === 'default' && options.sidebar.fixed
            ? options.sidebar.width
            : '',
        minHeight: visibleContentHeight,
      }"
    >
      <div
        class="container grow"
        :style="{
          padding: options.content.width !== 'container' ? '0px' : undefined,
          maxWidth:
            options.content.width !== 'container'
              ? options.content.width
              : undefined,
        }"
      >
        <slot name="content" v-bind="{options}" />
      </div>
    </main>
    <footer
      v-if="options.footer.show && operatorMode === 'default'"
      class="footer z-footer"
      :style="{
        marginLeft: options.sidebar.fixed ? options.sidebar.width : '',
      }"
    >
      <slot name="footer" v-bind="{options}" />
    </footer>
    <footer
      v-if="options.subFooter.show && operatorMode === 'default'"
      class="subfooter z-subfooter"
      :style="{
        marginLeft: options.sidebar.fixed ? options.sidebar.width : '',
      }"
    >
      <slot name="subfooter" v-bind="{options}" />
    </footer>
    <nav
      v-if="options.navBar.show && operatorMode === 'default'"
      :style="{
        height: options.navBar.height,
      }"
    >
      <div class="fixed bottom-0 z-subfooter-nav-bar">
        <slot name="nav-bar" v-bind="{options}" />
      </div>
    </nav>

    <div class="absolute z-100">
      <slot name="global" v-bind="{options}" />
    </div>
  </div>
</template>

<style lang="scss">
body {
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.sidebar {
  .slide {
    position: absolute;
    transform: translateX(-100%);
    transition: transform 0.3s;
    height: 100%;
  }

  &.open {
    .slide {
      transform: translateX(0%);
    }
  }

  &.fixed {
    .slide {
      position: relative;
    }
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    border-radius: $border-radius;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: $border-radius;
  }
}
</style>
