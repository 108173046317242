/**
 * Inserts an element into the head of the document
 */
export function insertElementIntoHead(
  tagName: string,
  id: string | null
): HTMLElement {
  const newEl = document.createElement(tagName);

  if (id) {
    newEl.id = id;
  }

  document.getElementsByTagName('head')[0].appendChild(newEl);

  return newEl;
}
