/**
 * @name formatCurrency
 */
export const formatCurrency = (
  value: number | string,
  thousandsSeparator: string,
  decimalPoint: string
): string =>
  value
    .toString()
    .replace('.', decimalPoint || '.')
    .replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator || ',');
