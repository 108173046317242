import {captureException, captureMessage, configureScope} from '@sentry/vue';
import {formatUrl, isLocalhost} from '@teemill/common/helpers';
import {toUint8Array} from '@teemill/utilities';
import {http} from './http';

export interface PushSubscriber {
  type: string;
  identifier: number;
}

export const isSupported = (): boolean => {
  return (
    navigator.serviceWorker &&
    window.PushManager &&
    window.Notification &&
    (window.location.protocol === 'https:' ||
      (isLocalhost() && window.location.port === '8080'))
  );
};

const getPermissionLevel = (): NotificationPermission => {
  return isSupported() ? window.Notification.permission : 'default';
};

const vapidKey = (): string => {
  return 'BATCO0CicWpmqB+xdxYxAvRx9sqK0aucS0cKsPfyCjo9KPEBWcv2nb2Sw0EZU1NfjPMn22994C+JNhsPApPYsFs=';
};

const createSubscription = async (): Promise<PushSubscription | null> => {
  if (!isSupported()) {
    return null;
  }

  const serviceWorker = await navigator.serviceWorker.ready;

  try {
    return serviceWorker.pushManager.subscribe({
      applicationServerKey: toUint8Array(vapidKey()),
      userVisibleOnly: true,
    });
  } catch (error) {
    captureException(error);

    return null;
  }
};

const store = async (
  subscription: PushSubscription,
  subscriber?: PushSubscriber
): Promise<void> => {
  await http.post(formatUrl('/omnis/v3/notifications/subscriptions/'), {
    subscription: subscription.toJSON(),
    ...(subscriber || {}),
  });
};

const hasPermission = (): boolean => {
  return getPermissionLevel() === 'granted';
};

const getServiceWorker =
  async (): Promise<ServiceWorkerRegistration | null> => {
    return isSupported() ? navigator.serviceWorker.ready : null;
  };

const getSubscription = async (): Promise<PushSubscription | null> => {
  const serviceWorker = await getServiceWorker();

  if (serviceWorker === null) {
    return null;
  }

  return serviceWorker.pushManager.getSubscription();
};

const subscribe = async (subscriber?: PushSubscriber): Promise<void> => {
  let subscription = await getSubscription();

  if (subscription === null) {
    subscription = await createSubscription();
  }

  if (subscription === null) {
    captureMessage('Unable to create push subscription for user');

    return;
  }

  store(subscription, subscriber);
};

export const usePush = () => ({
  isSupported,
  hasPermission,
  getPermissionLevel,
  getServiceWorker,
  getSubscription,
  subscribe,
});
