import {BaseClass} from './baseClass';

export default class Thumbnail extends BaseClass {
  constructor(image) {
    super();

    this.original = image?.original;
    this.position = image?.position;
    this.altText = image?.altText;
    this.color = image?.color;

    if (image?.id) {
      this.id = image.id;
      delete image.id;
    }

    this.sizes = image;
  }

  size(size) {
    if (!this.sizes) {
      return null;
    }

    if (this.sizes[size]) {
      return this.sizes[size];
    }

    let closestSizeDelta = null;
    let closestSize = null;

    Object.keys(this.sizes).forEach(thumbnailSize => {
      const currentSizeDelta = Math.abs(size - thumbnailSize);

      if (closestSize === null || currentSizeDelta < closestSizeDelta) {
        closestSizeDelta = currentSizeDelta;
        closestSize = thumbnailSize;
      }
    });

    return this.sizes[closestSize];
  }
}
