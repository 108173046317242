import {ref, onMounted, onBeforeUnmount} from 'vue';
import {useRegisterSW} from 'virtual:pwa-register/vue';
import {isNavigationFailure, Router} from 'vue-router';
import {captureException} from '@sentry/vue';

export interface UsePwaRefreshOptions {
  /**
   * @note TL;DR: Don't set this to less than 60_000, it will break things...
   *
   * Workbox-window optimises worker updates with a time-based heuristic algorithm.
   * If the worker is updated too frequently (under 1 min) the update events will
   * be handled differently, which causes issues with event listeners in the plugin we use.
   *
   * @see https://vite-plugin-pwa.netlify.app/examples/vue.html
   */
  updateIntervalMs?: number;
}

export const usePwaRefresh = (
  router: Router,
  options: UsePwaRefreshOptions = {}
) => {
  const {
    updateIntervalMs = 3_600_000, // 1 hour
  } = options;

  /**
   * Periodic service worker update
   */
  useRegisterSW({
    onRegistered(registration) {
      if (!registration) {
        return;
      }

      setInterval(() => {
        registration.update(); // Installs a new worker when change is detected in cached files
      }, updateIntervalMs);
    },
  });

  /**
   * Lifecycle hooks
   */
  const appNeedsReload = ref(false);
  const hasActiveWorker = ref(false);

  onMounted(() => {
    if (navigator.serviceWorker) {
      // Clean up registrations
      navigator.serviceWorker.getRegistrations().then(registrations => {
        for (const registration of registrations) {
          const serviceWorkerUrl = registration?.active?.scriptURL;

          if (!serviceWorkerUrl) {
            return;
          }

          if (!serviceWorkerUrl.includes('sw.js')) {
            registration.unregister();
          }
        }
      });

      hasActiveWorker.value = !!navigator.serviceWorker.controller;

      // Reload window when a new worker is activated
      navigator.serviceWorker.addEventListener(
        'controllerchange',
        onNewWorkerReady
      );
    }
  });

  onBeforeUnmount(() => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.removeEventListener(
        'controllerchange',
        onNewWorkerReady
      );
    }
  });

  function onNewWorkerReady() {
    if (hasActiveWorker.value) {
      appNeedsReload.value = true;

      console.log('PWA Refresh: New Worker Ready');
      // reloadWindow();
    }
  }

  /**
   * Reload window
   */
  const reloadingWindow = ref(false);

  function reloadWindow() {
    console.log('Reload Window: PWA Refresh');

    if (!reloadingWindow.value) {
      reloadingWindow.value = true;
      window.location.reload();
      reloadingWindow.value = false;
    }
  }

  /**
   * Catch failed reload and try to reload on the next navigation.
   */
  router.afterEach(async (to, from, failure) => {
    try {
      if (
        appNeedsReload.value &&
        to.path !== from.path && // skip navigation events that are not triggered by path change, e.g. when opening a popup in page editor
        !isNavigationFailure(failure) // skip failed navigation events, e.g. when user cancels navigation from confirm-leave popup
      ) {
        reloadWindow();
      }
    } catch (e) {
      captureException(e);
    }
  });

  return {appNeedsReload};
};
