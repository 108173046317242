export default [
  {
    path: '/teemail/:page',
    name: 'teemail',
    props: true,
    meta: {
      title: 'Teemail',
      breadcrumb: {
        type: 'page',
        parent: 'home',
      },
      layout: 'mail',
    },
    component: () => import('./TeemailPage.vue'),
  },
  {
    path: '/subscription-verified',
    name: 'teemail.subscription-verified',
    props: true,
    meta: {
      title: 'Subscription Verified',
      layout: 'studio',
    },
    component: () => import('./SubscriptionVerified.vue'),
  },
];
