<script>
import {h} from 'vue';

export default {
  name: 'TmlStage',

  props: {
    name: {
      type: String,
      required: false,
    },

    title: {
      type: String,
      required: false,
    },

    height: {
      type: String,
      default: 'auto',
    },
  },

  render() {
    if (this.$slots.default) {
      return h(
        'div',
        {
          class: 'tml-stage',
          style: {
            height: this.height,
          },
        },
        this.$slots.default()
      );
    }

    return null;
  },
};
</script>
