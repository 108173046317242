import {formatUrl} from '../../helpers';
import {BaseClass} from '../../classes/baseClass';
import Thumbnail from '../../classes/thumbnail';

/**
 * User.js
 *
 * Defines the standard structure for a User object.
 *
 * @author Aidan Brookes <aidan@teemill.com>
 */
export default class User extends BaseClass {
  static modelName = 'user';

  constructor({
    id,
    email,
    username,
    avatar,
    fullName,
    lastName,
    firstName,
    totalOkrCompletion,
    okrStatusColour,
    mission,
    mutedAt,

    login,
    twoFactorAuthEnabled,
    hasSavedPassword,
    mobilePhoneNumber,
    status,
    bio,
    dateOfBirth,
    team,
    colour,
    reportsTo,
    channel,
    size,
    isLeadership,
    lastActive,
    rfids,
    rfidCodes,
    okrs,
    permissions,
    storeControlRole,

    photoPath,
    profilePhotoThumbnail,
    profilePhotoWidth,
    profilePhotoHeight,

    streetAddress,
    city,
    county,
    postCode,

    nationalInsuranceNumber,
    bankAccountName,
    bankName,
    sortCode,
    bankAccountNumber,
    employeeStatementOption,
  } = {}) {
    super();

    this.id = id;
    this.email = email;
    this.username = username;
    this.name = fullName;
    this.lastName = lastName;
    this.firstName = firstName;
    this.photo = profilePhotoThumbnail;
    this.photoPath = photoPath;
    this.avatar = Thumbnail.map(avatar);
    this.okrStatusColour = okrStatusColour;
    this.mission = mission;
    this.mutedAt = mutedAt;

    this.streetAddress = streetAddress;
    this.city = city;
    this.county = county;
    this.postCode = postCode;

    this.nationalInsuranceNumber = nationalInsuranceNumber;
    this.bankAccountName = bankAccountName;
    this.bankName = bankName;
    this.sortCode = sortCode;
    this.bankAccountNumber = bankAccountNumber;
    this.employeeStatementOption = employeeStatementOption;

    this.login = login;
    this.twoFactorAuthEnabled = twoFactorAuthEnabled;
    this.password = '';
    this.hasSavedPassword = hasSavedPassword;
    this.passwordConfirmation = '';
    this.mobilePhoneNumber = mobilePhoneNumber;
    this.status = status;
    this.bio = bio;
    this.dateOfBirth = dateOfBirth;
    this.isLeadership = isLeadership;
    this.lastActive = lastActive;
    this.team = team
      ? {
          ...team,
          text: team.name,
          value: team.id,
        }
      : {
          text: 'None selected',
          value: '',
        };

    this.colour = colour
      ? {text: colour.colour, value: colour.id}
      : {text: 'None selected', value: ''};
    this.reportsTo = reportsTo
      ? {text: reportsTo.fullName, value: reportsTo.id}
      : {text: 'None selected', value: ''};
    this.channel = channel
      ? {text: channel.name, value: channel.id}
      : {text: 'None selected', value: ''};
    this.size = size
      ? {text: size, value: size}
      : {text: 'None selected', value: ''};

    this.rfids = rfids;
    this.rfidCodes = rfidCodes;

    this.okrs = okrs;
    this.totalOkrCompletion = totalOkrCompletion;

    this.permissions = permissions;
    this.storeControlRole = storeControlRole;

    this.photoWidth = profilePhotoWidth;
    this.photoHeight = profilePhotoHeight;
  }

  /**
   * @name active
   * @description Get the active logged in user
   *
   * @return {User}
   */
  static get active() {
    return User.map($store.state?.auth?.user);
  }

  get isDev() {
    return this.team && this.team.code === 'engineering';
  }

  get statusSelect() {
    if (this.status === 0) {
      return {
        text: 'Enabled',
        value: 0,
        selected: true,
      };
    } else if (this.status === null) {
      return {
        text: 'Pending',
        value: null,
        selected: true,
      };
    }

    return {text: 'Disabled', value: -1, selected: true};
  }

  set statusSelect(input) {
    this.status = input.value;
  }

  mute() {
    return $axios
      .post(formatUrl(`/omnis/v3/atlas/users/${this.id}/mute/`))
      .success(data => {
        this.mutedAt = data.mutedAt;
      })
      .handle(403, () => {
        snackbar.error('Permission denied');
      })
      .validation()
      .oops();
  }

  unmute() {
    return $axios
      .post(formatUrl(`/omnis/v3/atlas/users/${this.id}/unmute/`))
      .success(data => {
        this.mutedAt = data.mutedAt;
      })
      .handle(403, () => {
        snackbar.error('Permission denied');
      })
      .validation()
      .oops();
  }
}
