/**
 * @name escapeJSON
 * @description escapes double quotes for json, only if not already escaped
 */
export const escapeJSON = (string: string): string => {
  if (string) {
    // remove excess quote marks
    string = string.replace(/("")+/g, '"');

    return string.replace(/([^\\])(\\\\)*"|^"/g, match => {
      if (match.length > 1) {
        return `${match.charAt(0)}\\"`;
      }

      return '\\"';
    });
  }

  return '';
};
