import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';
import LiveChatBlock from './LiveChatBlock.vue';

export const liveChat = new BlockType({
  name: 'liveChat',
  title: 'Live Chat',
  compatibility: ['page'],
  component: LiveChatBlock,
  builder: () => BlockBuilder.create('liveChat'),
});
