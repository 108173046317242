import {Font} from '../../classes/font';
import {uniq} from 'lodash';
import WebFont from 'webfontloader';
import {fonts} from './fonts';

/**
 * @name fontLoader
 */
const loadedFonts = new Set();

export const fontLoader = (inputs = []) => {
  const webLoadInput = {
    fontactive: familyName => {
      $eventBus.emit('webfonts-loaded', familyName);
    },

    fontinactive: () => {
      $eventBus.emit('webfonts-failed-to-load');
    },
  };

  // Get the unique fonts which need to be loaded
  const unloadedFonts = uniq(inputs).filter(
    fontName => !loadedFonts.has(fontName)
  );

  unloadedFonts.forEach(fontName => {
    loadedFonts.add(fontName);

    let font;

    if (typeof fontName === 'string') {
      font = fonts.find(f => f.name === fontName);
    }

    if (font === undefined) {
      font = new Font({name: fontName, type: 'custom'});
    }

    /**
     * Adds ?display=swap to the last font in the array so that it is at the end of the request url:
     * https://fonts.googleapis.com/css?family=Lato:400,700|Poppins:400,700&display=swap
     */
    if (fontName === unloadedFonts[unloadedFonts.length - 1]) {
      font.display = 'swap';
    }

    if (!webLoadInput[font.source]) {
      webLoadInput[font.source] = {
        families: [],
        urls: [],
      };
    }

    webLoadInput[font.source].families.push(font.asString);

    if (font.url) {
      webLoadInput[font.source].urls.push(font.url);
    }
  });

  WebFont.load(webLoadInput);
};
