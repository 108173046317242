import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const card = new BlockType({
  name: 'card',
  title: 'Card',
  compatibility: ['page'],
  component: () =>
    import(/* webpackChunkName: "CardBlock" */ './CardBlock.vue'),
  builder: () => BlockBuilder.create('card'),
});
