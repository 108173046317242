import {ratio} from '@teemill/utilities';
import {range} from 'lodash';
import {SrcSetDimension} from '../types/srcSetDimension';
import {SrcSetDimensions} from '../types/srcSetDimensions';

export const createDimensionsForEachStep = (
  width: number,
  height: number,
  stepSize: number
): SrcSetDimensions => {
  const r = ratio(width, height);

  return range(stepSize, width + stepSize, stepSize).map<SrcSetDimension>(
    width => ({
      width: Math.round(width) || 0,
      height: Math.round(width / r) || 0,
    })
  );
};
