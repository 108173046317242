import {base} from './base';

export const clay = base
  .extend('clay')
  // Colors
  .property('primary.color', '#b85b3c')
  .property('primary.font', 'Suranna')

  // Page
  .property('page.background.color', '#d8ad9a')

  // Headings
  .property('heading1.transform', 'none')
  .property('heading2.transform', 'none')
  .property('heading3.transform', 'none')

  // Text
  .property('text.font', 'Varela')
  .property('text.color', '#23313e')

  // Menu
  .property('menu.background.color', '#b85b3c')
  .property('menu.text.font', 'Suranna')
  .property('menu.text.color', '#f9ddd2')
  .property('menu.text.transform', 'none')

  // Image
  .property('image.border.radius', '12px')
  .property(
    'image.default',
    'https://images.teemill.com/dmayps0qu2hb7zgyfvxp253q98s4eorpupkwsa3kapngrogl.png'
  )

  // Buttons
  .property('button.border.radius', '12px')

  // Quote
  .property('quote.text.color', '#f9ddd2')
  .property('quote.fullBackground.color', '#b85b3c')

  // AnnouncementV2
  .property('announcement.v2.text.color', '#D8AD9A')
  .property('announcement.v2.background.color', '#23313e')

  // Newsletter
  .property('newsletter.text.color', '#D8AD9A')
  .property('newsletter.fullBackground.color', '#23313E')

  // Footer
  .property('footer.text.color', '#D8AD9A')
  .property('footer.background.color', '#B85B3C');
