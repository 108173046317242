<script setup>
const reloadPage = () => {
  window.location.reload();
};
</script>

<template>
  <tml-full-page-banner
    class="!bg-transparent"
    title="Oops..."
    subtitle="Looks like something went wrong"
    action-url="#"
    action-text="Reload"
    hide-learn-more
    height="100vh"
    :action="reloadPage"
  />
</template>
