/**
 * @name stripHtmlWhitespace
 */
export const stripHtmlWhitespace = (html: string): string => {
  // trim whitespace from start/end
  const trimmedHtml = html.trim();

  // remove empty <p> tags
  const strippedHtml = trimmedHtml.replace(/<p> *<\/p>/g, '');

  return strippedHtml;
};
