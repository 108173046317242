export default [
  {
    path: '/store-under-review/',
    name: 'store-under-review',
    meta: {
      title: 'Store Under Review',
      layout: 'blank',
      allowPwaReload: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "PageStoreUnderReview" */ './StoreUnderReview.vue'
      ),
    // prefetchChunks: ['TmlGlobal'],
  },
];
