import breadcrumbTypes from './breadcrumb-types';
import {escapeJSON} from '@teemill/utilities';

let $store = null;
let $router = null;

export const breadcrumbs = {
  install(app, {store, router}) {
    app.config.globalProperties.$breadcrumbs = this.breadcrumbs;

    $store = store;
    $router = router;
  },

  breadcrumbs: {
    breadcrumbTypes,

    get(route) {
      let breadCrumbs = [];

      if (!route) {
        route = $router.currentRoute.value;
      }

      if (route.meta && route.meta.breadcrumb) {
        const routeBreadcrumbData = route.meta.breadcrumb;

        if (routeBreadcrumbData.parent) {
          const parentRoute = $router.resolve({
            name: routeBreadcrumbData.parent,
          });

          if (parentRoute) {
            const parentBreadcrumbs = this.get(parentRoute);

            if (parentBreadcrumbs) {
              breadCrumbs = [...breadCrumbs, ...parentBreadcrumbs];
            }
          }
        }

        const breadcrumb = this.breadcrumbTypes.find(
          breadcrumbType =>
            breadcrumbType.name === (routeBreadcrumbData.type || 'page')
        );

        const formattedBreadcrumb = breadcrumb.get({
          store: $store,
          route,
          breadcrumbMeta: routeBreadcrumbData,
        });

        return [
          ...breadCrumbs,
          ...(Array.isArray(formattedBreadcrumb)
            ? formattedBreadcrumb
            : [formattedBreadcrumb]),
        ];
      }

      const breadcrumb = this.breadcrumbTypes.find(
        breadcrumbType => breadcrumbType.name === 'page'
      );

      return [breadcrumb.get({store: $store, route})];
    },

    getLdJSON() {
      const host = `${location.protocol}//${location.hostname}`;
      const breadcrumbs = this.get();
      const ldJSON = [];

      let position = 1;
      breadcrumbs.forEach(breadcrumb => {
        if (breadcrumb && breadcrumb.href && breadcrumb.text) {
          ldJSON.push(`{
            "@type": "ListItem",
            "position": ${position},
            "item": {
              "@id": "${host}${breadcrumb.href}",
              "name": "${escapeJSON(breadcrumb.text)}"
            }
          }`);

          position += 1;
        }
      });

      return ldJSON.join(',');
    },
  },
};
