import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const customisationCatalog = new BlockType({
  name: 'customisationCatalog',
  title: 'Catalog',
  compatibility: ['page'],
  component: () => import('./CustomisationCatalogBlock.vue'),
  builder: () => BlockBuilder.create('customisationCatalog'),
});
