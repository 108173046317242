<template>
  <div
    class="tml-image block"
    :class="{
      'cursor-pointer': $attrs.onClick,
      disabled,
      ratio,
    }"
    :style="{
      height,
      maxWidth,
      minHeight,
      paddingBottom: `${ratio * 100}%`,
      borderRadius,
    }"
    @click="handleClick"
  >
    <div :key="src" class="h-full w-full">
      <tml-overlay
        v-if="overlayOpacity > 0"
        class="!absolute inset-0"
        :colors="overlayColors"
        :opacity="overlayOpacity"
        :direction="overlayDirection"
      />
      <picture>
        <source
          v-if="webPSrcSet"
          type="image/webp"
          :[srcsetAttributeName]="webPSrcSet"
          :data-sizes="lazyLoad && webPSrcSet ? 'auto' : undefined"
        />
        <img
          v-if="src"
          ref="image"
          :class="{
            'h-full': maxHeight || ratio,
            lazyload: lazyLoad,
          }"
          :style="getImageStyles"
          :alt="alt"
          :[srcAttributeName]="formatUrl(src)"
          :[srcsetAttributeName]="imgSrcSet"
          :data-sizes="lazyLoad && imgSrcSet ? 'auto' : undefined"
          @load="onImageLoad"
          @error="onImageFail"
        />
      </picture>
      <div
        v-if="$slots.default"
        class="slot-content"
        :style="{
          backgroundColor: overlayColour,
        }"
      >
        <!--

          Pass content to overlay the image

        -->
        <slot />
      </div>
    </div>
    <div
      v-show="!loaded"
      class="w-full h-full"
      style="position: absolute; top: 0; left: 0; bottom: 0; right: 0"
      :style="{
        backgroundColor: placeholderColor,
      }"
    />
  </div>
</template>

<script>
import 'lazysizes';

import {Vector} from '@teemill/common/classes';
import {formatUrl, isImageApi, image} from '@teemill/common/helpers';

import TmlOverlay from '../effects/TmlOverlay.vue';
import {default as overlayable} from '../../mixins/overlayable';

export default {
  name: 'TmlImage',

  components: {
    TmlOverlay,
  },

  mixins: [overlayable],

  inheritAttrs: false,

  compatConfig: {
    INSTANCE_LISTENERS: false,
  },
  props: {
    /**
     * Required alt text for the image
     */
    alt: {
      type: String,
      required: true,
    },

    /**
     * Static height for the image to use
     *
     * @description Using this will make the image take on the appearance of a
     *              background image, and will hide overflow to maintain this
     *              height value
     */
    height: {
      type: String,
    },

    /**
     * Maximum width that the image can stretch to
     */
    maxWidth: String,
    minHeight: String,
    maxHeight: String,

    /**
     * The image src you want to use
     */
    src: {
      type: [Object, String],
      required: true,
    },

    /**
     * Causes the target image to grey out, and emits a 'disabled' event
     * when clicked
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Used to apply CSS filters to the image.
     */
    imageStyles: {
      type: Object,
      default: () => ({}),
    },

    overlayColour: String,

    /**
     * Force the image to be a set ratio.
     */
    ratio: {
      type: Number,
    },

    /**
     * Object fill CSS property for the image to assume
     */
    objectFit: {
      type: String,
      default: 'cover',
    },

    objectPosition: {
      type: String,
      default: 'center',
    },

    /**
     * Only start loading the image when it is within the viewport.
     *
     * ! SEO warning
     *
     * ! Must not be used for important content that can be crawled by bots.
     * ! This includes all of the Store front and any Teemill pages that don't require login.
     *
     * ! Bots DO NOT scroll so any content below the fold will be invisible to them if using
     * ! lazyloading. This will negativly affect SEO as crawlers need to see the content to rank it.
     */
    lazyLoad: {
      type: Boolean,
      default: false,
    },

    /**
     * Image srcSet
     */
    srcSet: Array,

    borderRadius: String,

    placeholderColor: {
      type: String,
      default: '#eee',
    },
  },

  data() {
    return {
      loaded: false,
    };
  },

  computed: {
    getImageStyles() {
      return {
        maxHeight: this.maxHeight,
        maxWidth: this.maxWidth,
        objectFit: this.objectFit,
        objectPosition: this.objectPosition,
        ...this.imageStyles,
      };
    },

    webPSource() {
      if (this.isImageApi) {
        return image(this.src).webp();
      }

      return null;
    },

    isImageApi() {
      if (!this.src || typeof this.src.match !== 'function') {
        return false;
      }

      return isImageApi(this.src);
    },

    webPSrcSet() {
      return this.generateSrcSet(this.webPSource) || this.webPSource;
    },

    imgSrcSet() {
      return this.generateSrcSet(this.src);
    },

    srcAttributeName() {
      return this.lazyLoad ? 'data-src' : 'src';
    },

    srcsetAttributeName() {
      return this.lazyLoad ? 'data-srcset' : 'srcset';
    },
  },

  beforeMount() {
    /**
     * ? If the DOM exists before mount assume prerendered therefore loaded.
     */
    if (this.$el) {
      this.loaded = true;
    }
  },

  methods: {
    formatUrl,

    generateSrcSet(src) {
      if (this.isImageApi && (this.srcSet || this.lazyLoad)) {
        return image(src).srcSet(this.srcSet);
      }

      return null;
    },

    onImageLoad() {
      this.loaded = true;
      this.$emit('loaded', this.$refs.image);
    },

    onImageFail() {
      this.loaded = true;
      this.$emit('failed', this.$refs.image);
    },

    handleClick(e) {
      if (this.$attrs.onClick && !this.disabled) {
        this.$emit('click', e);
      }

      if (this.$attrs.onDisabled && this.disabled) {
        this.$emit('disabled', e);
      }
    },

    getSize() {
      if (this.$refs.image) {
        const imageSize = new Vector(
          this.$refs.image.width,
          this.$refs.image.height
        );

        const originalImageSize = new Vector(
          this.$refs.image.naturalWidth,
          this.$refs.image.naturalHeight
        );

        const scale = Math.max(
          imageSize.x / originalImageSize.x,
          imageSize.y / originalImageSize.y
        );

        const resizedImageSize = originalImageSize.copy().multiply(scale);

        const originOffset = imageSize
          .copy()
          .subtract(resizedImageSize)
          .divide(2);

        return {
          scale,
          originOffset,
          display: imageSize,
          resized: resizedImageSize,
          original: originalImageSize,
        };
      }

      return {
        scale: 1,
        originOffset: new Vector(0, 0),
        display: new Vector(0, 0),
        resized: new Vector(0, 0),
        original: new Vector(0, 0),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.tml-image {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  &.disabled {
    filter: grayscale(1);
    opacity: 0.5;
  }

  .slot-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  img {
    min-width: 100%;
    min-height: 100%;
  }

  &.ratio {
    position: relative;

    img {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}
</style>
