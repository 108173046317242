export const getTextMetrics = (text: string, font: string): TextMetrics => {
  const cnv = document.createElement('canvas');
  const ctx = cnv.getContext('2d');

  if (!ctx) {
    throw new Error('Failed to get context for canvas');
  }

  ctx.font = font;

  const measurement = ctx.measureText(text);

  if (!measurement) {
    throw new Error('Failed to get text metrics for string');
  }

  return measurement;
};
