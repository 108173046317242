import axios from 'axios';
import {castArray} from 'lodash';
import {configureScope, captureException} from '@sentry/vue';

/**
 * @name Api Response
 * @description ApiResponse is an extension of promises with some
 *              extra methods to make axios requests easier. You do
 *              not need to directly construct this class all axios
 *              responses will be returned as ApiResponse.
 *
 * @example - Basic usage
 *
 * In this example we handle any successful responses using the .success method,
 * then using the .any method we can handle all responses that aren't a success.
 *
 * this.axios
 *   .get('http://www.mocky.io/v2/5e4ec0342f000011fc16aaf8')
 *   .success((data) => {
 *     console.log('Success! The response contained:', data);
 *   })
 *   .any((data) => {
 *     console.log('The response contained:', data)
 *   });
 *
 *
 * @example - Handling a specific response code
 *
 * To handle a specific response code we can use .handle and pass the http
 * code as the first param. As a given response only gets handled once we
 * can then use .any to catch all remaining responses.
 *
 * this.axios
 *   .get('http://www.mocky.io/v2/5e4ec0342f000011fc16aaf8')
 *   .success((data) => { ... })
 *   .handle(418, (data) => {
 *     console.log(
 *       'Fail!',
 *       'The failed response contained:', data,
 *     );
 *   })
 *   .any((data) => { ... });
 *
 *
 * @example - Handling form validation
 *
 * For a short hand way of handling Laravel validation we can use the
 * .validation method which will catch any 422 responses and provide
 * the first validation message as well as the response body.
 *
 * this.axios
 *   .get('http://www.mocky.io/v2/5e4ec0342f000011fc16aaf8')
 *   .success((data) => { ... })
 *   .validation((message, data) => {
 *     console.log(
 *      'Validation Failed!',
 *      'First first validation message is:', message,
 *      'The failed response contained:', data,
 *     );
 *   })
 *   .any((data) => { ... });
 *
 *
 * @example - Returning an output from the response handler (.then)
 *
 * If you need to return data from the response chain, we can return from
 * each of the handlers and then use the .output method to catch the output
 * and return it as a new promise.
 *
 * this.axios
 *   .get('http://www.mocky.io/v2/5e4ec0342f000011fc16aaf8')
 *   .success((data) => {
 *     return 'succeeded';
 *   })
 *   .handle(418, (data) => {
 *     return 'failed with 418';
 *   })
 *   .any((data) => {
 *     return 'failed';
 *   })
 *   .output()
 *   .then((result) => {
 *     console.log('My request', result);
 *   });
 *
 *
 * @example - Returning an output from the response handler (async/await)
 *
 * For a more concise way of returning data from a response chain use async await.
 *
 * const output = await this.axios
 *   .get('http://www.mocky.io/v2/5e4ec0342f000011fc16aaf8')
 *   .success((data) => {
 *     return 'succeeded';
 *   })
 *   .handle(418, (data) => {
 *     return 'failed with 418';
 *   })
 *   .any((data) => {
 *     return 'failed';
 *   })
 *   .output();
 *
 * console.log('My request', result);
 *
 *
 * @example - Handling a range of specific http codes
 *
 * If you need to handle multiple specific response codes with the
 * same logic you can pass .handle an array of codes instead of just one.
 *
 * this.axios
 *   .get('http://www.mocky.io/v2/5e4ec0072f000078fc16aaf7')
 *   .success((data) => { ... })
 *   .handle([ 418, 420 ], (data) => {
 *     console.log('Request failed with 418 or 420 or any 300');
 *   })
 *   .any((data) => { ... });
 *
 *
 * @example - Handling errors with snackbars
 *
 * A error snackbar helper, that will handle any 4xx or 5xx error code with
 * an error dialog
 *
 * this.axios
 *   .get('http://www.mocky.io/v2/5e4ec0072f000078fc16aaf7')
 *   .success((data) => { ... })
 *   .oops();
 *
 *
 * @example - Handling a broad range of http codes
 *
 * If you need to handle a group of response codes e.g. all 500s or all 300s
 * then we can pass .handle a string where x can be any number. So '3xx' will
 * match all 300 response codes.
 *
 * this.axios
 *   .get('http://www.mocky.io/v2/5e4ec0072f000078fc16aaf7')
 *   .success((data) => { ... })
 *   .handle('5xx', (data) => {
 *     console.log('Request failed with any 500 error');
 *   })
 *   .any((data) => { ... });
 *
 *
 * @example - Handling cancelled requests
 *
 * You can specify a handler to perform an action in the event that
 * the user or the browser cancels a request min-execution
 *
 * this.axios
 *   .get('http://www.mocky.io/v2/5e4ec0072f000078fc16aaf7', {
 *     cancelToken: new this.axios.CancelToken((command) => {
 *       // this.cancelRequest can now be called as a method
 *       // to cancel the outgoing request and trigger the
 *       // .cancelled() handler
 *       this.cancelRequest = command;
 *     }),
 *   })
 *   .cancelled() => { ... });
 *
 */
export class ApiResponse extends Promise {
  handled = false;
  outputs = [];
  unhandledError;

  /**
   * @name Matches Codes
   * @description Checks if the given code matches the codes given
   *
   * @param {number|string|array} matchers List of codes to match with
   * @param {number|string}       code     A code to match
   *
   * @returns {boolean} Did the code match
   *
   * @examples Matchers:
   *  '2xx'          : Will match any 200 code
   *  '21x'          : Will match any 210 code
   *  200            : Will match 200 code
   *  [200, 500]     : Will match 200 or 500 code
   *  ['4xx', '5xx'] : Will match any 400 or any 500 code
   *  '*'            : Will match any code
   */
  static matchCodes(matchers, code) {
    return castArray(matchers)
      .map(matcher => matcher.toString().replace(/x/g, '[0-9]'))
      .some(matcher => matcher === '*' || new RegExp(matcher).test(code));
  }

  /**
   * @name Output
   * @description Used to get the last output from any handlers
   *
   * @returns {Promise}
   */
  output() {
    return new ApiResponse((resolve, reject) =>
      this.catch(reject).finally(() => {
        try {
          if (this.unhandledError) {
            return reject(this.unhandledError);
          }

          return resolve(
            this.outputs
              .filter(o => o !== null)
              .slice(-1)
              .pop()
          );
        } catch (error) {
          configureScope(scope => {
            scope.setExtra('instance of promise', this instanceof Promise);
            scope.setExtra(
              'instance of apiResponse',
              this instanceof ApiResponse
            );
          });

          captureException(error);

          return resolve(null);
        }
      })
    );
  }

  /**
   * @name Success
   * @description Handle a successful http response
   *
   * @param {handleResponseCallback} callback Callback to run when the response matches
   * @param {boolean}                handle   Should the handler handle the response
   *
   * @return {ApiResponse}
   */
  success(callback, handle = true) {
    return this.handle('2xx', callback, handle);
  }

  /**
   * @name Handle
   * @description Handles a failed http response
   *
   * @param {number|string}           code     Http response code to match
   * @param {handleResponseCallback}  callback Callback to run when the response matches
   * @param {boolean}                 handle   Should the handler handle the response
   *
   * @return {ApiResponse}
   */
  handle(code, callback, handle = true) {
    const handleResponse = new ApiResponse((resolve, reject) => {
      const handler = response => {
        if (response && !this.handled) {
          if (ApiResponse.matchCodes(code, response.status)) {
            if (handle) {
              this.handled = true;
            }

            this.outputs.push(callback(response.data, response));
          }
        }

        handleResponse.handled = this.handled;
        handleResponse.outputs = this.outputs;
        handleResponse.unhandledError = this.unhandledError;

        return this.handled;
      };

      this.then(response => {
        handler(response);

        return resolve(response);
      }).catch(err => {
        const isCancelError = axios.isCancel(err);

        if (err) {
          let response = err.response;

          if (isCancelError) {
            response = {status: 0, message: 'cancel'};
          }

          if (handler(response) && (err.isAxiosError || isCancelError)) {
            return resolve(err);
          }
        }

        return reject(err);
      });
    });

    return handleResponse;
  }

  /**
   * @name Any
   * @description Handles any remaining responses that haven't already been caught
   *
   * @param {handleResponseCallback} callback Callback to run when the response matches
   * @param {boolean}                handle   Should the handler handle the response
   *
   * @return {ApiResponse}
   */
  any(callback, handle = true) {
    return this.success(callback).handle('*', callback, handle);
  }

  /**
   * @name Throw
   * @description Throw a custom error on response
   *
   * @param {number|string}           code     Http response code to match
   * @param {handleResponseCallback}  callback Callback to run when the response matches
   *
   * @return {ApiResponse}
   */
  throw(code, callback) {
    /**
     * TODO - Figure out why we can't just use this
     */
    // return this.handle(code, async () => {
    //   throw await callback();
    // });
    const throwResponse = new ApiResponse((resolve, reject) =>
      this.then(response => {
        throwResponse.handled = this.handled;
        throwResponse.outputs = this.outputs;

        resolve(response);
      }).catch(error => {
        if (ApiResponse.matchCodes(code, error?.response?.status)) {
          throwResponse.unhandledError = callback(error.response);

          return reject(throwResponse.unhandledError);
        }

        throwResponse.unhandledError = this.unhandledError;

        return reject(error);
      })
    );

    return throwResponse;
  }

  /**
   * @name Validation
   * @description Handles validation http responses
   *
   * @param {handleValidationResponseCallback} callback Callback to run when the response matches
   *
   * @return {ApiResponse}
   */
  validation(callback = null) {
    return this.handle(422, (data, response) => {
      let message = data.message;

      if (data.errors) {
        message = Object.values(data.errors)[0][0];
      }

      if (callback) {
        return callback(message, data, response);
      }

      return snackbar.error(message);
    });
  }

  /**
   * @name ThrowValidation
   * @description Throw a custom error for validation http responses
   *
   * @param {handleValidationResponseCallback} callback Callback to run when the response matches
   *
   * @return {ApiResponse}
   */
  throwValidation(callback) {
    return this.throw(422, response => {
      const data = response.data;
      let message = data.message;

      if (data.errors) {
        message = Object.values(data.errors)[0][0];
      }

      return callback(message, data, response);
    });
  }

  /**
   * @name Oops
   * @description Error snackbar helper
   *
   * @param {string} message The snackbar message
   *
   * @return {ApiResponse}
   */
  oops(message = 'Oops.. looks like something went wrong') {
    return this.handle(500, () => {
      snackbar.error(message);
    }).handle(['4xx', '5xx'], data => {
      snackbar.error(data.message || message);
    });
  }

  /**
   * @name Cancelled
   * @description Handle the cancelled event
   *
   * @param {handleResponseCallback}  callback Callback to run when the response matches
   * @param {boolean}                 handle   Should the handler handle the response
   *
   * @return {ApiResponse}
   */
  cancelled(callback, handle = true) {
    return this.handle(
      0,
      response => (callback ? callback(response) : undefined),
      handle
    );
  }

  /**
   * @name Accepted
   * @description Handle the accepted event
   *
   * @param {handleResponseCallback}  callback Callback to run when the response matches
   * @param {boolean}                 handle   Should the handler handle the response
   *
   * @return {ApiResponse}
   */
  accepted(callback, handle = true) {
    return this.handle(
      202,
      response => (callback ? callback(response) : undefined),
      handle
    );
  }

  /**
   * @callback handleResponseCallback
   * @param {any}    Data     Response body
   * @param {object} Response Response
   */

  /**
   * @callback handleValidationResponseCallback
   * @param {string} Message  First validation message
   * @param {any}    Data     Response body
   * @param {object} Response Response
   */
}
