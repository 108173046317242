<template>
  <div
    :class="{'!h-4': !heading && !items.length}"
  >
    <h3 v-if="heading">
      {{ heading }}
    </h3>
    <div class="overflow-y-auto flex flex-wrap gap-2">
      <block-renderer
        v-for="item in items"
        :key="item.uid"
        class="max-w-min max-h-fit"
        :block="item"
      />
    </div>
  </div>
</template>

<script>
import blockMixin from '../blockMixin.js';
import BlockRenderer from '../../components/BlockRenderer.vue';

export default {
  name: 'PageBlockTagletBlock',

  components: {BlockRenderer},

  mixins: [blockMixin],

  computed: {
    heading() {
      return this.block.property('heading', 'string', '');
    },
    items() {
      return this.block.filteredItems;
    },
  },
};
</script>
