import {base} from './base';

export const angelo = base
  .extend('angelo')
  // Colors
  .property('primary.color', '#4C4C4C')
  .property('primary.font', 'Cinzel')

  // Page
  .property('page.background.color', '#FFF')

  // Text
  .property('text.font', 'Nunito')
  .property('text.color', '#000')

  // Menu
  .property('menu.background.color', '#000')
  .property('menu.text.color', '#DBDBDB')
  .property('menu.text.font', 'Cinzel')

  // Image
  .property('image.border.radius', '4px')
  .property(
    'image.default',
    'https://images.teemill.com/rzvfigjpna0zpex2zzoz03zgpeuqnliqnxsta0iddqkauagb.png'
  )

  // Buttons
  .property('button.border.radius', '4px')

  // Quote
  .property('quote.text.color', '#4C4C4C')
  .property('quote.fullBackground.color', '#DBDBDB')

  // AnnouncementV2
  .property('announcement.v2.text.color', '#4C4C4C')
  .property('announcement.v2.background.color', '#DBDBDB')

  // Newsletter
  .property('newsletter.text.color', '#DBDBDB')
  .property('newsletter.fullBackground.color', '#000')

  //Footer
  .property('footer.text.color', '#000')
  .property('footer.background.color', '#DBDBDB');
