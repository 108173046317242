import {formatUrl} from '@teemill/common/helpers';
import {Page} from '../../classes/page';

import {BlockFactorySource} from '../../classes/blockFactorySource';

export const page = new BlockFactorySource<
  {
    url: string;
    name: string;
    image: string;
  },
  {
    limit: number;
  }
>({
  name: 'page',
  request: async ({limit}) => {
    const divisionId =
      // @ts-expect-error Convert vuex to ts
      $store.state.store?.active?.id || $store.state.subdomain?.division;

    // @ts-expect-error Convert axios to ts
    return $axios
      .get(formatUrl(`/omnis/v3/divisions/${divisionId}/pages`), {
        params: {
          'page-type': 'StorePage',
        },
      })
      .success((pages: any[]) =>
        pages
          .reverse()
          .slice(0, limit as number)
          .map((p: any) => {
            const page = new Page(p);

            return {
              url: p.url,
              name: page.property('title'),
              image: page.property('ogImage'),
            };
          })
      )
      .any(() => [])
      .output() as any;
  },
  parser: rawData =>
    rawData.map(({url, name, image}) => ({
      title: name,
      images: [image],
      href: url,
    })),
  params: {
    limit: 1,
  },
});
