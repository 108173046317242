import {createStore} from 'vuex';

import {
  misc,
  currency,
  splitTest,
  subdomain,
  overlayElements,
  navigationElements,
  shipping,
  ShippingState,
  paymentProcessorState,
  PaymentProcessorState,
} from '@teemill/common/vuex';

import cart from '../vuex/cart';
import collection from '../vuex/collection';
import product from '../vuex/product';

export const store = createStore<{
  title?: string;
  subdomain?: any;
  shipping: ShippingState;
  splitTest: {
    splitTests: any[];
    forceVariations: any[];
  };
  paymentProcessor: PaymentProcessorState;
}>({
  modules: {
    misc,
    overlayElements,
    navigationElements,
    currency,
    splitTest,
    subdomain,
    cart,
    collection,
    product,
    shipping,
    paymentProcessor: paymentProcessorState,
  },
  //   // @ts-expect-error Vuex needs refactoring for better TS support
  state: {
    title: undefined,
    shipping: {christmas: {cutoffDates: {}, showContent: false}},
    splitTest: {splitTests: [], forceVariations: []},
    paymentProcessor: {type: 'teemill', accounts: {}},
  },

  mutations: {
    setTitle(state, value) {
      state.title = value;
    },
  },

  strict: import.meta.env.DEV,
});
