import {HexColor} from './colorTypes';

/**
 * @name stripHash
 * @description Strips the hash from a hex code
 */
export const stripHash = (hex: HexColor): HexColor => {
  if (!hex || typeof hex !== 'string') {
    return hex;
  }

  if (hex.charAt(0) === '#') {
    return hex.slice(1);
  }

  return hex;
};
