import {standardPageAccess} from '../../access';

export default [
  {
    path: '/returns/:orderHash?',
    name: 'returns',
    meta: {
      title: 'Returns',
      breadcrumb: {
        type: 'page',
        parent: 'home',
      },
      layout: 'standard',
      allowPwaReload: true,
    },
    access: [standardPageAccess],
    component: () =>
      import(/* webpackChunkName: "PageReturns" */ './ReturnsPage.vue'),
    // prefetchChunks: ['TmlGlobal', 'TmlForms'],
  },
  {
    path: '/edit-order/:orderHash?',
    name: 'Edit order',
    meta: {
      title: 'Edit order',
      breadcrumb: {
        type: 'page',
        parent: 'home',
      },
      layout: 'standard',
      allowPwaReload: true,
    },
    access: [standardPageAccess],
    component: () =>
      import(/* webpackChunkName: "PageReturns" */ './ReturnsPage.vue'),
    // prefetchChunks: ['TmlGlobal', 'TmlForms'],
  },
];
