import type {Store} from 'vuex';
import {base as baseTheme} from '../../theme-builder/themes/base';
import {HasTheme, Theme} from '@teemill/modules/theme-builder';
import {Page} from '@teemill/modules/page-framework';

export type AppMode = 'native' | 'preview';

export const apps: App[] = [];

export class App implements HasTheme {
  public name: string;
  public mode: AppMode;
  public pages: Page[];
  public theme: Theme;

  constructor({
    name,
    mode = 'native',
    pages = [],
  }: {
    name: string;
    mode?: AppMode;
    pages?: Page[];
  }) {
    const existingApp = apps.find(a => a.name === name);

    if (existingApp) {
      Object.assign(this, existingApp);
    }

    this.name = name;
    this.mode = mode;
    this.pages = pages;
    this.theme = baseTheme;

    if (!existingApp) {
      apps.push(this);
    }
  }

  static get(name: string): App {
    let app = apps.find(a => a.name === name);

    if (!app) {
      app = new App({name});
    }

    return app;
  }

  setMode(mode: AppMode) {
    if (mode) {
      this.mode = mode;
    }

    return this;
  }

  setTheme(theme: Theme) {
    if (theme) {
      this.theme = theme;
    }

    return this;
  }

  get isNative() {
    return top === window && this.mode === 'native';
  }

  /**
   * @name isTeemill
   * @description Is the current Vue app Teemill
   *
   * @returns {boolean}
   */
  get isTeemill() {
    return this.name === 'teemill' || this.name === 'teemill-subdomain';
  }

  /**
   * @name isAtlas
   * @description Is the current Vue app Atlas
   *
   * @returns {boolean}
   */
  get isAtlas() {
    return this.name === 'atlas';
  }

  /**
   * @name isSubdomains
   * @description Is the current Vue app a subdomain
   *
   * @returns {boolean}
   */
  get isSubdomain() {
    return this.name === 'subdomain';
  }

  public addPage(page: Page): Page {
    page.assignTo(this);

    const existingPage = this.pages.find(p => p.url === page.url);

    if (existingPage !== undefined) {
      existingPage.updateFrom(page);

      return existingPage;
    }

    if (this.mode) {
      page.setMode(this.mode);
    }

    this.pages.push(page);

    return page;
  }

  page(id?: number) {
    let page: Page | undefined;

    if (id !== undefined) {
      page = this.pages.find(p => p.id === id);
    }

    if (page === undefined) {
      page = this.pages.find(p => !p.exists);
    }

    if (page !== undefined) {
      return page.assignTo(this);
    }

    return undefined;
  }

  /**
   * @name previewHost
   * @description Get formatted domain for previewing partial links in editors
   */
  previewHost(store?: Store<any>) {
    let domain;

    if (this.isTeemill) {
      domain = store?.state.store?.active?.domain;
    } else if (this.isAtlas) {
      domain = 'teemill.com';
    }

    return domain ? `https://${domain}` : '';
  }
}
