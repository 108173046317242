import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const image = new BlockType({
  name: 'image',
  title: 'Image',
  compatibility: ['page'],
  component: () =>
    import(/* webpackChunkName: "ImageBlock" */ './ImageBlock.vue'),
  builder: () => BlockBuilder.create('image'),
});
