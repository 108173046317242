import {BaseClass} from '../../classes/baseClass';
import Thumbnail from '../../classes/thumbnail';

import FactoryLabel from '../factory/factoryLabel';
import Product from '../product/product';

/**
 * OrderItem.js
 *
 * Defines the standard structure for a OrderItem object.
 *
 * @author Aidan Brookes <aidan@teemill.com>
 */
export default class OrderItem extends BaseClass {
  static modelName = 'orderItem';

  constructor({
    id,
    tax,
    order,
    price,
    images,
    labels,
    options,
    product,
    quantity,
    xPrinted,
    parentItem,
    returnLogs,
    parentItemId,
    pigeonholeLogs,
    fulfillmentItem,
    priceTaxIncluded,
    masterBundleItem,
    optionMatrixRecord,
    discountTaxIncluded,
  } = {}) {
    super();

    this.id = id;
    this.order = order;
    this.options = options;
    this.quantity = quantity;
    this.printedCount = xPrinted;
    this.returnLogs = returnLogs;
    this.parentItemId = parentItemId;
    this.pigeonholeLogs = pigeonholeLogs;
    this.tax = parseFloat(tax);
    this.price = parseFloat(price);
    this.labels = FactoryLabel.map(labels);
    this.optionMatrixRecord = optionMatrixRecord;
    this.product = Product.map(product);
    this.images = Thumbnail.map(images);
    this.parentItem = OrderItem.map(parentItem);
    this.priceIncTax = parseFloat(priceTaxIncluded);
    this.fulfillmentItem = OrderItem.map(fulfillmentItem);
    this.masterBundleItem = OrderItem.map(masterBundleItem);
    this.discountTaxIncluded = parseFloat(discountTaxIncluded);
  }

  get pigeonholedCount() {
    return this.pigeonholeLogs ? this.pigeonholeLogs.length : 0;
  }

  get total() {
    return this.price + this.tax - this.discountTaxIncluded;
  }

  get activeQuantity() {
    let returnedCount = 0;

    if (this.returnLogs && this.returnLogs.length) {
      returnedCount = this.returnLogs.reduce(
        (returnTotal, log) => returnTotal + log.quantity,
        0
      );
    }

    const cancelledCount = this.labels.filter(
      label => label.cancelled || label.cancelledAt
    ).length;

    return this.quantity - returnedCount - cancelledCount;
  }
}
