import {bus} from '../bus';

export default (product, size, options = {}) =>
  new Promise((resolve, reject) => {
    bus.emit('add-dynamic-overlay', {
      props: {
        product,
        size,
        ...options,
      },
      component: 'tml-magic-model-popup',
      events: {
        created(result) {
          resolve(result);
        },

        close(result) {
          reject(result);
        },
      },
    });
  });
