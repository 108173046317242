import {base} from './base';

export const california = base
  .extend('california')
  // Colors
  .property('primary.color', '#F44241')
  .property('primary.font', 'Pacifico')

  // Page
  .property('page.background.color', '#ffffff')

  // Text
  .property('text.color', '#222222')
  .property('text.font', 'Montserrat')
  .property('text.shadow', 'none')

  // Menu
  .property('menu.background.color', '#FFFFFF')
  .property('menu.text.color', '#222222')
  .property('menu.text.font', 'Montserrat')

  // Image
  .property('image.border.radius', '0px')
  .property(
    'image.default',
    'https://images.teemill.com/ikxpgcregkqawqeexyuqwzrzjlkqyolbvnion1xthvsnxulk.png'
  )

  // Quote
  .property('quote.text.color', '#FFFFFF')
  .property('quote.background.color', '#222222')
  .property('quote.fullBackground.color', '#222222')

  // AnnouncementV2
  .property('announcement.v2.text.color', '#FFFFFF')
  .property('announcement.v2.text.color', '#FFFFFF')
  .property('announcement.v2.background.color', '#222222')
  .property('announcement.v2.fullBackground.color', '#0000')

  // Newsletter
  .property('newsletter.text.color', '#FFFFFF')
  .property('newsletter.background.color', '#F34241')
  .property('newsletter.fullBackground.color', '#F34241');
