import {BaseClass} from '../../classes/baseClass';

import User from '../user/user';
import Product from '../product/product';
import Order from '../order/order';
import OrderItem from '../order/orderItem';

import {dayjs} from '../../services/dayjs';

/**
 * FactoryLabel.js
 *
 * Defines the standard structure for a FactoryLabel object.
 *
 * @author Aidan Brookes <aidan@teemill.com>
 */
export default class FactoryLabel extends BaseClass {
  static modelName = 'factoryLabel';

  constructor({
    id,
    order,
    counter,
    product,
    printLogs,
    pretreatLogs,
    pickface,
    pickedAt,
    createdAt,
    orderItem,
    cancelled,
    toBePicked,
    pickedUser,
    childLabels,
    cancelledAt,
    timesPrinted,
    pigeonholeLog,
  } = {}) {
    super();

    this.id = id;
    this.counter = counter;
    this.pickface = pickface;
    this.printLogs = printLogs;
    this.pretreatLogs = pretreatLogs;
    this.cancelled = cancelled;
    this.toBePicked = toBePicked;
    this.timesPrinted = timesPrinted;
    this.pigeonholeLog = pigeonholeLog;
    this.order = Order.map(order);
    this.pickedUser = User.map(pickedUser);
    this.product = Product.map(product);
    this.childLabels = FactoryLabel.map(childLabels);
    this.orderItem = OrderItem.map(orderItem);
    this.pickedAt = pickedAt
      ? dayjs(pickedAt).format('DD/MM/YY hh:mm a')
      : null;
    this.createdAt = createdAt
      ? dayjs(createdAt).format('DD/MM/YY hh:mm a')
      : null;
    this.cancelledAt = cancelledAt
      ? dayjs(cancelledAt).format('DD/MM/YY hh:mm a')
      : null;
  }
}
