import {formatUrl} from '@teemill/common/helpers';

import {BlockFactorySource} from '../../classes/blockFactorySource';

export const drive = new BlockFactorySource<
  {
    name: string;
    thumbnails: {
      original: string;
    };
  },
  {
    limit: number;
    searchTerm: string;
  }
>({
  name: 'drive',
  request: ({limit, searchTerm}) => {
    const divisionId =
      // @ts-expect-error Convert vuex to ts
      $store.state.store?.active?.id || $store.state.subdomain?.division;

    // @ts-expect-error Convert axios to ts
    return $axios
      .get(formatUrl('/omnis/v3/artemis/query/'), {
        params: {
          macros: 'drive,imageLibrary',
          page: 1,
          perPage: limit,
          searchTerm,
          division: divisionId,
        },
      })
      .success((res: {data: any[]}) => res.data)
      .any(() => [])
      .output() as any;
  },
  parser: (rawData, {limit}) => {
    const data = rawData.map(({thumbnails: {original}}) => ({
      image: original,
    }));

    return new Array(limit).fill(undefined).map((_, index) => {
      let image = data[index]?.image;

      if (image === undefined) {
        image =
          'https://images.teemill.com/CxZwy3fLlq8eoDBp47rqlmQZbWuWOQoDUJ77hCKJ7FtteOCa.jpeg.jpg';
      }

      return {
        images: [image],
      };
    });
  },
  params: {
    limit: 1,
    searchTerm: 'type:uploaded',
  },
});
