import {formatUrl} from '@teemill/common/helpers';

import {BlockFactorySource} from '../../classes/blockFactorySource';

export const instagram = new BlockFactorySource<
  {
    content: string;
    media: Array<string>;
    socialAccount: {
      name: string;
    };
    posted: {
      formatted: string;
    };
    likes: number;
    commentCount: number;
  },
  {
    limit: number;
  }
>({
  name: 'instagram',
  request: async ({limit}) => {
    const divisionId =
      // @ts-expect-error Convert vuex to ts
      $store.state.store?.active?.id || $store.state.subdomain?.division;

    // @ts-expect-error Convert axios to ts
    return $axios
      .get(
        formatUrl(`/omnis/v3/divisions/${divisionId}/social/instagram/posts`),
        {
          params: {
            limit,
          },
        }
      )
      .success((posts: any[]) => posts)
      .any(() => [])
      .output() as any;
  },
  parser: rawData =>
    rawData.map(({media}) => ({
      images: media,
    })),
  params: {
    limit: 20,
  },
});
