import {BaseClass} from '../../classes/baseClass';

import Tag from '../tag/tag';
import User from '../user/user';
import Customer from '../customer/customer';
import Division from '../division/division';
import DivisionDemographic from '../division/divisionDemographic';
import TimeInterval from '../time/timeInterval';

/**
 * Manufacturer.js
 *
 * Defines the standard structure for a Manufacturer object.
 *
 * @author Aidan Brookes <aidan@teemill.com>
 * @deprecated
 */
export class Manufacturer extends BaseClass {
  static modelName = 'manufacturer';

  constructor({
    id,
    tags,
    name,
    logo,
    hash,
    logoThumbnail,
    email,
    isPro,
    badges,
    unlocked,
    active,
    orders,
    crmUser,
    isFraud,
    managed,
    chatChannels,
    division,
    customers,
    demographic,
    paymentInterval,
    integrationType,
    enableBulkStudio,
    force2fa,
    promoUrl,
    betas,
    paypalEmailLogs,
    isVip,
    currency,
  } = {}) {
    super();

    this.id = id;
    this.name = name;
    this.logo = logo;
    this.hash = hash;
    this.logoThumbnail = logoThumbnail;
    this.email = email;
    this.isPro = isPro;
    this.badges = badges;
    this.unlocked = unlocked;
    this.active = active;
    this.orders = orders;
    this.isFraud = isFraud;
    this.managed = managed;
    this.chatChannels = chatChannels;
    this.tags = Tag.map(tags);
    this.crmUser = User.map(crmUser);
    this.division = Division.map(division);
    this.customers = Customer.map(customers);
    this.demographic = DivisionDemographic.map(demographic);
    this.paymentInterval = TimeInterval.map(paymentInterval);
    this.integrationType = integrationType;
    this.enableBulkStudio = enableBulkStudio;
    this.force2fa = force2fa;
    this.promoUrl = promoUrl;
    this.betas = betas;
    this.paypalEmailLogs = paypalEmailLogs;
    this.isVip = isVip;
    this.currency = currency ?? 'GBP';
  }

  static get active() {
    return typeof window.$project === 'undefined'
      ? undefined
      : window.$project?.active;
  }

  /**
   * Does the active manufacturer have the specified badge
   *
   * @param {mixed} code
   *
   * @returns {boolean}
   */
  static hasBadge(code) {
    if (Manufacturer.active) {
      return Array.isArray(code)
        ? code.every(badgeCode =>
            Manufacturer.active.badges.find(
              badge => badge.unlocked && badge.code === badgeCode
            )
          )
        : Manufacturer.active.badges.find(
            badge => badge.unlocked && badge.code === code
          );
    }

    return false;
  }
}
