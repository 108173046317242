import {http} from '@teemill/common/services';
import {formatUrl} from '@teemill/common/helpers';
import {Token} from '@livechat/widget-core';

export class LiveChatProvider {
  public static getToken(): Promise<Token> {
    return http
      .get(formatUrl('/omnis/v3/live-chat/token'))
      .success(({token}: {token: Token}): Token => token)
      .output();
  }

  public static transferChat(chatId: string, groupId: string): Promise<void> {
    return http
      .post(formatUrl(`/omnis/v3/live-chat/chat/${chatId}/transfer`), {
        group_id: groupId,
      })
      .success(() => {})
      .output();
  }

  public static getGroup(chatId: string): Promise<string> {
    return http
      .get(formatUrl(`/omnis/v3/live-chat/chat/${chatId}/group`))
      .success(({group}: {group: string}) => group)
      .output();
  }

  public static getGroupStatus(groupId: string): Promise<string> {
    return http
      .get(formatUrl(`/omnis/v3/live-chat/group/${groupId}/status`))
      .success(({status}: {status: string}) => status)
      .output();
  }
}
