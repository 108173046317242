import {
  ServerError,
  NotFoundError,
  ValidationError,
  PermissionDeniedError,
  ProUpgradeRequiredError,
} from '@teemill/common/errors';

export class PageServerError extends ServerError {
  constructor(message = 'Server error encountered fetching page') {
    super(message);
  }
}

export class PageNotFoundError extends NotFoundError {
  constructor(message = 'Page not found') {
    super(message);
  }
}

export class PagePermissionDeniedError extends PermissionDeniedError {
  constructor(message = 'Insufficient permissions to access this page') {
    super(message);
  }
}

export class PageProUpgradeRequiredError extends ProUpgradeRequiredError {
  constructor(message = 'Pro required to save this page') {
    super(message);
  }
}

export class PageValidationError extends ValidationError {
  public fields: string[];

  constructor(message = 'Page failed validation', fields?: string[]) {
    super(message);

    this.fields = fields || [];
  }
}
