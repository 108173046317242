import {ref} from 'vue';
import type {Ref} from 'vue';
import {
  lcOnInit,
  lcOnDestroy,
  assignEventHandlers,
  getData,
} from '@livechat/widget-core';
import type {
  CustomerData,
  WidgetState as LiveChatWidgetState,
} from '@livechat/widget-core';

type WidgetState = Partial<LiveChatWidgetState & CustomerData> & {
  chatId?: string;
};

export function useWidgetState(): Ref<WidgetState | undefined> {
  const widgetState = ref<WidgetState>();
  let unsubscribeInit: VoidFunction | undefined = undefined;
  let unsubscribeDestroy: VoidFunction | undefined = undefined;

  const onReady = ({
    state,
    customerData,
  }: {
    state: LiveChatWidgetState;
    customerData: CustomerData;
  }) => {
    widgetState.value = state;
    widgetState.value.chatId = getData('chat')?.chatId;
    widgetState.value = {...widgetState.value, ...customerData};
  };

  const onVisibilityChanged = ({
    visibility,
  }: Pick<LiveChatWidgetState, 'visibility'>) => {
    widgetState.value = widgetState.value
      ? {...widgetState.value, visibility}
      : widgetState.value;
  };

  const onAvailabilityChanged = ({
    availability,
  }: Pick<LiveChatWidgetState, 'availability'>) => {
    widgetState.value = widgetState.value
      ? {...widgetState.value, availability}
      : widgetState.value;
  };

  const onCustomerStatusChanged = ({status}: Pick<CustomerData, 'status'>) => {
    widgetState.value = widgetState.value
      ? {...widgetState.value, status}
      : widgetState.value;
  };

  document.addEventListener('DOMContentLoaded', () => {
    unsubscribeInit = lcOnInit(() => {
      assignEventHandlers('once', {onReady});
      assignEventHandlers('on', {
        onVisibilityChanged,
        onAvailabilityChanged,
        onCustomerStatusChanged,
      });
    });

    unsubscribeDestroy = lcOnDestroy(() => {
      widgetState.value = undefined;
    });
  });

  window.addEventListener('beforeunload', () => {
    assignEventHandlers('off', {
      onReady,
      onVisibilityChanged,
      onAvailabilityChanged,
      onCustomerStatusChanged,
    });

    unsubscribeInit?.();
    unsubscribeDestroy?.();
  });

  return widgetState;
}
