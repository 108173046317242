import Vue from 'vue';
import types from './types';

export default {
  [types.REGISTER_ELEMENT](state, name) {
    Vue.set(state.elements, name, false);
  },

  [types.OPEN_ELEMENT](state, name) {
    if (typeof state.elements[name] !== 'undefined') {
      state.elements[name] = true;
    }
  },

  [types.CLOSE_ELEMENT](state, name) {
    if (typeof state.elements[name] !== 'undefined') {
      state.elements[name] = false;
    }
  },

  [types.CLOSE_ALL_ELEMENTS](state) {
    const allElements = Object.keys(state.elements);

    allElements.forEach(element => {
      state.elements[element] = false;
    });
  },

  [types.TOGGLE_ELEMENT](state, name) {
    state.elements[name] = !state.elements[name];
  },

  [types.ADD_TO_STACK](state, name) {
    if (state.stacked.indexOf(name) === -1) {
      state.stacked.push(name);
    }
  },

  [types.REMOVE_FROM_STACK](state, name) {
    state.stacked = state.stacked.filter(e => e !== name);
  },

  [types.EMPTY_STACK](state) {
    state.stacked = [];
  },
};
