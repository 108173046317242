import {bus} from '../bus';

export default (text, props) =>
  new Promise(resolve => {
    bus.emit('add-dynamic-overlay', {
      props: {
        text,
        ...props,
      },
      component: 'tml-loading-popup',
      events: {
        finished(promise) {
          resolve(promise);
        },
      },
    });
  });
