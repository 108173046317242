import {default as overlayable} from '../../mixins/overlayable';

export default {
  mixins: [overlayable],

  props: {
    title: String,

    copy: String,

    image: String,

    video: String,
    videoThumbnail: String,

    media: {
      type: String,
      default: 'image',
    },

    minHeight: {
      type: String,
      default: '400px',
    },

    verticalAlignment: {
      type: String,
      default: 'bottom',
    },

    horizontalAlignment: {
      type: String,
      default: 'left',
    },

    font: {
      type: String,
      default: 'Lato',
    },

    actions: {
      type: Array,
      default: () => [],
    },

    ratio: {
      type: Number,
      default: 0.4,
    },

    lazyLoad: {
      type: Boolean,
      default: false,
    },

    disabled: Boolean,

    srcSet: Array,

    titleType: {
      type: String,
      default: 'h1',
    },

    titleColor: {
      type: String,
      default: '#fff',
    },

    titleBackground: String,

    textShadow: {
      type: String,
      default: '0 2px 6px rgb(0 0 0 / 30%)',
    },

    overlayOpacity: {
      type: Number,
      default: 0.15,
    },

    contentWidth: {
      type: String,
      default: '95%',
    },

    copyColor: {
      type: String,
      default: '#fff',
    },

    copyBackground: String,

    buttonSize: {
      type: String,
      default: 'standard',
    },

    borderRadius: {
      type: String,
      default: '0px',
    },

    buttonBorderRadius: String,
    buttonBackgroundColor: String,

    imageAlignment: {
      type: String,
      default: 'center',
    },
  },
};
