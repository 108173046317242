import {BaseClass} from '../../classes/baseClass';

/**
 * CompanyInformation.js
 *
 * Defines the standard structure for a CompanyInformation object.
 *
 * @author Aidan Brookes <aidan@teemill.com>
 */
export default class CompanyInformation extends BaseClass {
  /**
   */
  constructor({id} = {}) {
    super();

    this.id = id;
  }
}
