<template>
  <div
    class="flex gap-2 item-tray"
    :class="{
      'flex-col': shouldWrap,
      'items-center': alignItemsCenter && !shouldWrap,
    }"
  >
    <div
      v-show="$slots.primary"
      :style="{
        flex: wideRightItem ? 0 : computedFlexRatio[0] || 1,
      }"
    >
      <slot name="primary" />
    </div>

    <div
      v-show="$slots.secondary"
      :style="{
        flex: wideLeftItem ? 0 : computedFlexRatio[1] || 1,
        flexBasis: wideLeftItem ? 'auto' : '0%',
      }"
    >
      <slot name="secondary" />
    </div>
  </div>
</template>

<script>
import {maxDevice} from '@teemill/common/helpers';
import TmlButton from '../buttons/TmlButton.vue';

/**
 * TmlItemTray is a small component wrapper that fixes the two side-by-side items
 * CSS problem with flexbox. The items will appear adjacent until the specified
 * breakpoint, at which they will wrap on top of each other.
 *
 * @example <tml-item-tray flex-ratio="1:1" wide-left-item>
    <template slot="primary">
      <tml-button primary class="w-full" text="I am Primary" href="#" @click="alert('Hello from TmlItemTray Primary')" />
    </template>
    <template slot="secondary">
      <tml-button class="w-full" text="I am Secondary" href="#" @click="alert('Hello from TmlItemTray Primary')"/>
    </template>
  </tml-item-tray>
 */

export default {
  name: 'TmlItemTray',

  components: {TmlButton},

  props: {
    /**
     * Secondary takes up as little as possible, Primary fills the rest
     */
    wideLeftItem: Boolean,

    /**
     * Primary takes up as little as possible, secondary fills the rest
     */
    wideRightItem: Boolean,

    /**
     * vertically aligns items
     */
    alignItemsCenter: Boolean,

    /**
     * The spacing to apply between items. Note that this will be applied to EACH
     * item and will make up half the space between them
     */
    spacing: {
      type: String,
      default: '0.25em',
    },

    /**
     * The point at which the component should wrap
     */
    wrapBreakpoint: {
      type: String,
      default: 'xs',
    },

    /**
     * Disable wrapping of the items completely
     */
    noWrap: {
      type: Boolean,
      default: false,
    },

    /**
     * Relative width for each item to take
     */
    flexRatio: {
      type: String,
      default: '1:1',
    },
  },

  data() {
    return {
      shouldWrap: false,
    };
  },

  computed: {
    computedFlexRatio() {
      return this.flexRatio.split(':');
    },
  },

  created() {
    if (!this.noWrap) {
      window.addEventListener('resize', this.onResize);
    }
  },

  mounted() {
    if (!this.noWrap) {
      this.onResize();
    }
  },

  beforeUnmount() {
    if (!this.noWrap) {
      window.removeEventListener('resize', this.onResize);

      this.onResize();
    }
  },

  methods: {
    onResize() {
      this.shouldWrap = maxDevice(this.wrapBreakpoint);
    },
  },
};
</script>
