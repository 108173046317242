import isbot from 'isbot';

export const features: Record<string, boolean> = {
  fontawesome: true,
  components: true,
  pages: true,
  sentry: import.meta.env.PROD,
  stripe: true,
  gtm: true,
  datadog: import.meta.env.PROD && !isbot(navigator.userAgent),
};

export const setup = () => {
  window.location.search
    .replace(/\?/, '')
    .split('&')
    .filter(q => q.startsWith('feature-'))
    .map<[string, boolean]>(q => {
      const split = q.split('=');
      return [split[0].replace(/feature-/, ''), split[1] === 'true'];
    })
    .forEach(([feature, value]) => {
      features[feature] = value;
    });
};

export const isFeatureEnabled = (name: keyof typeof features) => {
  return features[name];
};

export const enableFeature = (name: keyof typeof features) => {
  return (features[name] = true);
};

export const disableFeature = (name: keyof typeof features) => {
  return (features[name] = false);
};
