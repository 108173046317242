import {BlockBuilder} from '../classes/blockBuilder';

import {BlockFactory} from '../classes/blockFactory';
import {BlockSourceData} from '../classes/blockFactorySource';

export const pod = new BlockFactory({
  name: 'pod',
  builder: (data: BlockSourceData) => {
    return data.map(
      (
        {
          title,
          media,
          copy,
          hrefText,
          images,
          videos,
          href,
          icon,
          price,
          salePrice,
        },
        index
      ) => {
        return BlockBuilder.create('pod')
          .order(index)
          .property('title', title)
          .property('media', 'image')
          .property('copy', copy)
          .property('buttonText', hrefText || undefined)
          .property('image', images ? images[0] : undefined)
          .property('video', videos ? videos[0] : undefined)
          .property('autoplayInBackground', '1')
          .property('href', href)
          .property('icon', icon)
          .property('overlay', false)
          .property('price', price || undefined)
          .property('salePrice', salePrice || undefined);
      }
    );
  },
});
