import {canVisitLogin} from '../../access';

export default {
  path: '/login/',
  name: 'login',
  meta: {
    title: 'Login',
    access: [canVisitLogin],
    layout: 'blank',
    allowPwaReload: true,
  },
  component: () => import('./LoginPage.vue'),
};
