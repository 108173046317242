import {BaseClass} from '../../classes/baseClass';
import Thumbnail from '../../classes/thumbnail';

export default class ProductOption extends BaseClass {
  /**
   * @param {String}   name   Colour of the product option.
   * @param {object[]} sizes  Array of sizes the option has.
   * @param {object[]} images Flat product images for front and back.
   * @param {object} thumbnail HEX colour or image thumbnail for product colour selectors.
   * @param {boolean} pro   Whether the product option is a pro option.
   */
  constructor({
    name,
    sizes = [],
    images = [],
    pro = false,
    thumbnail = null,
  } = {}) {
    super();

    this.name = name;
    this.sizes = sizes;

    this.images = images
      ? images.map(image => ({
          front: new Thumbnail(image.front),
          back: new Thumbnail(image.back),
        }))
      : null;

    this.thumbnail = thumbnail ? thumbnail : {type: 'text', value: name};

    this.pro = pro;
  }

  /**
   * @name images
   * @description Get the first image of the product option.
   *
   * @return {Thumbnail}
   */
  get image() {
    if (this.images && this.images.length) {
      return this.images[0].front;
    }

    return null;
  }
}
