import {handleFailedChunk} from '@teemill/common/helpers';

export default {
  path: '/checkout/',
  name: 'Checkout',
  meta: {
    title: 'Checkout',
    layout: 'cart',
    shareToHomepage: true,
  },
  component: () =>
    import('./StoreFrontCheckoutPage.vue').catch(handleFailedChunk),
};
