import {forEach} from 'lodash';
import {App} from 'vue';

import * as components from './components';
import * as directiveDefinitions from './directives';
// import * as mixins from './mixins';

export const TeemillComponents = {
  install(app: App) {
    forEach(components, (component, name) => {
      app.component(name, component);
    });

    // TODO: register directives within components
    forEach(directiveDefinitions, definition => {
      app.directive(definition.name, definition.directive);
    });
  },
};
