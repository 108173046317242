export default {
  props: {
    overlayColors: {
      type: [Array, String],
      default: 'rgb(0, 0, 0), rgb(0, 0, 0)',
    },

    overlayDirection: {
      type: String,
      default: 'to right',
    },

    overlayOpacity: {
      type: Number,
      default: 0,
    },
  },
};
