<template>
  <div class="page-block-slideshow-block">
    <div class="inner">
      <tml-slide-show
        v-if="mode === 'slideshow'"
        :slides-per-page="1"
        pre-load-next-slide
        :slide-interval="parseInt(block.property('speed', 'number', 5000))"
        :show-navigation="block.property('navigation', 'boolean', false)"
        :show-position="block.property('navigation', 'boolean', false)"
      >
        <template v-if="block.property('template')">
          <block-renderer
            v-for="(banner, index) in banners"
            :key="index"
            :block="banner"
          />
        </template>

        <!-- ! LEGACY, Exists only to avoid v1 banners changing -->
        <template v-for="(banner, index) in banners" v-else>
          <tml-banner
            v-if="banner"
            :key="index"
            class="w-full"
            :title="banner.property('title')"
            :copy="banner.property('copy') || ''"
            :image="getImage(banner)"
            :vertical-alignment="
              banner.property('verticalAlignment', 'string', 'center')
            "
            :horizontal-alignment="banner.property('align', 'string', 'center')"
            :actions="getBannerActions(banner)"
            :lazy-load="block.order !== 0"
            :title-type="block.order === 0 ? 'h1' : 'h2'"
            :overlay-opacity="showOverlay(banner) ? 0.15 : 0"
            :src-set="[]"
            @loaded="onLoad"
          />
        </template>
      </tml-slide-show>
      <template v-else-if="singleBanner">
        <block-renderer
          v-if="block.property('template')"
          :block="singleBanner"
        />

        <!-- ! LEGACY, Exists only to avoid v1 banners changing -->
        <tml-banner
          v-else
          :title="singleBanner.property('title')"
          :copy="singleBanner.property('copy') || ''"
          :image="getImage(singleBanner)"
          :vertical-alignment="
            singleBanner.property('verticalAlignment', 'string', 'center')
          "
          :horizontal-alignment="
            singleBanner.property('align', 'string', 'center')
          "
          :actions="getBannerActions(singleBanner)"
          :lazy-load="block.order !== 0"
          :title-type="block.order === 0 ? 'h1' : 'h2'"
          :overlay-opacity="showOverlay(singleBanner) ? 0.15 : 0"
          :src-set="[]"
          @loaded="onLoad"
        />
      </template>
    </div>
  </div>
</template>

<script>
import {formatUrl, image} from '@teemill/common/helpers';
import blockMixin from '../blockMixin';

import BlockRenderer from '../../components/BlockRenderer.vue';

import {random} from 'lodash';

export default {
  name: 'PageBlockBannerBlock',

  components: {
    BlockRenderer,
  },

  mixins: [blockMixin],

  computed: {
    mode() {
      if (this.banners.length === 1) {
        return 'single';
      }

      return this.block.property('mode', 'string', 'slideshow');
    },

    singleBanner() {
      const primaryImage = this.$router.currentRoute.meta?.primaryImage?.url;

      if (this.mode === 'random') {
        const preloadableBanner = primaryImage
          ? this.banners.find(
              banner => banner.property('image')?.src === primaryImage
            )
          : null;

        return preloadableBanner || this.getRandomBanner();
      }

      return this.banners[0];
    },

    banners() {
      return this.block.filteredItems;
    },
  },

  methods: {
    showOverlay(banner) {
      return (
        banner.property('title', 'string', '') ||
        banner.property('copy', 'string', '')
      );
    },

    getImage(banner) {
      const imageSrc = banner.property('image')?.src;

      if (imageSrc) {
        return image(formatUrl(imageSrc), 1920, 768);
      }

      return '';
    },

    getBannerActions(banner) {
      const actions = banner.property('actions', 'json', []);

      return actions.map(action => {
        if (action) {
          action.primary = this.block.property(
            'primaryActions',
            'boolean',
            true
          );
        }

        return action;
      });
    },

    getRandomBanner() {
      return this.banners[random(0, this.banners.length - 1)];
    },

    onLoad() {
      if (this.block.order === 0) {
        this.emitPageBusEvent('largest-contentful-paint-loaded');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-block-slideshow-block {
  width: 100%;
  display: flex;
  justify-content: center;

  .inner {
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;
  }
}
</style>
