<template>
  <div class="page-block-text-block" :class="padding" :style="textBlockStyle">
    <document-block
      v-if="isDocument"
      :block="block"
      :parent-block="parentBlock"
      :ratio="ratio"
    />
    <template v-else>
      <tml-details
        v-if="block.property('truncateHeight')"
        class="tml-details"
        :string="html"
        :truncate-height="block.property('truncateHeight', 'string')"
      />

      <div v-else v-html="html" />
    </template>
  </div>
</template>

<script>
import {defineAsyncComponent} from 'vue';
import {fonts, fontLoader} from '@teemill/common/helpers';

import blockMixin from '../blockMixin.js';

export default {
  name: 'PageBlockTextBlock',
  components: {
    DocumentBlock: defineAsyncComponent(() => import('./DocumentBlock.vue')),
  },
  mixins: [blockMixin],
  data() {
    return {
      fonts: [],
    };
  },
  computed: {
    textBlockStyle() {
      const blockStyle = {
        backgroundColor: this.block.property(
          'backgroundColour',
          'string',
          '#0000'
        ),
        '--text-block-color': this.block.property(
          'textColor',
          'string',
          '#000000'
        ),
        borderRadius: this.block.property('borderRadius', 'string', '4px'),
      };
      if (this.block.properties.font) {
        blockStyle.fontFamily = this.block.property('font', 'string', 'lato');
      }
      return blockStyle;
    },
    padding() {
      return this.shouldApplyPadding ? 'px-4' : '';
    },
    html() {
      return this.block.property('html', 'string', null, this.bindingData);
    },
    isDocument() {
      // TODO - Optimise the document block so it can replace TextBlock completely.
      // Currently only render the html as a document if it contains a code block.
      return this.html?.match(/<pre><code/);
    },
  },
  watch: {
    font: {
      immediate: true,
      handler() {
        this.fonts = fonts;
        fontLoader(
          this.fonts.filter(
            f => f.name === this.block.property('font', 'string', 'lato')
          )
        );
      },
    },
  },
};
</script>

<style lang="scss">
.page-block-text-block {
  background-color: var(--tml-box-background-color);
  word-break: break-word;
  border-radius: $border-radius;
  min-height: 1rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: var(--text-block-color);
  }

  p {
    margin-bottom: 2rem;
  }

  .tml-details > p {
    margin-bottom: 0;

    p:last-child {
      margin-bottom: 0;
    }
  }

  blockquote > p {
    font-size: 1.25em;
    font-style: italic;
  }

  ul {
    > li {
      > p {
        margin-bottom: 0;
      }
    }
  }

  li {
    margin-left: 1.5em;
    list-style-type: circle;
    color: var(--text-block-color);
  }

  :last-child {
    margin-bottom: 0;
  }

  .tml-document {
    p {
      margin-bottom: 0rem;
    }
  }
}
</style>
