import {bus} from './bus';
import Prefabs from './prefabs';

export const overlays = {
  open(name) {
    bus.emit('open-overlay', name);
  },

  toggle(name) {
    bus.emit('toggle-overlay', name);
  },

  close(name) {
    bus.emit('close-overlay', name);
  },

  on(event, callback) {
    bus.on(event, callback);
  },

  off(event, callback) {
    bus.off(event, callback);
  },

  request(event, data, callback) {
    bus.request(event, data, callback);
  },

  reply(event, callback) {
    bus.reply(event, callback);
  },

  ...Prefabs,

  install(app) {
    app.config.globalProperties.$overlays = overlays;
  },
};
