import {base} from './base';

export const spring = base
  .extend('spring')
  // Colors
  .property('primary.color', '#ABCEDC')
  .property('primary.font', 'Libre Baskerville')

  // Page
  .property('page.background.color', '#FCFCFC')

  // Text
  .property('text.font', 'Open Sans')
  .property('text.color', '#564339')

  // Menu
  .property('menu.background.color', '#ABCEDC')
  .property('menu.text.color', '#564339')
  .property('menu.text.font', 'Libre Baskerville')

  // Image
  .property('image.border.radius', '4px')
  .property(
    'image.default',
    'https://images.teemill.com/k5svrgjzzbrahscabrkqizbvxvvfrh0ib2lvu3fz6djpu8zf.png'
  )

  // Buttons
  .property('button.border.radius', '999px')

  // Quote
  .property('quote.fullBackground.color', '#FEDCDC')

  // AnnouncementV2
  .property('announcement.v2.background.color', '#FEDCDC')

  // Newsletter
  .property('newsletter.fullBackground.color', '#FEDCDC')

  //Footer
  .property('footer.text.color', '#564339')
  .property('footer.background.color', '#ABCEDC');
