import {
  useNotifications,
  NotificationPosition,
  Notification,
} from '@teemill/modules/notifications';

export interface LiveSupportData {
  title: string;
  body: string;
  image: string;
  buttonStyle: 'default' | 'pro';
  buttonText: string;
}

const {create} = useNotifications();

const show = ({
  title,
  body,
  image,
  buttonStyle,
  buttonText,
}: LiveSupportData): Notification => {
  return create({
    id: 'live-support',
    title,
    body,
    image: image,
    position: NotificationPosition.TOPRIGHT,
    template: 'Shove',
    actions: [
      {
        text: buttonText,
        href: 'action://notifications/live-support/start-chatting',
        primary: true,
        forcePro: buttonStyle === 'pro',
      },
    ],
  });
};

export function useLiveSupport() {
  return {
    show,
  };
}
