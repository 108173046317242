<template>
  <div
    class="tml-logo flex items-center"
    :style="{
      height,
      justifyContent: center ? 'center' : 'left',
    }"
  >
    <tml-image
      v-if="internalImage"
      class="w-full h-full"
      :src="internalImage"
      :alt="internalImageAlt"
      object-fit="contain"
      :placeholder-color="imagePlaceholderColor"
      :image-styles="{
        minWidth: imageMinWidth,
        maxHeight: imageMaxHeight,
      }"
    />
    <component
      :is="logoComponentName"
      v-else
      :class="{
        'w-full': true,
      }"
      :header-align="headerAlign"
    />
  </div>
</template>

<script>
import {defineAsyncComponent, computed} from 'vue';
import {formatUrl} from '@teemill/common/helpers';
import {useHead} from '@vueuse/head';

import TmlImage from '../images/TmlImage.vue';

export default {
  name: 'TmlLogo',

  components: {
    TmlImage,
    TeemillLogo: defineAsyncComponent(() => import('./TmlTeemillLogo.vue')),
    TeemillFlowLogo: defineAsyncComponent(() => import('./TmlFlowLogo.vue')),
    TeemillProLogo: defineAsyncComponent(() =>
      import('./TmlTeemillProLogo.vue')
    ),
    AtlasLogo: defineAsyncComponent(() => import('./TmlAtlasLogo.vue')),
    NodeLogo: defineAsyncComponent(() => import('./TmlNodeLogo.vue')),
  },

  inject: ['app'],

  props: {
    image: {
      type: String,
    },

    height: {
      type: String,
    },

    center: {
      type: Boolean,
      default: false,
    },

    imageMaxHeight: {
      type: String,
      default: '100%',
    },

    imageMinWidth: String,

    /**
     * Correctly align logo for use in headers across apps
     */
    headerAlign: {
      type: Boolean,
      default: false,
    },

    imagePlaceholderColor: String,

    pro: {
      type: Boolean,
      default: false,
    },

    flow: {
      type: Boolean,
      default: false,
    },

    shopify: {
      type: Boolean,
      default: false,
    },

    teemill: {
      type: Boolean,
      default: false,
    },

    atlas: {
      type: Boolean,
      default: false,
    },

    node: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    internalImage() {
      if (this.image) {
        return this.image;
      }

      const subdomainLogo = this.$store.getters['subdomain/logo'];

      if (this.app.name === 'subdomain' && !this.teemill && subdomainLogo) {
        return formatUrl(subdomainLogo);
      }

      return null;
    },

    teemillMetaLogo() {
      return this.$store.state.logo?.url || '';
    },

    internalImageAlt() {
      if (
        this.app.name === 'subdomain' &&
        !this.teemill &&
        this.$store.state?.subdomain?.company?.name
      ) {
        return `${this.$store.state.subdomain.company.name}`;
      }

      return 'Logo';
    },

    logoComponentName() {
      if (this.teemill) {
        if (this.pro) {
          return 'TeemillProLogo';
        } else if (this.flow) {
          return 'TeemillFlowLogo';
        } else {
          return 'TeemillLogo';
        }
      } else if (this.atlas) {
        return 'AtlasLogo';
      } else if (this.node) {
        return 'NodeLogo';
      }

      return null;
    },
  },

  created() {
    useHead(
      computed(() => {
        const url = `${location.protocol}//${location.hostname}`;

        return {
          script: [
            {
              key: 'organization',
              type: 'application/ld+json',
              children: `
              {
                "@context": "http://schema.org/",
                "@type": "Organization",
                "url": "${url}",
                "logo": "${this.internalImage || this.teemillMetaLogo}"
              }
            `,
            },
          ],
        };
      })
    );
  },
};
</script>

<style lang="scss">
.st0 {
  fill: $teemill-charcoal;
}
.st1 {
  fill: $teemill-blue;
}
.st2 {
  fill: $teemill-magenta;
}
.st3 {
  fill: $teemill-yellow;
}
.st4 {
  fill: $teemill-green;
}
</style>

<style lang="scss" scoped>
.tml-logo {
  border-radius: $border-radius;
  transition: height 0.2s;
}
</style>
