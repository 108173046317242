import {useLiveChat} from '../../live-chat';
import {NotificationHandler} from '../types/notificationHandler';

export const startChatting: NotificationHandler = {
  path: 'notifications/live-support/start-chatting',
  handler: async (_, {dismiss}) => {
    const liveChat = useLiveChat();

    liveChat.startChatting();

    dismiss();
  },
};
