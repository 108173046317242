import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

import ButtonBlock from './ButtonBlock.vue';

export const button = new BlockType({
  name: 'button',
  title: 'Button',
  compatibility: ['page'],
  component: ButtonBlock,
  builder: () =>
    BlockBuilder.create('button')
      .size(6)
      .property('text', 'Button Text')
      .property('href', '#')
      .property('primary', 0),
});
