<template>
  <tml-anchor
    :href="href"
    :download="download"
    class="tml-placeholder-container block relative text-center"
    :style="{height, width}"
    @click.native="$emit('click')"
  >
    <div
      class="background absolute w-full h-full"
      :class="{
        'dot-background': dotBackground,
      }"
      :style="{
        borderRadius: borderRadius || theme().get('image.border.radius'),
        backgroundColor: backgroundColor || 'var(--tml-box-background-color)',
      }"
    />
    <canvas
      v-if="!height"
      ref="aspectCanvas"
      :width="getWidth()"
      :height="getHeight()"
      class="max-w-full"
      :class="{'default-cursor': disabled}"
    />
    <div
      class="tml-placeholder absolute flex flex-col justify-center items-center h-full p-0"
      :class="{disabled}"
    >
      <p
        v-if="icon"
        class="icon-container"
        :class="{
          big: bigIcon,
          'mb-2': text,
          'default-cursor': disabled,
        }"
      >
        <span v-if="additive" class="fa-layers">
          <font-awesome-icon v-if="icon" :icon="icon" />
          <font-awesome-icon :icon="faPlusCircle" class="additive" />
        </span>
        <font-awesome-icon v-else :icon="icon" />
      </p>
      <div v-else-if="svg" class="custom-svg-holder icon-container">
        <div class="inner">
          <img :src="svg" />
          <font-awesome-icon
            v-if="additive"
            :icon="faPlusCircle"
            class="additive"
          />
        </div>
      </div>
      <p v-if="text">
        <small :class="{bold: boldText}">{{ text }} </small>
      </p>
      <p v-if="subheading">
        <small>{{ subheading }}</small>
      </p>
      <slot />
    </div>
  </tml-anchor>
</template>

<script>
import {faPlusCircle} from '@fortawesome/pro-regular-svg-icons';
import TmlAnchor from '../links/TmlAnchor.vue';

/**
 * TmlPlaceholder is a button that fits in place of content that is yet to be filled, e.g. where an image should be inserted
 *
 * @example <tml-placeholder :icon="faStar" text="Add something" :aspect-ratio="4 / 3" />
 * @example <tml-placeholder :icon="faStar" :additive="false" text="Non-additive" :aspect-ratio="16 / 9" />
 */
export default {
  name: 'TmlPlaceholder',

  components: {TmlAnchor},

  inject: ['theme'],

  props: {
    /**
     * the text content of the button
     * @example Hello
     */
    text: String,
    /**
     * Text that can be used under the text prop, for example displaying
     * image formats (e.g "Png, Jpeg or Tiff supported").
     * @example There
     */
    subheading: String,
    /**
     * a URL to redirect to on click
     * @example /dashboard
     */
    href: {
      type: String,
      default: '#',
    },
    /**
     * a URL to download on click
     * @example /dashboard
     */
    download: {
      type: String,
      default: '#',
    },

    /**
     * a FontAwesome SVG icon that will appear in the middle of the button
     */
    icon: Object,
    /**
     * a Custom SVG that can be an alternative to a FontAwesome SVG
     */
    svg: String,
    /**
     * If additive, the provided font awesome icon will have a plus icon hovering above it
     */
    additive: {
      type: Boolean,
      default: true,
    },
    /**
     * This is the aspect ratio of the TmlPlaceholder. The element will maintain this aspect ratio at all screen sizes.
     */
    aspectRatio: {
      type: Number,
      default: 1,
    },

    /**
     * Define a static height for the placeholder
     */
    height: {
      type: String,
    },

    /**
     * Define a static width for the placeholder
     */
    width: {
      type: String,
    },

    /**
     * Increase size of the icon and additive
     */
    bigIcon: Boolean,

    /**
     * Set placeholder as disabled
     */
    disabled: Boolean,

    borderRadius: String,
    backgroundColor: String,

    dotBackground: Boolean,

    boldText: Boolean,
  },

  data() {
    return {
      faPlusCircle,
    };
  },

  computed: {
    inputListeners() {
      // eslint-disable-next-line
      const vm = this;
      return Object.assign({}, this.$listeners, {
        click(event) {
          vm.$parent.$emit('click', event);
          vm.$emit('click', event);
        },
      });
    },
  },

  mounted() {
    this.applyAspectRatio();
  },

  methods: {
    applyAspectRatio() {
      const cnv = this.$refs.aspectCanvas;
      if (cnv) {
        cnv.width = this.getWidth();
        cnv.height = this.getHeight();
      }
    },
    getWidth() {
      return this.aspectRatio * 400;
    },
    getHeight() {
      return 400;
    },
  },
};
</script>

<style lang="scss" scoped>
.tml-placeholder-container {
  .background {
    top: 0;
    left: 0;
  }

  .tml-placeholder {
    border: none;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
  }

  .custom-svg-holder {
    width: 2em;
    height: 2em;
    margin: auto;
  }

  .custom-svg-holder .inner {
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .icon-container {
    font-size: 1.5em;

    &.big {
      font-size: 3rem;
    }

    @include media-breakpoint-down(sm) {
      &.big {
        font-size: 2.4rem;
      }
    }
  }

  .fa-layers,
  .custom-svg-holder .inner {
    position: relative;
  }

  .fa-layers svg.additive,
  .custom-svg-holder .inner svg.additive {
    position: absolute;
    bottom: -60%;
    right: -80%;
    background-color: var(--tml-box-background-color);
    font-size: 60%;
    border-radius: 100%;
    border: 1px solid var(--tml-box-background-color);
  }

  .custom-svg-holder .inner svg.additive {
    bottom: -4px;
    right: 0;
  }

  .disabled {
    opacity: 0.2;
  }

  .default-cursor {
    cursor: default !important;
  }
}

.dot-background {
  width: 100%;
  height: 100%;
  background-image: radial-gradient(var(--tml-border-color) 8%, transparent 10%),
    radial-gradient(var(--tml-border-color) 8%, transparent 10%);
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;
  background-repeat: repeat;
}
</style>
