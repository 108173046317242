import {captureException} from '@sentry/vue';

export const handleFailedChunk = async e => {
  if (import.meta.env.DEV) {
    throw e;
  }

  const TmlStoragePackage = import('../classes/tmlStorage');

  // we vary the session storage key by error message as that will include the
  // chunk number, making sure we only record repeated failurs of the same chunk
  // base64 encode to make sure it uses only compatible characters
  const encodedError = btoa(e.message);
  // vary by day
  const day = new Date().getDate();
  const storageKey = `${day}-${encodedError}`;

  let sessionStorage = null;

  await TmlStoragePackage.then(({TmlStorage}) => {
    sessionStorage = TmlStorage.session;
  });

  const failedReloadAttempts = sessionStorage.get(storageKey, 0);

  if (failedReloadAttempts >= 3) {
    captureException(
      new Error(
        `Chunk repeatedly failed to load at ${location.href}: ${e.message}`
      )
    );
  } else {
    sessionStorage.set(storageKey, failedReloadAttempts + 1);

    location.reload();
  }
};
