<template>
  <div
    class="tml-ratio"
    :style="{
      paddingBottom: `${ratio * 100}%`,
      height: ratio ? null : 'auto',
      minHeight
    }"
  >
    <div
      class="content h-full"
      :style="{
        position: ratio ? 'absolute' : 'relative',
      }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TmlRatio',

  props: {
    ratio: {
      type: Number,
      default: 1,
    },
    minHeight: String,
  },
};
</script>

<style lang="scss" scoped>
.tml-ratio {
  position: relative;
  height: 0;

  > .content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
</style>
