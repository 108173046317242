import {BlockBuilder} from '../../classes/blockBuilder';
import {BlockType} from '../../classes/blockType';

export const affiliateAttribution = new BlockType({
  name: 'affiliateAttribution',
  title: 'affiliate attribution',
  compatibility: ['page'],
  component: () =>
    import(
      /* webpackChunkName: "AffiliatesBlocks" */ './AffiliateAttributionBlock.vue'
    ),
  builder: () => BlockBuilder.create('affiliateAttribution'),
});
