import {base} from './base';

export const popart = base
  .extend('popart')
  // Colors
  .property('primary.color', '#05965F')
  .property('primary.font', 'Fredoka One')

  // Text
  .property('text.font', 'Comfortaa')
  .property('text.color', '#404040')
  .property('text.shadow', 'none')

  // Menu
  .property('menu.background.color', '#FE077A')
  .property('menu.text.font', 'Fredoka One')
  .property('menu.text.color', '#FFFFFF')
  .property('menu.text.transform', 'none')

  // Image
  .property('image.border.radius', '12px')
  .property(
    'image.default',
    'https://images.teemill.com/lxbkk9pmjxnjrpql68t7hszve5uhmi99m45iqr4baty5os01.png'
  )

  // Quote
  .property('quote.text.shadow', 'none')
  .property('quote.background.color', '#FCBC0E')
  .property('quote.fullBackground.color', '#FCBC0E')

  //AnnouncementV2
  .property('announcement.v2.background.color', '#FCBC0E')
  .property('announcement.v2.fullBackground.color', '#0000')

  // Newsletter
  .property('newsletter.text.color', '#FFFFFF')
  .property('newsletter.background.color', '#FE077A')
  .property('newsletter.fullBackground.color', '#FE077A')

  // Banners
  .property('banner.titleColor', '#fff')
  .property('banner.titleBackgroundColor', '#0000')
  .property('banner.copyColor', '#fff')
  .property('banner.copyBackgroundColor', '#0000')

  // Buttons
  .property('button.borderRadius', '8px')

  // Other
  .property('displayFont', 'Montserrat')
  .property('dropShadow', 'none')
  .property('textShadow', 'none');
